import { connect } from "react-redux";
import Switch from "react-switch";

import Lang from "../../LanguageSelect/Lang";

const handleChange = (value, setValues) => {
	setValues({ systemsConfigBeepActive: value });
};

function BeepAlert({ systemsConfigBeepActive, editAuth, setValues }) {
	if (systemsConfigBeepActive !== null) {
		return (
			<span className="setting beep-selector scrollable-dropdown separated-alignment-parent switch-setting">
				<span className="title separated-alignment-left">
					<Lang en="Beep alert" fi="Piippaushälytys" sv="Pip larm" />
				</span>
				<span className="separated-alignment-right">
					<Switch
						disabled={!editAuth}
						onChange={(value) => handleChange(value, setValues)}
						checked={systemsConfigBeepActive}
						onHandleColor="#5cb75c"
						onColor="#b1dbaf"
						height={13}
						width={33}
						handleDiameter={19}
						boxShadow="1px 1px 3px 1px rgba(0, 0, 0, 0.3)"
						uncheckedIcon={false}
						checkedIcon={false}
					/>
				</span>
			</span>
		);
	}
	return null;
}

function mapStateToProps(state) {
	const { systemsConfigBeepActive } = state;
	return { systemsConfigBeepActive };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeepAlert);