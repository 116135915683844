import "./Blueprint.css";

import { Card } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";
import LoggerComponent from "./LoggerComponent";

const onDragOver = (event) => {
	event.preventDefault();
};

export default function LoggerList({ loggers, bp, lang }) {
	const loggerImages = [];
	loggers.forEach(logger => {
		if (!logger.blueprint || logger.projectId !== bp.projectId || logger.systemId !== bp.systemId) {
			loggerImages.push(<LoggerComponent key={logger.loggerId}
				logger={logger}
				cssClass="loggerFigure" />);
		}
	});
	return (
		<Card className="projectLoggerListPanel">
			<Card.Header>
				<Lang lang={lang} fi="Loggerit" sv="Loggare" en="Loggers" />
			</Card.Header>
			<Card.Body className="projectLoggerList"
				onDragOver={onDragOver} onDrop={onDragOver}>
				{loggerImages}
			</Card.Body>
		</Card>
	);
}