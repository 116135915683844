import { Button } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

export default function getFooterButtons({
	newView,
	deleteView,
	regenView,
	tokenView,
	passwordResetView,
	setDeleteViewState,
	setRegenViewState,
	setPasswordResetViewState,
	submit,
	closeModal,
	deleteFunc,
	valid,
	regenFunc,
	passwordResetFunc,
	userMatch,
	disableDelete,
	disableRegen
}) {
	const buttons = [];
	if (!deleteView && !tokenView && !regenView && !passwordResetView) {
		if (!newView && !userMatch && !disableDelete) {
			buttons.push(
				<Button
					className="open-deleteView"
					key="open-deleteView"
					variant="danger"
					onClick={() => setDeleteViewState(true)}
				>
					<Lang en="Delete" fi="Poista" sv="Ta bort" />
				</Button>
			);
		}

		if (!newView && regenView !== undefined && !disableRegen) {
			buttons.push(
				<Button
					className="open-regenView"
					key="open-regenView"
					variant="light"
					onClick={() => setRegenViewState(true)}
				>
					<Lang en="Regen" fi="Uudista" sv="Förnya" />
				</Button>
			);
		}

		if (!newView && passwordResetView !== undefined) {
			buttons.push(
				<Button
					className="open-passResetView"
					key="open-passResetView"
					variant="warning"
					onClick={() => setPasswordResetViewState(true)}
				>
					<Lang en="Reset" fi="Resetoi" sv="Återställ" />
				</Button>
			);
		}

		buttons.push(
			<Button
				key="ok"
				variant="success"
				onClick={submit}
				disabled={!valid}
			>
				<Lang en="OK" fi="OK" sv="OK" />
			</Button>,
			<Button
				key="cancel"
				variant="light"
				onClick={closeModal}
			>
				<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
			</Button>
		);
	} else if (tokenView)
		buttons.push(
			<Button
				key="cancelTokenView"
				variant="success"
				onClick={closeModal}
			>
				<Lang en="OK" fi="OK" sv="OK" />
			</Button>
		);
	else if (regenView)
		buttons.push(
			<Button
				className="regenButton"
				key="regenButton"
				variant="danger"
				onClick={regenFunc}
			>
				<Lang en="Regenerate" fi="Uudista" sv="Förnya" />
			</Button>,
			<Button
				key="cancelRegenView"
				variant="light"
				onClick={() => setRegenViewState(false)}
			>
				<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
			</Button>
		);
	else if (passwordResetView)
		buttons.push(
			<Button
				className="passResetButton"
				key="passResetButton"
				variant="warning"
				onClick={passwordResetFunc}
			>
				<Lang en="Reset" fi="Resetoi" sv="Återställ" />
			</Button>,
			<Button
				key="cancelPassResetView"
				variant="light"
				onClick={() => setPasswordResetViewState(false)}
			>
				<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
			</Button>
		);
	else
		buttons.push(
			<Button
				className="deleteButton"
				key="deleteButton"
				variant="danger"
				onClick={deleteFunc}
			>
				<Lang en="Delete" fi="Poista" sv="Ta bort" />
			</Button>,
			<Button
				key="cancelDeleteView"
				variant="light"
				onClick={() => setDeleteViewState(false)}
			>
				<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
			</Button>
		);

	return buttons;
};