import "../css/DeviceNameEditor.css";

import { Component, createRef } from "react";
import { Accordion, Card, Form, Overlay, Table, Tooltip } from "react-bootstrap";
import { isDesktop } from "react-device-detect";
import { IconContext } from "react-icons";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import Lang from "./LanguageSelect/Lang";

export default class DeviceNameEditor extends Component {
	constructor() {
		super();

		this.myRef = createRef();
	}

	state = {
		show: false,
		clickedIn: false,
		editId: undefined,
		text: "",
	};

	handleItemClick = (id, text) => {
		this.setState({ editId: id, text });
	};

	handleTextChange = (e) => {
		const text = e.target.value.replace(/[+,:;|]/g, "");
		this.setState({ text });
	};

	getItems = (devices) => {
		const { editId, text } = this.state;
		let items = [];
		const keys = Object.keys(devices);
		keys.sort((a, b) => {
			if (devices[a].index === undefined) {
				return 1;
			}
			if (devices[b].index === undefined) {
				return -1;
			}
			return devices[a].index - devices[b].index;
		});
		if (keys.length > 0) {
			items = keys.map(device => {
				if (editId !== device) {
					const name = devices[device].description === undefined ? devices[device].n : devices[device].description;
					return (
						<tr
							key={`deviceNameItem_${device}`}
							className="deviceNameItem"
							onClick={this.handleItemClick.bind(this, device, name)}
						>
							<td>{name}</td>
						</tr>
					);
				}
				return (
					<tr
						key={`deviceNameItem_${device}`}
						className="deviceNameItem"
					>
						<td>
							<Form.Control
								type="text"
								value={text}
								onChange={this.handleTextChange}
								onBlur={this.handleBlur}
							/>
						</td>
					</tr>
				);

			});
		}
		return items;
	};

	toggleShow = () => {
		const { show, clickedIn } = this.state;
		if (!show && !clickedIn) {
			this.setState({ show: true, clickedIn: true });
		} else {
			this.setState({ show: false, clickedIn: false });
		}
	};

	handleBlur = () => {
		const { deviceSettings, updateAncestor } = this.props;
		const { editId, text } = this.state;
		if (deviceSettings) {
			// Making deep copy of dataset settings
			const deviceSettingsCopy = JSON.parse(JSON.stringify(deviceSettings));
			// Initilize device specific settings if not  defined
			if (deviceSettingsCopy[editId] === undefined) {
				deviceSettingsCopy[editId] = {};
			}
			// Specify device name
			if (text !== "") {
				deviceSettingsCopy[editId].deviceName = text;
			} else if (deviceSettingsCopy[editId] && deviceSettingsCopy[editId].deviceName !== undefined) {
				delete deviceSettingsCopy[editId].deviceName;
			}
			updateAncestor({ deviceSettings: deviceSettingsCopy });
		}
		this.setState({ editId: undefined, text: "" });
	};

	render() {
		const { devices, lang, selectedSystem } = this.props;
		const { show } = this.state;
		return Array.isArray(devices) || devices === undefined || Object.keys(devices).length === 0 ? null : (
			<div className="deviceNameEditor">
				<Accordion
					id="accordianPanel"
					className="menuPanel deviceNamePanel"
				>
					<Accordion.Button as={Card.Header} ref={this.myRef} onClick={this.toggleShow}>
						<Lang lang={lang}>
							<div key="en">Device names</div>
							<div key="fi">Laitteiden nimet</div>
							<div key="sv">Aparat namn</div>
						</Lang>
						<IconContext.Provider value={{ className: "reactIcon-alert" }}>
							<div className='alert-icon-background' />
							<BsExclamationOctagonFill />
						</IconContext.Provider>
					</Accordion.Button>
					<Overlay target={this.myRef.current} show={show} placement={isDesktop ? "right" : "top"} rootClose onHide={() => this.setState({ show: false })}>
						{(props) => (
							<Tooltip id="overlay-alert" {...props}>
								<Lang
									en="It's possible to modify the names of the devices directly in the system "
									fi="Laitteiden nimet on mahdollista muokata suoraan järjestelmään "
									sv="Det är möjligt att ändra namnen på enheterna direkt i systemet "
								/>
								<Link to={`/systems?systemId=${selectedSystem.id}`}>
									<Lang en="here" fi="tästä" sv="här" />
								</Link>
								<Lang
									en=". The names edited here can only be saved in the settings template, but not in the system or elsewhere in the cloud service."
									fi=". Tässä muokatut nimet voidaan tallentaa vain asetuspohjaan, mutta ei järjestelmään tai muualle pilvipalveluun."
									sv=". Namnen som redigeras här kan bara sparas i inställningsmallen, men inte i systemet eller någon annanstans i molntjänsten."
								/>
							</Tooltip>
						)}
					</Overlay>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<Table
								className="deviceNameTable"
								striped hover bordered
							>
								<tbody>
									{this.getItems(devices)}
								</tbody>
							</Table>
						</Card.Body>
					</Accordion.Collapse>
				</Accordion>
			</div>
		);
	}
}