import "../css/FontSizeSelector.css";

import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

export default function FontSizeSelector({ updateAncestor, fontSize }) {
	const ToggleFontSize = (event) => {
		if (updateAncestor) {
			updateAncestor({ fontSize: event });
		}
	};
	return (
		<span className="fontSizeWrapper">
			<Lang>
				<div className="subtitle" key="en">Graph text size:</div>
				<div className="subtitle" key="fi">Graafin tekstin koko:</div>
				<div className="subtitle" key="sv">Graftextstorlek:</div>
			</Lang>
			<div className="fontSizeFlexer">
				<ToggleButtonGroup type="radio" name="options" value={fontSize} onChange={ToggleFontSize}>
					<ToggleButton id="tbg-radio-small" value={1} variant="outline-dark" style={{ marginLeft: "0px" }}>
						aA
					</ToggleButton>
					<ToggleButton id="tbg-radio-medium" value={2} variant="outline-dark">
						aA
					</ToggleButton>
					<ToggleButton id="tbg-radio-large" value={3} variant="outline-dark">
						aA
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
		</span>
	);
};