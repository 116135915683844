import { Table } from "react-bootstrap";
import rfdc from "rfdc";

import Lang from "../LanguageSelect/Lang";
import { langString } from "../LanguageSelect/LanguageSelect";

const rfdclone = rfdc();

const getRightTableDisplayName = ({ right, lang }) => {
    const displayNames = {
        projectsSome: langString({
            lang,
            strings: {
                fi: "Pääsy yksittäisiin projekteihin",
                en: "Access to individual projects",
                sv: "Tillgång till enskilda projekt",
            }
        }),
        supportCustomers: langString({
            lang,
            strings: {
                fi: "Asiakastuki",
                en: "Customer support",
                sv: "Kundstöd",
            }
        }),
        admin: langString({
            lang,
            strings: {
                fi: "Käyttäjien hallinta",
                en: "User management",
                sv: "Användarhantering",
            }
        }),
        system: langString({
            lang,
            strings: {
                fi: "Laitteiden hallinta",
                en: "Device management",
                sv: "Enhetshantering",
            }
        }),
        reports: langString({
            lang,
            strings: {
                fi: "Asetuspohjien hallinta",
                en: "Settings template management",
                sv: "Inställningsmallhantering",
            }
        }),
        project_admin: langString({
            lang,
            strings: {
                fi: "Projektin asetuksien hallinta",
                en: "Project settings management",
                sv: "Hantering av project inställningar",
            }
        }),
    };
    if (right !== undefined && displayNames[right] !== undefined) {
        return displayNames[right];
    }
    return right;
};

const getUserRights = (user, lang) => {
    if (user.rights !== undefined) {
        const { supportCustomers, projectsSome } = user;
        let { rights } = user;
        if (supportCustomers !== undefined && supportCustomers.length > 0) {
            rights = [...rights, "supportCustomers"];
        }
        if (projectsSome !== undefined && Object.keys(projectsSome).length > 0) {
            rights = [...rights, "projectsSome"];
        }
        return rights.map(right => getRightTableDisplayName({ right, lang })).join(", ");
    }
    return "";
};

const parseRows = (lang, customers, customerId, select) => {
    let entries = [];
    if (
        customers !== undefined &&
        customerId !== undefined &&
        customers[customerId] !== undefined &&
        customers[customerId].users !== undefined
    ) {
        const keys = Object.keys(customers[customerId].users);
        keys.sort((a, b) => customers[customerId].users[a].userName.localeCompare(customers[customerId].users[b].userName, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true }));
        entries = keys.map(key => (
            <tr
                key={`user_${key}`}
                onClick={
                    () => {
                        if (select !== undefined) {
                            select(rfdclone(customers[customerId].users[key]));
                        }
                    }
                }
            >
                <td>{customers[customerId].users[key].userName ?? "-"}</td>
                <td>{getUserRights(customers[customerId].users[key], lang)}</td>
            </tr>
        ));
    }
    return entries;
};

export default function UsersTable({ lang, customers, customerId, selectUser }) {
    return (
        <Table id='table'>
            <thead>
                <tr>
                    <th>
                        <Lang en="Email" fi="Sähköposti" sv="E-post" />
                    </th>
                    <th>
                        <Lang en="Rights" fi="Oikeudet" sv="Rättigheter" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {parseRows(lang, customers, customerId, selectUser)}
            </tbody>
        </Table>
    );
}