import { Col, Container, Row, Table } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";
import SystemsSelector from "./SystemsSelector";

// eslint-disable-next-line sonarjs/cognitive-complexity
const getTables = (systems, selectedProjects, selectedSystem, selectProject, lang) => {
    let elements = { selectable: [], selected: [] };
    if (systems !== undefined) {
        systems.forEach((system) => {
            if (system.projects !== undefined) {
                system.projects.sort((a, b) => b.id - a.id);
                // eslint-disable-next-line unicorn/no-array-reduce
                elements = system.projects.reduce((res, project) => {
                    if (selectedProjects[system.id] !== undefined && selectedProjects[system.id][project.id]) {
                        res.selected.push(
                            <tr
                                key={`project_${system.id}_${project.id}`}
                                onClick={() => selectProject({ systemId: system.id, projectId: project.id })}
                            >
                                <td>{system.systemName === undefined ? "-" : system.systemName}</td>
                                <td>{project.name === undefined ? "-" : project.name}</td>
                            </tr>
                        );
                    } else if (system.id === selectedSystem) {
                        res.selectable.push(
                            <tr
                                key={`project_${system.id}_${project.id}`}
                                onClick={() => selectProject({ systemId: system.id, projectId: project.id })}
                            >
                                <td>{project.id === undefined ? "-" : project.id}</td>
                                <td>{project.name === undefined ? "-" : project.name}</td>
                            </tr>
                        );
                    }
                    return res;
                }, elements);
            }
        });
    }
    return (
        <Container fluid>
            <Row className="user-table-row">
                <Col className="user-table-column" xs={6} sm={6} md={6} lg={6}>
                    <div className="table-wrapper">
                        <Lang lang={lang}>
                            <span key="en">Selectable projects:</span>
                            <span key="fi">Valittavat projektit:</span>
                            <span key="sv">Valbara projekt:</span>
                        </Lang>
                        <Table key="selectable-table" className="selectable-table" id="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>
                                        <Lang lang={lang}>
                                            <span key="en">Project</span>
                                            <span key="fi">Projekti</span>
                                            <span key="sv">Projekt</span>
                                        </Lang>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {elements.selectable}
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col className="user-table-column" xs={6} sm={6} md={6} lg={6}>
                    <div className="table-wrapper">
                        <Lang lang={lang}>
                            <span key="en">Selected projects:</span>
                            <span key="fi">Valitut projektit:</span>
                            <span key="sv">Utvalda projekt:</span>
                        </Lang>
                        <Table key="selected-table" className="selected-table" id="table">
                            <thead>
                                <tr>
                                    <th>
                                        <Lang lang={lang}>
                                            <span key="en">System</span>
                                            <span key="fi">Järjestelmä</span>
                                            <span key="sv">System</span>
                                        </Lang>
                                    </th>
                                    <th>
                                        <Lang lang={lang}>
                                            <span key="en">Project</span>
                                            <span key="fi">Projekti</span>
                                            <span key="sv">Projekt</span>
                                        </Lang>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {elements.selected}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default function ProjectsSelector({ visible, selectedSystem, systems, selectSystem, lang, selectedProjects, selectProject }) {
    if (visible) {
        return (
            <div className="projectsSelector">
                <Lang lang={lang}>
                    <span key="en">Choose a system:</span>
                    <span key="fi">Valitse järjestelmä:</span>
                    <span key="sv">Välj ett system:</span>
                </Lang>
                <SystemsSelector
                    systemId={selectedSystem}
                    systems={systems}
                    selectSystem={selectSystem}
                    lang={lang}
                />
                {getTables(systems, selectedProjects, selectedSystem, selectProject, lang)}
            </div>
        );
    }
    return null;
}
