import { Component } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import getFooterButtons from "../ConfigModalOptions";
import Lang from "../LanguageSelect/Lang";

const getEditorModalTitle = ({ newView, deleteView, lang }) => {
    if (!deleteView) {
        return newView ? <Lang en="New message" fi="Uusi viesti" sv="Nyt meddelande" lang={lang} /> : <Lang en="Edit message" fi="Muokkaa viestiä" sv="Redigera meddelande" lang={lang} />;
    }
    return <Lang en="Message removal" fi="Viestin poisto" sv="Radering av meddelande" lang={lang} />;

};

export default class EditingModal extends Component {
    state = {
        content: "",
    };

    componentDidUpdate(prevProps) {
        const { show, contentProp } = this.props;
        if (prevProps.show !== show && show) {
            this.setState({ content: contentProp });
        }
    }

    handleModalClose = () => {
        const { close } = this.props;
        close();
    };

    handleTextChange = (e, prop) => {
        if (e && e.target && e.target.value !== undefined && prop) {
            this.setState({ [prop]: e.target.value });
        }
    };

    deleteMessage = () => {
        const { fetchMessages, editId } = this.props;
        const callback = (data) => {
            if (data.status === "success") {
                toast.success(<Lang en="Message deleted" fi="Viesti poistettu" sv="Meddelandet raderades" />);
            } else if (data.status === "error") {
                toast.error(<Lang en="Failed delete message" fi="Viestin poisto epäonnistui" sv="Kunde inte radera meddelandet" />);
            }
            fetchMessages();
        };
        Messenger.requestDeleteInfoMessage({ payload: { infoMessageId: editId }, callback: callback.bind(this) });
        this.handleModalClose();
    };

    submit = () => {
        const { newMessage, fetchMessages } = this.props;
        const { content } = this.state;
        if (newMessage) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="New message created" fi="Uusi viesti luotu" sv="Nyt meddelande skapades" />);
                    fetchMessages();
                } else if (data.status === "error") {
                    toast.error(<Lang en="Failed to create message" fi="Viestin luonti epäonnistui" sv="Kunde inte skapa meddelandet" />);
                }
            };
            Messenger.requestNewInfoMessage({ payload: { infoMessageText: content }, callback: callback.bind(this) });
        }
        this.handleModalClose();
    };

    render() {
        const { show, newMessage, lang, contentProp } = this.props;
        const { content } = this.state;
        return (
            <Modal
                className="customerEditModal"
                show={show}
                onHide={this.handleModalClose}
                backdrop="static"
                size="xl"
                fullscreen="md-down"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {getEditorModalTitle({
                            newView: newMessage,
                            deleteView: !newMessage,
                            lang
                        })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditingOptions
                        new={newMessage}
                        visible={newMessage}
                        handleTextChange={this.handleTextChange}
                        content={content}
                        lang={lang}
                    />
                    <DeleteView
                        visible={!newMessage}
                        delete={this.deleteMessage}
                        name={contentProp}
                        lang={lang}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {getFooterButtons({
                        newView: newMessage,
                        deleteView: !newMessage,
                        setDeleteViewState: () => this.handleModalClose(),
                        submit: this.submit,
                        valid: !!content,
                        closeModal: this.handleModalClose,
                        deleteFunc: this.deleteMessage,
                        disableDelete: !newMessage
                    })}
                </Modal.Footer>
            </Modal>
        );
    }
}

function EditingOptions({ visible, lang, contentProp, handleTextChange }) {
    if (visible) {
        return (
            <form>
                <Form.Group controlId="customerName">
                    <Form.Label>
                        <Lang en="Message" fi="Viesti" sv="Meddelande" lang={lang} />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={20}
                        value={contentProp}
                        onChange={(e) => handleTextChange(e, "content")}
                    />
                </Form.Group>
            </form>
        );
    }
    return null;
}

function DeleteView({ visible, lang, name }) {
    if (visible) {
        return (
            <div className="deleteView">
                <div className="title">
                    <Lang en="Delete message" fi="Poista viesti" sv="Ta bort meddelande" lang={lang} />
                </div>
                <div className="deletable-name" style={{ textAlign: "left" }}>
                    <Alert
                        variant="secondary"
                        style={{ whiteSpace: "pre-line", maxHeight: "65vh", overflow: "auto" }}
                    >
                        {name}
                    </Alert>
                </div>
            </div>
        );
    }
    return null;
}