import { Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

const getName = (lang) => {
    const definitions = [
        { lang: "fi", name: "Suomi" },
        { lang: "en", name: "English" },
        { lang: "sv", name: "Svenska" }
    ];
    const definition = definitions.find(x => x.lang === lang);
    if (definition) {
        return definition.name;
    }
    return "Unknown";
};

const getItems = (langs, lang) => langs.map(option => (
    <Dropdown.Item
        key={`option_${option}`}
        eventKey={option}
        active={lang === option}
    >
        {getName(option)}
    </Dropdown.Item>
));

const handleSelect = (lang, updateAncestor) => {
    updateAncestor({ lang });
};

export default function LangDropdown({ lang, langs, updateAncestor }) {
    return lang && langs ? (
        <div className="langDropdown">
            <DropdownButton
                variant="default"
                title={
                    lang === undefined
                        ? <Lang lang={lang}>
                            <span className="title" key="en">Select...</span>
                            <span className="title" key="fi">Valitse...</span>
                            <span className="title" key="sv">Välj...</span>
                        </Lang>
                        :
                        <span className="title">{getName(lang)}</span>
                }
                id="typeDropdown"
                onSelect={(language) => handleSelect(language, updateAncestor)}
            >
                {getItems(langs, lang)}
            </DropdownButton >
        </div>
    ) : null;
}
