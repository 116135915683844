import { Component } from "react";
import { Form } from "react-bootstrap";

const handleFocus = (e) => {
    e.target.select();
};

export default class NumberField extends Component {
    state = {
        // eslint-disable-next-line react/destructuring-assignment
        copy: this.props.value,
        detached: false,
    };

    updateAncestor = (obj) => {
        const { updateAncestor } = this.props;
        updateAncestor(obj);
    };

    handleChange = (e) => {
        this.setState({ copy: e.target.value, detached: true });
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    handleBlur = (e) => {
        const { propertyName, max, maxProperty, min, minProperty } = this.props;
        if (propertyName) {
            const newValue = Number(e.target.value);
            if (!Number.isNaN(newValue) && typeof newValue === "number") {
                const update = { [propertyName]: newValue };
                // Checking optional limits
                if (max !== undefined && newValue > max) {
                    if (maxProperty) {
                        update[maxProperty] = newValue + 1;
                    } else {
                        this.setState({ detached: false });
                    }
                }
                if (min !== undefined && newValue < min) {
                    if (minProperty) {
                        update[minProperty] = newValue - 1;
                    } else {
                        this.setState({ detached: false });
                    }
                }
                // Updating ancestor with new value if tests are passed
                this.setState({ detached: false }, () => {
                    this.updateAncestor(update);
                });
            } else {
                // Incase of invalid input discarding input by returning to props value
                this.setState({ detached: false });
            }
        }
    };

    render() {
        const { value, className, title, size } = this.props;
        const { detached, copy } = this.state;
        // If detached use component value else use ancestor value
        const finalValue = detached ? copy : value;
        const finalClassName = className === undefined ? "" : (` ${className}`);
        return (
            <div className={`numberField${finalClassName}`}>
                <div className="title">{title}</div>
                <Form.Control
                    size={size}
                    type="text"
                    placeholder=""
                    value={finalValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={handleFocus}
                />
            </div>
        );
    }
}
