import { Spinner, Table } from "react-bootstrap";
import rfdc from "rfdc";

import Lang from "../LanguageSelect/Lang";

const rfdclone = rfdc();

// eslint-disable-next-line sonarjs/cognitive-complexity
const parseRows = (customers, customerId, systems, select) => {
    const entries = [];
    systems.sort((a, b) => a.systemName.localeCompare(b.systemName, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true }));
   
    systems.forEach((system) => {
        if (system.id !== "all") {
            if (system.projects !== undefined)
                system.projects.sort((a, b) => b.id - a.id);
            entries.push(
                <tr
                    key={`system_${system.id}`}
                    onClick={
                        () => {
                            if (select !== undefined) {
                                select(rfdclone(system));
                            }
                        }
                    }
                >
                    <td>{system.systemName ?? "-"}</td>
                    <td>{system.id ?? "-"}</td>
                    <td>{system.projects !== undefined && system.projects[0] !== undefined ? system.projects[0].name : "-"}</td>
                    <td>{system.fw ?? "-"}</td>
                </tr>
            );
        }
    });
    return entries;
};

export default function SystemsTable({ loading, selectSystem, systems, customers, customerId }) {
    if (loading) {
        return (
            <Spinner animation="border" className="systems-spinner" />
        );
    }
    return (
        <Table id="table">
            <thead>
                <tr>
                    <th>
                        <Lang en="Name" fi="Nimi" sv="Namn" />
                    </th>
                    <th>ID</th>
                    <th aria-label="Current project"><Lang en="Current project" fi="Nykyinen projekti" sv="Nuvarande projekt" /></th>
                    <th aria-label="Software version"><Lang en="Software version" fi="Ohjelmisto&shy;versio" sv="Mjukvaru&shy;version" /></th>
                </tr>
            </thead>
            <tbody>
                {parseRows(customers, customerId, systems, selectSystem)}
            </tbody>
        </Table>
    );
}