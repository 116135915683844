import { Component } from "react";
import { Form } from "react-bootstrap";

const handleFocus = (e) => {
    e.target.select();
};

export default class SingleNumberField extends Component {
    state = {
        // eslint-disable-next-line react/destructuring-assignment
        copy: this.props.value,
        detached: false,
    };

    updateAncestor = (obj) => {
        const { updateAncestor } = this.props;
        updateAncestor(obj);
    };

    handleChange = (e) => {
        this.setState({ copy: e.target.value, detached: true });
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    handleBlur = (e) => {
        const { propertyName, emptyIsNull, min, max } = this.props;
        if (propertyName) {
            if (emptyIsNull && e.target.value === "") {
                // Updating ancestor with new value if tests are passed
                this.setState({ detached: false }, () => {
                    this.updateAncestor({ [propertyName]: null });
                });
            } else {
                const newValue = Number(e.target.value.replace(/,/g, "."));
                if (!Number.isNaN(newValue) && typeof newValue === "number") {
                    const update = { [propertyName]: newValue };
                    if (newValue < min) {
                        update[propertyName] = min;
                    }
                    if (newValue > max) {
                        update[propertyName] = max;
                    }
                    // Updating ancestor with new value if tests are passed
                    this.setState({ detached: false }, () => {
                        this.updateAncestor(update);
                    });
                } else {
                    // Incase of invalid input discarding input by returning to props value
                    this.setState({ detached: false });
                }
            }
        }
    };

    render() {
        const { value, className, title, size } = this.props;
        const { detached, copy } = this.state;
        // If detached use component value else use ancestor value
        let finalValue = detached ? copy : value;
        if (finalValue === null)
            finalValue = "";
        const finalClassName = className === undefined ? "" : (` ${className}`);
        return (
            <div className={`numberField${finalClassName}`}>
                <div className="title">{title}</div>
                <Form.Control
                    size={size}
                    type="text"
                    placeholder=""
                    value={finalValue}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={handleFocus}
                />
            </div>
        );
    }
}
