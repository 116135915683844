import { Component } from "react";
import { Form } from "react-bootstrap";

export default class HexInput extends Component {
	handleTextChange = (e) => {
		const { valueProp, updateAncestor } = this.props;
		const text = e.target.value.toUpperCase();
		if ((text === "" || /^[\dA-F]+$/.test(text)) && valueProp !== undefined) {
			updateAncestor({ [valueProp]: text });
		}
	};

	render() {
		const { value, maxLength } = this.props;
		return (
			<Form.Control
				className="hex-input"
				type="text"
				value={value}
				maxLength={maxLength}
				onChange={this.handleTextChange}
			/>
		);
	}
}