import dayjs from "dayjs";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import Lang from "../LanguageSelect/Lang";

class GraphCalcButton extends Component {
	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleClick = () => {
		const {
			statisticsViewDevicesettings, customerId, statisticsViewSelectedSystem, statisticsViewSelectedProject, statisticsViewXAxisStartTime, statisticsViewXAxisEndTime, statisticsViewXAxisFilter,
			statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime, setValues
		} = this.props;
		const LOGGERS = Object.keys(statisticsViewDevicesettings);
		// eslint-disable-next-line unicorn/no-array-reduce
		const DATASETS = LOGGERS.reduce((res, logger) => {
			if (statisticsViewDevicesettings[logger].selected) {
				const selectedKeys = Object.keys(statisticsViewDevicesettings[logger].selected);
				// eslint-disable-next-line unicorn/no-array-reduce
				const quantityIndexes = selectedKeys.reduce((sres, quantityIndex) => {
					if (statisticsViewDevicesettings[logger].selected[quantityIndex])
						sres.push(parseInt(quantityIndex, 10));
					return sres;
				}, []);
				if (quantityIndexes.length > 0)
					res[logger] = quantityIndexes;
			}
			return res;
		}, {});
		if (
			Object.keys(DATASETS.length > 0) &&
			customerId !== undefined &&
			statisticsViewSelectedSystem && statisticsViewSelectedSystem.id !== undefined &&
			statisticsViewSelectedProject && statisticsViewSelectedProject.id !== undefined &&
			statisticsViewXAxisStartTime !== undefined &&
			statisticsViewXAxisEndTime !== undefined
		) {
			let timeFilter = { filterStart: undefined, filterEnd: undefined };
			if (statisticsViewXAxisFilter && statisticsViewXAxisFilterStartTime !== undefined && statisticsViewXAxisFilterEndTime !== undefined) {
				timeFilter = { filterStart: dayjs(statisticsViewXAxisFilterStartTime).utc().format("H:m"), filterEnd: dayjs(statisticsViewXAxisFilterEndTime).utc().format("H:m") };
			}
			const statisticsViewRequestedCalcualtion = {
				customerId,
				systemId: statisticsViewSelectedSystem.id,
				projectId: statisticsViewSelectedProject.id,
				start: dayjs(statisticsViewXAxisStartTime).valueOf(),
				end: dayjs(statisticsViewXAxisEndTime).valueOf(),
				timeFilter,
			};
			const loadingToast = toast.loading(<Lang en="Calculating statistics" fi="Lasketaan tilastot" sv="Beräknar statistik" />, { autoClose: false });
			const callback = (data) => {
				if (data.status === "success") {
					toast.update(loadingToast, { render: <Lang en="Statistics calculated" fi="Tilastot laskettu" sv="Statistiken beräknades" />, type: "success", isLoading: false, autoClose: 5000 });
					setValues({
						statisticsViewProjectDataStatistics: data.statistics,
						statisticsViewProjectDataStatisticsInterval: { start: data.start, end: data.end },
						statisticsViewRequestedCalcualtion,
					});
				} else {
					toast.update(loadingToast, { render: <Lang en="Failed to get project data statistics." fi="Projektin tilastojen laskeminen epäonnistui." sv="Beärkning av projektets statistik misslyckades." />, type: "error", isLoading: false, autoClose: 5000 });
				}
			};
			const payload = {
				customerId,
				systemId: statisticsViewSelectedSystem.id,
				projectId: statisticsViewSelectedProject.id,
				start: dayjs(statisticsViewXAxisStartTime).valueOf() / 1000,
				end: dayjs(statisticsViewXAxisEndTime).valueOf() / 1000,
				datasets: DATASETS,
				timeFilter,
			};
			setValues({ statisticsViewProjectDataStatistics: undefined });
			Messenger.requestProjectDataStatistics({ payload, callback: callback.bind(this) });
		}
	};

	render() {
		const { statisticsViewDevicesettings, customerId, statisticsViewSelectedSystem, statisticsViewSelectedProject, statisticsViewXAxisStartTime, statisticsViewXAxisEndTime } = this.props;
		return Object.keys(statisticsViewDevicesettings).length > 0 &&
			customerId !== undefined &&
			statisticsViewSelectedSystem && statisticsViewSelectedSystem.id !== undefined &&
			statisticsViewSelectedProject && statisticsViewSelectedProject.id !== undefined &&
			statisticsViewXAxisStartTime !== undefined &&
			statisticsViewXAxisEndTime !== undefined ? (
			<Button
				onClick={this.handleClick}
				variant="primary"
			>
				<Lang en="Calc. stat." fi="Laske tilasto" sv="Räkna stat." />
			</Button>
		) : null;
	}
}

function mapStateToProps(state) {
	const { customerId,
		statisticsViewSelectedSystem, statisticsViewSelectedProject,
		statisticsViewXAxisStartTime, statisticsViewXAxisEndTime,
		statisticsViewXAxisFilter, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime,
		statisticsViewSelectedDatasets, statisticsViewDevicesettings
	} = state;
	return {
		customerId,
		statisticsViewSelectedSystem, statisticsViewSelectedProject,
		statisticsViewXAxisStartTime, statisticsViewXAxisEndTime,
		statisticsViewXAxisFilter, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime,
		statisticsViewSelectedDatasets, statisticsViewDevicesettings
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphCalcButton);