import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import store from "../utilities/redux";
import NavigationBar from "./NavigationBar";

export default function RootLayout() {
    const userLogged = JSON.parse(sessionStorage.getItem("userLogged"))
    return (
        <Provider store={store}>
            <ToastContainer
                draggable={false}
                theme="colored"
            />
            <div className="router" style={{ height: userLogged ? "calc(100% - 50px)" : "100%" }}>
                <NavigationBar userLogged={userLogged} />
                <div className="pageWrapper" style={{ marginTop: userLogged ? "50px" : "0px", height: userLogged ? "calc(100% - 50px)" : "100%" }}>
                    <Suspense fallback={<Spinner animation="border" />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
        </Provider>
    );
}