import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import Messenger from "../../utilities/messenger";
import store, { LANGUAGE_NAMES } from "../../utilities/redux";
import Lang from "./Lang";

const rfdclone = rfdc();

class LanguageSelect extends Component {
	constructor() {
		super();

		this.languageNames = LANGUAGE_NAMES;
	}

	handleSelect = (eventKey, setLang) => {
		if (setLang) {
			const callback = (data) => {
				if (data.status === "success") {
					setLang(eventKey);
				} else if (data.status === "error") {
					toast.error(<Lang en="Failed to set language" fi="Kielen asetus epäonnistui" sv="Det gick inte att ställa in språk" />);
				}
			};
			const payload = {
				language: eventKey,
				resetNewInfoMessages: false,
			};
			Messenger.requestUpdateUserOwnSettings({ payload: rfdclone(payload), callback: callback.bind(this) });
		}
	};

	getItems = (languages) => Array.isArray(languages) && languages.length > 0 ? languages.map(lang => (
		<Dropdown.Item
			key={`langItem_${lang}`}
			eventKey={lang}
		>{this.languageNames[lang]}</Dropdown.Item>
	)) : [];

	render() {
		const { lang, setLang } = this.props;
		return (
			<DropdownButton
				variant="light"
				id="lanaguageSelect"
				title={this.languageNames[lang]}
				onSelect={(eventKey) => this.handleSelect(eventKey, setLang)}
			>
				{this.getItems(Object.keys(LANGUAGE_NAMES))}
			</DropdownButton>
		);
	}
}

function mapStateToProps(state) {
	const { lang } = state;
	return { lang };
}

const mapDispatchToProps = (dispatch) => ({
	setLang: (val) => dispatch({ type: "SET_LANG", val }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);

export const langString = ({ lang, strings, recall }) => {
	if (lang) {
		if (strings[lang] !== undefined)
			return strings[lang];
		return strings.en === undefined ? "" : strings.en;
	}
	const stateLang = store.getState().lang;
	if (!recall)
		return langString({ stateLang, strings, recall: true });
	return "";
};
