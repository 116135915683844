import { closestCenter, DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { BsArrowsMove } from 'react-icons/bs';
import { toast } from 'react-toastify';

import Messenger from '../../utilities/messenger';
import Lang from '../LanguageSelect/Lang';

function SortableItem({ id, project, cursor, setCursor }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Card body onMouseDown={() => setCursor("grabbing")} onMouseUp={() => setCursor("grab")}>
                <IconContext.Provider value={{ className: "reactIcon" }}>
                    <BsArrowsMove />
                </IconContext.Provider>
                <div>{project.loggerNames.split(",")[project.loggerIds.split(",").indexOf(id)]}</div>
                <div>{id}</div>
            </Card>
        </div>
    );
}

export default function ProjectLoggerList({ systemId, project, lang, updateSystems }) {
    const [items, setItems] = useState(project.loggerIds.split(","));
    const [names, setNames] = useState(project.loggerNames.split(","));
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const [cursor, setCursor] = useState("grab");
    const [loading, setLoading] = useState(false);

    function handleDragEnd(event) {
        const { active, over } = event;
        let oldIndex = 0;
        let newIndex = 0;
        if (active.id !== over.id) {
            setItems((prevItems) => {
                oldIndex = prevItems.indexOf(active.id);
                newIndex = prevItems.indexOf(over.id);
                return arrayMove(prevItems, oldIndex, newIndex);
            });
            setNames((prevNames) => arrayMove(prevNames, oldIndex, newIndex));
        }
    }

    async function saveOrder() {
        const payload = {
            systemId,
            projectId: project.id,
            loggerIds: items
        };
        const response = await Messenger.saveLoggerOrder(payload);
        if (response.status === "success") {
            updateSystems(systemId, project, items, names);
            setLoading(false);
            toast.success(<Lang lang={lang} fi="Loggerien järjestys tallennettu" en="Logger order saved" sv="Loggerorder sparad" />);
        } else {
            toast.error(<Lang lang={lang} fi="Loggerien järjestyksen tallennuksessa tapahtui virhe" en="An error occurred when saving the logger order" sv="Ett fel uppstod när loggerordern skulle sparas" />);
        }
    }

    function handleSave(event) {
        event.preventDefault();
        setLoading(true);
        saveOrder();
    }

    return (
        <>
            <div className='logger-list-header'>
                <div><Lang en="Name" fi="Nimi" sv="Namn" /></div>
                <div>ID</div>
            </div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={(event) => handleDragEnd(event)}
            >
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                >
                    {items.map(id => <SortableItem key={id} id={id} project={project} cursor={cursor} setCursor={setCursor} />)}
                </SortableContext>
            </DndContext>
            <Button
                onClick={(event) => handleSave(event)}
                disabled={items.toString() === project.loggerIds || loading}
                className={items.toString() !== project.loggerIds || loading ? '' : 'hidden-button'}
            >
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                <Lang en="Save" fi="Tallenna" sv="Spara" />
            </Button>
        </>
    );
}