import "../css/DatasetSelect.css";

import { Component } from "react";
import { Accordion, Button, Card } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

const getActiveState = (total, active) => {
	if (total === active) {
		return " active";
	}
	return active > 0 ? " some-active" : " none-active";
};

export default class DatasetByLoggerSelect extends Component {
	handleClick = ({ deviceId }) => {
		const { datasets, deviceSettings, updateAncestor } = this.props;
		if (datasets && deviceSettings) {
			const deviceSettingsCopy = JSON.parse(JSON.stringify(deviceSettings));
			let newState = true;
			const measTypes = datasets
				.filter((x) => x.visible === true)
				.map((x) => x.measType + x.measUnit);
			const selIndex = datasets.findIndex(x => x.deviceKey === deviceId && x.visible !== true && (measTypes.includes(x.measType + x.measUnit) || measTypes.length === 0));
			newState = selIndex > -1;
			if (deviceSettingsCopy[deviceId] === undefined) {
				deviceSettingsCopy[deviceId] = {};
			}
			if (deviceSettingsCopy[deviceId].selected === undefined) {
				deviceSettingsCopy[deviceId].selected = {};
			}
			const measIndexes = datasets
				.filter((x) => x.deviceKey === deviceId && (measTypes.includes(x.measType + x.measUnit) || measTypes.length === 0))
				.map((x) => x.measi);
			const measIndexesLength = measIndexes.length;
			for (let i = 0; i < measIndexesLength; i += 1) {
				deviceSettingsCopy[deviceId].selected[measIndexes[i]] = newState;
			}
			if (updateAncestor)
				updateAncestor({ deviceSettings: deviceSettingsCopy });
		}
	};

	renderSelectionOptions = (datasets, devices) => {
		const devicesData = {};
		datasets.forEach(dataset => {
			const deviceId = dataset.deviceKey;
			if (devicesData[deviceId] !== undefined) {
				devicesData[deviceId].total += 1;
				devicesData[deviceId].active += (dataset.visible === true ? 1 : 0);
			} else if (deviceId !== 'Paristojännitteen alaraja') {
				devicesData[deviceId] = {
					total: 1,
					active: (dataset.visible === true ? 1 : 0),
					name: dataset.legendgroup,
					index: devices[deviceId] === undefined ? undefined : devices[deviceId].index,
				};
			}
		});
		const keys = Object.keys(devicesData);
		keys.sort((a, b) => {
			if (devicesData[a].index === undefined) {
				return 1;
			}
			if (devicesData[b].index === undefined) {
				return -1;
			}
			return devicesData[a].index - devicesData[b].index;
		});
		return keys.map(deviceId => (
			<Button
				variant="primary"
				key={deviceId}
				className={`btn-sm typeOption${getActiveState(devicesData[deviceId].total, devicesData[deviceId].active)}`}
				onClick={() => this.handleClick({ deviceId })}
			>{devicesData[deviceId].name}</Button>
		));
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleClickAll = () => {
		const { deviceSettings, datasets, updateAncestor } = this.props;
		if (deviceSettings && datasets) {
			let newState = true;
			const deviceSettingsCopy = JSON.parse(JSON.stringify(deviceSettings));
			const measTypes = datasets
				.filter((x) => x.visible === true)
				.map((x) => x.measType + x.measUnit);
			const selIndex = datasets.findIndex(x => x.visible !== true && (measTypes.includes(x.measType + x.measUnit) || measTypes.length === 0));
			newState = selIndex > -1;
			datasets.forEach((dataset) => {
				if (measTypes.includes(dataset.measType + dataset.measUnit) || measTypes.length === 0) {
					if (deviceSettingsCopy[dataset.deviceKey] === undefined)
						deviceSettingsCopy[dataset.deviceKey] = { selected: {} };
					if (deviceSettingsCopy[dataset.deviceKey].selected === undefined)
						deviceSettingsCopy[dataset.deviceKey].selected = {};
					deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = newState;
				}
			});
			updateAncestor({ deviceSettings: deviceSettingsCopy });
		}
	};

	renderSelectAll = (datasets) => {
		const { lang } = this.props;
		if (datasets.length > 0) {
			let state = "";
			const filtered = datasets.filter((dataset) => dataset.visible === true);
			if (filtered.length === datasets.length) {
				state = " active";
			} else if (filtered.length > 0) {
				state = " some-active";
			} else {
				state = " none-active";
			}
			return (
				<Button
					variant="primary"
					className={`btn-sm selectAll${state}`}
					onClick={this.handleClickAll}
				>
					<Lang lang={lang}>
						<span key="en">All</span>
						<span key="fi">Kaikki</span>
						<span key="sv">All</span>
					</Lang>
				</Button>
			);
		}
		return null;
	};

	render() {
		const { datasets, alwaysOpen, lang, devices } = this.props;
		if (Array.isArray(datasets) && datasets.length > 0) {
			return (
				<div className="datasetSelect">
					<Accordion
						id="accordianPanel"
						className="menuPanel titlePanel"
						alwaysOpen={alwaysOpen ?? false}
						defaultActiveKey={alwaysOpen ? ['0'] : undefined}
					>
						<Accordion.Button as={Card.Header}>
							<Lang lang={lang}>
								<div key="en">Loggers</div>
								<div key="fi">Loggerit</div>
								<div key="sv">Loggare</div>
							</Lang>
						</Accordion.Button>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								{this.renderSelectAll(datasets)}
								<div className="selectionOptions">
									{this.renderSelectionOptions(datasets, devices)}
								</div>
							</Card.Body>
						</Accordion.Collapse>
					</Accordion>
				</div>
			);
		}
		return null;
	}
}