import "../../css/CardBackground.css";
import "../../css/dropdownFlexer.css";
import "../../css/scrollableDropdown.css";

import { Component } from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import store from "../../utilities/redux";
import Lang from "../LanguageSelect/Lang";

const TITLES = {
	config: {
		en: "System configuration",
		fi: "Järjestelmän konfigurointi",
		sv: "System konfiguration",
	},
	info: {
		en: "System info",
		fi: "Järjestelmän informaatio",
		sv: "System info",
	}
};

const OPTIONS = [
	{
		id: "config",
		title: TITLES.config,
		authorized: { one: ["super", "system", "projectsSome"] }
	},
	{
		id: "info",
		title: TITLES.info,
		authorized: { one: ["super", "system"] }
	}
];

// eslint-disable-next-line sonarjs/cognitive-complexity
const callback = (data) => {
	if (data.status === "success") {
		const update = { systemsConfigCorruptedData: data.corrupted };
		if (data.corrupted === "") {
			if (data.sys_config !== undefined && data.sys_config.alarm !== undefined) {
				update.systemsConfigSMSActive = data.sys_config.alarm.sms_on ?? null;
				update.systemsConfigEmailActive = data.sys_config.alarm.email_on ?? null;
				update.systemsConfigBeepActive = data.sys_config.alarm.beep_on ?? null;
				update.systemsConfigExternalBeepActive = data.sys_config.alarm.ext_beep_on ?? null;
				update.systemsConfigEmailHeader = data.sys_config.alarm.title ?? "";
				update.systemsConfigEmails = data.sys_config.alarm.email !== undefined && data.sys_config.alarm.email !== null ? data.sys_config.alarm.email.split(",") : [];
				update.systemsConfigSMSNumbers = data.sys_config.alarm.sms !== undefined && data.sys_config.alarm.sms !== null ? data.sys_config.alarm.sms.split(",") : [];
				update.systemsConfigWirelessLinkActive = data.sys_config.alarm.node_on ?? null;
				update.systemsConfigPowerDropActive = data.sys_config.alarm.pwr_on ?? null;
				update.systemsConfigRecordingInterval = data.sys_config.log !== undefined && data.sys_config.log.period !== undefined ? data.sys_config.log.period * 1000 : 0;
				update.systemsConfigStartupDelay = data.sys_config.log !== undefined && data.sys_config.log.bootdelay !== undefined ? data.sys_config.log.bootdelay * 1000 : 0;
			}
			update.systemsConfigNodeConfig = data.node_config ?? null;
			update.systemsConfigSensorTypes = data.sensor_types ?? null;
		} else {
			// If data is corrupted set corrupted and default the other variables
			update.systemsConfigSMSActive = null;
			update.systemsConfigEmailActive = null;
			update.systemsConfigBeepActive = null;
			update.systemsConfigExternalBeepActive = null;
			update.systemsConfigEmailHeader = "";
			update.systemsConfigEmails = [];
			update.systemsConfigSMSNumbers = [];
			update.systemsConfigWirelessLinkActive = null;
			update.systemsConfigPowerDropActive = null;
			update.systemsConfigRecordingInterval = 0;
			update.systemsConfigStartupDelay = 0;
			update.systemsConfigNodeConfig = null;
			update.systemsConfigSensorTypes = null;
		}
		store.dispatch({
			type: "SET_VALUES",
			obj: update,
		});
	} else {
		throw data.msg === undefined ? new Error("SystemConfig request error") : new Error("SystemConfig request error:", data.msg);
	}
};

export const fetchSystemConfig = ({ customerId, systemId }) => {
	const payload = {
		customerId,
		systemId,
	};
	Messenger.requestSystemConfig({ payload, callback });
};

const handleSelect = (id, setValues) => {
	setValues({ systemsSelectedFunction: id });
};

const getNavLinks = (setValues) => OPTIONS.map((option) => option.authorized === undefined || isAuthorized(option.authorized) ?
	<Nav.Item key={option.id}>
		<Nav.Link eventKey={option.id} onClick={() => handleSelect(option.id, setValues)}>
			<Lang {...option.title} />
		</Nav.Link>
	</Nav.Item>
	:
	<div />);

class SystemsFuctionSelect extends Component {
	constructor() {
		super();

		this.fetchSystemConfig = fetchSystemConfig.bind(this);
	}

	componentDidMount() {
		const { systemsConfigSystemInfo, customerId, systemsSelectedSystem, systemsSelectedFunction, setValues } = this.props;
		if (systemsConfigSystemInfo && systemsConfigSystemInfo.API_ver >= 2)
			this.fetchSystemConfig({ customerId, systemId: systemsSelectedSystem.id });
		else if (systemsSelectedFunction !== "config")
			setValues({ systemsSelectedFunction: "config" });
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	componentDidUpdate(prevProps) {
		const { systemsConfigSystemInfo, systemsSelectedSystem, customerId, systemsSelectedFunction, systemsCurrentProject, setValues } = this.props;
		if (
			systemsConfigSystemInfo === undefined || prevProps.systemsConfigSystemInfo === undefined ||
			systemsConfigSystemInfo.API_ver !== prevProps.systemsConfigSystemInfo.API_ver ||
			prevProps.systemsSelectedSystem === undefined ||
			(systemsSelectedSystem !== undefined && systemsSelectedSystem.id !== prevProps.systemsSelectedSystem.id)
		) {
			if (systemsConfigSystemInfo && systemsConfigSystemInfo.API_ver >= 2)
				this.fetchSystemConfig({ customerId, systemId: systemsSelectedSystem.id });
			else if (systemsSelectedFunction !== "config")
				setValues({ systemsSelectedFunction: "config" });
		}
		if ((
			(prevProps.systemsCurrentProject === undefined && systemsCurrentProject !== undefined) || prevProps.systemsCurrentProject.id !== systemsCurrentProject.id ||
			(prevProps.systemsSelectedSystem === undefined && systemsSelectedSystem !== undefined) || prevProps.systemsSelectedSystem.id !== systemsSelectedSystem.id ||
			(prevProps.customerId === undefined && customerId !== undefined) || prevProps.systemsSelectedFunction !== systemsSelectedFunction
		) && systemsCurrentProject.id !== undefined && systemsSelectedSystem.id !== undefined && customerId !== undefined && systemsSelectedFunction === "config") {
			const deviceDataCallback = (data) => {
				if (data.status === "success") {
					setValues({ systemsConfigDeviceData: data.devices });
				}
			};
			const payload = {
				customerId,
				systemId: systemsSelectedSystem.id,
				projectId: systemsCurrentProject.id,
			};
			Messenger.requestDeviceData({ payload, callback: deviceDataCallback.bind(this) });
		}
	}

	render() {
		const { systemsConfigSystemInfo, systemsSelectedFunction, setValues, systemsSelectedSystem } = this.props;
		if (systemsConfigSystemInfo !== null && systemsConfigSystemInfo !== undefined && systemsConfigSystemInfo.API_ver >= 2) {
			return (
				<div data-testid="systems-nav">
					{systemsSelectedSystem !== undefined && systemsSelectedSystem.id !== undefined ?
						<Nav fill variant="tabs" activeKey={systemsSelectedFunction} defaultActiveKey="projects" className="flex-column">
							{getNavLinks(setValues)}
						</Nav>
						:
						null
					}
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { customerId, systems,
		systemsSelectedSystem, systemsSelectedFunction, systemsConfigSystemInfo, systemsCurrentProject
	} = state;
	return {
		customerId, systems,
		systemsSelectedSystem, systemsSelectedFunction, systemsConfigSystemInfo, systemsCurrentProject
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemsFuctionSelect);
