import "./DeviceTypeDropdown.css";

import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

export const TITLES = {
	"APw": {
		en: "APw",
		fi: "APw",
		sv: "APw",
	},
	"APw+": {
		en: "APw+",
		fi: "APw+",
		sv: "APw+",
	},
	"DL-P1": {
		en: "DL-P1",
		fi: "DL-P1",
		sv: "DL-P1",
	},
	"DL-P2": {
		en: "DL-P2",
		fi: "DL-P2",
		sv: "DL-P2",
	},
};

const OPTIONS = [
	{
		id: "APw",
		title: TITLES.APw,
	},
	{
		id: "APw+",
		title: TITLES["APw+"],
	},
	{
		id: "DL-P1",
		title: TITLES["DL-P1"],
	},
	{
		id: "DL-P2",
		title: TITLES["DL-P2"],
	},
];

const getTitle = (titles, selection) => {
	if (selection !== undefined && titles[selection] !== undefined)
		return titles[selection];

	return ({ en: "Select...", fi: "Valitse...", sv: "Välj..." });
};

// eslint-disable-next-line unicorn/no-array-reduce
const getItems = (options, selected) => options.reduce((res, option) => {
	res.push(
		<Dropdown.Item
			key={`new_system_devicetype_${option.id}`}
			eventKey={option.id}
			active={selected === option.id}
		>
			{typeof option.title === "object" ? <Lang {...option.title} /> : <Lang en="Error" fi="Error" sv="Error" />}
		</Dropdown.Item>
	);
	return res;
}, []);

export default class DeviceTypeDropdown extends Component {
	handleSelect = (selection) => {
		const { onChange } = this.props;
		if (onChange)
			onChange(selection);
	};

	render() {
		const { value } = this.props;
		return (
			<div className="devicetype-selector">
				<div className="title">
					<Lang en="Device type" fi="Laitetyyppi" sv="Enhetens typ" />
				</div>
				<div className="selection-dropdown dropdownFlexer scrollable-dropdown">
					<DropdownButton
						variant="default"
						title={<span className="title"><Lang {...getTitle(TITLES, value)} /></span>}
						id="system-device-type-dropdown"
						onSelect={this.handleSelect}
					>
						{getItems(OPTIONS, value)}
					</DropdownButton >
				</div>
			</div>
		);
	}
}