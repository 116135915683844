import "../../../css/CardBackground.css";
import "./LoggerSettings.css";

import { connect } from "react-redux";

import isAuthorized from "../../../utilities/authorization";
import Lang from "../../LanguageSelect/Lang";
import LoggersList from "./LoggersList";

const getTitle = (sysInfo) => {
	if (sysInfo !== undefined) {
		if (sysInfo.deviceType === "APw" || sysInfo.deviceType === "APw+")
			return (
				<Lang en="Logger- and sensor settings" fi="Loggeri- ja anturiasetukset" sv="Logger- och sensor inställningar" />
			);
		if (sysInfo.deviceType === "DL-P1" || sysInfo.deviceType === "DL-P2")
			return (
				<Lang en="Sensor settings" fi="Anturiasetukset" sv="Sensor inställningar" />
			);
	}

	return <Lang en="Settings" fi="Asetukset" sv="Inställningar" />;
};

function LoggerSettings({ systemsConfigSystemInfo, systemsLoading }) {
	return (
		<div className="systems-config-logger-settings cardBackground">
			<div className="title loggers-header-title">
				{getTitle(systemsConfigSystemInfo)}
			</div>
			{
				systemsLoading ?
					null : <div className="loggers-list">
						<LoggersList editAuth={isAuthorized({ one: ["super", "system", "customer-support"] })} />
					</div>
			}
		</div>
	);
}

function mapStateToProps(state) {
	const { systemsConfigSystemInfo, systemsLoading } = state;
	return { systemsConfigSystemInfo, systemsLoading };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggerSettings);
