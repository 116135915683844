import "./RecordingInterval.css";

import { connect } from "react-redux";

import Lang from "../../LanguageSelect/Lang";
import Timeselector from "./Timeselector/Timeselector";

const onChange = (value, setValues) => {
	setValues({ systemsConfigStartupDelay: value });
};

function StartupDelay({ editAuth, setValues, systemsConfigStartupDelay }) {
	return (
		<span className="setting recording-startupdelay-selector dropdownFlexer scrollable-dropdown separated-alignment-parent">
			<span className="title separated-alignment-left">
				<Lang en="Startup delay" fi="Viive käynnistäessä" sv="Försening vid start" />
			</span>
			<span className="time-selector separated-alignment-right">
				<Timeselector
					disabled={!editAuth}
					onChange={(value) => onChange(value, setValues)}
					minutes
					value={systemsConfigStartupDelay}
				/>
			</span>
		</span>
	);
}

function mapStateToProps(state) {
	const { systemsConfigStartupDelay } = state;
	return { systemsConfigStartupDelay };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartupDelay);