import { Component } from "react";
import { DragSource } from "react-dnd";

const ItemTypes = {
	ITEM: "side-item"
};

const itemSource = {
	beginDrag(props) {
		return { id: props.id };
	},
	endDrag(props, monitor, component) {
		return props.handleDrop(props, monitor, component);
	}
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
}

class QuantitySideItem extends Component {
	render() {
		const { connectDragSource, id } = this.props;
		return connectDragSource(
			<div
				className="sideItem btn btn-primary"
				draggable
			>
				{id}
			</div>
		);
	}
}

export default DragSource(ItemTypes.ITEM, itemSource, collect)(QuantitySideItem);