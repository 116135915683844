import "../css/DatasetSelect.css";

import { Component } from "react";
import { Accordion, Button, Card } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

const getActiveState = (total, active) => {
	if (total === active) {
		return " active";
	}
	return active > 0 ? " some-active" : " none-active";
};

const getMeasName = (dataset) => {
	if (dataset.measType === "Pres") {
		return dataset.measUnit === "Pa" ? "Pres diff" : "Baro pres";
	}
	return dataset.measType;
};

export default class DatasetByTypeSelect extends Component {
	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleClick = ({ measType, measUnit }) => {
		const { datasets, deviceSettings, updateAncestor } = this.props;
		const Pres = measType.includes("Pres")
		if (datasets && deviceSettings) {
			let newState = false;
			const enabledDevices = [];
			let anyEnabled = -1;
			const deviceSettingsCopy = JSON.parse(JSON.stringify(deviceSettings));
			Object.keys(deviceSettingsCopy).forEach(deviceSetting => {
				if (Object.values(deviceSettingsCopy[deviceSetting].selected).includes(true)) {
					enabledDevices.push(deviceSetting);
				}
			})
			anyEnabled = Pres ? datasets.findIndex(x => x.measType === "Pres" && x.measUnit === measUnit && x.visible === true) : datasets.findIndex(x => x.measType === measType && x.visible === true);
			const index = datasets.findIndex((x) => Pres ? x.measType === "Pres" && x.measUnit === measUnit && x.visible !== true && (enabledDevices.includes(x.deviceKey) || enabledDevices.length === 0) : x.measType === measType && x.visible !== true && (enabledDevices.includes(x.deviceKey) || enabledDevices.length === 0));
			newState = !!(index > -1 || anyEnabled === -1);
			datasets.forEach((dataset) => {
				if (!Pres) {
					if (dataset.measType === measType) {
						if (deviceSettingsCopy[dataset.deviceKey] === undefined)
							deviceSettingsCopy[dataset.deviceKey] = { selected: {} };
						if (deviceSettingsCopy[dataset.deviceKey].selected === undefined)
							deviceSettingsCopy[dataset.deviceKey].selected = {};
						if (enabledDevices.includes(dataset.deviceKey) || enabledDevices.length === 0 || anyEnabled === -1) {
							deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = newState;
						}
					}
				} else if (dataset.measType === "Pres" && dataset.measUnit === measUnit) {
					if (deviceSettingsCopy[dataset.deviceKey] === undefined)
						deviceSettingsCopy[dataset.deviceKey] = { selected: {} };
					if (deviceSettingsCopy[dataset.deviceKey].selected === undefined)
						deviceSettingsCopy[dataset.deviceKey].selected = {};
					if (enabledDevices.includes(dataset.deviceKey) || enabledDevices.length === 0 || anyEnabled === -1) {
						deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = newState;
					}
				}
			});
			updateAncestor({ deviceSettings: deviceSettingsCopy });
		}
	};

	renderSelectionOptions = (datasets) => {
		const types = {};
		datasets.forEach(dataset => {
			// eslint-disable-next-line prefer-const
			let { measType, measUnit, visible } = dataset;
			if (measType !== '') {
				if (measType === "Pres") {
					measType = `${measType}_${measUnit}`;
				}
				if (types[measType] === undefined) {
					types[measType] = {
						total: 1,
						active: (visible === true ? 1 : 0),
						name: getMeasName(dataset),
						measType,
						measUnit,
					};
				} else {
					types[measType].total += 1;
					types[measType].active += (visible === true ? 1 : 0);
				}
			}
		});
		return Object.keys(types).map(type => (
			<Button
				variant="primary"
				key={`${type}_${types[type].measUnit}`}
				className={`btn-sm typeOption${getActiveState(types[type].total, types[type].active)}`}
				onClick={() => this.handleClick({ measType: types[type].measType, measUnit: types[type].measUnit })}
			>{types[type].name}</Button>
		));
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleClickAll = () => {
		const { deviceSettings, datasets, updateAncestor } = this.props;
		if (deviceSettings && datasets) {
			let newState = true;
			const enabledDevices = [];
			const deviceSettingsCopy = JSON.parse(JSON.stringify(deviceSettings));
			Object.keys(deviceSettingsCopy).forEach(deviceSetting => {
				if (Object.values(deviceSettingsCopy[deviceSetting].selected).includes(true)) {
					enabledDevices.push(deviceSetting);
				}
			})
			const selIndex = datasets.findIndex(x => x.visible !== true && (enabledDevices.includes(x.deviceKey) || enabledDevices.length === 0));
			newState = selIndex > -1;
			datasets.forEach((dataset) => {
				if (deviceSettingsCopy[dataset.deviceKey] === undefined)
					deviceSettingsCopy[dataset.deviceKey] = { selected: {} };
				if (deviceSettingsCopy[dataset.deviceKey].selected === undefined)
					deviceSettingsCopy[dataset.deviceKey].selected = {};
				if (enabledDevices.includes(dataset.deviceKey) || enabledDevices.length === 0) {
					deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = newState;
				}
			});
			updateAncestor({ deviceSettings: deviceSettingsCopy });
		}
	};

	renderSelectAll = (datasets) => {
		const { lang } = this.props;
		if (datasets.length > 0) {
			let state = "";
			const filtered = datasets.filter((dataset) => dataset.visible === true);
			if (filtered.length === datasets.length) {
				state = " active";
			} else if (filtered.length > 0) {
				state = " some-active";
			} else {
				state = " none-active";
			}
			return (
				<Button
					variant="primary"
					className={`btn-sm selectAll${state}`}
					onClick={this.handleClickAll}
				>
					<Lang lang={lang}>
						<span key="en">All</span>
						<span key="fi">Kaikki</span>
						<span key="sv">All</span>
					</Lang>
				</Button>
			);
		}
		return null;
	};

	render() {
		const { types, alwaysOpen, lang, datasets } = this.props;
		if (Array.isArray(types) && types.length > 0) {
			return (
				<div className="datasetSelect">
					<Accordion
						id="accordianPanel"
						className="menuPanel titlePanel"
						alwaysOpen={alwaysOpen ?? false}
						defaultActiveKey={alwaysOpen ? ['0'] : undefined}
					>
						<Accordion.Button as={Card.Header}>
							<Lang lang={lang}>
								<div key="en">Type selections</div>
								<div key="fi">Suureet</div>
								<div key="sv">Typ val</div>
							</Lang>
						</Accordion.Button>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								{this.renderSelectAll(datasets)}
								<div className="selectionOptions">
									{this.renderSelectionOptions(datasets)}
								</div>
							</Card.Body>
						</Accordion.Collapse>
					</Accordion>
				</div>
			);
		}
		return null;
	}
}