import "./Blueprint.css";

import { Component } from "react";
import { v4 as uuid } from "uuid";

const onDrop = () => {

};

const onDrag = (e) => {
	e.preventDefault();
};

const renderTimestamp = (logger) => {
	if (!logger.timestamp) {
		return null;
	}
	const t = new Date();
	t.setTime(logger.timestamp * 1000);
	let minutes = t.getMinutes();
	minutes = minutes >= 10 ? minutes : `0${minutes}`;
	const loggerTimestamp = `${t.getDate()}.${t.getMonth() + 1}.${t.getFullYear()} ${t.getHours()}:${minutes}`;
	return (
		<div className="loggerFigureTimestamp">{loggerTimestamp}</div>
	);
};

const renderMeasurements = (logger) => (
	<div className="loggerFigureValuesContainer">
		{logger.measurements.map(measurement => {
			if (!measurement.value || measurement.value === "null") {
				return (
					<div key={logger.loggerId + uuid()}><var className="loggerFigureValue">-&nbsp;{measurement.unit}</var></div>
				);
			}
			const rounded = Math.round(measurement.value * 10) / 10;
			return (
				<div key={logger.loggerId + uuid()}><var className="loggerFigureValue">{rounded}&nbsp;{measurement.unit}</var></div>
			);
		})}
	</div>
);

export default class LoggerComponent extends Component {
	setLoggerToEvent = (event) => {
		const { logger } = this.props;
		event.dataTransfer.setData("loggerId", logger.loggerId);
		event.dataTransfer.setData("startX", event.clientX);
		event.dataTransfer.setData("startY", event.clientY);
		event.dataTransfer.setData("loggerX", logger.x);
		event.dataTransfer.setData("loggerY", logger.y);
	};

	render() {
		const { logger, cssClass } = this.props;
		const x = logger.x || 0;
		const y = logger.y || 0;
		// eslint-disable-next-line react/destructuring-assignment
		const topOffset = this.props.topOffset || 0;
		// eslint-disable-next-line react/destructuring-assignment
		const leftOffset = this.props.leftOffset || 0;
		const position = {
			top: y + topOffset,
			left: x + leftOffset
		};
		let loggerImage = "/img/sensor_default.png";
		if (logger.type !== "default") {
			loggerImage = `/img/sensor_${logger.type}.png`;
		}
		return (
			<span onDragOver={onDrag} onDrop={onDrop}>
				<figure className={cssClass} style={position}>
					<img src={loggerImage} className="loggerImage"
						draggable onDragStart={(event) => this.setLoggerToEvent(event)} alt="logger" />
					<figcaption className="loggerFigureCaption">{logger.name}
						{renderTimestamp(logger)}
						{renderMeasurements(logger)}
					</figcaption>

				</figure>
			</span>
		);
	}
}