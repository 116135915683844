import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Accordion, Button, Collapse, Spinner } from "react-bootstrap";
import { isDesktop } from "react-device-detect";
import { IconContext } from "react-icons";
import { BsBoxArrowUpRight, BsDash, BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import ConfirmationModal from "../ConfirmationModal";
import Lang from "../LanguageSelect/Lang";
import ProjectLoggerList from "./ProjectLoggerList";
import SettingsNewProject from "./SettingsNewProject";

const getProjects = (system, lang, updateSystems, handleDeleteClick) => {
    const dateFormat = 'DD.MM.YYYY H:mm';
    let projects = [];
    system.projects.sort((a, b) => parseInt(a.id, 10) < parseInt(b.id, 10) ? 1 : -1);
    projects = system.projects.map((project, index) => (
        <Accordion.Item eventKey={index.toString()} key={project.id}>
            <Accordion.Header>
                <div className="project-name">{project.name}</div>
                {index === 0 ?
                    <div className="date-range">{project.oldest === null ? dayjs().format(dateFormat) : dayjs.unix(project.oldest).format(dateFormat)} -&gt;</div>
                    :
                    <div className="date-range">{dayjs.unix(project.oldest).format(dateFormat)} - {dayjs.unix(project.newest).format(dateFormat)}</div>
                }
            </Accordion.Header>
            <Accordion.Body>
                <div className="project-buttons">
                    <IconContext.Provider value={{ className: "reactIcon" }}>
                        <Link to={`/mainpage?systemId=${system.id}&projectId=${project.id}`}>
                            <Button variant="primary" size={isDesktop ? "" : "sm"}>
                                <Lang en="Project measurement data" fi="Projektin mittausdataan" sv="Projektmätdata" />&nbsp;<BsBoxArrowUpRight />
                            </Button>
                        </Link>
                    </IconContext.Provider>
                    <IconContext.Provider value={{ className: "reactIcon" }}>
                        <Link to={`/blueprints?systemId=${system.id}&projectId=${project.id}`}>
                            <Button variant="primary" size={isDesktop ? "" : "sm"}>
                                <Lang en="Project blueprints" fi="Projektin pohjakuviin" sv="Projektritningar" />&nbsp;<BsBoxArrowUpRight />
                            </Button>
                        </Link>
                    </IconContext.Provider>
                    {isAuthorized({ one: ["super", "project_admin"] }) && index > 0 ?
                        <Button disabled={isAuthorized({ all: ["projectsSome"]})} variant="danger" onClick={() => handleDeleteClick(project)} size={isDesktop ? "" : "sm"} >
                            <Lang en="Delete project" fi="Poista projekti" sv="Ta bort projekt" />
                        </Button>
                        :
                        null
                    }
                </div>
                {project.loggerIds.split(",").length > 1 && ["APw", "APw+"].includes(system.deviceType) ?
                    <div className="project-logger-list">
                        <h5><Lang en="Order of loggers in the cloud service" fi="Loggerien järjestys pilvipalvelussa" sv="Beställning av loggare i molntjänsten" /></h5>
                        <ProjectLoggerList
                            systemId={system.id}
                            project={project}
                            lang={lang}
                            updateSystems={updateSystems}
                        />
                    </div>
                    :
                    null
                }
            </Accordion.Body>
        </Accordion.Item>
    ));
    return projects;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function ProjectsPage({ loading, selectedSystem, lang, customerId, getCustomerSystems, userId, updateSystems, activeNav }) {
    const [showForm, setShowForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [project, setProject] = useState({});
    const [expandedItem, setExpandedItem] = useState("0");

    const prevSystemRef = useRef();

    useEffect(() => {
        if (prevSystemRef.current !== selectedSystem.id)
            setExpandedItem("0");
        prevSystemRef.current = selectedSystem.id;
    }, [selectedSystem]);

    const handleClick = () => setShowForm(!showForm);

    const handleDeleteClick = (selectedProject) => {
        setShowDeleteModal(!showDeleteModal);
        setProject(selectedProject);
    };

    const deleteProject = async (confirmed) => {
        if (confirmed) {
            const payload = {
                customerId,
                systemId: selectedSystem.id,
                projectId: project.id
            };
            const response = await Messenger.deleteProject(payload);
            if (response.status === "success") {
                updateSystems(selectedSystem.id, project);
                toast.success(<Lang en="Project deleted" fi="Projekti poistettu" sv="Projektet raderat" />);
            } else {
                toast.error(<Lang en="An error occurred while deleting the project" fi="Projektia poistettaessa tapahtui virhe" sv="Ett fel uppstod när projektet skulle tas bort" />);
            }
        }
        setShowDeleteModal(false);
    };

    if (selectedSystem !== undefined && selectedSystem.id !== undefined && activeNav === "projects")
        return (
            loading ?
                <Spinner id="settingsRightSpinner" animation="border" />
                :
                <div className="cardBackground">
                    {isAuthorized({ one: ["super", "project_admin", "customer_support", "projectsSome" ] }) ?
                        <>
                            <Button className="new-project-button" variant="primary" onClick={handleClick} disabled={isAuthorized({ all: ["projectsSome" ]})}>
                                <IconContext.Provider value={{ className: "reactIcon" }}>
                                    {showForm ?
                                        <><BsDash /><Lang en="Hide field" fi="Piilota kenttä" sv="Göm fält" /></>
                                        : <><BsPlus /><Lang en="New project" fi="Uusi projekti" sv="Nytt projekt" /></>
                                    }
                                </IconContext.Provider>
                            </Button>
                            <Collapse in={showForm}>
                                <div>
                                    <SettingsNewProject
                                        customerId={customerId}
                                        selectedSystem={selectedSystem}
                                        loading={loading}
                                        lang={lang}
                                        getCustomerSystems={getCustomerSystems}
                                        userId={userId}
                                    />
                                </div>
                            </Collapse>
                        </>
                        :
                        null}
                    <Accordion
                        defaultActiveKey="0"
                        alwaysOpen
                        activeKey={expandedItem}
                        onSelect={(e) => {
                            if (e[0] !== null) {
                                setExpandedItem(e[0]);
                            }
                        }}>
                        {getProjects(selectedSystem, lang, updateSystems, handleDeleteClick)}
                    </Accordion>
                    <ConfirmationModal confirm={deleteProject} show={showDeleteModal} lang={lang}>
                        <Lang lang={lang} fi="Haluatko poistaa projektin" en="Do you want to delete the project" sv="Vill du ta bort projektet" />
                        &nbsp;{project.name}?
                    </ConfirmationModal>
                </div >
        );
    return null;
}
