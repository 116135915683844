import { Component } from "react";
import { Alert } from "react-bootstrap";
import Linkify from "react-linkify";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import Lang from "../LanguageSelect/Lang";

const getMessages = (messages) => {
    if (Array.isArray(messages)) {
        return messages.map((msg) => (
            <Alert
                variant="secondary"
                style={{ whiteSpace: "pre-line" }}
                key={`info-message_${msg.infoMessageId}`}
            >
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        decoratedText.includes("IAQ.") || decoratedText.includes("WP.") ? decoratedText :
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                    )}
                >{msg.infoMessageText}</Linkify>
            </Alert>
        ));
    }
    return [];
};

export default class InfoMessages extends Component {
    state = {
        infoMessages: [],
    };

    componentDidMount() {
        const { newInfoMessages, updateAncestor, lang } = this.props;
        const fetchCallback = (data) => {
            if (data.status === "error") {
                toast.error(<Lang en="Failed to fetch messages." fi="Viestin haku epäonnistui" sv="Kunde inte hämta meddelanden" />);
            } else if (data.status === "success") {
                this.setState({ infoMessages: data.infoMessages }, () => {
                    if (newInfoMessages) {
                        const resetCallback = (resetData) => {
                            if (resetData.status === "error") {
                                toast.error(<Lang en="Failed to clear new messages." fi="Uusien viestien nollaus epäonnistui" sv="Kunde inte nollställa nya meddelanden" />);
                            } else if (resetData.status === "success") {
                                updateAncestor({ newInfoMessages: false });
                            }
                        };
                        Messenger.requestUpdateUserOwnSettings({ payload: { resetNewInfoMessages: true, language: lang }, callback: resetCallback.bind(this) });
                    }
                });
            }
        };
        Messenger.requestLatestInfoMessages({ payload: { maxAmount: 10 }, callback: fetchCallback.bind(this) });
    }



    render() {
        const { visible, lang } = this.props;
        const { infoMessages } = this.state;
        if (visible) {
            return (
                <div className="rightSidePanel changePassword-wrapper cardBackground">
                    <div
                        className="infoMessages settingsWell"
                    >
                        <h3 className="settingsTitle">
                            <Lang en="Messages" fi="Viestit" sv="Meddelanden" lang={lang} />
                        </h3>
                        <div className="messages-wrapper">
                            {getMessages(infoMessages)}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
}