import { Component } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import CustomerSelect from "../CustomerSelect";
import Lang from "../LanguageSelect/Lang";
import { MainSelectorDropdownsOnly } from "../ProjectSelections/ProjectSelections";


const ALARM_MAP = {
	selectedProject: "alarmSelectedProject", selectedSystem: "alarmSelecedSystem",
	loading: "alarm_loading",
};

const remapToAlarmView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (ALARM_MAP[key])
			output[ALARM_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const setSystemProject = (objProp) => {
	const obj = remapToAlarmView(objProp);
	StorageEvent.dispatch({
		type: "SET_VALUES",
		obj
	});
};



class AlarmsLeftPanel extends Component {
	state = {
		showUploadModal: false,
		loading: false,
	};

	render() {
		const { blueprints, lang, bp, customers, customerId, selectCustomer, systems, selectedSystem, selectedProject } = this.props;
		let blueprintItems = [];
		if (blueprints) {
			blueprintItems = blueprints.map(item => (
				<Dropdown.Item
					key={item.uuid}
					href="#"
					eventKey={item.uuid}
				>
					{item.name}
				</Dropdown.Item>
			));
		}
		let dropdownTitle = (
			<Lang lang={lang} fi="Valitse" en="Select" sv="Valja" />
		);
		if (bp) {
			dropdownTitle = bp.name;
		}
		return (
			<div>
				<CustomerSelect
					customers={customers}
					customerId={customerId}
					selectCustomer={selectCustomer}
					lang={lang}
					checkProjects
				/>
				<div className="mainSelector cardBackground">
					<MainSelectorDropdownsOnly
						systems={systems}
						selectedSystem={selectedSystem}
						lang={lang}
						updateAncestor={setSystemProject}
						projects={selectedSystem.id === undefined ? undefined : selectedSystem.projects}
						selectedProject={selectedProject}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, username, systems, customerId, alarmSelectedSystem } = state;
	return {
		lang, username, systems, customerId,
		selectedSystem: alarmSelectedSystem
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsLeftPanel);


