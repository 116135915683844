import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import rfdc from "rfdc";

import Lang from "../../LanguageSelect/Lang";

const rfdclone = rfdc();

const OPTIONS = [
	{
		id: "2_0.01",
		values: { q: 2, r: 0.01 },
		title: "0",
	},
	{
		id: "1_0.25",
		values: { q: 1, r: 0.25 },
		title: "1",
	},
	{
		id: "0.5_0.5",
		values: { q: 0.5, r: 0.5 },
		title: "2",
	},
	{
		id: "0.25_1",
		values: { q: 0.25, r: 1 },
		title: "3",
	},
	{
		id: "0.125_2",
		values: { q: 0.125, r: 2 },
		title: "4",
	},
	{
		id: "0.125_4",
		values: { q: 0.125, r: 4 },
		title: "5",
	},
];

const getTitle = (options, values) => {
	const selected = `${values.q}_${values.r}`;
	const index = options.findIndex(x => x.id === selected);
	if (index > -1) {
		return <Lang en={options[index].title} fi={options[index].title} sv={options[index].title} />;
	}

	return <Lang en="Select..." fi="Valitse..." sv="Välj..." />;
};

const getItems = (options, values) => {
	const selected = `${values.q}_${values.r}`;
	// eslint-disable-next-line unicorn/no-array-reduce
	return options.reduce((res, option) => {
		res.push(
			<Dropdown.Item
				key={`system_config_filtercoef_${option.id}`}
				eventKey={option}
				active={selected === option.id}
			>
				<Lang en={option.title} fi={option.title} sv={option.title} />
			</Dropdown.Item>
		);

		return res;
	}, []);
};

export default class FilterCoefDropdown extends Component {
	handleSelect = (option) => {
		const { onChange, metaData } = this.props;
		onChange({ values: rfdclone(option.values), metaData });
	};

	render() {
		const { values, disabled } = this.props;
		if (values) {
			return (
				<DropdownButton
					disabled={disabled}
					variant="default"
					size="sm"
					title={<span className="title">{getTitle(OPTIONS, values)}</span>}
					id="system-filtercoef-dropdown"
					onSelect={this.handleSelect}
				>
					{getItems(OPTIONS, values)}
				</DropdownButton >
			);
		}
		return null;
	}
}