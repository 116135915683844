import "./Timeselector.css";

import { Component } from "react";
import { Form } from "react-bootstrap";
import { isDesktop } from "react-device-detect";

const mobileInput = `timeselector-number-input${isDesktop ? "" : " mobile-input"}`;

const calcValues = (time) => {
	const output = { h: 0, m: 0, s: 0, ms: 0 };
	output.h = Math.floor(time / 3_600_000);
	output.m = Math.floor((time - output.h * 3_600_000) / 60_000);
	output.s = Math.floor((time - output.h * 3_600_000 - output.m * 60_000) / 1000);
	output.ms = Math.floor(time - output.h * 3_600_000 - output.m * 60_000 - output.s * 1000);
	return output;
};

export default class Timeselector extends Component {
	onHours = (e) => {
		const { onChange, value } = this.props;
		if (onChange) {
			const times = calcValues(value);
			onChange(e.target.value * 3_600_000 + times.m * 60_000 + times.s * 1000 + times.ms);
		}
	};

	onMinutes = (e) => {
		const { onChange, value } = this.props;
		if (onChange) {
			const times = calcValues(value);
			onChange(times.h * 3_600_000 + e.target.value * 60_000 + times.s * 1000 + times.ms);
		}
	};

	onSeconds = (e) => {
		const { onChange, value } = this.props;
		if (onChange) {
			const times = calcValues(value);
			onChange(times.h * 3_600_000 + times.m * 60_000 + e.target.value * 1000 + times.ms);
		}
	};

	render() {
		const { value, disabled, hours, minutes, seconds } = this.props;
		if (typeof value === "number" && !Number.isNaN(value)) {
			const times = calcValues(value);
			return (
				<div className="timeselector">
					<Hours
						disabled={disabled}
						value={times.h}
						show={hours}
						onChange={this.onHours}
					/>
					<Minutes
						disabled={disabled}
						value={times.m}
						show={minutes}
						onChange={this.onMinutes}
						enforceMinMax={this.enforceMinMax}
					/>
					<Seconds
						disabled={disabled}
						value={times.s}
						show={seconds}
						onChange={this.onSeconds}
					/>
				</div>
			);
		}
		return null;
	}
}

function Hours({ show, disabled, value, onChange }) {
	if (show) {
		return (
			<span className="timeselector-hours timeselector-input-unit">
				<Form>
					<Form.Group>
						<Form.Control
							disabled={disabled}
							className={mobileInput}
							type="number"
							max="999"
							min="0"
							maxLength="3"
							value={value}
							onChange={onChange}
						/>
					</Form.Group>
				</Form>
				<span className="unit">h</span>
			</span>
		);
	}
	return null;
}

function Minutes({ show, disabled, value, onChange }) {
	if (show) {
		return (
			<span className="timeselector-minutes timeselector-input-unit">
				<Form>
					<Form.Group>
						<Form.Control
							disabled={disabled}
							className={mobileInput}
							type="number"
							max="59"
							min="0"
							maxLength="2"
							value={value}
							onChange={onChange}
						/>
					</Form.Group>
				</Form>
				<span className="unit">min</span>
			</span>
		);
	}
	return null;
}

function Seconds({ show, disabled, value, onChange }) {
	if (show) {
		return (
			<span className="timeselector-seconds timeselector-input-unit">
				<Form>
					<Form.Group>
						<Form.Control
							disabled={disabled}
							className={mobileInput}
							type="number"
							max="59"
							min="0"
							maxLength="2"
							value={value}
							onChange={onChange}
						/>
					</Form.Group>
				</Form>
				<span className="unit">s</span>
			</span>
		);
	}
	return null;
}