import { BsCheckCircle, BsDashCircleFill, BsXCircleFill } from "react-icons/bs";

const getIcon = (status) => {
	if (status === "valid")
		return <BsCheckCircle fill="green" />;
	if (status === "invalid")
		return <BsXCircleFill fill="red" />;
	return <BsDashCircleFill fill="lightgray" />;
};

export default function Check({ className, children }) {
	return (
		<div data-testid={`check-${className}`} className="check">
			{getIcon(className)}
			{children}
		</div>
	);
}