import "../../css/CardBackground.css";
import "../../css/DeleteView.css";
import "../../css/LeftSidePanel.css";
import "../../css/pageDefault.css";
import "../../css/RegenView.css";
import "../../css/scrollableDropdown.css";
import "./Systems.css";
import "../../css/TokenView.css";
import "../../css/DatasetSelect.css";

import dayjs from "dayjs";
import queryString from "query-string";
import { Component, lazy, Suspense } from "react";
import { Accordion, Button, Card, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import rfdc from "rfdc";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import store from "../../utilities/redux";
import isDefined from "../../utilities/util";
import DatasetByLoggerSelect from "../DatasetByLoggerSelect";
import DatasetByTypeSelect from "../DatasetByTypeSelect";
import Lang from "../LanguageSelect/Lang";
import { langString } from "../LanguageSelect/LanguageSelect";
import SystemDropdown from "../ProjectSelections/SystemDropdown";
import { setDeviceSettings } from "../StatisticsView";
import SystemConfig from "../SystemConfig/SystemConfig";
import CustomerSelect from "./CustomerSelect";
import EditingModal from "./EditingModal";
import SystemsFuctionSelect from "./SystemsFunctionSelect";
import SystemsTable from "./SystemsTable";
import ViewSystemBasicInfo from "./ViewSystemBasicInfo";

const SystemGraph = lazy(() => import(/* webpackChunkName: "plotlysystem" */ "./SystemGraph"));

const rfdclone = rfdc();

const batLowLimit = "Paristojännitteen alaraja";

const applyCustomQuantity = (quantity) => {
	if (quantity !== undefined)
		return ` ${quantity}`;
	return "";
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const applyDeviceSettings = ({ datasets, deviceSettings, hideLegends }) => {
	const output = { datasets };
	// Applying device settings to datasets
	if (isDefined({ datasets, deviceSettings })) {
		// Making deep copy of datasets
		const datasetsCopy = [];
		const keys = Object.keys(deviceSettings);
		datasets.forEach((datasetCopy) => {
			const dataset = rfdclone(datasetCopy);
			const index = keys.indexOf(dataset.deviceKey);
			// If dataset settings available apply them
			if (index > -1) {
				dataset.legendgroup = (deviceSettings[keys[index]].deviceName ?? dataset.deviceName);
				dataset.legendgrouptitle.text = `<b>${deviceSettings[keys[index]].deviceName ?? dataset.deviceName}</b>`;
				dataset.name = `${dataset.measType + applyCustomQuantity(dataset.customQuantity)} [${dataset.measUnit}]`;
				dataset.text = `${dataset.legendgroup} ${dataset.name}`;
				// If dataset visibility information available set dataset visible, by default set it invisible
				if (dataset.measi !== undefined && deviceSettings[keys[index]] !== undefined && deviceSettings[keys[index]].selected !== undefined && deviceSettings[keys[index]].selected[dataset.measi]) {
					dataset.visible = true;
				} else {
					dataset.visible = hideLegends ? false : "legendonly";
				}
				// Default dataset settings if no settings available
			} else {
				dataset.legendgroup = dataset.deviceName;
				dataset.legendgrouptitle.text = `<b>${dataset.deviceName}</b>`;
				dataset.name = `${dataset.measType + applyCustomQuantity(dataset.customQuantity)} [${dataset.measUnit}]`;
				dataset.text = `${dataset.legendgroup} ${dataset.name}`;
				dataset.visible = hideLegends ? false : "legendonly";
			}
			datasetsCopy.push(dataset);
		});
		output.datasets = datasetsCopy;
	}
	return output;
};

const getSystems = ({ customers, customerId, systems }) => {
	if (isDefined({ customers, customerId })) {
		return Object.keys(customers).length > 1 && customers[customerId] !== undefined && customers[customerId].systems !== undefined ? customers[customerId].systems : systems;
	}
	return [];
};

const processStatuses = (statuses) => {
	if (statuses) {
		// Sort by timestamp (t)
		statuses.sort((a, b) => a.t - b.t);
		return statuses.map((status) => ({ ...status, t: dayjs(status.t * 1000).format() }));
	}
	return [];
};

const getProjectName = (projectId, system, systems) => {
	if (projectId !== undefined && system !== undefined && system.id !== undefined && systems.length > 0) {
		const sysIndex = systems.findIndex(x => x.id === system.id);
		if (sysIndex > -1 && Array.isArray(systems[sysIndex].projects)) {
			const proIndex = systems[sysIndex].projects.findIndex(x => x.id === projectId);
			if (proIndex > -1 && systems[sysIndex].projects[proIndex].name !== undefined) {
				return systems[sysIndex].projects[proIndex].name;
			}
		}
	}
	return "-";
};

const getMeasTypes = (datasets) => datasets.length > 0 ? [...new Set(datasets.flatMap(dataset => dataset.measType === '' ? [] : dataset.measType))] : [];

const getDevices = (system) => {
	const loggerIds = system.id !== undefined && system.projects !== undefined && system.projects.length > 0 ? system.projects[0].loggerIds.split(",") : false;
	return loggerIds ? Object.fromEntries(
		loggerIds.map(loggerId => [loggerId, {
			index: loggerIds.indexOf(loggerId)
		}])
	) : {};
};

// Mapping to and from redux systems_ props (this.state.systemView)
const SYSTEMS_MAP = {
	showModal: "systemsShowModal", modalType: "systemsModalType", selectedMenuSystem: "systemsSelectedMenuSystem",
	datasets: "systemsDatasets", statuses: "systemsStatuses", lastTimeOnline: "systemsLastTimeOnline",
	selectedSystem: "systemsSelectedSystem",
	xAxisStartTime: "systemsXAxisStartTime", xAxisAbsoluteStartTime: "systemsXAxisAbsoluteStartTime", xAxisEndTime: "systemsXAxisEndTime", xAxisAbsoluteEndTime: "systemsXAxisAbsoluteEndTime",
	deviceSettings: "systemsDeviceSettings", hideLegends: "systemsHideLegends", init: "systemsInit",
	dragmode: "systemsDragmode", showspikes: "systemsShowspikes", hovermode: "systemsHovermode",
	loading: "systemsLoading", autoRangeTime: "systemsAutoRangeTime",
};

const remapToSystemsView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (SYSTEMS_MAP[key])
			output[SYSTEMS_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const remapFromSystemsView = (obj) => {
	// eslint-disable-next-line unicorn/no-array-reduce
	const MAP = Object.keys(SYSTEMS_MAP).reduce((res, key) => {
		res[SYSTEMS_MAP[key]] = key;
		return res;
	}, {});
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (MAP[key])
			output[MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const SYSTEMS_VALUES = [
	"systemsShowModal", "systemsModalType", "systemsSelectedMenuSystem",
	"systemsDatasets", "systemsStatuses", "systemsLastTimeOnline",
	"systemsSelectedSystem", "systemsCurrentProject",
	"systemsAutoRangeTime",
	"systemsXAxisStartTime", "systemsXAxisAbsoluteStartTime", "systemsXAxisEndTime", "systemsXAxisAbsoluteEndTime",
	"systemsDeviceSettings", "systemsHideLegends", "systemsInit",
	"systemsDragmode", "systemsShowspikes", "systemsHovermode",
];

const getOnlySystemsViewValues = (obj) => {
	// eslint-disable-next-line unicorn/no-array-reduce
	const objKeys = Object.keys(obj).reduce((res, key) => {
		res[key] = true;
		return res;
	}, {});
	// eslint-disable-next-line unicorn/no-array-reduce
	return SYSTEMS_VALUES.reduce((res, key) => {
		if (objKeys[key])
			res[key] = obj[key];
		return res;
	}, {});
};

class SystemsFunction extends Component {
	constructor() {
		super();

		this.mounted = false;
	}

	state = {
		loading: false,
	};

	componentDidMount() {
		const { location, customerId, getCustomerSystems, userId, customers, getCustomerData, systemsSelectedSystem } = this.props;
		let queryParams = false;
		if (location.search) {
			queryParams = queryString.parse(location.search);
		}
		// Refresh systems data
		if (!this.mounted && customerId !== undefined)
			getCustomerSystems({ customerId, userId, noUpdate: true });
		if (!this.mounted) {
			const state = store.getState();
			if ((state.systemsSelectedSystem && state.systemsSelectedSystem.id === undefined) || queryParams) {
				store.dispatch({
					type: "SET_VALUES",
					obj: {
						systemsSelectedSystem: this.autoSelectSystem({ customers: state.customers, customerId: state.customerId, systems: state.systems, queryParams }),
					}
				});
			}
		}
		this.mounted = true;
		// Fetch user data if initialy missing
		if (
			isAuthorized({ one: ["super"] }) &&
			getCustomerData &&
			customers !== undefined &&
			customerId !== undefined &&
			(customers[customerId] !== undefined ||
				customers[customerId].systems === undefined)
		) {
			getCustomerData({ customerId: Number(customerId) });
		}
		if (systemsSelectedSystem.id !== "all" && systemsSelectedSystem.id !== undefined) {
			const state = store.getState();
			this.updateViewSystem({ selectedSystem: state.systemsSelectedSystem });
		}
	}

	componentDidUpdate(prevProps) {
		const { systemsSelectedSystem, customers, customerId, systems, systemsLoading } = this.props;
		if (systemsSelectedSystem && (systemsSelectedSystem.id === undefined || systemsSelectedSystem.id === "all")) {
			const prevAuto = this.autoSelectSystem({ customers: prevProps.customers, customerId: prevProps.customerId, systems: prevProps.systems, queryParams: false });
			const newAuto = this.autoSelectSystem({ customers, customerId, systems, queryParams: false });
			if (prevAuto.id !== newAuto.id || prevProps.customerId !== customerId) {
				store.dispatch({
					type: "SET_VALUES",
					obj: { systemsSelectedSystem: newAuto }
				});
			}
		}
		if (!systemsLoading && systemsSelectedSystem.id !== prevProps.systemsSelectedSystem.id && systemsSelectedSystem.id !== "all" && systemsSelectedSystem.id !== undefined) {
			this.updateViewSystem({ selectedSystem: systemsSelectedSystem });
		} else if (systems && prevProps.systems && systemsSelectedSystem.id !== "all" && systemsSelectedSystem.id !== undefined) {
			const newIndex = systems.findIndex(x => x.id === systemsSelectedSystem.id);
			const prevIndex = prevProps.systems.findIndex(x => x.id === systemsSelectedSystem.id);
			if (newIndex > -1 && prevIndex > -1 && systems[newIndex].projects && prevProps.systems[prevIndex].projects && systems[newIndex].projects.length !== prevProps.systems[prevIndex].projects.length) {
				this.updateViewSystem({ selectedSystem: systemsSelectedSystem });
			}
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	getExtendedSystems(systems) {
		const { lang } = this.props;
		if (Array.isArray(systems))
			return [...systems, { id: "all", systemName: langString({ lang, strings: { en: "All", fi: "Kaikki", sv: "Alla" } }), sortIndex: 1 }];
		return systems;
	}

	autoSelectSystem = ({ customers, customerId, systems, selectedSystem, queryParams }) => {
		const { lang } = this.props;
		if (!selectedSystem || selectedSystem.id === undefined) {
			const sys = getSystems({ customers, customerId, systems });
			if (queryParams && queryParams.systemId !== undefined) {
				const index = sys.findIndex((element) => element.id === queryParams.systemId);
				if (index !== -1)
					return sys[index];
			}
			if (sys.length === 1) {
				return sys[0];
			}
		}
		return { id: "all", systemName: langString({ lang, strings: { en: "All", fi: "Kaikki", sv: "Alla" } }), sortIndex: 1 };
	};

	openModal = (system) => {
		const { setValues } = this.props;
		if (system === undefined) {
			setValues({
				systemsShowModal: true,
				systemsSelectedMenuSystem: {
					systemName: "",
					deviceType: "",
				},
				systemsModalType: "new",
			});
		} else {
			setValues({ systemsShowModal: true, systemsSelectedMenuSystem: system, systemsModalType: "edit" });
		}
	};

	closeModal = () => {
		const { setValues } = this.props;
		setValues({ systemsShowModal: false });
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	systemStatusCallback = (data) => {
		const { systemsXAxisStartTime, systemsXAxisEndTime, systemsXAxisAbsoluteStartTime, systemsXAxisAbsoluteEndTime, setValues } = this.props;
		if (data.status === "success") {
			const state = store.getState();
			const proStatuses = processStatuses(data.statuses);
			try {
				if (state.systemsSelectedSystem.projects && state.systemsSelectedSystem.projects.length > 0) {
					const loggerIds = data.projectId === undefined ? state.systemsSelectedSystem.projects[0].loggerIds.split(",") : state.systemsSelectedSystem.projects.find(project => project.id === data.projectId).loggerIds.split(",");
					const datasets = this.createDatasets(proStatuses, state.systemsConfigSystemInfo, loggerIds);
					const deviceSettings = setDeviceSettings({ datasets, deviceSettings: state.systemsDeviceSettings });
					// Applying device settings
					const transformed = applyDeviceSettings({
						datasets,
						deviceSettings,
						hideLegends: state.systemsHideLegends,
					});
					const update = remapToSystemsView({
						autoRangeTime: false,
						lastTimeOnline: data.lastTimeOnline,
						statuses: proStatuses,
						systemsCurrentProject: {
							name: getProjectName(data.projectId, state.systemsSelectedSystem, this.getExtendedSystems(state.systems)),
							id: data.projectId,
						},
						xAxisStartTime: data.statuses.length > 0 ? dayjs.unix(data.statuses[0].t).format() : systemsXAxisStartTime,
						xAxisAbsoluteStartTime: data.firstTimeOnline && systemsXAxisAbsoluteStartTime === undefined ? dayjs.unix(data.firstTimeOnline).format() : systemsXAxisAbsoluteStartTime,
						xAxisEndTime: data.statuses.length > 0 ? dayjs.unix(data.statuses[data.statuses.length - 1].t).format() : systemsXAxisEndTime,
						xAxisAbsoluteEndTime: data.lastTimeOnline && systemsXAxisAbsoluteEndTime === undefined ? dayjs.unix(data.lastTimeOnline).format() : systemsXAxisAbsoluteEndTime,
						datasets: transformed.datasets,
						deviceSettings,
						loading: false
					});
					setValues(update);
				} else {
					setValues({ loading: false });
				}
				
			} finally {

				// pass

			}
			
		} else {
			setValues({ loading : false });
		}
	};

	updateViewSystem = (obj) => {
		const { setValues } = this.props;
		if (obj.selectedSystem !== undefined && obj.selectedSystem.id !== undefined && obj.selectedSystem.id !== "all") {
			const update = remapToSystemsView({
				...obj,
				autoRangeTime: false,
				deviceSettings: {},
				loading: true,
				xAxisStartTime: dayjs().subtract(1, "months").format(),
				xAxisAbsoluteStartTime: undefined,
				xAxisEndTime: dayjs().format(),
				xAxisAbsoluteEndTime: undefined
			});
			setValues(update);
			const callback = (data) => {
				if (data.status === "success") {
					const dataCopy = rfdclone(data);
					delete dataCopy.status;
					if (data.API_ver >= 2) {
						setValues({ systemsConfigSystemInfo: dataCopy, loading: true });
					} else {
						setValues({ systemsConfigSystemInfo: dataCopy, systemsSelectedFunction: "info", loading: true });
					}
					const state = store.getState();
					Messenger.requestSystemStatus({
						payload: {
							systemId: obj.selectedSystem.id,
							start: false,
							end: parseInt(dayjs(state.systemsXAxisEndTime).valueOf() / 1000, 10),
						},
						callback: this.systemStatusCallback
					});
				} else {
					setValues({ systemsLoading: false });
				}
			};
			Messenger.requestSystem({
				payload: {
					systemId: obj.selectedSystem.id,
				},
				callback: callback.bind(this)
			});
		} else if (obj.selectedSystem !== undefined) {
			const update = remapToSystemsView({ ...obj, deviceSettings: {} });
			setValues({ ...update, systemsSelectedFunction: "info", systemsConfigSystemInfo: undefined });
		}
	};

	systemGraphUpdate = (objCopy) => {
		const { systemsDatasets, systemsDeviceSettings, systemsHideLegends, systemsSelectedSystem, systemsXAxisStartTime, systemsXAxisEndTime, setValues } = this.props;
		const obj = rfdclone(objCopy);
		const requests = [];
		if (obj.datasets || obj.deviceSettings || obj.hideLegends !== undefined) {
			const transformed = applyDeviceSettings({
				datasets: obj.datasets ?? systemsDatasets,
				deviceSettings: obj.deviceSettings ?? systemsDeviceSettings,
				hideLegends: obj.hideLegends === undefined ? systemsHideLegends : obj.hideLegends,
			});
			Object.assign(obj, transformed);
		}
		if ((obj.autoRangeTime !== undefined && obj.autoRangeTime) || ((obj.xAxisStartTime !== undefined || obj.xAxisEndTime !== undefined) && systemsSelectedSystem.id !== "all")) {
			let requestData = {};
			if (obj.autoRangeTime) {
				const currentTime = dayjs().format();
				requestData = {
					selectedSystem: systemsSelectedSystem,
					xAxisStartTime: currentTime,
					xAxisEndTime: currentTime,
					...obj
				};
			} else {
				requestData = {
					selectedSystem: systemsSelectedSystem,
					xAxisStartTime: systemsXAxisStartTime,
					xAxisEndTime: systemsXAxisEndTime,
					...obj
				};
			}
			requests.push(() => {
				Messenger.requestSystemStatus({
					payload: {
						systemId: requestData.selectedSystem.id,
						start: parseInt(dayjs(requestData.xAxisStartTime).valueOf() / 1000, 10),
						end: parseInt(dayjs(requestData.xAxisEndTime).valueOf() / 1000, 10),
					},
					callback: this.systemStatusCallback
				});
			});
		}
		if (requests.length === 0) {
			obj.loading = false;
		}
		const update = remapToSystemsView(obj);
		setValues(update);
		if (requests.length > 0)
			requests.forEach((request) => {
				request();
			});
	};

	toggleState = (loading) => {
		this.setState({ loading });
	};

	chooseSystems({ customers, customerId, systems }) {
		if (isAuthorized &&
			isAuthorized({ one: ["super"] }) &&
			customers !== undefined &&
			customerId !== undefined &&
			customers[customerId] !== undefined &&
			customers[customerId].systems !== undefined) {
			return this.getExtendedSystems(customers[customerId].systems);
		}
		if (systems !== undefined) {
			return this.getExtendedSystems(systems);
		}
		return [];
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	createDatasets(statuses, systemInfo, loggerIds) {
		const { start, end } = this.props;
		const cuVbat = { t: [], vbat: [] };
		let hasRRSSI = false; // Variable to keep track of values that may be present
		const rchChannelsObj = {};
		// eslint-disable-next-line unicorn/no-array-reduce
		const stats = statuses.reduce((res, status) => {
			if (status.t && status.vbat !== undefined) {
				cuVbat.t.push(status.t);
				cuVbat.vbat.push(status.vbat);
			}
			if (Array.isArray(status.nodes)) {
				status.nodes.forEach((node) => {
					if (res[node.id] === undefined)
						res[node.id] = { vbat: [], rssi_lo: [], rssi_hi: [], t: [], rrssi: [], rch: [] };
					if (node.vbat !== undefined && node.vbat !== null && node.vbat !== 0)
						res[node.id].vbat.push(node.vbat);
					else
						res[node.id].vbat.push(null);
					if (node.rssi_lo !== undefined && node.rssi_lo !== null)
						res[node.id].rssi_lo.push(node.rssi_lo);
					else
						res[node.id].rssi_lo.push(null);
					if (node.rssi_hi !== undefined && node.rssi_hi !== null)
						res[node.id].rssi_hi.push(node.rssi_hi);
					else
						res[node.id].rssi_hi.push(null);
					if (res[node.id].legendName === undefined && node.loggerName !== undefined)
						res[node.id].legendName = node.loggerName;
					// Values that may be present
					if (node.rrssi !== undefined && node.rrssi !== null) {
						res[node.id].rrssi.push(node.rrssi);
						if (!hasRRSSI)
							hasRRSSI = true;
					} else {
						res[node.id].rrssi.push(null);
					}
					if (node.rch !== undefined && node.rch !== null) {
						res[node.id].rch.push(node.rch);
						rchChannelsObj[node.rch] = true;
					} else {
						res[node.id].rch.push(null);
					}
					res[node.id].t.push(status.t);
				});
			}
			const sortedRes = {};
			Object.keys(res).sort((a, b) => loggerIds.indexOf(a) - loggerIds.indexOf(b)).forEach(id => {
				sortedRes[id] = res[id];
			});
			return sortedRes;
		}, {});
		const RCH_CHANNELS = Object.keys(rchChannelsObj);
		const keys = Object.keys(stats);
		let datasets = [];
		if (!systemInfo || (systemInfo.deviceType !== "DL-P1" && systemInfo.deviceType !== "DL-P2")) {
			// eslint-disable-next-line unicorn/no-array-reduce
			datasets = keys.reduce((res, key) => {
				const dataset = [
					{
						id: `${key}_vbat`,
						x: stats[key].t,
						y: stats[key].vbat.every(item => item === null) ? [] : stats[key].vbat,
						yaxis: undefined,
						type: "scattergl",
						mode: "lines",
						deviceName: stats[key].legendName === undefined ? key : stats[key].legendName,
						measType: "bat",
						measUnit: "V",
						name: "bat [V]",
						text: `${key} bat [V]`,
						hoverinfo: "x+y+text",
						legendgroup: stats[key].legendName === undefined ? key : stats[key].legendName,
						legendgrouptitle: {
							text: `<b>${stats[key].legendName === undefined ? key : stats[key].legendName}</b>`,
						},
						deviceKey: key,
						measi: 0,
						line: {
							width: 1,
						},
						marker: {
							size: 2,
						},
						visible: true
					},
					{
						id: `${key}_rssi_lo`,
						x: stats[key].t,
						y: stats[key].rssi_lo,
						yaxis: "y2",
						type: "scattergl",
						mode: "lines",
						deviceName: stats[key].legendName === undefined ? key : stats[key].legendName,
						measType: "RSSI LO",
						measUnit: "dBm",
						name: "RSSI LO [dBm]",
						text: `${key} RSSI LO [dBm]`,
						hoverinfo: "x+y+text",
						legendgroup: stats[key].legendName === undefined ? key : stats[key].legendName,
						legendgrouptitle: {
							text: `<b>${stats[key].legendName === undefined ? key : stats[key].legendName}</b>`,
						},
						deviceKey: key,
						measi: 1,
						line: {
							width: 1,
						},
						marker: {
							size: 2,
						},
						visible: true
					},
					{
						id: `${key}_rssi_hi`,
						x: stats[key].t,
						y: stats[key].rssi_hi,
						yaxis: "y2",
						type: "scattergl",
						mode: "lines",
						deviceName: stats[key].legendName === undefined ? key : stats[key].legendName,
						measType: "RSSI HI",
						measUnit: "dBm",
						name: "RSSI HI [dBm]",
						text: `${key} RSSI HI [dBm]`,
						hoverinfo: "x+y+text",
						legendgroup: stats[key].legendName === undefined ? key : stats[key].legendName,
						legendgrouptitle: {
							text: `<b>${stats[key].legendName === undefined ? key : stats[key].legendName}</b>`,
						},
						deviceKey: key,
						measi: 2,
						line: {
							width: 1,
						},
						marker: {
							size: 2,
						},
						visible: true
					}
				];
				res.push(...dataset);
				// Datasets that may be present
				if (hasRRSSI && stats[key].rrssi.length === stats[key].rch.length) {
					RCH_CHANNELS.forEach((channel, cindex) => {
						res.push({
							id: `${key}_rrssi_${channel}`,
							x: stats[key].t,
							y: stats[key].rrssi.every(item => item === null) ? [] : stats[key].rrssi.map((val, index) => {
								if (stats[key].rch[index] === channel)
									return val;
								return null;
							}),
							yaxis: "y2",
							type: "scattergl",
							mode: "lines",
							deviceName: stats[key].legendName === undefined ? key : stats[key].legendName,
							measType: `Rep RSSI ${channel}`,
							measUnit: "dBm",
							name: `Rep RSSI ${channel} [dBm]`,
							text: `${key} Rep RSSI ${channel} [dBm]`,
							hoverinfo: "x+y+text",
							legendgroup: stats[key].legendName === undefined ? key : stats[key].legendName,
							legendgrouptitle: {
								text: `<b>${stats[key].legendName === undefined ? key : stats[key].legendName}</b>`,
							},
							deviceKey: key,
							measi: 3 + cindex,
							line: {
								width: 1,
							},
							marker: {
								size: 2,
							},
							visible: true
						});
					});
				}
				return res;
			}, datasets);
		}
		if (!systemInfo || systemInfo.deviceType === "DL-P1") {
			if (cuVbat.t.length > 0) {
				datasets.push({
					id: "cu_vbat",
					x: cuVbat.t,
					y: cuVbat.vbat,
					yaxis: undefined,
					type: "scattergl",
					mode: "lines",
					deviceName: "DL-P1",
					measType: "bat",
					measUnit: "V",
					name: "bat [V]",
					text: "DL-P1 bat [V]",
					hoverinfo: "x+y+text",
					legendgroup: "DL-P1",
					legendgrouptitle: {
						text: "<b>DL-P1</b>",
					},
					deviceKey: "DL-P1",
					measi: 0,
					line: {
						width: 1,
					},
					marker: {
						size: 2,
					},
					visible: true
				});
			}
		} else if (systemInfo.deviceType === "DL-P2") {
			if (cuVbat.t.length > 0) {
				datasets.push({
					id: "cu_vbat",
					x: cuVbat.t,
					y: cuVbat.vbat,
					yaxis: undefined,
					type: "scattergl",
					mode: "lines",
					deviceName: "DL-P2",
					measType: "bat",
					measUnit: "V",
					name: "bat [V]",
					text: "DL-P2 bat [V]",
					hoverinfo: "x+y+text",
					legendgroup: "DL-P2",
					legendgrouptitle: {
						text: "<b>DL-P2</b>",
					},
					deviceKey: "DL-P2",
					measi: 0,
					line: {
						width: 1,
					},
					marker: {
						size: 2,
					},
					visible: true
				});
			}
		} else if (systemInfo.deviceType === "APw") {
			if (cuVbat.t.length > 0) {
				datasets.push({
					id: "cu_vbat",
					x: cuVbat.t,
					y: cuVbat.vbat,
					yaxis: undefined,
					type: "scattergl",
					mode: "lines",
					deviceName: "APw",
					measType: "bat",
					measUnit: "V",
					name: "bat [V]",
					text: "APw bat [V]",
					hoverinfo: "x+y+text",
					legendgroup: "APw",
					legendgrouptitle: {
						text: "<b>APw</b>",
					},
					deviceKey: "APw",
					measi: 0,
					line: {
						width: 1,
					},
					marker: {
						size: 2,
					},
					visible: true
				});
			}
			datasets.push({
				id: batLowLimit,
				x: [dayjs(start).subtract(1, "days").toDate(), dayjs(end).add(1, "days").toDate()],
				y: [3.2, 3.2],
				yaxis: undefined,
				type: "scattergl",
				mode: "lines",
				deviceName: batLowLimit,
				measType: "",
				measUnit: "V",
				name: batLowLimit,
				text: batLowLimit,
				hoverinfo: "x+y+text",
				deviceKey: batLowLimit,
				legendgroup: batLowLimit,
				legendgrouptitle: {
					text: "<b>Paristojännitteen alaraja</b>",
				},
				measi: 1,
				line: {
					width: 3,
					color: "#bf2303",
				},
				marker: {
					size: 2,
				},
				visible: true
			});
		} else {
			if (cuVbat.t.length > 0) {
				datasets.push({
					id: "cu_vbat",
					x: cuVbat.t,
					y: cuVbat.vbat,
					yaxis: undefined,
					type: "scattergl",
					mode: "lines",
					deviceName: "APw+",
					measType: "bat",
					measUnit: "V",
					name: "bat [V]",
					text: "APw+ bat [V]",
					hoverinfo: "x+y+text",
					legendgroup: "APw+",
					legendgrouptitle: {
						text: "<b>APw+</b>",
					},
					deviceKey: "APw+",
					measi: 0,
					line: {
						width: 1,
					},
					marker: {
						size: 2,
					},
					visible: true
				});
			}
			datasets.push({
				id: batLowLimit,
				x: [dayjs(start).subtract(1, "days").toDate(), dayjs(end).add(1, "days").toDate()],
				y: [3.2, 3.2],
				yaxis: undefined,
				type: "scattergl",
				mode: "lines",
				deviceName: batLowLimit,
				measType: "",
				measUnit: "V",
				name: batLowLimit,
				text: batLowLimit,
				hoverinfo: "x+y+text",
				deviceKey: batLowLimit,
				legendgroup: batLowLimit,
				legendgrouptitle: {
					text: "<b>Paristojännitteen alaraja</b>",
				},
				measi: 1,
				line: {
					width: 3,
					color: "#bf2303",
				},
				marker: {
					size: 2,
				},
				visible: true
			});
		}
		return datasets;
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	render() {
		const {
			customers, customerId, selectCustomer, lang, projectsSome, systemsSelectedFunction, systemsDatasets, systemsDeviceSettings, systemsSelectedSystem, systemsSpinnerLoading, systemsShowModal, scrollTop,
			systemsSelectedMenuSystem, removeSystemData, systemsModalType, getCustomerData, getCustomerSystems, userId, systemsCurrentProject, systemsLastTimeOnline, systemsStatuses, systemsLoading, systemsAutoRangeTime,
			systemsXAxisStartTime, systemsXAxisEndTime, systemsXAxisAbsoluteStartTime, systemsXAxisAbsoluteEndTime, systemsHideLegends, systemsDragmode, systemsShowspikes, systemsHovermode, systemsConfigSystemInfo
		} = this.props;
		const { loading } = this.state;
		if (isAuthorized({ one: ["super", "system"] })) {
			const systems = this.chooseSystems(this.props);
			return (
				<div className="pageDefault systems">
					<div className="leftSidePanel">
						<div>
							<CustomerSelect
								customers={customers}
								customerId={customerId}
								selectCustomer={selectCustomer}
							/>
							<div className="mainSelector">
								<Accordion
									defaultActiveKey="0"
									id="accordianPanel"
									className="menuPanelStatic"
								>
									<Card>
										<Card.Header>
											<Lang lang={lang}>
												<div key="en">Systems</div>
												<div key="fi">Järjestelmät</div>
												<div key="sv">System</div>
											</Lang>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												<SystemDropdown
													systems={systems}
													selectedSystem={remapFromSystemsView(getOnlySystemsViewValues({ ...this.props })).selectedSystem}
													updateAncestor={this.updateViewSystem}
												/>
												{
													(isAuthorized({ one: ["super"] })) ?
														<div className="text-center"><Button className="addSystems-button" variant="primary" onClick={() => this.openModal()}>
															<Lang en="Add system" fi="Lisää järjestelmä" sv="Lägg till system" />
														</Button></div> : null
												}
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</div>
						<SystemsFuctionSelect
							projectsSome={projectsSome}
						/>
						{systemsSelectedFunction === "info" ?
							<>
								<div className="cardBackground">
									<DatasetByTypeSelect
										updateAncestor={this.systemGraphUpdate}
										datasets={systemsDatasets}
										deviceSettings={systemsDeviceSettings}
										types={getMeasTypes(systemsDatasets)}
										lang={lang}
										alwaysOpen
									/>
								</div>
								<div className="cardBackground">
									<DatasetByLoggerSelect
										updateAncestor={this.systemGraphUpdate}
										datasets={systemsDatasets}
										deviceSettings={systemsDeviceSettings}
										devices={getDevices(systemsSelectedSystem)}
										lang={lang}
										alwaysOpen
									/>
								</div>
							</>
							: null}
					</div>
					<div className="rightSidePanel">
						{systemsSelectedSystem.id === "all" ? <div className="systems-table-wrapper cardBackground">
							<SystemsTable
								customers={customers}
								customerId={customerId}
								systems={systems}
								selectSystem={this.openModal}
								isAuthorized={isAuthorized}
								loading={systemsSpinnerLoading || loading}
							/>
						</div> : null}
						{
							systemsShowModal ?
								<EditingModal
									show={systemsShowModal}
									close={this.closeModal}
									selectedSystem={systemsSelectedMenuSystem}
									removeSystemData={removeSystemData}
									customerId={customerId}
									customers={customers}
									newSystem={systemsModalType === "new"}
									getCustomerData={getCustomerData}
									isAuthorized={isAuthorized}
									getCustomerSystems={getCustomerSystems}
									userId={userId}
									toggleState={this.toggleState}
								/>
								:
								null
						}
						{systemsSelectedSystem.id === "all" ?
							null : <ViewSystemBasicInfo
								system={systemsSelectedSystem}
								projectName={systemsCurrentProject.name}
								lastTimeOnline={systemsLastTimeOnline}
								statuses={systemsStatuses}
								loading={systemsLoading}
							/>
						}
						{systemsSelectedSystem.id !== "all" && systemsSelectedFunction === "info" ?
							<Suspense fallback={<Spinner animation="border" />}>
								<SystemGraph
									datasets={systemsDatasets}
									autoRangeTime={systemsAutoRangeTime}
									xAxisStartTime={systemsXAxisStartTime}
									xAxisAbsoluteStartTime={systemsXAxisAbsoluteStartTime}
									xAxisEndTime={systemsXAxisEndTime}
									xAxisAbsoluteEndTime={systemsXAxisAbsoluteEndTime}
									updateAncestor={this.systemGraphUpdate}
									deviceSettings={systemsDeviceSettings}
									hideLegends={systemsHideLegends}
									lang={lang}
									dragmode={systemsDragmode}
									showspikes={systemsShowspikes}
									hovermode={systemsHovermode}
									systemInfo={systemsConfigSystemInfo}
									loading={systemsLoading}
								/>
							</Suspense>
							: null
						}
						{systemsSelectedSystem.id !== "all" && systemsSelectedFunction === "config" && systemsConfigSystemInfo !== undefined && systemsConfigSystemInfo.API_ver >= 2 ?
							<SystemConfig scrollTop={scrollTop} />
							: null
						}
						{systemsSelectedSystem.id !== "all" && systemsConfigSystemInfo !== undefined && systemsConfigSystemInfo.API_ver < 2 ?
							<div className="systems-config">
								<Lang lang={lang}>
									<div className="erro-msg cardBackground" key="en">The software version of the device is too old, and the system configuration wont work via the cloud. Please update the device&apos;s software with the program found in this link, or contact Pietiko.</div>
									<div className="erro-msg cardBackground" key="fi">Laitteen ohjelmistoversio on liian vanha, eikä järjestelmän konfigurointi toimi pilven kautta. Ole hyvä ja päivitä laitteen ohjelmisto tästä linkistä löytyvällä ohjelmalla, tai ota yhteyttä Pietikoon.</div>
									<div className="erro-msg cardBackground" key="sv">Programvaruversionen av enheten är för gammal och systemkonfigurationen fungerar inte via molnet. Vänligen uppdatera enhetens programvara med programmet som finns i denna länk, eller kontakta Pietiko.</div>
								</Lang>
							</div>
							: null
						}
						<Spinner id="systemsRightSpinner" animation="border" style={{ display: systemsLoading ? "inline-block" : "none" }} />
					</div>
				</div>
			);
		}
		return null;
	}
}

function Systems(props) {
	const location = useLocation();
	return <SystemsFunction {...props} location={location} />;
}

function mapStateToProps(state) {
	const { lang, customers, customerId, systems, userId, projectsSome,
		systemsShowModal, systemsModalType, systemsSelectedMenuSystem,
		systemsDatasets, systemsStatuses, systemsLastTimeOnline,
		systemsSelectedSystem, systemsCurrentProject,
		systemsAutoRangeTime,
		systemsXAxisStartTime, systemsXAxisAbsoluteStartTime, systemsXAxisEndTime, systemsXAxisAbsoluteEndTime,
		systemsDeviceSettings, systemsHideLegends, systemsInit,
		systemsDragmode, systemsShowspikes, systemsHovermode,
		systemsSelectedFunction, systemsConfigSystemInfo,
		systemsLoading,
	} = state;
	return {
		lang, customers, customerId, systems, userId, projectsSome,
		systemsShowModal, systemsModalType, systemsSelectedMenuSystem,
		systemsDatasets, systemsStatuses, systemsLastTimeOnline,
		systemsSelectedSystem, systemsCurrentProject,
		systemsAutoRangeTime,
		systemsXAxisStartTime, systemsXAxisAbsoluteStartTime, systemsXAxisEndTime, systemsXAxisAbsoluteEndTime,
		systemsDeviceSettings, systemsHideLegends, systemsInit,
		systemsDragmode, systemsShowspikes, systemsHovermode,
		systemsSelectedFunction, systemsConfigSystemInfo,
		systemsLoading,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Systems);
