import "./EmailAlert.css";

import { Component } from "react";
import { Accordion, Button, Card, Form, Table } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import { connect } from "react-redux";
import Switch from "react-switch";
import rfdc from "rfdc";

import Lang from "../../LanguageSelect/Lang";

const rfdclone = rfdc();

const getPanelTitle = (emails) => {
	const lastIndex = emails.length - 1;
	// eslint-disable-next-line unicorn/no-array-reduce
	const string = emails.reduce((res, email, index) => {
		let emailString = res;
		emailString += email + (lastIndex === index ? "" : ", ");
		return emailString;
	}, "");
	if (string.length === 0 || /^[ ,]+$/g.test(string))
		return <Lang en="Emails" fi="Sähköpostit" sv="E-posten" />;
	return string.length > 40 ? `${string.slice(0, 40)}...` : string;
};

const handleChange = (value, setValues) => {
	setValues({ systemsConfigEmailActive: value });
};

const handleHeaderChange = (e, setValues) => {
	setValues({ systemsConfigEmailHeader: e.target.value.replace(/[+,:;|]/g, "") });
};

class EmailAlert extends Component {
	handelEmailChange = (index, text) => {
		const { systemsConfigEmails, setValues } = this.props;
		const systemsConfigEmailsCopy = rfdclone(systemsConfigEmails);
		systemsConfigEmailsCopy[index] = text;
		setValues({ systemsConfigEmails: systemsConfigEmailsCopy });
	};

	addEmail = () => {
		const { systemsConfigEmails, setValues } = this.props;
		const systemsConfigEmailsCopy = rfdclone(systemsConfigEmails);
		systemsConfigEmailsCopy.push("");
		setValues({ systemsConfigEmails: systemsConfigEmailsCopy });
	};

	removeEmail = (index) => {
		const { systemsConfigEmails, setValues } = this.props;
		const systemsConfigEmailsCopy = rfdclone(systemsConfigEmails);
		systemsConfigEmailsCopy.splice(index, 1);
		setValues({ systemsConfigEmails: systemsConfigEmailsCopy });
	};

	getEmails = (emails) => {
		const { editAuth } = this.props;
		let output = [];
		if (Array.isArray(emails)) {
			output = emails.map((email, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<tr key={`email_item_${i}`} className="email-item">
					<td>
						<Form.Control
							disabled={!editAuth}
							type="text"
							value={email}
							onChange={(e) => this.handelEmailChange(i, e.target.value)}
						/>
					</td>
					<td className="email-remove-button-cell">
						<Button
							className="btn-xs"
							disabled={!editAuth}
							size="sm"
							variant="danger"
							onClick={() => this.removeEmail(i)}
						>
							<IconContext.Provider
								value={{ className: "reactIcon" }}
							>
								<BsX />
							</IconContext.Provider>
						</Button>
					</td>
				</tr>
			));
		}
		return output;
	};

	render() {
		const { systemsConfigEmailActive, editAuth, setValues, systemsConfigEmails, systemsConfigEmailHeader } = this.props;
		if (systemsConfigEmailActive !== null) {
			const components = [
				<span key="email_switch" className="setting email-selector separated-alignment-parent switch-setting">
					<span className="title separated-alignment-left">
						<Lang en="Email alert" fi="Sähköpostihälytys" sv="E-post larm" />
					</span>
					<span className="separated-alignment-right">
						<Switch
							disabled={!editAuth}
							onChange={(value) => handleChange(value, setValues)}
							checked={systemsConfigEmailActive}
							onHandleColor="#5cb75c"
							onColor="#b1dbaf"
							height={13}
							width={33}
							handleDiameter={19}
							boxShadow="1px 1px 3px 1px rgba(0, 0, 0, 0.3)"
							uncheckedIcon={false}
							checkedIcon={false}
						/>
					</span>
				</span>
			];
			if (systemsConfigEmailActive) {
				const elements = [
					<span key="email_addresses" className="email-addresses separated-alignment-parent">
						<span className="title separated-alignment-left">
							<Lang en="Emails" fi="Sähköpostiosoitteet hälytyksille" sv="E-posten" />
						</span>
						<span className="separated-alignment-right">
							<Accordion id="accordianPanel" className="menuPanelStatic">
								<Accordion.Item eventKey="0">
									<Accordion.Button as={Card.Header} style={{ cursor: "pointer", minWidth: "250px", height: "30px" }}>
										{getPanelTitle(systemsConfigEmails)}
									</Accordion.Button>
									<Accordion.Body>
										<Card.Body>
											<div className="email-list-table-wrapper">
												<Table className="email-list" size="sm">
													<tbody>
														{this.getEmails(systemsConfigEmails)}
													</tbody>
												</Table>
											</div>
											<div className="add-button-wrapper">
												<Button
													disabled={!editAuth || systemsConfigEmails.length >= 5}
													className="add-button"
													size="sm"
													variant="success"
													onClick={this.addEmail}
												>
													<Lang en="Add" fi="Lisää" sv="Tillägg" />
												</Button>
											</div>
										</Card.Body>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</span>
					</span>,
					<span key="email-header" className="email-header separated-alignment-parent">
						<span className="title separated-alignment-left">
							<Lang en="Alert header" fi="Hälytysviestin otsikko" sv="Larm rubrik" />
						</span>
						<span className="separated-alignment-right">
							<Form.Control
								disabled={!editAuth}
								style={{ minWidth: "250px" }}
								type="text"
								size="sm"
								value={systemsConfigEmailHeader}
								onChange={(e) => handleHeaderChange(e, setValues)}
							/>
						</span>
					</span>
				];
				components.push(...elements);
			}
			return components;
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { systemsConfigEmailActive, systemsConfigEmailHeader, systemsConfigShowEmails, systemsConfigEmails } = state;
	return { systemsConfigEmailActive, systemsConfigEmailHeader, systemsConfigShowEmails, systemsConfigEmails };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailAlert);
