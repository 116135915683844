import "../css/CardBackground.css";
import "../css/LeftSidePanel.css";

import { Component } from "react";

import CustomerSelect from "./CustomerSelect";
import GraphSettings from "./GraphSettings";
import { MainSelector } from "./ProjectSelections/ProjectSelections";


export default class LeftSidePanel extends Component {
	updateAncestor = (obj) => {
		const { updateAncestor } = this.props;
		const objCopy = obj;
		objCopy.lastUpdate = "leftSidePanel";
		updateAncestor(objCopy);
	};

	render() {
		const {
			customers, customerId, selectCustomer, clearSelections, lang, systems, selectedSystem, projects,
			selectedProject, nTh, avgNth, xAxisStartTime, xAxisAbsoluteStartTime, xAxisEndTime, xAxisAbsoluteEndTime,
			reportId, reportName, storeReport, deleteReport, updateAncestor, title, subtitle, xAxisTitle, xAxisTicks,
			xAxisTickZero, xAxisTicksDays, xAxisTicksHours, xAxisTicksMinutes, xAxisFilter, xAxisFilterStartTime,
			xAxisFilterEndTime, yAxisTitle, yAxisMax, yAxisMin, yAxisTicks, yAxisTickZero, y2AxisTitle, y2AxisMax,
			y2AxisMin, y2AxisQuantities, datasets, measTypes, selectedMeasType, deviceSettings, limits, selectedLimits,
			devices, settingsLoading, lastUpdate, tempNth, fontSize
		} = this.props;
		return (
			<div>
				<CustomerSelect
					customers={customers}
					customerId={customerId}
					selectCustomer={selectCustomer}
					clearSelections={clearSelections}
					lang={lang}
					checkProjects
				/>
				<MainSelector
					customerId={customerId}
					systems={systems}
					selectedSystem={selectedSystem}
					projects={projects}
					selectedProject={selectedProject}
					nTh={nTh}
					avgNth={avgNth}
					xAxisStartTime={xAxisStartTime}
					xAxisAbsoluteStartTime={xAxisAbsoluteStartTime}
					xAxisEndTime={xAxisEndTime}
					xAxisAbsoluteEndTime={xAxisAbsoluteEndTime}
					updateAncestor={this.updateAncestor}
					lang={lang}
				/>
				<GraphSettings
					customerId={customerId}
					selectedSystem={selectedSystem}
					selectedProject={selectedProject}
					reportId={reportId}
					reportName={reportName}
					storeReport={storeReport}
					deleteReport={deleteReport}
					updateAncestor={updateAncestor}
					lang={lang}
					projects={projects}
					title={title}
					subtitle={subtitle}
					xAxisTitle={xAxisTitle}
					xAxisStartTime={xAxisStartTime}
					xAxisAbsoluteStartTime={xAxisAbsoluteStartTime}
					xAxisEndTime={xAxisEndTime}
					xAxisAbsoluteEndTime={xAxisAbsoluteEndTime}
					xAxisTicks={xAxisTicks}
					xAxisTickZero={xAxisTickZero}
					xAxisTicksDays={xAxisTicksDays}
					xAxisTicksHours={xAxisTicksHours}
					xAxisTicksMinutes={xAxisTicksMinutes}
					xAxisFilter={xAxisFilter}
					xAxisFilterStartTime={xAxisFilterStartTime}
					xAxisFilterEndTime={xAxisFilterEndTime}
					yAxisTitle={yAxisTitle}
					yAxisMax={yAxisMax}
					yAxisMin={yAxisMin}
					yAxisTicks={yAxisTicks}
					yAxisTickZero={yAxisTickZero}
					y2AxisTitle={y2AxisTitle}
					y2AxisMax={y2AxisMax}
					y2AxisMin={y2AxisMin}
					y2AxisQuantities={y2AxisQuantities}
					datasets={datasets}
					measTypes={measTypes}
					selectedMeasType={selectedMeasType}
					deviceSettings={deviceSettings}
					limits={limits}
					selectedLimits={selectedLimits}
					devices={devices}
					settingsLoading={settingsLoading}
					lastUpdate={lastUpdate}
					nTh={nTh}
					avgNth={avgNth}
					tempNth={tempNth}
					fontSize={fontSize}
					updateQualityAncestor={this.updateAncestor}
				/>
			</div>
		);
	}
}