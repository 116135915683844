/* eslint-disable no-console */
/* eslint-disable promise/no-callback-in-promise */
/* eslint-disable promise/catch-or-return */
/* eslint-disable no-param-reassign */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable promise/always-return */
/* eslint-disable unicorn/no-static-only-class */
import FileSaver from "file-saver";
import { toast } from "react-toastify";

import TokenHandler from "./tokenHandler";
import isDefined from "./util";

const applicationJson = "application/json";

class Messenger {
	static async requestLogin({ username, password, callback }) {
		const payload = JSON.stringify({ username, password });
		const response = await fetch("../api/login/", {
			"method": "post",
			"headers": {
				"accept": applicationJson,
				"Content-Type": applicationJson,
			},
			"body": payload
		});
		const data = await response.json();
		callback(data);
	};


	// Customer requests
	static async requestCustomerData({ customerId, callback }) {
		const token = TokenHandler.getCurrentToken();
		const payload = JSON.stringify({ customerId, token });
		const response = await fetch("../api/getCustomer/", {
			"method": "post",
			"headers": {
				"accept": applicationJson,
				"Content-Type": applicationJson,
			},
			"body": payload
		});
		const data = await response.json();
		callback(data);
	};

	static async requestNewCustomer({ customerName, maxUsers, loginAllowed, callback }) {
		const token = TokenHandler.getCurrentToken();
		const payload = JSON.stringify({ name: customerName, maxUsers, loginAllowed, token });
		const response = await fetch("../api/newCustomer/", {
			"method": "post",
			"headers": {
				"accept": applicationJson,
				"Content-Type": applicationJson,
			},
			"body": payload
		});
		const data = await response.json();
		callback(data);
	}

	static async requestDeleteCustomer({ customerId, callback }) {
		const token = TokenHandler.getCurrentToken();
		const payload = JSON.stringify({ customerId, token });
		const response = await fetch("../api/deleteCustomer/", {
			"method": "post",
			"headers": {
				"accept": applicationJson,
				"Content-Type": applicationJson,
			},
			"body": payload
		});
		const data = await response.json();
		callback(data);
	}

	static async requestUpdateCustomer({ customerId, customerName, maxUsers, loginAllowed, callback }) {

		const token = TokenHandler.getCurrentToken();
		const payload = JSON.stringify({ customerId, name: customerName, maxUsers, loginAllowed, token });
		const response = await fetch("../api/updateCustomer/", {
			"method": "post",
			"headers": {
				"accept": applicationJson,
				"Content-Type": applicationJson,
			},
			"body": payload
		});
		const data = await response.json();
		callback(data);
	}

	static async requestGetCustomerSystems({ customerId, callback }) {
		const token = TokenHandler.getCurrentToken();
		const payload = JSON.stringify({ customerId, token });
		const response = await fetch('../api/getCustomerSystems/', {
			'method': 'post',
			'headers': {
				'accept': applicationJson,
				'Content-Type': applicationJson,
			},
			'body': payload
		});
		const data = await response.json();
		callback(data);
	}


	// User requests
	static async requestNewUser({ customerId, userName, rights, projectsSome, supportCustomers, language, callback }) {
		if (isDefined({ customerId, userName, rights, callback })) {
			const token = TokenHandler.getCurrentToken();
			const base = { customerId, userName, rights, language: language === undefined ? "fi" : language, token };
			const additional = {};
			if (projectsSome !== undefined)
				additional.projectsSome = projectsSome;
			if (supportCustomers !== undefined)
				additional.supportCustomers = supportCustomers;
			const payload = JSON.stringify({ ...base, ...additional });
			const response = await fetch("../api/newUser/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);

		}
	}

	static async requestUpdateUser({ userId, rights, projectsSome, supportCustomers, callback }) {
		if (isDefined({ userId, rights, callback })) {
			const token = TokenHandler.getCurrentToken();
			const base = { userId, rights, token };
			const additional = {};
			if (projectsSome !== undefined)
				additional.projectsSome = projectsSome;
			if (supportCustomers !== undefined)
				additional.supportCustomers = supportCustomers;
			const payload = JSON.stringify({ ...base, ...additional });
			const response = await fetch("../api/updateUser/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestUpdateUserOwnSettings({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token
		) {
			const response = await fetch("../api/updateUserOwnSettings/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestDeleteUser({ userId, callback }) {
		if (isDefined({ userId, callback })) {
			const token = TokenHandler.getCurrentToken();
			const payload = JSON.stringify({ userId, token });
			const response = await fetch("../api/deleteUser/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestSetPassword({ token, password, language, callback }) {
		if (isDefined({ token, password, language, callback })) {
			const payload = JSON.stringify({ token, password, language: language === undefined ? null : language });
			const response = await fetch("../api/setPassword/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestResetPassword({ userName, email, token, callback }) {
		if (isDefined({ userName, email, callback })) {
			// eslint-disable-next-line security/detect-possible-timing-attacks
			if (token === undefined) {
				token = TokenHandler.getCurrentToken();
			}
			const payload = JSON.stringify({ userName, email, token });
			const response = await fetch("../api/resetPassword/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestResetPasswordEmail({ username, callback }) {
		if (isDefined({ username, callback })) {
			const payload = JSON.stringify({ username });
			const response = await fetch("../api/resetPasswordEmail/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestChangePassword({ oldPassword, newPassword, callback }) {
		if (isDefined({ oldPassword, newPassword, callback })) {
			const token = TokenHandler.getCurrentToken();
			const payload = JSON.stringify({ oldPassword, newPassword, token });
			const response = await fetch("../api/changePassword/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}


	// System requests
	static async requestNewSystem({ payload, callback }) {
		if (callback && payload.customerId && payload.systemId && payload.name && payload.deviceType) {
			payload.token = TokenHandler.getCurrentToken();
			const response = await fetch("../api/newSystem/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestUpdateSystem({ systemId, systemName, callback }) {
		if (isDefined({ systemId, systemName, callback })) {
			const token = TokenHandler.getCurrentToken();
			const payload = JSON.stringify({ systemId, name: systemName, token });
			const response = await fetch("../api/updateSystem/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestDeleteSystem({ systemId, callback }) {
		if (isDefined({ systemId, callback })) {
			const token = TokenHandler.getCurrentToken();
			const payload = JSON.stringify({ systemId, token });
			const response = await fetch("../api/deleteSystem/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestRegenerateSystemToken({ systemId, callback }) {
		if (isDefined({ systemId, callback })) {
			const token = TokenHandler.getCurrentToken();
			const payload = JSON.stringify({ systemId, token });
			const response = await fetch("../api/regenerateSystemToken/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	}

	// Project requests

	static async requestProjectData({ projectId, callback }) {
		const token = TokenHandler.getCurrentToken();
		if (token) {
			const payload = JSON.stringify({ projectId, token });
			const response = await fetch("../api/getProjectData/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": payload
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestNthProjectData({ payload, callback }) {
		const token = TokenHandler.getCurrentToken();
		Object.assign(payload, { amount: 1_000_000, token });
		if (payload.token) {
			const response = await fetch("../api/getProjectData/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestProjectDataStatistics({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined &&
			payload.projectId !== undefined &&
			payload.start !== undefined &&
			payload.end !== undefined &&
			payload.datasets !== undefined
		) {
			const response = await fetch("../api/getProjectDataStatistics/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestDeviceData({ payload, callback }) {
		const token = TokenHandler.getCurrentToken();
		if (token) {
			const response = await fetch("../api/getDeviceData/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(Object.assign(payload, { token }))
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestProjectLimits({ payload, callback }) {
		const token = TokenHandler.getCurrentToken();
		if (token) {
			const response = await fetch("../api/getProjectLimits/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(Object.assign(payload, { token }))
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async updateProjectLimits({ payload, callback }) {
		const token = TokenHandler.getCurrentToken();
		if (token) {
			const response = await fetch("../api/updateProjectLimits/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(Object.assign(payload, { token }))
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestUpdateProjectData({ payload, callback }) {
		if (isDefined({ payload, callback })) {
			const token = TokenHandler.getCurrentToken();
			Object.assign(payload, { token });
			if (payload.token) {
				const response = await fetch("../api/updateProjectData/", {
					"method": "post",
					"headers": {
						"accept": applicationJson,
						"Content-Type": applicationJson,
					},
					"body": JSON.stringify(payload)
				});
				const data = await response.json();
				callback(data);
			}
		}
	};

	static checkReportValidity(report) {
		if (report.customerId === undefined) {
			return false;
		}
		if (report.systemId === undefined) {
			return false;
		}
		return !(report.projectId === undefined);
	}

	static async submitReport(payload, callback) {
		payload.token = TokenHandler.getCurrentToken();
		const reportValidity = this.checkReportValidity(payload);
		if (payload.token && reportValidity) {
			const response = await fetch("../api/saveReportSettings/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestReports({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined &&
			payload.projectId !== undefined
		) {
			const response = await fetch("../api/getReportsForProject/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestReportData({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.reportId !== undefined &&
			payload.projectId !== undefined
		) {
			const response = await fetch("../api/getReportSettings/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestDeleteReport({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.reportId
		) {
			const response = await fetch("../api/deleteReport/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async copyProjectData({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined &&
			payload.projectId !== undefined &&
			payload.targetProjectId !== undefined
		) {
			const response = await fetch("../api/copyProjectSettings/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	static requestCSV = ({ payload, addData }) => {
		payload.token = TokenHandler.getCurrentToken();
		const FILENAME = payload.filename;
		if (payload.token &&
			payload.start !== undefined &&
			payload.end !== undefined &&
			payload.projectId !== undefined &&
			payload.format !== undefined &&
			payload.filename !== undefined &&
			payload.lang !== undefined
		) {
			fetch("../api/exportCSV/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			})
				.then(res => res.status === 200 ? res.blob() : res.json())
				.then(data => {
					if (data instanceof Blob) {
						FileSaver.saveAs(data, FILENAME);
						if (addData && addData.successMsg && addData.notification)
							toast.update(addData.notification, { render: addData.successMsg, type: "success", isLoading: false, autoClose: 5000, pauseOnFocusLoss: false });
						else
							toast.dismiss();
					} else {
						if (addData && addData.failMsg && addData.notification)
							toast.update(addData.notification, { render: addData.failMsg, type: "error", isLoading: false, autoClose: 5000 });
						else
							toast.dismiss();
						toast.error("CSV file download failed");
						// Printing out server error to console
						if (data.status === "error" && data.msg !== undefined)
							console.log("CSV download failed. Error msg:", data.msg);
					}
				})
				.catch(error => {
					console.warn("CSV export failed.");
					console.warn("Error:", error);
					if (addData && addData.failMsg && addData.notification)
						toast.update(addData.notification, { render: addData.failMsg, type: "error", isLoading: false, autoClose: 5000 });
					else
						toast.dismiss();
					console.log("CSV download failed");
				});
		} else {
			console.warn("Missing csv request information.");
		}
	};

	static async requestSystemStatus({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined &&
			payload.start !== undefined &&
			payload.end !== undefined
		) {
			const response = await fetch("../api/getSystemStatuses/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestSystem({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined
		) {
			const response = await fetch("../api/getSystem/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestSystemConfigState({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined &&
			payload.customerId !== undefined
		) {
			const response = await fetch("../api/getSystemConfigState/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestSystemConfig({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined
		) {
			const response = await fetch("../api/getSystemConfig/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestSetSystemConfig({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.customerId !== undefined && payload.systemId !== undefined &&
			payload.device !== undefined && payload.log !== undefined && payload.alarm !== undefined && payload.loggers
		) {
			const response = await fetch("../api/setSystemConfig/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestLoggerState({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined &&
			payload.customerId !== undefined
		) {
			const response = await fetch("../api/getSystemLoggerState/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async addLogger({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined &&
			payload.loggerId !== undefined &&
			payload.loggerName !== undefined) {
			const response = await fetch("../api/addLoggerToSystem/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async removeLogger({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined &&
			payload.loggerId !== undefined) {
			const response = await fetch("../api/removeLoggerFromSystem/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async sendNodeCommand({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token &&
			payload.customerId !== undefined &&
			payload.systemId !== undefined &&
			payload.loggerId !== undefined) {
			const response = await fetch("../api/sendCommandForNode/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestNodeCommandHistory({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined &&
			payload.customerId !== undefined &&
			payload.loggerId !== undefined
		) {
			const response = await fetch("../api/getNodeCommandHistory/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestNewProject({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined &&
			payload.customerId !== undefined &&
			payload.projectName !== undefined
		) {
			const response = await fetch("../api/newProject/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	};

	static async requestNewProjectState({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.systemId !== undefined &&
			payload.customerId !== undefined
		) {
			const response = await fetch("../api/getNewProjectState/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async deleteProject(payload) {
		const body = payload;
		body.token = TokenHandler.getCurrentToken();
		if (
			body.token &&
			body.customerId !== undefined &&
			body.systemId &&
			body.projectId
		) {
			const response = await fetch("../api/deleteProject/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(body)
			});
			return response.json();
		}
		return { status: "error" };
	}

	static async requestNewInfoMessage({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.infoMessageText !== undefined
		) {
			const response = await fetch("../api/newInfoMessage/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestDeleteInfoMessage({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.infoMessageId
		) {
			const response = await fetch("../api/deleteInfoMessage/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestLatestInfoMessages({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.maxAmount !== undefined
		) {
			const response = await fetch("../api/getLatestInfoMessages/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async addGraphComment({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token && payload.projectId && payload.systemId && payload.comment && callback
		) {
			const response = await fetch("../api/newGraphComment/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async requestGraphComments({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token && payload.projectId !== undefined && payload.systemId !== undefined) {
			const response = await fetch("../api/getGraphComments/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async editGraphComment({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token && payload.commentId && payload.projectId && payload.systemId && payload.commentText && callback
		) {
			const response = await fetch("../api/editGraphComment/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async deleteGraphComment({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token && payload.commentId && payload.projectId && payload.systemId && callback
		) {
			const response = await fetch("../api/deleteGraphComment/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}

	static async saveLoggerOrder(funcPayload) {
		const payload = funcPayload;
		payload.token = TokenHandler.getCurrentToken();
		if (payload.token && payload.projectId && payload.systemId && payload.loggerIds) {
			const response = await fetch("../api/saveLoggerOrder/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			return response.json();
		}
		return new Error("Payload incomplete");
	}

	static async requestLatestsAlarms({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token &&
			payload.maxAmount !== undefined
		) {
			const response = await fetch("../api/getSystemAlarms/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}


	static async requestNodeById({ payload, callback }) {
		payload.token = TokenHandler.getCurrentToken();
		if (
			payload.token !== undefined
		) {
			const response = await fetch("../api/getNodeById/", {
				"method": "post",
				"headers": {
					"accept": applicationJson,
					"Content-Type": applicationJson,
				},
				"body": JSON.stringify(payload)
			});
			const data = await response.json();
			callback(data);
		}
	}
}


export default Messenger;
/* eslint-enable no-console */
/* eslint-enable promise/no-callback-in-promise */
/* eslint-enable promise/catch-or-return */
/* eslint-enable no-param-reassign */
/* eslint-enable sonarjs/no-duplicate-string */
/* eslint-enable promise/always-return */
/* eslint-enable unicorn/no-static-only-class */
