import { Spinner, Table, Accordion, Button, Card } from "react-bootstrap";
import rfdc from "rfdc";
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";

import Lang from "../LanguageSelect/Lang";
import Messenger from "../../utilities/messenger";
import TokenHandler from "../../utilities/tokenHandler";


const rfdclone = rfdc();

const unixToDate = (timestamp) => {
    const date = new Date(timestamp * 1000).toUTCString();
    return date;
}

const getLoggerName = async (data, logger_id) => {

    if (data.status === "success") {
        const nodeData = data.nodeData[0];
        const projectLoggerIds = nodeData[1].split(',');
        const desiredLoggerId = logger_id;
        const index = projectLoggerIds.indexOf(desiredLoggerId);

        if (index !== -1) {
            const loggerNames = nodeData[0].split(',');
            const correspondingLoggerName = loggerNames[index];
            return correspondingLoggerName;
        } else if (desiredLoggerId == "-") {
            return "-"
        } else {
            return `${desiredLoggerId} not found in the list of logger IDs.`;
        }
    } else {
        return "Invalid response data structure or status is not 'success'.";
    }
}

const constructAlarmMessage = (alarm) => {
    const message = alarm.alarm_type;

    switch (message) {
        case "pwr_disconn":
            return <Lang en="Power supply disconnected" fi="Virtalähde irroitettu" sv="Strömkälla frånkopplad"/>;

        case "pwr_conn":
            return <Lang en="Power supply connected" fi="Virtalähde kytketty" sv="Strömkälla ansluten"/>;
        
        case "link_up":
            return <Lang en={`Wireless connection established`} fi={`Langaton yhteys muodostettu`} sv={`Trådlös anslutning upprättad`}/>;

        case "link_down":
            return <Lang en={`Wireless connection lost`} fi={`Langaton yhteys katkennut`} sv={`Trådlös anslutning förlorad`}/>;

        case "a_low":
            return <Lang en={`Low measurement alarm`} fi={`Alhaisen mittauksen hälytys`} sv={`Alarm för låg mätning`}/>;

        case "r_low":
            return <Lang en={`Low measurement return to normal`} fi={`Alhainen mittaus palaa normaaliksi`} sv={`Låg mätning återgår till det normala`}/>;
        
        case "a_high":
            return <Lang en={`High measurement alarm`} fi={`Korkean mittauksen hälytys`} sv={`Alarm för hög mätning`}/>;

        case "r_high":
            return <Lang en={`High measurement return to normal`} fi={`Korkea mittaus palaa normaaliksi`} sv={`Hög mätning återgår till det normala`}/>;

        case "bat_low":
            return <Lang en="Low battery voltage" fi="Alhainen pariston jännite" sv="Låg batterispänning"/>;

        case "node_bat_low":
            return <Lang en={`Node battery low`} fi={`Noden paristo vähissä`} sv={`Nodens batteri låg`}/>;

        default:
            return message;
    }
}

const fetchAndParseAlarmsForSystem = async (customerId, selectedSystem, projectId) => {
    try {
        const alarmsDataPromise = new Promise((resolve, reject) => {
            const callback = (data) => {
                console.log("API Response:", data); // Log the API response for debugging
                if (data && data.alarmsData) {
                    resolve(data.alarmsData);
                } else {
                    resolve([]); // Return an empty array when no alarmsData is available
                }
            };

            const payload = {
                token: TokenHandler.getCurrentToken(),
                customerId: customerId,
                systemId: selectedSystem,
                maxAmount: 5 // Set the desired maximum amount
            };

            Messenger.requestLatestsAlarms({ payload, callback });
        });

        const alarmsData = await alarmsDataPromise;

        const nodeDataPromise = new Promise((resolve, reject) => {
            const callback = (data) => {
                console.log("API Response:", data); // Log the API response for debugging
                if (data.nodeData && data.status === "success") {
                    resolve(data)
                } else {
                    reject("Invalid response data structure or status is not 'success'.")
                }
            };

            const payload = {
                token: TokenHandler.getCurrentToken(),
                systemId: selectedSystem,
                projectId: projectId,
                customerId: customerId
            };

            Messenger.requestNodeById({ payload, callback});
        }); 

        const nodeData = await nodeDataPromise;

        // Parse alarmsData and return an array of parsed rows
        if (Array.isArray(alarmsData)) {
            const parsedRows = alarmsData.map(async (alarm) => {
                const loggerName = await getLoggerName(nodeData, alarm.logger_id)
                return (
                    <tr className="alarm-list" key={`alarm_${alarm.id}`}>
                        <td>{alarm.id}</td>
                        <td>{unixToDate(alarm.timestamp)}</td>
                        <td>{constructAlarmMessage(alarm)}</td>
                        <td>{loggerName}</td>
                        <td>{alarm.sensor_id}</td>
                        <td>{alarm.measurement_result}</td>
                        <td>{alarm.threshold_value}</td>
                    </tr>
                );
            });

            return Promise.all(parsedRows); // Wait for all Promises to resolve
        } else {
            console.error("Invalid alarmsData format:", alarmsData);
            return [];
        }
    } catch (error) {
        console.error("Error fetching and parsing alarms:", error);
        // Handle error case
        throw error;
    }
};


// eslint-disable-next-line sonarjs/cognitive-complexity
const parseRows = async (customers, customerId, systems, select, projectId) => {
    const entries = [];
  
    systems.sort((a, b) =>
        a.systemName.localeCompare(b.systemName, undefined, {
            numeric: true,
            sensitivity: 'base',
            ignorePunctuation: true,
        })
    );
  
    for (const system of systems) {
        if (system.id !== 'all' && system.projects !== undefined) {
            system.projects.sort((a, b) => b.id - a.id);
    
            // Fetch and parse alarm rows
            const alarmRows = await fetchAndParseAlarmsForSystem(customerId, system.id, system.projects[0].id);
    
            const projectButtons = (
                <div className="project-buttons">
                    <Link to={`/mainpage?systemId=${system.id}&projectId=${system.projects[0].id}`}>
                        <Button>Projektin PK:iin</Button>
                    </Link>
                    <Link to={`/blueprints?systemId=${system.id}&projectId=${system.projects[0].id}`}>
                        <Button>Projektin PK:iin</Button>
                    </Link>
                </div>
            );
    
            const alarmTable = alarmRows.length > 0 ? (           
                <tbody>
                    {alarmRows}
                </tbody>               
            ) : (
                <p>No alarms to display</p>
            );
    
            entries.push(
            <Accordion.Item key={`system_${system.id}`}>
                    <Accordion.Header>
                        <div className="projectName">{system.systemName ?? '-'}</div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="project-buttons">
                            {projectButtons}
                        </div>                        
                        <div className="alarm-list">
                            <h3><Lang en="Latest alarms" fi="Viimeisimmät hälytykset" sv="Senaste larmen" /></h3>
                            <Table>
                                <thead>
                                    <tr className="alarm-list-headers">
                                        <td>ID</td>
                                        <td>Ajankohta</td>
                                        <td>Hälytys</td>
                                        <td>Loggerin nimi</td>
                                        <td>Sensori</td>
                                        <td>Mittausarvo</td>
                                        <td>Raja</td>
                                    </tr>
                                </thead>
                                    {alarmTable}
                            </Table>
                        </div>
                    </Accordion.Body>
            </Accordion.Item>
            );
        }
    }
  
    return entries;
  };
  
  


const AlarmsView = ({ loading, selectSystem, systems, customers, customerId, projectId }) => {
    const [parsedRows, setParsedRows] = useState([]);
    const [expandedItem, setExpandedItem] = useState("0");

    const prevSystemRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const parsedRows = await parseRows(customers, customerId, systems, selectSystem, projectId);
                setParsedRows(parsedRows);
            } catch (error) {
                console.error("Error fetching and parsing rows:", error);
            }
        };

        fetchData();
    }, [customers, customerId, systems, selectSystem]);

    useEffect(() => {
        if (prevSystemRef.current !== undefined && prevSystemRef.current !== systems.id)
            setExpandedItem("0");
        prevSystemRef.current = systems.id;
    }, [systems]);

    if (loading) {
        return <Spinner animation="border" className="systems-spinner" />;
    }
    // Define the number of accordions based on the number of parsed rows
    const numAccordions = parsedRows.length;

    // Initialize the expandedItems array with `false` for each accordion
    if (expandedItem.length !== numAccordions) {
        setExpandedItem(Array(numAccordions).fill(false));
    }

    return (
        <div className="cardBackground">
            {parsedRows.map((row, index) => (
                <Accordion
                    key={`accordion_${index}`}
                    defaultActiveKey="0"
                    alwaysOpen
                    activeKey={expandedItem[index]}
                    onSelect={(e) => {
                        const newExpandedItem = [...expandedItem];
                        newExpandedItem[index] = e[0];
                        setExpandedItem(newExpandedItem);
                    }}
                >
                    {row}
                </Accordion>
            ))}
      </div>
    );
};

export default AlarmsView;