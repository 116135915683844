const RENEWAL_TIMEOUT_MINUTES = 10;
let RENEWAL = null;

class TokenHandler {
	token = undefined;

	logout = undefined;

	setLogout = (logout) => {
		this.logout = logout;
	};

	setToken = (token) => {
		this.token = token;
		sessionStorage.setItem("token", JSON.stringify(this.token));
		this.setRenewal(RENEWAL_TIMEOUT_MINUTES * 60_000);
	};

	requestNewToken = () => {
		const payload = JSON.stringify({ token: JSON.parse(sessionStorage.getItem("token")) });
		if (payload !== '{"token":null}') {
			fetch("../api/renew_session_token/", {
				"method": "post",
				"headers": {
					"accept": "application/json",
					"Content-Type": "application/json",
				},
				"body": payload
			})
				.then(res => res.json())
				.then(data => {
					if (
						data &&
						data.status === "success" &&
						data.session_token !== undefined
					) {
						this.token = data.session_token;
						sessionStorage.setItem("token", JSON.stringify(data.session_token));
						this.setRenewal(RENEWAL_TIMEOUT_MINUTES * 60_000);
						return true;
					}
					// Log user out if renewal fails
					this.logout(true);
					return true;
				})
				.catch(() => {
					// Log user out if renewal fails
					this.logout(true);
				});
		}
	};

	setRenewal = (time) => {
		clearTimeout(RENEWAL);
		RENEWAL = setTimeout(() => this.requestNewToken(), time);
	};

	getCurrentToken() {
		this.token = JSON.parse(sessionStorage.getItem("token"));
		return JSON.parse(sessionStorage.getItem("token"));
	}
}

export default new TokenHandler();