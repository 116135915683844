import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import Lang from "../LanguageSelect/Lang";

export default class UploadBlueprintModal extends Component {
	state = {
		filename: null,
		file: null,
		fileSize: null,
		blueprintName: "",
		blueprintDescription: ""
	};

	onFileSelected = (event) => {
		const supportedTypes = ["image/jpg", "image/jpeg", "image/png"];
		const selectedFile = event.target.files[0];
		if (!selectedFile) {
			return;
		}
		if (!supportedTypes.includes(selectedFile.type)) {
			toast.warning(<Lang en="Blueprints support only JPG/JPEG/PNG formats" fi="Pohjakuvat tukevat vain JPG/JPEG/PNG tiedostoja" sv="Plan stöder endast JPG/JPEG/PNG -format" />);
			return;
		}
		this.setFile(selectedFile);
	};

	setFile(selectedFile) {
		this.setState({
			file: selectedFile,
			filename: selectedFile.name,
			fileSize: `${Math.round(selectedFile.size / 1024)} KB`
		});
	}

	handleDescriptionChange = (event) => {
		this.setState({ blueprintDescription: event.target.value });
	};

	handleNameChange = (event) => {
		this.setState({ blueprintName: event.target.value });
	};

	upload = () => {
		const { uploadFile } = this.props;
		const { file, filename, blueprintName, blueprintDescription } = this.state;
		uploadFile(file, filename, blueprintName, blueprintDescription);
		this.setState({
			file: null,
			filename: null,
			fileSize: null,
			blueprintName: "",
			blueprintDescription: ""
		});
	};

	close = () => {
		const { uploadModalClosed } = this.props;
		uploadModalClosed();
	};

	dragAndDropFile = (event) => {
		event.stopPropagation();
		event.preventDefault();
		if (event.dataTransfer.items) {
			// Use DataTransferItemList interface to access the file(s)
			for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
				// If dropped items aren't files, reject them
				if (event.dataTransfer.items[i].kind === "file") {
					const file = event.dataTransfer.items[i].getAsFile();
					this.setFile(file);
				}
			}
		} else {
			// Use DataTransfer interface to access the file(s)
			for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
				this.setFile(event.dataTransfer.files[i]);
			}
		}
	};

	render() {
		const { show, lang, uploadModalClosed } = this.props;
		const { filename, fileSize, blueprintName, blueprintDescription } = this.state;
		return (
			<Modal className="upload-blueprint-modal" show={show} onHide={this.close} onDrop={this.dragAndDropFile} onDragOver={(evt) => evt.preventDefault()} onDragEnter={(evt) => evt.preventDefault()}>
				<Modal.Header closeButton>
					<Modal.Title>
						<Lang lang={lang} fi="Lähetä pohjakuva" en="Upload blueprint" sv="Ladda upp planet" />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="blueprintModal">
					{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label htmlFor="file-upload" className="btn btn-light">
						<Lang lang={lang} fi="Valitse tiedosto" sv="Välja fil" en="Select file" />
					</label>&nbsp;
					<Lang lang={lang} fi="Tukee vain .jpg/.jpeg/.png" sv="Stöder endast .jpg/.jpeg/.png" en="Supports only .jpg/.jpeg/.png" /><br />
					<input id="file-upload" type="file" accept=".jpg,.jpeg,.png" className="form-control" onChange={this.onFileSelected} />
					<span className="uploadFilename"><strong>{filename}</strong>&nbsp;
						{filename ? <Lang lang={lang} fi="Koko:" en="Size:" sv="Storlek:" /> : null}
						&nbsp;{fileSize}
					</span>
					<div className="upload-blueprint-fields">
						<label htmlFor="blueprint-name">
							<Lang lang={lang} fi="Pohjakuvan nimi" en="Blueprint name" sv="Plan namn" />
							<input id="blueprint-name" type="text" value={blueprintName} className="form-control" onChange={this.handleNameChange} />
						</label>
						<label htmlFor="blueprint-description">
							<Lang lang={lang} fi="Kuvaus (valinnainen)" en="Description (optional)" sv="Beskrivning (valfri)" />
							<textarea id="blueprint-description" type="text"
								value={blueprintDescription}
								className="form-control blueprintDescription"
								maxLength="4000"
								onChange={this.handleDescriptionChange} />
						</label>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						onClick={() => uploadModalClosed()}>
						<Lang lang={lang} fi="Peruuta" en="Cancel" sv="Ångra" />
					</Button>
					<Button
						type="submit"
						variant="success"
						onClick={this.upload}
						disabled={!filename || !blueprintName}
					>
						<Lang lang={lang} fi="Lähetä " sv="Ladda upp " en="Upload " />
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}