import { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import getFooterButtons from "../ConfigModalOptions";
import Lang from "../LanguageSelect/Lang";
import EditingOptions from "./EditingOptions";

const rfdclone = rfdc();

const getRightDisplayName = ({ right, lang }) => {
    const displayNames = {
        admin: (
            <Lang lang={lang}>
                <span key="en">User management</span>
                <span key="fi">Käyttäjien hallinta</span>
                <span key="sv">Användarhantering</span>
            </Lang>
        ),
        supportCustomers: (
            <Lang lang={lang}>
                <span key="en">Customer support</span>
                <span key="fi">Asiakastuki</span>
                <span key="sv">Kundstöd</span>
            </Lang>
        ),
        system: (
            <Lang lang={lang}>
                <span key="en">Device management</span>
                <span key="fi">Laitteiden hallinta</span>
                <span key="sv">Enhetshantering</span>
            </Lang>
        ),
        reports: (
            <Lang lang={lang}>
                <span key="en">Settings template management</span>
                <span key="fi">Asetuspohjien hallinta</span>
                <span key="sv">Inställningsmallhantering</span>
            </Lang>
        ),
        projectsSome: (
            <Lang lang={lang}>
                <span key="en">Access to individual projects</span>
                <span key="fi">Pääsy yksittäisiin projekteihin</span>
                <span key="sv">Tillgång till enskilda projekt</span>
            </Lang>
        ),
        project_admin: (
            <Lang lang={lang}>
                <span key="en">Project settings management</span>
                <span key="fi">Projektin asetuksien hallinta</span>
                <span key="sv">Hantering av project inställningar</span>
            </Lang>
        ),
    };
    if (right !== undefined && displayNames[right] !== undefined) {
        return displayNames[right];
    }
    return <span>{right}</span>;
};

const getEditorModalTitle = ({ newView, deleteView, passwordResetView, lang }) => {
    if (!deleteView && !passwordResetView) {
        return newView ? (
            <Lang lang={lang}>
                <span key="en">New user</span>
                <span key="fi">Uusi käyttäjä</span>
                <span key="sv">Ny användare</span>
            </Lang>
        ) : (
            <Lang lang={lang}>
                <span key="en">Edit user</span>
                <span key="fi">Muokkaa käyttäjää</span>
                <span key="sv">Redigera kund</span>
            </Lang>
        );
    }
    return passwordResetView ? (
        <Lang lang={lang}>
            <span key="en">Password reset</span>
            <span key="fi">Salasanan resetointi</span>
            <span key="sv">Återställandet av lösenord</span>
        </Lang>
    ) : (
        <Lang lang={lang}>
            <span key="en">User removal</span>
            <span key="fi">Käyttäjän poisto</span>
            <span key="sv">Användar radering</span>
        </Lang>
    );

};

const getFirstSystem = (systems) => {
    if (systems !== undefined && systems[0] !== undefined) {
        return systems[0];
    }
    return {};
};

const parseRights = (user, availableRights) => {
    const out = rfdclone(availableRights);
    if (user !== undefined && user.rights !== undefined) {
        user.rights.forEach(right => {
            if (availableRights[right] !== undefined)
                out[right] = true;
        });
        if (availableRights.supportCustomers !== undefined) {
            out.supportCustomers = false;
        }
        if (user.projectsSome !== undefined) {
            out.projectsSome = true;
        }
    }
    return out;
};

const parseSupportCustomers = (user) => {
    const selectedSupportCustomers = {};
    if (user !== undefined && user.rights !== undefined && user.supportCustomers !== undefined) {
        user.supportCustomers.forEach((customerId) => {
            selectedSupportCustomers[customerId] = true;
        });
    }
    return selectedSupportCustomers;
};

const filterValidRights = (selectedRights, currentUserRights) => {
    const rightsCopy = rfdclone(selectedRights);
    currentUserRights.forEach((right) => {
        if (rightsCopy[right] === undefined) {
            rightsCopy[right] = true;
        }
    });
    return Object.keys(rightsCopy).filter((key) => {
        if (key !== "supportCustomers" && key !== "projectsSome")
            return rightsCopy[key];
        return false;
    });
};

const getSelectedProjectSome = (selectedProjects) => {
    const tempSelectedProjects = {};
    Object.keys(selectedProjects).forEach(systemId => {
        Object.keys(selectedProjects[systemId]).forEach(projectId => {
            if (selectedProjects[systemId][projectId]) {
                if (tempSelectedProjects[systemId] === undefined)
                    tempSelectedProjects[systemId] = [];
                tempSelectedProjects[systemId].push(projectId);
            }
        });
    });
    return tempSelectedProjects;
};

const getUserProjects = (customers, customerId, user) => {
    if (customers && customerId !== undefined && customers[customerId] !== undefined && customers[customerId].users !== undefined && user !== undefined && user.userId !== undefined) {
        const index = customers[customerId].users.findIndex(x => x.userId === user.userId);
        if (index > -1 && customers[customerId].users[index].projectsSome !== undefined) {
            // eslint-disable-next-line unicorn/no-array-reduce
            return Object.keys(customers[customerId].users[index].projectsSome).reduce((res, sysKey) => {
                if (res[sysKey] === undefined)
                    res[sysKey] = {};
                if (customers[customerId].users[index].projectsSome[sysKey].length > 0) {
                    // eslint-disable-next-line unicorn/no-array-reduce
                    customers[customerId].users[index].projectsSome[sysKey].reduce((res2, proId) => {
                        const tempRes2 = res2;
                        tempRes2[proId] = true;
                        return tempRes2;
                    }, res[sysKey]);
                }
                return res;
            }, {});
        }
    }
    return {};
};

// Check if user is not admin of customer
const nonOwnAdmin = (customerId, customers, userId) => {
    if (customerId !== undefined && userId !== undefined && customers !== undefined && isAuthorized !== undefined && isAuthorized({ one: ["admin"] })) {
        const keys = Object.keys(customers);
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
            if (customers[key].users !== undefined) {
                const index = customers[key].users.findIndex(x => x.userId === userId);
                if (index > -1) {
                    return customers[key].customerId !== customerId;
                }
            }
        }
    }
    return false;
};

const userMatchDisable = ({ matchId, userId }) => matchId === userId && !isAuthorized({ one: ["admin"] });

export default class EditingModal extends Component {
    /* eslint-disable react/destructuring-assignment */
    state = {
        user: this.props.selectedUser,
        selectedRights: parseRights(this.props.selectedUser, this.props.availableRights),
        deleteView: false,
        passwordResetView: false,
        selectedSupportCustomers: parseSupportCustomers(this.props.selectedUser),
        selectedProjects: getUserProjects(this.props.customers, this.props.customerId, this.props.selectedUser),
        selectedSystem: getFirstSystem(this.props.systems).id,
        newUserLang: "fi",
    };
    /* eslint-enable react/destructuring-assignment */

    handleModalClose = () => {
        const { close } = this.props;
        if (close)
            close();
    };

    handleUserTextChange = (e, prop) => {
        if (prop && e) {
            const value = e.target.value.replace(" ", "");
            this.setState(({ user }) => {
                const tempUser = rfdclone(user);
                tempUser[prop] = value;
                return { user: tempUser };
            });
        }
    };

    handleRightToggle = (right) => {
        this.setState(({ selectedRights }) => {
            const tempSelectedRights = rfdclone(selectedRights);
            tempSelectedRights[right] = !tempSelectedRights[right];
            return { selectedRights: tempSelectedRights };
        });
    };

    getRights = ({ lang }) => {
        const { availableRights } = this.props;
        const { selectedRights } = this.state;
        let entries = [];
        if (availableRights !== undefined && selectedRights !== undefined) {
            entries = Object.keys(availableRights).map(right => (
                <Form.Check
                    type="checkbox"
                    id={`right_${right}`}
                    key={`right_${right}`}
                    checked={selectedRights[right]}
                    onChange={() => { this.handleRightToggle(right); }}
                    label={getRightDisplayName({ right, lang })}
                />
            ));
        }
        return entries;
    };

    setDeleteViewState = (bool) => {
        if (bool === undefined)
            {this.setState({ deleteView: false });}
        else
            {this.setState({ deleteView: bool });}
    };

    setPasswordResetViewState = (bool) => {
        if (bool === undefined)
            {this.setState({ passwordResetView: false });}
        else
            {this.setState({ passwordResetView: bool });}
    };

    selectProject = ({ systemId, projectId }) => {
        this.setState(({ selectedProjects }) => {
            const tempSelectedProjects = rfdclone(selectedProjects);
            if (tempSelectedProjects[systemId] === undefined)
                tempSelectedProjects[systemId] = {};
            tempSelectedProjects[systemId][projectId] = !tempSelectedProjects[systemId][projectId];
            return { selectedProjects: tempSelectedProjects };
        });
    };

    resetPassword = () => {
        const { user } = this.state;
        if (user && user.userId !== undefined) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="User password reset" fi="Käyttäjän salasana resetoitu" sv="Användarens lösenord har återställts" />);
                } else {
                    toast.error(<Lang en="Failed to reset user password" fi="Käyttäjän salasanan resetointi epäonnistui" sv="Återställandet av användrens lösenord misslyckades" />);
                }
            };
            Messenger.requestResetPassword({ callback, ...user, email: true });
        }
        this.handleModalClose();
    };

    deleteUser = () => {
        const { customerId, removeUserData } = this.props;
        const { user } = this.state;
        if (user && user.userId !== undefined) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="User deleted" fi="Käyttäjä poistettu" sv="Användaren raderades" />);
                    removeUserData({ customerId, userId: user.userId });
                } else {
                    toast.error(<Lang en="Failed to remove user" fi="Käyttäjän poisto epäonnistui" sv="Raderingen av användren misslyckades" />);
                }
            };
            Messenger.requestDeleteUser({ callback, ...user });
        }
        this.handleModalClose();
    };

    selectSupportCustomer = ({ customerId }) => {
        this.setState(({ selectedSupportCustomers }) => {
            const tempSelectedSupportCustomers = rfdclone(selectedSupportCustomers);
            tempSelectedSupportCustomers[customerId] = !tempSelectedSupportCustomers[customerId];
            return { selectedSupportCustomers: tempSelectedSupportCustomers };
        });
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    submit = () => {
        const { newUser, getCustomerData, customerId, userId } = this.props;
        const { user, selectedRights, selectedSupportCustomers, selectedProjects, newUserLang } = this.state;
        if (newUser) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="New user created" fi="Uusi käyttäjä luotu" sv="Ny användare skapad" />);
                    getCustomerData({ customerId, userId: data.userId });
                } else if (data.msg && data.msg === "UniqueViolation") {
                    toast.error(<Lang en="User with given email already exists in the database. Please use a different email." fi="Annetulla sähköpostiosoitteella löytyy jo käyttäjä tietokannasta. Käytä toista sähköpostiosoitetta." sv="Användare med angiven e-postadress finns redan i databasen. Använd en annan e-postadress." />, { autoClose: 10_000, });
                } else {
                    toast.error(<Lang en="User creation failed" fi="Uuden käyttäjän luonti epäonnistui" sv="Skapning av användare misslyckades" />);
                }
            };
            Messenger.requestNewUser({
                ...user,
                callback,
                rights: filterValidRights(selectedRights, []),
                customerId,
                // eslint-disable-next-line unicorn/no-array-reduce
                supportCustomers: Object.keys(selectedSupportCustomers).reduce((res, key) => {
                    if (selectedSupportCustomers[key])
                        res.push(Number(key));
                    return res;
                }, []),
                projectsSome: getSelectedProjectSome(selectedProjects),
                language: newUserLang,
            });
        } else {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="User information updated" fi="Käyttäjän tiedot päivitetty" sv="Användarens information updaterades" />);
                    getCustomerData({ customerId, userId });
                } else {
                    toast.error(<Lang en="Failed to update user information" fi="Käyttäjän tietojen päivitys epäonnistui" sv="Updateringen av användarens information misslyckades" />);
                }
            };
            Messenger.requestUpdateUser({
                ...user,
                rights: filterValidRights(selectedRights, user.rights),
                callback,
                // eslint-disable-next-line unicorn/no-array-reduce
                supportCustomers: Object.keys(selectedSupportCustomers).reduce((res, key) => {
                    if (selectedSupportCustomers[key])
                        res.push(Number(key));
                    return res;
                }, []),
                projectsSome: selectedRights.projectsSome !== undefined && selectedRights.projectsSome === true ? getSelectedProjectSome(selectedProjects) : {}
            });
        }
        this.handleModalClose();
    };

    selectSystem = ({ systemId }) => {
        this.setState({ selectedSystem: systemId });
    };

    updateNewUserLang = ({ lang }) => {
        this.setState({ newUserLang: lang });
    };

    render() {
        const { show, newUser, lang, availableRights, customers, systems, customerId, userId } = this.props;
        const { user, selectedRights, deleteView, passwordResetView, selectedSupportCustomers, selectedProjects, selectedSystem, newUserLang } = this.state;
        if (user !== undefined) {
            return (
                <Modal
                    size={selectedRights.projectsSome ? "xl" : "lg"}
                    className="customerEditModal user-edit-modal"
                    show={show}
                    onHide={this.handleModalClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {getEditorModalTitle({
                                newView: newUser,
                                deleteView,
                                passwordResetView,
                                lang
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditingOptions
                            newUser={newUser}
                            handleUserTextChange={this.handleUserTextChange}
                            getRights={this.getRights}
                            availableRights={availableRights}
                            selectedRights={selectedRights}
                            user={user}
                            lang={lang}
                            visible={!deleteView && !passwordResetView}
                            customers={customers}
                            systems={systems}
                            selectedSupportCustomers={selectedSupportCustomers}
                            selectSupportCustomer={this.selectSupportCustomer}
                            selectProject={this.selectProject}
                            selectedProjects={selectedProjects}
                            selectSystem={this.selectSystem}
                            selectedSystem={selectedSystem}
                            customerId={customerId}
                            newUserLang={newUserLang}
                            updateNewUserLang={this.updateNewUserLang}
                        />
                        <DeleteView
                            visible={deleteView}
                            name={user.userName}
                            lang={lang}
                        />
                        <PasswordResetView
                            visible={passwordResetView}
                            name={user.userName}
                            lang={lang}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {getFooterButtons({
                            newView: newUser,
                            deleteView,
                            setDeleteViewState: this.setDeleteViewState,
                            submit: this.submit,
                            closeModal: this.handleModalClose,
                            deleteFunc: this.deleteUser,
                            userMatch: userMatchDisable({ matchId: user.userId, userId }),
                            valid: !!(user.userName && user.userName.includes("@")),
                            passwordResetView,
                            setPasswordResetViewState: this.setPasswordResetViewState,
                            passwordResetFunc: this.resetPassword,
                            disableDelete: nonOwnAdmin(customerId, customers, userId),
                        })}
                    </Modal.Footer>
                </Modal>
            );
        }
        return null;
    }
}

function DeleteView({ visible, lang, name }) {
    if (visible) {
        return (
            <div className="deleteView">
                <div className="title">
                    <Lang lang={lang}>
                        <span key="en">Delete user?</span>
                        <span key="fi">Poistetaanko käyttäjä?</span>
                        <span key="sv">Radera användare?</span>
                    </Lang>
                </div>
                <div className="deletable-name">
                    {name}
                </div>
            </div>
        );
    }
    return null;
}

function PasswordResetView({ visible, lang, name }) {
    if (visible) {
        return (
            <div className="passResetView">
                <div className="title">
                    <Lang lang={lang}>
                        <span key="en">Reset user password?</span>
                        <span key="fi">Resetoi käyttäjän salasana?</span>
                        <span key="sv">Återställ användarens lösenord?</span>
                    </Lang>
                </div>
                <div className="resetable-name">
                    {name}
                </div>
            </div>
        );
    }
    return null;
};
