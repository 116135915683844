import { Component } from "react";
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";

import isDefined from "../../utilities/util";
import Lang from "../LanguageSelect/Lang";

const PERCENT_OPTIONS = [
    {
        type: "nTh",
        value: 20,
        text: [
            <div key="en">5%</div>,
            <div key="fi">5%</div>,
            <div key="sv">5%</div>
        ]
    },
    {
        type: "nTh",
        value: 10,
        text: [
            <div key="en">10%</div>,
            <div key="fi">10%</div>,
            <div key="sv">10%</div>
        ]
    },
    {
        type: "nTh",
        value: 4,
        text: [
            <div key="en">25%</div>,
            <div key="fi">25%</div>,
            <div key="sv">25%</div>
        ]
    },
    {
        type: "nTh",
        value: 2,
        text: [
            <div key="en">50%</div>,
            <div key="fi">50%</div>,
            <div key="sv">50%</div>
        ]
    },
    {
        type: "nTh",
        value: 1,
        text: [
            <div key="en">100%</div>,
            <div key="fi">100%</div>,
            <div key="sv">100%</div>
        ]
    },
    {
        type: "avgNth",
        value: 3,
        text: [
            <div key="en">Average from 3 points</div>,
            <div key="fi">Keskiarvo 3 pisteestä</div>,
            <div key="sv">Medletal från 3 värden</div>
        ]
    },
    {
        type: "avgNth",
        value: 5,
        text: [
            <div key="en">Average from 5 points</div>,
            <div key="fi">Keskiarvo 5 pisteestä</div>,
            <div key="sv">Medletal från 5 värden</div>
        ]
    },
    {
        type: "avgNth",
        value: 10,
        text: [
            <div key="en">Average from 10 points</div>,
            <div key="fi">Keskiarvo 10 pisteestä</div>,
            <div key="sv">Medletal från 10 värden</div>
        ]
    },
    {
        type: "avgNth",
        value: 15,
        text: [
            <div key="en">Average from 15 points</div>,
            <div key="fi">Keskiarvo 15 pisteestä</div>,
            <div key="sv">Medletal från 15 värden</div>
        ]
    },
    {
        type: "avgNth",
        value: 20,
        text: [
            <div key="en">Average from 20 points</div>,
            <div key="fi">Keskiarvo 20 pisteestä</div>,
            <div key="sv">Medletal från 20 värden</div>
        ]
    },
];

const isActive = ({ type, value }, nTh, avgNth) => {
    if (nTh && type === "nTh") {
        return value === nTh;
    }
    if (type === "avgNth") {
        return value === avgNth;
    }
    return false;
};

export default class QualityDropdown extends Component {
    componentDidUpdate(prevProps) {
        const { disabled, tempNth, updateAncestor } = this.props;
        let update = {};
        if (prevProps.disabled === false && disabled === true) {
            if (prevProps.avgNth !== null) {
                update = { tempNth: { nTh: null, avgNth: prevProps.avgNth }, dontFlush: true };
            }
            else if (prevProps.nTh !== null) {
                update = { tempNth: { nTh: prevProps.nTh, avgNth: null }, dontFlush: true };
            }
        } else if (prevProps.disabled === true && disabled === false) {
            if (tempNth.avgNth !== null) {
                update = { tempNth: {}, nTh: null, avgNth: tempNth.avgNth, dontFlush: true };
            } else if (tempNth.nTh !== null) {
                update = { tempNth: {}, nTh: tempNth.nTh, avgNth: null, dontFlush: true };
            }
        }
        if (Object.keys(update).length > 0) {
            updateAncestor(update);
        }
    }

    handlePercentSelect = (index) => {
        const { updateAncestor } = this.props;
        const selectedPercent = PERCENT_OPTIONS[index];
        const { type, value } = selectedPercent;
        if (isDefined({ type, value }) && updateAncestor) {
            if (type === "nTh")
                updateAncestor({ nTh: value, avgNth: null });
            else
                updateAncestor({ nTh: null, avgNth: value });
        }
    };

    getTitle = (nTh, avgNth) => {
        const { lang } = this.props;
        let index = -1;
        index = nTh ? PERCENT_OPTIONS.findIndex(x => x.type === "nTh" && x.value === nTh) : PERCENT_OPTIONS.findIndex(x => x.type === "avgNth" && x.value === avgNth);
        return index > -1 ? <span className="title"><Lang lang={lang}>{PERCENT_OPTIONS[index].text}</Lang> </span> : <span className="title"><Lang lang={lang}>?</Lang></span>;
    };

    getItems = (nTh, avgNth) => {
        const { lang } = this.props;
        const options = [];
        PERCENT_OPTIONS.forEach((option, index) => {
            options.push(
                <Dropdown.Item
                    key={`option_${option.type}_${option.value}`}
                    eventKey={index}
                    active={isActive(option, nTh, avgNth)}
                >
                    <Lang lang={lang}>
                        {option.text}
                    </Lang>
                </Dropdown.Item>
            );
        });
        return options;
    };

    render() {
        const { disabled, nTh, avgNth } = this.props;
        return (
            <OverlayTrigger
                key="top"
                placement="top-end"
                overlay={
                    disabled ?
                        <Tooltip id="tooltip-top-end">
                            <Lang en="Always 100% with time filter enabled" fi="Aikasuodatuksessa näytetään aina 100%" sv="Alltid 100 % med tidsfilter aktiverat" />
                        </Tooltip>
                        :
                        <span />
                }
            >
                <DropdownButton
                    variant="default"
                    title={this.getTitle(nTh, avgNth)}
                    id="percentDropdown"
                    className="percentDropdown"
                    onSelect={this.handlePercentSelect}
                    disabled={disabled}
                >
                    {this.getItems(nTh, avgNth)}
                </DropdownButton>
            </OverlayTrigger>
        );
    }
}