import "../../css/CardBackground.css";
import "../../css/pageDefault.css";
import "../../css/LeftSidePanel.css";
import "../../css/UserSettings.css";

import { Accordion, Button, Card } from "react-bootstrap";
import { connect } from "react-redux";

import Lang from "../LanguageSelect/Lang";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import ChangePassword from "./ChangePassword";
import InfoMessages from "./InfoMessages";

const logOut = (logoutFunc) => {
	logoutFunc();
};

const selectMenu = (string, setValues) => {
	setValues({ userSettingsSelectedMenuItem: string });
};

function UserSettings({ username, logout, lang, userSettingsSelectedMenuItem, setValues, languages, infoMessages, newInfoMessages, updateAncestor }) {
	return (
		<div className="pageDefault userSettings">
			<div className="leftSidePanel">
				<div className="cardBackground">
					<div>
						<Accordion className="menuPanelStaticLoose" defaultActiveKey="0">
							<Card>
								<Card.Header>
									<Card.Title><span>{username}</span></Card.Title>
								</Card.Header>
								<Accordion.Collapse eventKey="0">
									<Card.Body>
										<div>
											<Button className="btn btn-warning" variant="warning" onClick={() => logOut(logout)}>
												<Lang lang={lang} fi="Kirjaudu ulos" en="Log out" sv="Logga ut" />
											</Button>
										</div>
										<div>
											<Button active={userSettingsSelectedMenuItem === "changePassword"} className="selectChangePassword-button" variant="primary" onClick={() => selectMenu("changePassword", setValues)}>
												<Lang en="Change password" fi="Vaihda salasana" sv="Byt lösenord" lang={lang} />
											</Button>
										</div>
										<div>
											<Button active={userSettingsSelectedMenuItem === "infoMessages"} className="selectInfoMessages-button" variant="primary" onClick={() => selectMenu("infoMessages", setValues)}>
												<Lang en="Messages" fi="Viestit" sv="Meddelanden" lang={lang} />
											</Button>
										</div>
										<div>
											<LanguageSelect
												lang={lang}
												languages={languages}
											/>
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
				</div>
			</div>
			<InfoMessages
				lang={lang}
				visible={userSettingsSelectedMenuItem === "infoMessages"}
				infoMessages={infoMessages}
				newInfoMessages={newInfoMessages}
				updateAncestor={updateAncestor}
			/>
			<ChangePassword
				lang={lang}
				visible={userSettingsSelectedMenuItem === "changePassword"}
			/>
		</div>
	);
}

function mapStateToProps(state) {
	const { lang, username, newInfoMessages,
		userSettingsSelectedMenuItem
	} = state;
	return {
		lang, username, newInfoMessages,
		userSettingsSelectedMenuItem
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);