import "../css/Login.css";

import { Component } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import SITE_VERSION from "../config/version";
import Messenger from "../utilities/messenger";
import PasswordField from "./GenericMenuComponents/PasswordField";
import TextField from "./GenericMenuComponents/TextField";
import Lang from "./LanguageSelect/Lang";
import LangDropdown from "./SetPassword/LangDropdown";
import { LANGUAGES } from "./SetPassword/SetPassword";

const rfdclone = rfdc();

// Mapping to and from redux login_ props
const LOGIN_MAP = {
	username: "loginUsername",
	invalid: "loginState",
	password: "loginPassword",
};

const remapToLoginView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (LOGIN_MAP[key])
			output[LOGIN_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

class Login extends Component {
	state = {
		loading: false,
		resetPassword: false,
	};

	componentDidMount() {
		const { invalid } = this.props;
		if (invalid !== null)
			toast.error(<Lang en="Incorrect username/password." fi="Väärä käyttäjänimi/salasana." sv="Fel användarnamn/lösenord." />);
	}

	componentDidUpdate() {
		const { invalid } = this.props;
		const { loading, resetPassword } = this.state;
		if (loading && invalid === "error" && !resetPassword) {
			this.setState({ loading: false });
		}
	}

	updateData = (obj) => {
		const { setValues } = this.props;
		const state = rfdclone(this.props);
		Object.assign(state, obj, { invalid: null });
		setValues(remapToLoginView(state));
	};

	handleLogin = (e) => {
		e.preventDefault();
		const { login, username, password, setValues } = this.props;
		const { resetPassword } = this.state;
		if (!resetPassword) {
			this.setState({ loading: true });
			if (login) {
				const loginData = { username: username.trim(), password: password.trim() };
				setValues({ username: username.trim(), password: "" });
				login(loginData.username, loginData.password);
			}
		}
	};

	handleReset = (e) => {
		e.preventDefault();
		const { username } = this.props;
		const { resetPassword } = this.state;
		if (resetPassword && username) {
			this.setState({ loading: true });
			const callback = (data) => {
				this.setState({ loading: false });
				if (data) {
					if (data === "success") {
						toast.success(<Lang en="Password reset link sent to email address" fi="Salasanan resetointilinkki lähetetty sähköpostiin" sv="Länk till återställning av lösenord skickas till e-postadress" />, { autoClose: 10_000, });
					} else if (data.status === "Can not find user") {
						toast.error(<Lang en="User can not be found" fi="Käyttäjää ei löydy" sv="Användaren kan inte hittas" />, { autoClose: 10_000, });
					} else {
						toast.error(<Lang en="Failed to reset user password" fi="Käyttäjän salasanan resetointi epäonnistui" sv="Återställandet av användrens lösenord misslyckades" />, { autoClose: 10_000, });
					}
				}
			};
			Messenger.requestResetPasswordEmail({ username, callback });
		}
	};

	updateUserLang = ({ lang }) => {
		this.updateData({ lang });
	};

	resetPassword = (e) => {
		e.preventDefault();
		this.setState(prevState => ({
			resetPassword: !prevState.resetPassword
		}));
	};

	render() {
		const { userLogged, username, invalid, lang, password } = this.props;
		const { resetPassword, loading } = this.state;
		if (userLogged)
			return <Navigate to="/mainpage" />;
		return resetPassword ? (
			<div className="login">
				<div className="siteVersion">{SITE_VERSION()}</div>
				<Form className="formBox" onSubmit={this.handleReset}>
					<Lang en="Write your username(email address) and reset your password" fi="Kirjoita käyttäjänimesi(sähköpostiosoite) ja resetoi salasana." sv="Skriv ditt användarnamn(e-postadress) och återställ ditt lösenord" />
					<br />
					<Lang en="You will receive an email with a link to set a new password" fi="Saat sähköpostiisi linkin, mistä voit luoda uuden salasanan." sv="Du får ett e-postmeddelande med en länk för att ställa in ett nytt lösenord" />
					<TextField
						className="username"
						type="email"
						title={
							<Lang en="Username" fi="Käyttäjänimi" sv="Användarnamn" />
						}
						content={username}
						updateAncestor={this.updateData}
						propertyName="username"
						realtimeUpdate
						maxLength="128"
						validation={invalid}
						autoComplete="username"
					/>
					<Button type="submit" variant="success" disabled={loading}>
						{loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
						<Lang en="Reset" fi="Resetoi" sv="Återställa" />
					</Button>
					<div className="new-user-lang">
						<LangDropdown
							langs={LANGUAGES}
							lang={lang}
							updateAncestor={this.updateUserLang}
						/>
					</div>
					<Button disabled={loading} type="button" variant="link" className="return-button" onClick={this.resetPassword}><BsArrowLeftShort /><Lang en="Return" fi="Palaa" sv="tillbaka" /></Button>
				</Form>
			</div>
		) : (
			<div className="login">
				<div className="siteVersion">{SITE_VERSION()}</div>
				<Form className="formBox" onSubmit={this.handleLogin}>
					<TextField
						className="username"
						type="email"
						title={
							<Lang en="Username" fi="Käyttäjänimi" sv="Användarnamn" />
						}
						content={username}
						updateAncestor={this.updateData}
						propertyName="username"
						realtimeUpdate
						maxLength="128"
						validation={invalid}
						autoComplete="username"
					/>
					<PasswordField
						className="password"
						title={
							<Lang en="Password" fi="Salasana" sv="Lösenord" />
						}
						content={password}
						updateAncestor={this.updateData}
						propertyName="password"
						realtimeUpdate
						maxLength="128"
						validation={invalid}
						autoComplete="current-password"
					/>
					<Button type="submit" variant="success" disabled={loading}>
						{loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
						<Lang en="Login" fi="Kirjaudu" sv="Logga in" />
					</Button>
					<div className="new-user-lang">
						<LangDropdown
							langs={LANGUAGES}
							lang={lang}
							updateAncestor={this.updateUserLang}
						/>
					</div>
					<Button disabled={loading} type="button" variant="link" className="reset-password" onClick={this.resetPassword}><Lang en="Forgot your password?" fi="Unohditko salasanasi?" sv="Glömt ditt lösenord?" /></Button>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang,
		loginState,
		loginUsername,
		loginPassword,
	} = state;
	return {
		lang,
		username: loginUsername,
		invalid: loginState,
		password: loginPassword,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);