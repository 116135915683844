import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import Lang from "./LanguageSelect/Lang";

const updateData = (updateAncestor, obj) => {
    if (updateAncestor) {
        updateAncestor(obj);
    }
};

const handleChange = (value, propertyName, maxProperty, max, minProperty, min, updateAncestor) => {
    if (propertyName) {
        let update = [];
        update = { [propertyName]: dayjs(value).toDate() };
        // Max check
        if (max !== undefined && maxProperty &&
            (dayjs(value).isAfter(max) || Math.abs(dayjs(value).valueOf() - dayjs(max)) < 300_000)
        ) {
            update = { [propertyName]: dayjs(max).subtract(1, "hours").toDate() };
        }
        // Min check
        if (min !== undefined && minProperty &&
            (dayjs(value).isBefore(min) || Math.abs(dayjs(value).valueOf() - dayjs(min)) < 300_000)
        ) {
            update = { [propertyName]: dayjs(min).add(1, "hours").toDate() };
        }
        updateData(updateAncestor, update);
    }
};

export default function DateTimePicker({ className, title, value, min, max, propertyName, maxProperty, minProperty, updateAncestor }) {
    return (
        <div className={`dateTimepicker ${className === undefined ? "" : className}`}>
            {title === undefined ? null : <div className="title">{title}</div>}
            <DatePicker
                selected={value === null ? null : dayjs(value).toDate()}
                onChange={(date) => handleChange(date, propertyName, maxProperty, max, minProperty, min, updateAncestor)}
                timeInputLabel={<Lang en="Time:" fi="Aika:" sv="Tid:" />}
                minDate={min}
                maxDate={max}
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeInput
                locale="fi"
                shouldCloseOnSelect={false}
                className="smallTextField form-control form-control-sm"
            />
        </div>
    );
}