import "react-datepicker/dist/react-datepicker.css";
import "../css/XAxisSelections.css";

import dayjs from "dayjs";
import { Component } from "react";
import { Accordion, Button, Card, Form } from "react-bootstrap";

import DateTimePicker from "./DateTimePicker";
import SingleNumberField from "./GenericMenuComponents/SingleNumberField";
import TextField from "./GenericMenuComponents/TextField";
import Lang from "./LanguageSelect/Lang";
import TimePicker from "./TimePicker";

export default class XAxisSelections extends Component {
	updateTicks = ({ xAxisTicksDays, xAxisTicksHours, xAxisTicksMinutes }) => {
		const { updateData } = this.props;
		if (updateData) {
			/* eslint-disable react/destructuring-assignment */
			const xAxisTicksDaysFinal = xAxisTicksDays === undefined ? this.props.xAxisTicksDays : xAxisTicksDays;
			const xAxisTicksHoursFinal = xAxisTicksHours === undefined ? this.props.xAxisTicksHours : xAxisTicksHours;
			const xAxisTicksMinutesFinal = xAxisTicksMinutes === undefined ? this.props.xAxisTicksMinutes : xAxisTicksMinutes;
			/* eslint-enable react/destructuring-assignment */
			let xAxisTicks = 86_400_000 * xAxisTicksDaysFinal;
			xAxisTicks += 3_600_000 * xAxisTicksHoursFinal;
			xAxisTicks += 60_000 * xAxisTicksMinutesFinal;
			updateData({
				xAxisTicksDays: xAxisTicksDaysFinal,
				xAxisTicksHours: xAxisTicksHoursFinal,
				xAxisTicksMinutes: xAxisTicksMinutesFinal,
				xAxisTicks,
			});
		}
	};

	updateTicksZero = ({ xAxisTickZero }) => {
		const { updateData } = this.props;
		if (updateData) {
			updateData({ xAxisTickZero: xAxisTickZero === null ? null : dayjs(xAxisTickZero).valueOf() });
		}
	};

	toggleSwitch = () => {
		const { updateData, xAxisFilter } = this.props;
		if (updateData) {
			updateData({ xAxisFilter: !xAxisFilter, xAxisFilterStartTime: undefined, xAxisFilterEndTime: undefined });
		}
	};

	applyFilter = () => {
		const { updateData, xAxisFilterStartTime, xAxisFilterEndTime } = this.props;
		if (updateData && xAxisFilterStartTime !== undefined && xAxisFilterEndTime !== undefined) {
			updateData({ xAxisFilterStartTime, xAxisFilterEndTime });
		}
	};

	render() {
		const { updateData, selectedProject, lang, xAxisTitle, xAxisStartTime, xAxisEndTime, xAxisAbsoluteStartTime, xAxisAbsoluteEndTime, xAxisFilter, xAxisFilterStartTime, xAxisFilterEndTime, xAxisTicksDays, xAxisTicksHours, xAxisTicksMinutes, xAxisTickZero } = this.props;
		return selectedProject !== undefined && selectedProject.id !== undefined ? (
			<div className="xAxisSelections">
				<Accordion
					id="accordianPanel"
					className="menuPanel titlePanel"
				>
					<Accordion.Button as={Card.Header}>
						<Lang lang={lang}>
							<div key="en">Time-axis</div>
							<div key="fi">Aika-akseli</div>
							<div key="sv">Tids-axel</div>
						</Lang>
					</Accordion.Button>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<TextField
								className="xAxisTitle"
								title={
									<Lang lang={lang}>
										<span key="en">X-axis title:</span>
										<span key="fi">X-akseli otsikko:</span>
										<span key="sv">X-axel rubrik:</span>
									</Lang>
								}
								content={xAxisTitle}
								updateAncestor={updateData}
								propertyName="xAxisTitle"
								validation={null}
							/>
							<DateTimePicker
								className="timeStart"
								title={
									<Lang lang={lang}>
										<span key="en">Start</span>
										<span key="fi">Alku</span>
										<span key="sv">Från</span>
									</Lang>
								}
								value={xAxisStartTime}
								updateAncestor={updateData}
								propertyName="xAxisStartTime"
								max={xAxisAbsoluteEndTime > xAxisEndTime ? xAxisEndTime : xAxisAbsoluteEndTime}
								maxProperty="xAxisEndTime"
								min={xAxisAbsoluteStartTime}
								size="sm"
							/>
							<DateTimePicker
								className="timeEnd"
								title={
									<Lang lang={lang}>
										<span key="en">End</span>
										<span key="fi">Loppu</span>
										<span key="sv">Till</span>
									</Lang>
								}
								value={xAxisEndTime}
								updateAncestor={updateData}
								propertyName="xAxisEndTime"
								min={xAxisAbsoluteStartTime < xAxisStartTime ? xAxisStartTime : xAxisAbsoluteStartTime}
								minProperty="xAxisStartTime"
								max={xAxisAbsoluteEndTime}
								size="sm"
							/>
							<div className='xAxisPeriod'>
								<Form>
									<Form.Check
										type="switch"
										id="xAxisPeriod-switch"
										onChange={this.toggleSwitch}
										checked={xAxisFilter}
										label={<Lang lang={lang}>
											<span className="title" key="en">Time filter</span>
											<span className="title" key="fi">Kellonaikasuodatus</span>
											<span className="title" key="sv">Tidsfilter</span>
										</Lang>}
									/>
								</Form>
								<TimePicker
									className="filterStart"
									title={
										<Lang lang={lang}>
											<span key="en">Start</span>
											<span key="fi">Alku</span>
											<span key="sv">Från</span>
										</Lang>
									}
									disabled={!xAxisFilter}
									value={xAxisFilterStartTime}
									updateAncestor={updateData}
									propertyName="xAxisFilterStartTime"
									max={xAxisFilterEndTime}
									maxProperty="xAxisFilterEndTime"
								/>
								<TimePicker
									className="filterEnd"
									title={
										<Lang lang={lang}>
											<span key="en">End</span>
											<span key="fi">Loppu</span>
											<span key="sv">Till</span>
										</Lang>
									}
									disabled={!xAxisFilter}
									value={xAxisFilterEndTime}
									updateAncestor={updateData}
									propertyName="xAxisFilterEndTime"
									min={xAxisFilterStartTime}
									minProperty="xAxisFilterStartTime"
								/>
								<Button
									onClick={this.applyFilter}
									variant="success"
									size="sm"
									disabled={xAxisFilterStartTime === undefined || xAxisFilterEndTime === undefined || !xAxisFilter || xAxisFilterStartTime.getTime() === xAxisFilterEndTime.getTime()}
								>
									<Lang lang={lang}>
										<span key="en">Apply</span>
										<span key="fi">Käytä</span>
										<span key="sv">Tillämpa</span>
									</Lang>
								</Button>
							</div>
							<div className="xAxisTicks">
								<Lang lang={lang}>
									<span className="title" key="en">Tick separation</span>
									<span className="title" key="fi">Apuviivojen väli</span>
									<span className="title" key="sv">Linje mellanrum</span>
								</Lang>
								<SingleNumberField
									className="xAxisTicksDays"
									title={
										<Lang lang={lang}>
											<span key="en">Days</span>
											<span key="fi">Päiviä</span>
											<span key="sv">Dagar</span>
										</Lang>
									}
									value={xAxisTicksDays}
									updateAncestor={this.updateTicks}
									propertyName="xAxisTicksDays"
									min={0}
								/>
								<SingleNumberField
									className="xAxisTicksHours"
									title={
										<Lang lang={lang}>
											<span key="en">Hours</span>
											<span key="fi">Tunteja</span>
											<span key="sv">Timmar</span>
										</Lang>
									}
									value={xAxisTicksHours}
									updateAncestor={this.updateTicks}
									propertyName="xAxisTicksHours"
									min={0}
								/>
								<SingleNumberField
									className="xAxisTicksMinutes"
									title={
										<Lang lang={lang}>
											<span key="en">Minutes</span>
											<span key="fi">Minuutteja</span>
											<span key="sv">Minuter</span>
										</Lang>
									}
									value={xAxisTicksMinutes}
									updateAncestor={this.updateTicks}
									propertyName="xAxisTicksMinutes"
									min={0}
								/>
								<DateTimePicker
									className="xAxisTickZero"
									title={
										<Lang lang={lang}>
											<span key="en">Zero point</span>
											<span key="fi">Nollapiste</span>
											<span key="sv">Nollställe</span>
										</Lang>
									}
									value={Number.isNaN(xAxisTickZero) ? null : xAxisTickZero}
									updateAncestor={this.updateTicksZero}
									max={xAxisAbsoluteEndTime}
									min={xAxisAbsoluteStartTime}
									propertyName="xAxisTickZero"
								/>
								<div className="xAxisTickZeroReset">
									<Button
										onClick={() => this.updateTicksZero({ xAxisTickZero: null })}
										variant={xAxisTickZero === null ? "default" : "success"}
										size="sm"
										disabled={xAxisTickZero === null}
									>
										<Lang lang={lang}>
											<span key="en">Reset</span>
											<span key="fi">Palauta</span>
											<span key="sv">Åteställ</span>
										</Lang>
									</Button>
								</div>
							</div>
						</Card.Body>
					</Accordion.Collapse>
				</Accordion>
			</div>
		) : null;
	}
}

XAxisSelections.defaultProps = {
	xAxisTitle: "",
	xAxisStartTime: dayjs().subtract(1, "days"),
	xAxisEndTime: dayjs(),
	xAxisTicks: 0,
	xAxisTicksDays: 0,
	xAxisTicksHours: 0,
	xAxisTicksMinutes: 0,
};
