import "../../css/CardBackground.css";
import "../../css/LeftSidePanel.css";
import "../../css/pageDefault.css";
import "./Alarms.css"




import dayjs from "dayjs";
import queryString from "query-string";
import { Component, lazy, Suspense } from "react";
import { Accordion, Button, Card, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import rfdc from "rfdc";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import store from "../../utilities/redux";
import isDefined from "../../utilities/util";
import DatasetByLoggerSelect from "../DatasetByLoggerSelect";
import DatasetByTypeSelect from "../DatasetByTypeSelect";
import Lang from "../LanguageSelect/Lang";
import { langString } from "../LanguageSelect/LanguageSelect";
import SystemDropdown from "../ProjectSelections/SystemDropdown";
import { setDeviceSettings } from "../StatisticsView";
import CustomerSelect from "../Systems/CustomerSelect";
import SystemConfig from "../SystemConfig/SystemConfig";
import SystemsFunctionSelect from "../Systems/SystemsFunctionSelect";
import ViewSystemBasicInfo from "../Systems/ViewSystemBasicInfo";
import SystemsTable from "../Systems/SystemsTable";
import AlarmsView from "./AlarmsView";
import AlarmsLeftPanel from "./AlarmsLeftPanel";

export function getProjects(systems, selectedSystem) {
	let output = [];
	if (Array.isArray(systems) && systems !== undefined) {
		const index = systems.findIndex(x => x.id === selectedSystem.id);
		if (index > -1 && systems[index].projects !== undefined) {
			output = systems[index].projects;
		}
	}
	return output;
}

const rfdclone = rfdc();

const getOnlySystemsViewValues = (obj) => {
	// eslint-disable-next-line unicorn/no-array-reduce
	const objKeys = Object.keys(obj).reduce((res, key) => {
		res[key] = true;
		return res;
	}, {});
	// eslint-disable-next-line unicorn/no-array-reduce
	return ALARMS_VALUES.reduce((res, key) => {
		if (objKeys[key])
			res[key] = obj[key];
		return res;
	}, {});
};
const getMeasTypes = (datasets) => datasets.length > 0 ? [...new Set(datasets.flatMap(dataset => dataset.measType === '' ? [] : dataset.measType))] : [];

const getDevices = (system) => {
	const loggerIds = system.id !== undefined && system.projects !== undefined && system.projects.length > 0 ? system.projects[0].loggerIds.split(",") : false;
	return loggerIds ? Object.fromEntries(
		loggerIds.map(loggerId => [loggerId, {
			index: loggerIds.indexOf(loggerId)
		}])
	) : {};
};

const getSystems = ({ customers, customerId, systems }) => {
	if (isDefined({ customers, customerId })) {
		return Object.keys(customers).length > 1 && customers[customerId] !== undefined && customers[customerId].systems !== undefined ? customers[customerId].systems : systems;
	}
	return [];
};

const getAlarms = ({ systems, systemId, alarms }) => {
	if (isDefined({ systems, systemId })) {
		return Object.keys(systems).length > 1 && systems[systemId] !== undefined && systems[systemId].alarms !== undefined ? systems[systemId].alarms : alarms;
	}
	return []
}

const processStatuses = (statuses) => {
	if (statuses) {
		// Sort by timestamp (t)
		statuses.sort((a, b) => a.t - b.t);
		return statuses.map((status) => ({ ...status, t: dayjs(status.t * 1000).format() }));
	}
	return [];
};

const getProjectName = (projectId, system, systems) => {
	if (projectId !== undefined && system !== undefined && system.id !== undefined && systems.length > 0) {
		const sysIndex = systems.findIndex(x => x.id === system.id);
		if (sysIndex > -1 && Array.isArray(systems[sysIndex].projects)) {
			const proIndex = systems[sysIndex].projects.findIndex(x => x.id === projectId);
			if (proIndex > -1 && systems[sysIndex].projects[proIndex].name !== undefined) {
				return systems[sysIndex].projects[proIndex].name;
			}
		}
	}
	return "-";
};

const ALARMS_MAP = {
	selectedProject: "settingsSelectedProject",
	selectedSystem: "systemsSelectedSystem",
	loading: "systemsLoading",
	showModal: "systemsShowModal", modalType: "systemsModalType", selectedMenuSystem: "systemsSelectedMenuSystem",
	datasets: "systemsDatasets", statuses: "systemsStatuses", lastTimeOnline: "systemsLastTimeOnline",
	xAxisStartTime: "systemsXAxisStartTime", xAxisAbsoluteStartTime: "systemsXAxisAbsoluteStartTime", xAxisEndTime: "systemsXAxisEndTime", xAxisAbsoluteEndTime: "systemsXAxisAbsoluteEndTime",
	deviceSettings: "systemsDeviceSettings", hideLegends: "systemsHideLegends", init: "systemsInit",
	dragmode: "systemsDragmode", showspikes: "systemsShowspikes", hovermode: "systemsHovermode",
	autoRangeTime: "systemsAutoRangeTime",
};

const ALARMS_VALUES = [
	"systemsSelectedMenuSystem",
	"systemsDatasets", "systemsStatuses",
	"systemsSelectedSystem", "systemsCurrentProject",
];

const remapToAlarmsView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (ALARMS_MAP[key])
			output[ALARMS_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const remapFromAlarmsView = (obj) => {
	// eslint-disable-next-line unicorn/no-array-reduce
	const MAP = Object.keys(ALARMS_MAP).reduce((res, key) => {
		res[ALARMS_MAP[key]] = key;
		return res;
	}, {});
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (MAP[key])
			output[MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

class Alarms extends Component {
	constructor() {
		super();

		this.mounted = false;
	}

	state = {
		loading: false,
	};

	setActiveNav = (nav) => {
		this.setState({ activeNav: nav });
	};

	chooseSystems({ customers, customerId, systems }) {
		if (isAuthorized &&
			isAuthorized({ one: ["super"] }) &&
			customers !== undefined &&
			customerId !== undefined &&
			customers[customerId] !== undefined &&
			customers[customerId].systems !== undefined) {
			return this.getExtendedSystems(customers[customerId].systems);
		}
		if (systems !== undefined) {
			return this.getExtendedSystems(systems);
		}
		return [];
	}

	getExtendedSystems(systems) {
		const { lang } = this.props;
		if (Array.isArray(systems))
			return [...systems, { id: "all", systemName: langString({ lang, strings: { en: "All", fi: "Kaikki", sv: "Alla" } }), sortIndex: 1 }];
		return systems;
	}

	updateViewSystem = (obj) => {
		const { setValues } = this.props;
		if (obj.selectedSystem !== undefined && obj.selectedSystem.id !== undefined && obj.selectedSystem.id !== "all") {
			const update = remapToAlarmsView({
				...obj,
				autoRangeTime: false,
				deviceSettings: {},
				loading: true,
				xAxisStartTime: dayjs().subtract(1, "months").format(),
				xAxisAbsoluteStartTime: undefined,
				xAxisEndTime: dayjs().format(),
				xAxisAbsoluteEndTime: undefined
			});
			setValues(update);
			const callback = (data) => {
				if (data.status === "success") {
					const dataCopy = rfdclone(data);
					delete dataCopy.status;
					if (data.API_ver >= 2) {
						setValues({ systemsConfigSystemInfo: dataCopy, loading: true });
					} else {
						setValues({ systemsConfigSystemInfo: dataCopy, systemsSelectedFunction: "info", loading: true });
					}
					const state = store.getState();
					Messenger.requestSystemStatus({
						payload: {
							systemId: obj.selectedSystem.id,
							start: false,
							end: parseInt(dayjs(state.systemsXAxisEndTime).valueOf() / 1000, 10),
						},
						callback: this.systemStatusCallback
					});
				} else {
					setValues({ systemsLoading: false });
				}
			};
			Messenger.requestSystem({
				payload: {
					systemId: obj.selectedSystem.id,
				},
				callback: callback.bind(this)
			});
		} else if (obj.selectedSystem !== undefined) {
			const update = remapToAlarmsView({ ...obj, deviceSettings: {} });
			setValues({ ...update, systemsSelectedFunction: "info", systemsConfigSystemInfo: undefined });
		}
	};

	toggleState = (loading) => {
		this.setState({ loading });
	};

	render() {
		const { customers, customerId, projectId, alarmId, selectCustomer, lang, projectsSome, updateAncestor, systemsSelectedFunction, systemsDatasets, systemsDeviceSettings, systemsSelectedSystem,
			 userId,  systemsLoading} = this.props;
		const { activeNav } = this.state;
		const systems = this.chooseSystems(this.props);
		return (
			<div data-testid="Alarms" className="pageDefault alarms">
				<div className="leftSidePanel">
					<AlarmsLeftPanel
					customerId={customerId}
					customers={customers}
					selectedSystem={systemsSelectedSystem}
					selectedProject={projectsSome}
					updateAncestor={updateAncestor}
					lang={lang}
					selectCustomer={selectCustomer}
					/>
					<SystemsFunctionSelect
						projectsSome={projectsSome}
					/>
					{systemsSelectedFunction === "info" ?
						<>
							<div className="cardBackground">
								<DatasetByTypeSelect
									updateAncestor={this.systemGraphUpdate}
									datasets={systemsDatasets}
									deviceSettings={systemsDeviceSettings}
									types={getMeasTypes(systemsDatasets)}
									lang={lang}
									alwaysOpen
								/>
							</div>
							<div className="cardBackground">
								<DatasetByLoggerSelect
									updateAncestor={this.systemGraphUpdate}
									datasets={systemsDatasets}
									deviceSettings={systemsDeviceSettings}
									devices={getDevices(systemsSelectedSystem)}
									lang={lang}
									alwaysOpen
								/>
							</div>
						</>
						: null}
				</div>
				<div className="rightSidePanel">
					<AlarmsView
						customers={customers}
						customerId={customerId}
						systems={systems}
						selectSystem={this.openModal}
						isAuthorized={isAuthorized}
						projectId={projectId}
						alarmId={alarmId}
					/>
					<Spinner id="systemsRightSpinner" animation="border" style={{ display: systemsLoading ? "inline-block" : "none" }} />
				</div>
			</div>
		);
	}
}


function Systems(props) {
	const location = useLocation();
	return <Alarms {...props} location={location} />;
}

function mapStateToProps(state) {
	const { lang, customers, customerId, systems, userId, projectsSome,
		systemsShowModal, systemsModalType, systemsSelectedMenuSystem,
		systemsDatasets, systemsStatuses, systemsLastTimeOnline,
		systemsSelectedSystem, systemsCurrentProject,
		systemsAutoRangeTime,
		systemsXAxisStartTime, systemsXAxisAbsoluteStartTime, systemsXAxisEndTime, systemsXAxisAbsoluteEndTime,
		systemsDeviceSettings, systemsHideLegends, systemsInit,
		systemsDragmode, systemsShowspikes, systemsHovermode,
		systemsSelectedFunction, systemsConfigSystemInfo,
		systemsLoading,
	} = state;
	return {
		lang, customers, customerId, systems, userId, projectsSome,
		systemsShowModal, systemsModalType, systemsSelectedMenuSystem,
		systemsDatasets, systemsStatuses, systemsLastTimeOnline,
		systemsSelectedSystem, systemsCurrentProject,
		systemsAutoRangeTime,
		systemsXAxisStartTime, systemsXAxisAbsoluteStartTime, systemsXAxisEndTime, systemsXAxisAbsoluteEndTime,
		systemsDeviceSettings, systemsHideLegends, systemsInit,
		systemsDragmode, systemsShowspikes, systemsHovermode,
		systemsSelectedFunction, systemsConfigSystemInfo,
		systemsLoading,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Systems);
