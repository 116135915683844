import "../css/GraphComments.css";

import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { Component } from "react";
import { Alert, Button, Card, Collapse, Form, Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsDash, BsDownload, BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import isAuthorized from "../utilities/authorization";
import Messenger from "../utilities/messenger";
import getFooterButtons from "./ConfigModalOptions";
import Lang from "./LanguageSelect/Lang";

const downloadComments = () => {
	const loadingToast = toast.loading(<Lang en="Downloading comments" fi="Ladataan kommentteja" sv="Laddar ner kommentarer" />, { autoClose: false });
	const htmlCanvas = document.querySelector("#graph-comments");
	const buttons = htmlCanvas.querySelectorAll("button");
	// eslint-disable-next-line no-restricted-syntax
	for (const button of buttons) {
		button.style.opacity = 0;
	}
	html2canvas(htmlCanvas, {
		logging: false,
		scale: 1.2,
	}).then((canvas) => {
		const img = canvas.toDataURL("image/png");
		const element = document.createElement("a");
		element.setAttribute("href", img);
		element.setAttribute("download", `Miran_graph_comments_${dayjs().format("DD_MM_YYYY_HH_mm")}`);
		element.style.display = "none";
		document.body.append(element);
		element.click();
		toast.update(loadingToast, { render: <Lang en="Comments downloaded" fi="Kommentit ladattu" sv="Kommentarer nedladdad" />, type: "success", isLoading: false, autoClose: 5000 });
		element.remove();
		return true;
	}).catch(() => {
		toast.error(<Lang en="Failed to load comments" fi="Kommenttien lataaminen epäonnistui" sv="Kommentarer kunde inte laddas" />);
	});
	// eslint-disable-next-line no-restricted-syntax
	for (const button of buttons) {
		button.style.opacity = 1;
	}
};

class GraphComments extends Component {
	state = {
		comment: "",
		showForm: false,
		showEditModal: false,
		editComment: "",
		commentId: 0,
		deleteView: false,
	};

	componentDidUpdate() {
		const { selectedProject, statisticsViewComments } = this.props;
		if (selectedProject.id !== undefined && statisticsViewComments === undefined) {
			this.fetchComments();
		}
	}

	fetchComments = () => {
		const { statisticsViewComments, setValues, selectedProject, selectedSystem } = this.props;
		if (statisticsViewComments === undefined) {
			setValues({ statisticsViewComments: [] });
		}
		const callback = (data) => {
			if (data) {
				if (data.status === "success") {
					setValues({ statisticsViewComments: data.graphComments });
				} else if (data.status === "error") {
					toast.error(<Lang en="Failed to fetch comments" fi="Kommenttien haku epäonnistui" sv="Det gick inte att hämta kommentarer" />);
				}
			}
		};
		const payload = {
			projectId: selectedProject.id,
			systemId: selectedSystem.id,
		};
		Messenger.requestGraphComments({ payload, callback: callback.bind(this) });
	};

	getComments = (comments) => {
		if (comments) {
			return comments.map((cmt) => (
				<Card className='comment-card' key={`comment_${cmt.commentId}`}>
					<Card.Header>
						<p>
							{dayjs.unix(cmt.timestamp).format("DD.MM.YYYY HH:mm")}&emsp;{cmt.editedTimestamp ? <> <span className='no-wrap'>(<Lang en="Edited: " fi="Muokattu: " sv="Redigerat: " />{dayjs.unix(cmt.editedTimestamp).format("DD.MM.YYYY HH:mm")})</span> </> : null}
						</p>
						{isAuthorized({ one: ["project_admin"] }) ?
							<Button size='sm' variant="warning" onClick={() => this.openEditModal(cmt.commentId)}><Lang en="Edit" fi="Muokkaa" sv="Redigera" /></Button>
							: null}
					</Card.Header>
					<Card.Body>
						<blockquote className="blockquote mb-0">
							<p className='wrap-newline'>
								{cmt.commentText}
							</p>
							<footer className="blockquote-footer">
								<cite>{cmt.username}&emsp;{cmt.editedTimestamp && cmt.username !== cmt.editedUsername ? <> <span className='no-wrap'>(<Lang en="Edited: " fi="Muokattu: " sv="Redigerat: " /> {cmt.editedUsername})</span> </> : null}</cite>
							</footer>
						</blockquote>
					</Card.Body>
				</Card>
			));
		}
		return null;
	};

	openEditModal = (commentId) => {
		const { statisticsViewComments } = this.props;
		const index = statisticsViewComments.findIndex(x => x.commentId === commentId);
		this.setState({ showEditModal: true, commentId, editComment: !Number.isNaN(index) && statisticsViewComments[index] ? statisticsViewComments[index].commentText : "" });
	};

	closeEditModal = () => {
		this.setState({ showEditModal: false, editComment: "" });
	};

	setDeleteViewState = () => {
		this.setState(({ deleteView }) => ({ deleteView: !deleteView }));
	};

	handleChange = (event) => {
		const { showEditModal } = this.state;
		if (showEditModal) {
			this.setState({ editComment: event.target.value });
		} else {
			this.setState({ comment: event.target.value });
		}
	};

	updateComment = () => {
		const { selectedProject, selectedSystem } = this.props;
		const { commentId, editComment } = this.state;
		const callback = (data) => {
			if (data) {
				if (data.status === "success") {
					this.setState({ showEditModal: false, editComment: "" });
					this.fetchComments();
					toast.success(<Lang en="Comment updated" fi="Kommentti päivitetty" sv="Kommentar uppdaterad" />);
				} else if (data.status === "error") {
					toast.error(<Lang en="Failed to edit comment" fi="Kommentin muokkaus epäonnistui" sv="Det gick inte att redigera kommentaren" />);
				}
			}
		};
		const payload = {
			commentId,
			commentText: editComment,
			projectId: selectedProject.id,
			systemId: selectedSystem.id,
		};
		Messenger.editGraphComment({ payload, callback: callback.bind(this) });
	};

	handleAddComment = (event) => {
		const { selectedProject, selectedSystem } = this.props;
		event.preventDefault();
		const comment = event.target[0].value;
		const callback = (data) => {
			if (data) {
				if (data.status === "success") {
					this.setState({ comment: "" });
					this.fetchComments();
					toast.success(<Lang en="Comment created" fi="Kommentti luotu" sv="Kommentar skapad" />);
				} else if (data.status === "error") {
					toast.error(<Lang en="Failed to create comment" fi="Kommentin luonti epäonnistui" sv="Det gick inte att skapa kommentar" />);
				}
			}
		};
		const payload = {
			projectId: selectedProject.id,
			systemId: selectedSystem.id,
			comment,
		};
		Messenger.addGraphComment({ payload, callback: callback.bind(this) });
	};

	handleDeleteComment = () => {
		const { selectedProject, selectedSystem } = this.props;
		const { commentId } = this.state;
		const callback = (data) => {
			if (data) {
				if (data.status === "success") {
					this.setState({ showEditModal: false, editComment: "" });
					this.fetchComments();
					toast.success(<Lang en="Comment deleted" fi="Kommentti poistettu" sv="Kommentar raderad" />);
				} else if (data.status === "error") {
					toast.error(<Lang en="Failed to delete comment" fi="Kommentin poisto epäonnistui" sv="Kommentaren kunde inte raderas" />);
				}
			}
		};
		const payload = {
			commentId,
			projectId: selectedProject.id,
			systemId: selectedSystem.id,
		};
		Messenger.deleteGraphComment({ payload, callback: callback.bind(this) });
	};

	render() {
		const { showComments, ref, statisticsViewComments, lang } = this.props;
		const { showForm, comment, showEditModal, deleteView, editComment } = this.state;
		return showComments ? (
			<>
				<Alert ref={ref} id='graph-comments' variant='light'>
					<div className='button-container'>
						{isAuthorized({ one: ["project_admin"] }) ?
							<Button variant="primary" onClick={() => this.setState({ showForm: !showForm })}>
								<IconContext.Provider value={{ className: "reactIcon" }}>
									{showForm ?
										<><BsDash /><Lang en="Hide field" fi="Piilota kenttä" sv="Göm fält" /></>
										: <><BsPlus /><Lang en="Add a comment" fi="Lisää kommentti" sv="Lägg till en kommentar" /></>
									}
								</IconContext.Provider>
							</Button>
							: <Lang en="You do not have the permission to add comments" fi="Sinulla ei ole tarvittavia oikeuksia lisätä kommentteja" sv="Du har inte behörighet att lägga till kommentarer" />}
						<Button className="comment-dl" variant="secondary" onClick={downloadComments}>
							<IconContext.Provider value={{ className: "comment-dl-icon" }}>
								<BsDownload />
								<Lang en="Download comments" fi="Lataa kommentit" sv="Ladda ner kommentarer" />
							</IconContext.Provider>
						</Button>
					</div>
					<Collapse in={showForm}>
						<Form className='comments-form' onSubmit={this.handleAddComment}>
							<Form.Group className="mb-2" controlId="ControlTextarea1">
								<Form.Label><Lang en="Comment" fi="Kommentti" sv="Kommentar" /></Form.Label>
								<Form.Control as="textarea" rows={2} value={comment} onChange={this.handleChange} />
							</Form.Group>
							<Button type='submit' variant="success">
								<Lang en="Add" fi="Lisää" sv="Lägg till" />
							</Button>
						</Form>
					</Collapse>
					{this.getComments(statisticsViewComments)}
				</Alert>
				<Modal show={showEditModal} onHide={this.closeEditModal} size="lg" centered>
					<Modal.Header closeButton>
						<Modal.Title><Lang en="Edit comment" fi="Muokkaa kommenttia" sv="Redigera kommentar" /></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{deleteView ?
							<div className="deleteView">
								<div className="title">
									<Lang lang={lang}>
										<span key="en">Delete comment?</span>
										<span key="fi">Poistetaanko kommentti?</span>
										<span key="sv">Ta bort kommentar?</span>
									</Lang>
								</div>
								<div className="deletable-name">
									{editComment}
								</div>
							</div>
							:
							<Form className='comments-form'>
								<Form.Group className="mb-2" controlId="ControlTextarea1">
									<Form.Label><Lang en="Comment" fi="Kommentti" sv="Kommentar" /></Form.Label>
									<Form.Control as="textarea" rows={3} value={editComment} onChange={this.handleChange} />
								</Form.Group>
							</Form>
						}
					</Modal.Body>
					<Modal.Footer>
						{getFooterButtons({
							deleteView,
							setDeleteViewState: this.setDeleteViewState,
							submit: this.updateComment,
							closeModal: this.closeEditModal,
							deleteFunc: this.handleDeleteComment,
							valid: !!editComment,
						})}
					</Modal.Footer>
				</Modal>
			</>
		) : null;
	}
}

function mapStateToProps(state) {
	const { statisticsViewComments } = state;
	return { statisticsViewComments };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphComments);