import "../../css/CardBackground.css";
import "../../css/LeftSidePanel.css";
import "../../css/pageDefault.css";
import "./Projects.css";

import { Component } from "react";
import { connect } from "react-redux";
import rfdc from "rfdc";

import isAuthorized from "../../utilities/authorization";
import ProjectsLeftsidePanel from "./ProjectsLeftSidePanel";
import ProjectsPage from "./ProjectsPage";
import ReportCopier from "./ReportCopier";

const rfdclone = rfdc();

// Mapping to and from redux settings_ props
const SETTINGS_MAP = {
	selectedProject: "settingsSelectedProject", selectedSystem: "settingsSelectedSystem",
	loading: "settingsLoading",
	selectedSetting: "settingsSelectedSetting",
	// eslint-disable-next-line no-secrets/no-secrets
	newProjectInvalid: "settingsNewProjectInvalid",
};

const remapToSettingsView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (SETTINGS_MAP[key])
			output[SETTINGS_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

function autoSelectSystem({ systems, selectedSystem }) {
	if ((!selectedSystem || selectedSystem.id === undefined) && systems !== undefined && systems.length === 1) {
		return systems[0];
	}
	return { id: undefined, title: undefined };
}

export function getProjects(systems, selectedSystem) {
	let output = [];
	if (Array.isArray(systems) && systems !== undefined) {
		const index = systems.findIndex(x => x.id === selectedSystem.id);
		if (index > -1 && systems[index].projects !== undefined) {
			output = systems[index].projects;
		}
	}
	return output;
}

class Projects extends Component {
	state = {
		activeNav: "projects"
	};

	componentDidMount() {
		const { customerId, getCustomerSystems, userId, selectedSystem, systems, customers, setValues } = this.props;
		if (customerId !== undefined)
			getCustomerSystems({ customerId, userId, noUpdate: true });
		if (selectedSystem && selectedSystem.id === undefined && systems !== undefined && systems.length === 1) {
			setValues({
				settingsSelectedSystem: autoSelectSystem({ customers, customerId, systems })
			});
		}
	}

	updateData = (obj) => {
		const { setValues } = this.props;
		const update = remapToSettingsView(obj);
		setValues(update);
	};

	setActiveNav = (nav) => {
		this.setState({ activeNav: nav });
	};

	updateSystems = (systemId, currentProject, loggerIds = false, loggerNames = false) => {
		const { systems, setValues } = this.props;
		const newSystems = rfdclone(systems);
		try {
			const currentSystemIndex = systems.findIndex(system => system.id === systemId);
			const currentProjectIndex = systems[currentSystemIndex].projects.findIndex(project => project.id === currentProject.id);
			if (loggerIds && loggerNames) {
				newSystems[currentSystemIndex].projects[currentProjectIndex].loggerIds = loggerIds.join(",");
				newSystems[currentSystemIndex].projects[currentProjectIndex].loggerNames = loggerNames.join(",");
			} else {
				newSystems[currentSystemIndex].projects.splice(currentProjectIndex, 1);
			}
			setValues({
				systems: newSystems,
				settingsSelectedSystem: newSystems[currentSystemIndex]
			});
		} catch (error) {
			throw new Error("Unable to update systems");
		}
	};

	render() {
		const { customers, customerId, selectCustomer, systems, selectedSystem, selectedProject, lang, loading, selectedSetting, updateAncestor, getCustomerSystems, userId } = this.props;
		const { activeNav } = this.state;
		return (
			<div data-testid="Projects" className="pageDefault projects">
				<div className="leftSidePanel">
					<ProjectsLeftsidePanel
						customers={customers}
						customerId={customerId}
						selectCustomer={selectCustomer}
						systems={systems}
						selectedSystem={selectedSystem}
						projects={getProjects(systems, selectedSystem)}
						selectedProject={selectedProject}
						updateAncestor={this.updateData}
						lang={lang}
						selectedSetting={selectedSetting}
						isAuthorized={isAuthorized}
						setActiveNav={this.setActiveNav}
						activeNav={activeNav}
					/>
				</div>
				<div className="rightSidePanel">
					<ProjectsPage
						customerId={customerId}
						loading={false}
						selectedSystem={selectedSystem}
						lang={lang}
						getCustomerSystems={getCustomerSystems}
						userId={userId}
						updateSystems={this.updateSystems}
						activeNav={activeNav}
					/>
					<ReportCopier
						customerId={customerId}
						selectedSystem={selectedSystem}
						selectedProject={selectedProject}
						selectedSetting={selectedSetting}
						loading={loading}
						lang={lang}
						projects={getProjects(systems, selectedSystem)}
						systems={systems}
						updateAncestor={updateAncestor}
						activeNav={activeNav}
						getCustomerSystems={getCustomerSystems}
						userId={userId}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, customerId, customers, systems, userId,
		settingsSelectedProject, settingsSelectedSystem,
		settingsLoading,
		settingsSelectedSetting,
		settingsNewProjectInvalid,
	} = state;
	return {
		lang, customerId, customers, systems, userId,
		selectedProject: settingsSelectedProject, selectedSystem: settingsSelectedSystem,
		loading: settingsLoading,
		selectedSetting: settingsSelectedSetting,
		newProjectInvalid: settingsNewProjectInvalid,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
