import dayjs from "dayjs";
import { Component } from "react";
import DatePicker from "react-datepicker";

import Lang from "../LanguageSelect/Lang";

export default class DateTimePicker extends Component {
    updateAncestor = (obj) => {
        const { updateAncestor } = this.props;
        updateAncestor(obj);
    };

    handleChange = (value) => {
        const { propertyName, max, maxProperty, min, minProperty } = this.props;
        if (propertyName) {
            let update = { [propertyName]: dayjs(value).toDate() };
            // Max check
            if (max !== undefined && maxProperty &&
                (dayjs(value).isAfter(max) || Math.abs(dayjs(value).valueOf() - dayjs(max)) < 300_000)
            ) {
                update = { [propertyName]: dayjs(max).subtract(1, "hours").toDate() };
            }
            // Min check
            if (min !== undefined && minProperty &&
                (dayjs(value).isBefore(min) || Math.abs(dayjs(value).valueOf() - dayjs(min)) < 300_000)
            ) {
                update = { [propertyName]: dayjs(min).add(1, "hours").toDate() };
            }
            this.updateAncestor(update);
        }
    };

    render() {
        const { className, title, value, min, max } = this.props;
        const finalClassName = className === undefined ? "" : (` ${className}`);
        return (
            <div className={`dateTimepicker${finalClassName}`}>
                {title === undefined ? null : <div className="title">{title}</div>}
                <DatePicker
                    selected={Number.isNaN(value) ? null : value}
                    onChange={(date) => this.handleChange(date)}
                    timeInputLabel={<Lang en="Time:" fi="Aika:" sv="Tid:" />}
                    minDate={min}
                    maxDate={max}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeInput
                    locale="fi"
                    shouldCloseOnSelect={false}
                    className="smallTextField form-control"
                />
            </div>
        );
    }
}
