import "whatwg-fetch";
import "./css/customBootstrap.scss";
import "./index.css";

import { isDesktop } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { createRoot } from "react-dom/client";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
	<DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
		<App />
	</DndProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
