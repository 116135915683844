import { Form, Table } from "react-bootstrap";
import rfdc from "rfdc";

import Lang from "../LanguageSelect/Lang";

const rfdclone = rfdc();

function parseRows(customers, select) {
    let entries = [];
    if (customers !== undefined) {
        const keys = Object.keys(customers);
        keys.sort((a, b) => customers[a].customerName.localeCompare(customers[b].customerName, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true }));
        entries = keys.map(key => (
            <tr
                key={`customer_${key}`}
                onClick={
                    () => {
                        if (select !== undefined) {
                            select(rfdclone(customers[key]));
                        }
                    }
                }
            >
                <td>{key}</td>
                <td>{customers[key].customerName ?? "-"}</td>
                <td>{customers[key].maxUsers ?? "-"}</td>
                <td>{customers[key].loginAllowed === undefined ? "-" : <Form.Check readOnly checked={customers[key].loginAllowed} />}</td>
            </tr>
        ));
    }
    return entries;
}

export default function CustomersTable({ customers, selectCustomer }) {
    return (
        <Table id='table'>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>
                        <Lang en="Name" fi="Nimi" sv="Namn" />
                    </th>
                    <th>
                        <Lang en="Max users" fi="Maksimi käyttäjämäärä" sv="Max användare" />
                    </th>
                    <th>
                        <Lang en="Login allowed" fi="Kirjautuminen sallittu" sv="Får skriva in" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {parseRows(customers, selectCustomer)}
            </tbody>
        </Table>
    );
}