import "../css/CardBackground.css";

import { Accordion, Card, Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

const getName = (type) => {
	if (type === "Pres_Pa") {
		return "Pres diff";
	} return type === "Pres_Bar" ? "Baro pres" : type;
};

const getItems = (types, selected) => types.map(type => (
	<Dropdown.Item
		key={`typeSelection_${type}`}
		eventKey={type}
		active={selected === type}
	>
		{getName(type)}
	</Dropdown.Item>
));

const handleSelect = (type, updateAncestor) => {
	if (updateAncestor !== undefined)
		updateAncestor({ selectedMeasType: type });
};

export default function TypeDropdown({ types, selectedType, updateAncestor }) {
	if (Array.isArray(types) && types.length > 0) {
		return (
			<div id='typeDropdown' className="cardBackground">
				<Accordion defaultActiveKey="0"
					id="typePanelAccordian"
					className="menuPanelStatic typePanel"
				>
					<Card>
						<Card.Header>
							<Lang en="Types" fi="Suureet" sv="Typer" />
						</Card.Header>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<span className="projectSelectionWrapper">
									<div className="subtitle">
										<Lang en="Type:" fi="Suure:" sv="Typ:" />
									</div>
									<span className="dropdownFlexer scrollable-dropdown">
										<DropdownButton
											variant="default"
											title={
												selectedType === undefined
													? <span className="title">
														<Lang en="Select..." fi="Valitse..." sv="Välj..." />
													</span>
													:
													<span className="title">{getName(selectedType)}</span>
											}
											id="typeDropdown"
											onSelect={(type) => handleSelect(type, updateAncestor)}
										>
											{getItems(types, selectedType)}
										</DropdownButton>
									</span>
								</span>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			</div>
		);
	}
	return null;
}
