import "../../css/pageDefault.css";
import "./Blueprint.css";

import queryString from "query-string";
import { Component, lazy, Suspense } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import isAuthorized from "../../utilities/authorization";
import store from "../../utilities/redux";
import ConfirmationModal from "../ConfirmationModal";
import Lang from "../LanguageSelect/Lang";
import BlueprintClient from "./blueprintClient";
import BlueprintLeftPanel from "./BlueprintLeftPanel";
import EditBlueprintModal from "./EditBlueprintModal";

const Blueprint = lazy(() => import(/* webpackChunkName: "blueprint" */ "./Blueprint"));

const rfdclone = rfdc();

const setLoggers = (loggers) => {
	store.dispatch({
		type: "SET_VALUES",
		obj: {
			blueprintBlueprintLoggers: rfdclone(loggers),
		}
	});
};

const modifyAuthorized = () => isAuthorized({ one: ["project_admin", "super"] });

class BlueprintsViewClass extends Component {
	state = {
		showDeleteModal: false,
		showEditModal: false,
	};

	componentDidMount() {
		const { customerId, getCustomerSystems, userId, location, systems } = this.props;
		if (customerId !== undefined)
			getCustomerSystems({ customerId, userId, noUpdate: true });
		let queryParams = false;
		if (location.search) {
			queryParams = queryString.parse(location.search);
			const systemIndex = systems.findIndex((element) => element.id === queryParams.systemId);
			if (systemIndex > -1) {
				const projectIndex = systems[systemIndex].projects.findIndex((element) => element.id === queryParams.projectId);
				if (projectIndex > -1)
					store.dispatch({
						type: "SET_VALUES",
						obj: {
							blueprintSelectedSystem: systems[systemIndex],
							blueprintSelectedProject: systems[systemIndex].projects[projectIndex]
						}
					});
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { selectedSystem, selectedProject } = this.props;
		if (prevProps.selectedSystem.id !== selectedSystem.id && prevProps.selectedProject.id === selectedProject.id)
			store.dispatch({
				type: "SET_VALUES",
				obj: {
					blueprintSelectedProject: { id: undefined, title: undefined }
				}
			});
	}

	deleteBlueprint = (confirmed) => {
		const { bp, blueprints, loggers, lang } = this.props;
		if (confirmed) {
			BlueprintClient.deleteBlueprint(bp.uuid).then(() => {
				const { uuid } = bp;
				const remainingBlueprints = blueprints.filter(blueprint => blueprint.uuid !== uuid);
				let nextBlueprint = null;
				if (remainingBlueprints.length > 0) {
					[nextBlueprint,] = remainingBlueprints;
				}
				this.selectBlueprint(nextBlueprint, remainingBlueprints);
				// eslint-disable-next-line no-restricted-syntax, promise/always-return
				for (const logger of loggers) {
					if (logger.blueprint === uuid) {
						logger.blueprint = null;
						logger.x = null;
						logger.y = null;
					}
				}
				setLoggers(loggers);
				this.forceUpdate();
				toast.success(<Lang lang={lang} fi="Pohjakuva poistettu." en="Blueprint deleted." sv="Planen raderad" />);
			}).catch(() => {
				toast.error(<Lang lang={lang} en="Failed to remove blueprint" fi="Pohjakuvan poisto epäonnistui" sv="Det gick inte att ta bort planen" />);
			});
		}
		this.setState({ showDeleteModal: false });
	};

	loggerMoved = (movedLogger) => {
		const { loggers } = this.props;
		// eslint-disable-next-line no-restricted-syntax
		for (const logger of loggers) {
			if (logger.loggerId === movedLogger.loggerId) {
				logger.x = movedLogger.x;
				logger.y = movedLogger.y;
				logger.dot_x = movedLogger.dot_x;
				logger.dot_y = movedLogger.dot_y;
			}
		}
		this.forceUpdate();
	};

	editModalClosed = () => {
		this.setState({ showEditModal: false });
	};

	blueprintUpdated = (blueprint) => {
		const { blueprints } = this.props;
		// eslint-disable-next-line no-restricted-syntax
		for (const bp of blueprints) {
			if (bp.uuid === blueprint.uuid) {
				bp.name = blueprint.name;
				bp.description = blueprint.description;
				bp.rotation = blueprint.rotation;
				bp.locked = blueprint.locked;
			}
		}
		const blueprintsCopy = rfdclone(blueprints);
		this.selectBlueprint(blueprint, blueprintsCopy);
	};

	onRotate = (angle) => {
		const { bp } = this.props;
		bp.rotation = angle;
		this.blueprintUpdated(bp);
	};

	showInsufficientRights() {
		const { lang } = this.props;
		toast.warning(<Lang lang={lang} fi="Sinulla ei ole tarvittavia käyttöoikeuksia." en="You do not have the required access rights." sv="Du inte har rättigheter." />);
	}

	showEditModal() {
		if (!modifyAuthorized()) {
			this.showInsufficientRights();
			return;
		}
		this.setState({ showEditModal: true });
	}

	showDeleteModal() {
		if (!modifyAuthorized()) {
			this.showInsufficientRights();
			return;
		}
		this.setState({ showDeleteModal: true });
	}

	selectBlueprint(blueprint, blueprintsProp) {
		const { blueprints } = this.props;
		const newBlueprint = { ...blueprint };
		const newBlueprints = blueprintsProp || blueprints;
		store.dispatch({
			type: "SET_VALUES",
			obj: {
				blueprintSelectedBlueprint: newBlueprint,
				blueprintBlueprints: newBlueprints
			}
		});
	}

	render() {
		const { bp, customerId, customers, selectedSystem, selectedProject, updateAncestor, lang, selectCustomer, blueprints, loggers, rotation } = this.props;
		const { showEditModal, showDeleteModal } = this.state;
		let blueprintTitle = "";
		if (bp && bp.name && bp.name !== "null") {
			blueprintTitle = bp.name;
		}
		return (
			<div className="pageDefault blueprints">
				<div className="leftSidePanel">
					<BlueprintLeftPanel
						customerId={customerId}
						customers={customers}
						selectedSystem={selectedSystem}
						selectedProject={selectedProject}
						updateAncestor={updateAncestor}
						lang={lang}
						selectCustomer={selectCustomer}
					/>
				</div>
				<div className="rightSidePanel">
					{selectedSystem !== undefined && selectedProject.id !== undefined && bp ?
						<Card>
							<Card.Header>
								<Card.Title>
									<Button
										variant="light"
										onClick={() => this.showEditModal()}
										disabled={blueprints.length <= 0}
									>
										<IconContext.Provider value={{ className: "reactIcon-text" }}>
											<FaRegEdit />
										</IconContext.Provider>
										{blueprintTitle}
									</Button>
									<Button
										className="deleteBlueprintButton"
										variant="danger"
										onClick={() => this.showDeleteModal()}
										disabled={blueprints.length <= 0}
									>
										<IconContext.Provider value={{ className: "reactIcon" }}>
											<BsX />
										</IconContext.Provider>
									</Button>
									<EditBlueprintModal
										show={showEditModal}
										lang={lang}
										uploadModalClosed={this.editModalClosed}
										blueprint={bp}
										blueprintUpdated={this.blueprintUpdated}
										onRotate={this.onRotate}
									/>
									<ConfirmationModal confirm={this.deleteBlueprint} show={showDeleteModal} lang={lang}>
										<Lang lang={lang} fi="Poistetaanko pohjakuva" en="Delete blueprint" sv="Radera planet" />
										&nbsp;{blueprintTitle}?
									</ConfirmationModal>
								</Card.Title>
							</Card.Header>
							<Suspense fallback={<Spinner animation="border" />}>
								<Blueprint
									bp={bp}
									loggers={loggers}
									loggerMoved={this.loggerMoved}
									blueprintUpdated={this.blueprintUpdated}
									rotation={rotation}
									lang={lang}
								/>
							</Suspense>
						</Card>
						: null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, username, userId, systems, customerId, customers, blueprintSelectedSystem, blueprintSelectedProject, blueprintSelectedBlueprint, blueprintBlueprints, blueprintBlueprintLoggers } = state;
	const rotation = blueprintSelectedBlueprint ? blueprintSelectedBlueprint.rotation : 0;
	return {
		lang, username, userId, systems, customerId, customers,
		selectedSystem: blueprintSelectedSystem,
		selectedProject: blueprintSelectedProject,
		bp: blueprintSelectedBlueprint,
		blueprints: blueprintBlueprints,
		loggers: blueprintBlueprintLoggers,
		rotation
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

function BlueprintsView(props) {
	const location = useLocation();
	return <BlueprintsViewClass {...props} location={location} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintsView);
