import { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

export default class SupportCustomersSelector extends Component {
    getTables() {
        const { selectSupportCustomer, customers, selectedCustomers, lang } = this.props;
        if (selectSupportCustomer !== undefined && customers !== undefined && selectedCustomers !== undefined) {
            // eslint-disable-next-line unicorn/no-array-reduce
            const tableEntries = Object.keys(customers).reduce((res, key) => {
                const entry = (
                    <tr
                        key={`customer_${key}`}
                        onClick={() => selectSupportCustomer({ customerId: key })}
                    >
                        <td>{key}</td>
                        <td>{customers[key].customerName ?? "-"}</td>
                    </tr>
                );
                if (selectedCustomers[key])
                    res.selected.push(entry);
                else
                    res.selectable.push(entry);
                return res;
            }, { selectable: [], selected: [] });
            return (
                <Container fluid>
                    <Row className="user-table-row">
                        <Col className="user-table-column" xs={6} sm={6} md={6} lg={6}>
                            <div className="table-wrapper">
                                <Table key="selectable-table" className="selectable-table" striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                <Lang lang={lang}>
                                                    <span key="en">Name</span>
                                                    <span key="fi">Nimi</span>
                                                    <span key="sv">Namn</span>
                                                </Lang>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableEntries.selectable}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col className="user-table-column" xs={6} sm={6} md={6} lg={6}>
                            <div className="table-wrapper">
                                <Table key="selected-table" className="selected-table" striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                <Lang lang={lang}>
                                                    <span key="en">Name</span>
                                                    <span key="fi">Nimi</span>
                                                    <span key="sv">Namn</span>
                                                </Lang>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableEntries.selected}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            );
        }
        return null;
    }

    render() {
        const { visible } = this.props;
        if (visible) {
            return (
                <div className="supportCustomersSelector">
                    {this.getTables()}
                </div>
            );
        }
        return null;
    }
}