import "react-datepicker/dist/react-datepicker.css";
import "./ExportModal.css";

import fi from "date-fns/locale/fi";
import dayjs from "dayjs";
import { Component } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton, Modal, ToggleButton } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import Lang from "../LanguageSelect/Lang";
import DateTimePicker from "./ExportDateTimePicker";


registerLocale("fi", fi);

export default class ExportModalButton extends Component {
	state = {
		showModal: false,
		csvFormat: "semicolon",
		// eslint-disable-next-line react/destructuring-assignment
		start: this.props.xAxisAbsoluteStartTime ?? null,
		// eslint-disable-next-line react/destructuring-assignment
		end: this.props.xAxisAbsoluteEndTime ?? null,
		timeSelection: "all",
	};

	handleModalClose = () => {
		this.setState({ showModal: false });
	};

	handleExport = () => {
		const { customerId, selectedSystem, selectedProject, lang } = this.props;
		const { timeSelection, start, end, csvFormat } = this.state;
		if (customerId !== undefined &&
			selectedSystem !== undefined && selectedSystem.id !== undefined &&
			selectedProject !== undefined && selectedProject.id !== undefined
		) {
			const loadingToast = toast.loading(<Lang en="CSV generation in progress" fi="Luodaan CSV-tiedostoa" sv="Skapar CSV fil" />, { autoClose: false, });
			Messenger.requestCSV({
				payload: {
					customerId,
					systemId: selectedSystem.id,
					projectId: selectedProject.id,
					start: timeSelection === "all" || start === null ? null : Math.round(dayjs(start).valueOf() / 1000),
					end: timeSelection === "all" || end === null ? null : Math.round(dayjs(end).valueOf() / 1000),
					format: csvFormat,
					filename: `Miran ${dayjs().format("HHmm DD.MM.YYYY")}.csv`,
					lang,
				},
				addData: {
					notification: loadingToast,
					successMsg: (
						<Lang lang={lang}>
							<span key="en">Downloading CSV...</span>
							<span key="fi">Ladataan CSV:tä...</span>
							<span key="sv">Laddar CSV...</span>
						</Lang>
					),
					failMsg: (
						<Lang lang={lang}>
							<span key="en">CSV download failed</span>
							<span key="fi">CSV:n luonti epäonnistui</span>
							<span key="sv">Nedladdning av CSV misslyckades</span>
						</Lang>
					)
				}
			});
		} else {
			toast.warning(<Lang en="Choose a project" fi="Valitse projekti" sv="Välj projekt" />);
		}
		this.handleModalClose();
	};

	handleDropdownSelect = (eventkey) => {
		this.setState({ csvFormat: eventkey });
	};

	getFormatTitle(csvFormat) {
		const { lang } = this.props;
		switch (csvFormat) {
			case "comma": {
				return (
					<Lang lang={lang}>
						<span className="title" key="en">Comma</span>
						<span className="title" key="fi">Pilkku</span>
						<span className="title" key="sv">Komma</span>
					</Lang>
				);
			}

			case "semicolon": {
				return (
					<Lang lang={lang}>
						<span className="title" key="en">Semicolon</span>
						<span className="title" key="fi">Puolipiste</span>
						<span className="title" key="sv">Semikolon</span>
					</Lang>
				);
			}

			default: {
				return (
					<Lang lang={lang}>
						<span className="title" key="en">Select...</span>
						<span className="title" key="fi">Valitse...</span>
						<span className="title" key="sv">Välj...</span>
					</Lang>
				);
			}
		}
	}

	getItems(csvFromat) {
		const { lang } = this.props;
		return [
			<Dropdown.Item
				key="option_semicolon"
				eventKey="semicolon"
				active={csvFromat === "semicolon"}
			>
				<Lang lang={lang}>
					<span className="title" key="en">Semicolon</span>
					<span className="title" key="fi">Puolipiste</span>
					<span className="title" key="sv">Semikolon</span>
				</Lang>
			</Dropdown.Item>,
			<Dropdown.Item
				key="option_comma"
				eventKey="comma"
				active={csvFromat === "comma"}
			>
				<Lang lang={lang}>
					<span className="title" key="en">Comma</span>
					<span className="title" key="fi">Pilkku</span>
					<span className="title" key="sv">Komma</span>
				</Lang>
			</Dropdown.Item>
		];
	}

	openModal = () => {
		const { xAxisStartTime, xAxisEndTime } = this.props;
		this.setState({
			showModal: true,
			csvFormat: "semicolon",
			start: xAxisStartTime ?? null,
			end: xAxisEndTime ?? null,
			timeSelection: "all",
		});
	};

	updateTime = (obj) => {
		this.setState(prevState => ({ ...prevState, ...obj }));
	};

	setTimeSelection = (string) => {
		this.setState({ timeSelection: string });
	};

	render() {
		const { customerId, selectedSystem, selectedProject, xAxisAbsoluteStartTime, xAxisAbsoluteEndTime } = this.props;
		const { showModal, csvFormat, timeSelection, start, end } = this.state;
		return customerId !== undefined &&
			selectedSystem !== undefined && selectedSystem.id !== undefined &&
			selectedProject !== undefined && selectedProject.id !== undefined ? [
			<Button
				key="exportModalButton"
				className="exportButton"
				variant="primary"
				onClick={this.openModal}
			>
				<Lang en="Export CSV" fi="Lataa CSV" sv="Exportera CSV" />
			</Button>,
			<Modal
				key="exportModal"
				className="reportSaveModal"
				show={showModal}
				onHide={this.handleModalClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<Lang en="Export CSV" fi="Lataa CSV" sv="Exportera CSV" />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="subtitle"><Lang en="Separator" fi="Erottaja" sv="Skiljetecken" /></div>
					<span className="dropdownFlexer">
						<DropdownButton
							variant="default"
							title={this.getFormatTitle(csvFormat)}
							id="csvFromatDropdown"
							onSelect={this.handleDropdownSelect}
						>
							{this.getItems(csvFormat)}
						</DropdownButton >
					</span>
					<div className="time-selection">
						<Lang en="Data" fi="Data" sv="Data" />
						<ButtonGroup className="buttons">
							<ToggleButton
								key="all"
								id="csvRadioAll"
								type="radio"
								onChange={() => this.setTimeSelection("all")}
								variant="outline-primary"
								value="all"
								checked={timeSelection === "all"}
							>
								<Lang en="All" fi="Kaikki" sv="Allt" />
							</ToggleButton>
							<ToggleButton
								key="other"
								id="csvRadioOther"
								type="radio"
								onChange={() => this.setTimeSelection("other")}
								variant="outline-primary"
								value="other"
								checked={timeSelection === "other"}
							>
								<Lang en="Limited" fi="Rajattu" sv="Begränsad" />
							</ToggleButton>
						</ButtonGroup>
						{
							timeSelection === "all" ?
								null
								: <div className="time-interval">
									<DateTimePicker
										className="timeStart"
										title={
											<Lang en="Start" fi="Alku" sv="Från" />
										}
										value={start}
										updateAncestor={this.updateTime}
										propertyName="start"
										max={xAxisAbsoluteEndTime > end ? end : xAxisAbsoluteEndTime}
										maxProperty="end"
										min={xAxisAbsoluteStartTime}
									/>
									<DateTimePicker
										className="timeEnd"
										title={
											<Lang en="End" fi="Loppu" sv="Till" />
										}
										value={end}
										updateAncestor={this.updateTime}
										propertyName="end"
										min={xAxisAbsoluteStartTime < start ? start : xAxisAbsoluteStartTime}
										minProperty="start"
										max={xAxisAbsoluteEndTime}
									/>
								</div>
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="success"
						onClick={this.handleExport}
					>
						<Lang en="OK" fi="OK" sv="OK" />
					</Button>
					<Button
						variant="light"
						onClick={this.handleModalClose}
					>
						<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
					</Button>
				</Modal.Footer>
			</Modal>
		] : null;
	}
}
