import "../../../css/CardBackground.css";
import "./SystemSettings.css";

import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";

import isAuthorized from "../../../utilities/authorization";
import Lang from "../../LanguageSelect/Lang";
import BeepAlert from "./BeepAlert";
import EmailAlert from "./EmailAlert";
import ExternalBeepAlert from "./ExternalBeepAlert";
import PowerAlert from "./PowerAlert";
import RecordingInterval from "./RecordingInterval";
import SMSAlert from "./SMSAlert";
import StartupDelay from "./StartupDelay";
import TimeZoneSelect from "./TimeZoneSelect";
import WirelessLinkAlarm from "./WirelessLinkAlarm";

function SystemConfig({ systemsLoading }) {
	if (!systemsLoading) {
		const editAuth = isAuthorized({ one: ["super", "system", "customer-support"] });
		return (
			<div className={`systems-config-system-settings cardBackground${isDesktop ? "" : " mobile-config-system-settings"}`}>
				<div className="title">
					<Lang en="System settings" fi="Järjestelmäasetukset" sv="System inställningar" />
				</div>
				<div className="general-settings">
					<div className="subtitle">
						<Lang en="General settings:" fi="Yleiset asetukset:" sv="Allmänna inställningar:" />
					</div>
					<div className="settings-block">
						<TimeZoneSelect editAuth={editAuth} />
					</div>
				</div>
				<div className="logging-settings">
					<div className="subtitle">
						<Lang en="Logging settings:" fi="Loggausasetukset:" sv="Loggningsinställningar:" />
					</div>
					<div className="settings-block">
						<RecordingInterval editAuth={editAuth} />
						<StartupDelay editAuth={editAuth} />
					</div>
				</div>
				<div className="alert-settings">
					<div className="subtitle">
						<Lang en="Alert settings:" fi="Hälytysasetukset:" sv="Larm inställningar:" />
					</div>
					<div className="settings-block">
						<SMSAlert editAuth={editAuth} />
						<EmailAlert editAuth={editAuth} />
						<BeepAlert editAuth={editAuth} />
						<ExternalBeepAlert editAuth={editAuth} />
					</div>
				</div>
				<div className="device-alert-settings">
					<div className="subtitle">
						<Lang en="Device alert settings:" fi="Laitehälytysasetukset:" sv="Enhetens larm inställningar:" />
					</div>
					<div className="settings-block">
						<WirelessLinkAlarm editAuth={editAuth} />
						<PowerAlert editAuth={editAuth} />
					</div>
				</div>
			</div>
		);
	}
	return null;
}

function mapStateToProps(state) {
	const { systemsLoading } = state;
	return { systemsLoading };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemConfig);