import { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import Messenger from "../../utilities/messenger";
import getFooterButtons from "../ConfigModalOptions";
import Lang from "../LanguageSelect/Lang";

const rfdclone = rfdc();

function EditingOptions({ visible, customer, handleCustomerTextChange, handleNumberChange, handleCheckbox }) {
    if (visible) {
        return (
            <form>
                <Form.Group controlId="customerName">
                    <Form.Label>
                        <Lang en="Customer name" fi="Asiakkaan nimi" sv="Kundens namn" />
                    </Form.Label>
                    <Form.Control
                        maxLength="64"
                        type="text"
                        value={customer.customerName}
                        onChange={(e) => handleCustomerTextChange(e, "customerName")}
                    />
                </Form.Group>
                <Form.Group controlId="maxUsers">
                    <Form.Label>
                        <Lang en="Max users" fi="Maksimi käyttäjämäärä" sv="Max användare" />
                    </Form.Label>
                    <Form.Control
                        maxLength="8"
                        type="text"
                        value={customer.maxUsers}
                        onChange={(e) => handleNumberChange(e, "maxUsers")}
                    />
                </Form.Group>
                <Form.Check
                    type="checkbox"
                    id="login_allowed_check"
                    checked={customer.loginAllowed}
                    onChange={(e) => { handleCheckbox(e, "loginAllowed"); }}
                    label={<Lang en="Allow login" fi="Salli kirjautuminen" sv="Tillåt inskrivning" />}
                />
            </form>
        );
    }
    return null;
}

function DeleteView({ visible, name }) {
    if (visible) {
        return (
            <div className="deleteView">
                <div className="title">
                    <Lang en="Delete customer?" fi="Poistetaanko asiakas?" sv="Radera kund?" />
                </div>
                <div className="deletable-name">
                    {name}
                </div>
            </div>
        );
    }
    return null;
}

const getEditorModalTitle = ({ newView, deleteView }) => {
    if (deleteView) {
        return (<Lang en="Customer removal" fi="Asiakkaan poisto" sv="Kund radering" />);
    }
    if (newView) {
        return (<Lang en="New customer" fi="Uusi asiakas" sv="Ny kund" />);
    }
    return (<Lang en="Edit customer" fi="Muokkaa asiakasta" sv="Redigera kund" />);
};

export default class EditingModal extends Component {
    state = {
        // eslint-disable-next-line react/destructuring-assignment
        customer: this.props.customer,
        deleteView: false,
    };

    handleModalClose = () => {
        const { close } = this.props;
        if (close)
            close();
    };

    handleTextChange = (e, prop) => {
        if (e && e.target && e.target.value !== undefined && prop) {
            this.setState({ [prop]: e.target.value });
        }
    };

    handleCustomerTextChange = (e, prop) => {
        if (prop && e) {
            this.setState(({ customer }) => {
                const customerCopy = rfdclone(customer);
                customerCopy[prop] = e.target.value;
                return { customer: customerCopy };
            });
        }
    };

    handleCheckbox = (e, prop) => {
        if (e && prop) {
            this.setState(({ customer }) => {
                const customerCopy = rfdclone(customer);
                customerCopy[prop] = e.target.checked ? 1 : 0;
                return { customer: customerCopy };
            });
        }
    };

    handleNumberChange = (e, prop) => {
        if (prop && e) {
            const number = Number(e.target.value);
            if (e.target.value === "") {
                this.setState(({ customer }) => {
                    const customerCopy = rfdclone(customer);
                    customerCopy[prop] = "";
                    return { customer: customerCopy };
                });
            } else if (!Number.isNaN(number) && typeof number === "number") {
                this.setState(({ customer }) => {
                    const customerCopy = rfdclone(customer);
                    customerCopy[prop] = number;
                    return { customer: customerCopy };
                });
            }
        }
    };

    deleteCustomer = () => {
        const { numCustomers, removeCustomerData } = this.props;
        const { customer } = this.state;
        if (customer && customer.customerId !== undefined && numCustomers > 1) {
            const { customerId } = customer;
            const callback = (data) => {
                if (data) {
                    if (data.status === "success") {
                        toast.success(<Lang en="Customer deleted" fi="Asiakas poistettu" sv="Kunden raderades" />);
                        removeCustomerData({ customerId });
                    } else if (data.status === "error") {
                        toast.error(<Lang en="Failed to remove customer" fi="Asiakkaan poisto epäonnistui" sv="Radering av kund misslyckades" />);
                    }
                }
            };
            Messenger.requestDeleteCustomer({ customerId, callback });
            this.handleModalClose();
        }
    };

    setDeleteViewState = (bool) => {
        if (bool === undefined) { this.setState({ deleteView: false }); }
        else { this.setState({ deleteView: bool }); }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    submit = () => {
        const { newCustomer, getCustomerData } = this.props;
        const { customer } = this.state;
        if (newCustomer) {
            const callback = (data) => {
                if (data) {
                    if (data.status === "success") {
                        toast.success(<Lang en="New customer created" fi="Uusi asiakas luotu" sv="Ny kund skapad" />);
                        getCustomerData(data);
                    } else if (data.status === "error") {
                        toast.error(<Lang en="Customer creation failed" fi="Uuden asiakkaan luonti epäonnistui" sv="Skapning av kund misslyckades" />);
                    }
                }
            };
            Messenger.requestNewCustomer({ ...customer, callback });
        } else {
            const { customerId } = customer;
            const callback = (data) => {
                if (data) {
                    if (data.status === "success") {
                        toast.success(<Lang en="Customer information updated" fi="Asiakkaan tiedot päivitetty" sv="Kund information updaterad" />);
                        getCustomerData({ customerId });
                    } else if (data.status === "error") {
                        toast.error(<Lang en="Failed to update customer information" fi="Asiakkaan tietojen päivitys epäonnistui" sv="Updatering av kundens information misslyckades" />);
                    }
                }
            };
            Messenger.requestUpdateCustomer({ ...customer, callback });
        }
        this.handleModalClose();
    };

    render() {
        const { show, newCustomer, lang, numCustomers } = this.props;
        const { deleteView, customer } = this.state;
        return (
            <Modal
                className="customerEditModal"
                show={show}
                onHide={this.handleModalClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {getEditorModalTitle({
                            newView: newCustomer,
                            deleteView,
                            lang
                        })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditingOptions
                        new={newCustomer}
                        visible={!deleteView}
                        handleCustomerTextChange={this.handleCustomerTextChange}
                        handleNumberChange={this.handleNumberChange}
                        handleCheckbox={this.handleCheckbox}
                        handleTextChange={this.handleTextChange}
                        customer={customer}
                        lang={lang}
                    />
                    <DeleteView
                        visible={deleteView}
                        delete={this.deleteCustomer}
                        name={customer.customerName}
                        lang={lang}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {getFooterButtons({
                        newView: newCustomer,
                        deleteView,
                        setDeleteViewState: this.setDeleteViewState,
                        submit: this.submit,
                        valid: !!(customer.customerName && customer.maxUsers),
                        closeModal: this.handleModalClose,
                        deleteFunc: this.deleteCustomer,
                        disableDelete: numCustomers < 2
                    })}
                </Modal.Footer>
            </Modal>
        );
    }
}