import { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsPencilSquare } from "react-icons/bs";

import getFooterButtons from "../ConfigModalOptions";
import Lang from "../LanguageSelect/Lang";

export default class SaveButton extends Component {
    state = {
        showModal: false,
        reportNameState: "",
        reportNameValid: false,
        deleteView: false,
    };

    // Shows confirmation modal if non report name found
    handleEditClick = () => {
        const { reportName } = this.props;
        this.setState({ showModal: true, reportNameState: reportName === undefined ? "" : reportName, reportNameValid: reportName !== undefined });
    };

    handleSaveReport = () => {
        const { saveReport, reportName } = this.props;
        const { reportNameState } = this.state;
        if (saveReport) {
            const saveName = reportNameState === "" ? reportName : reportNameState;
            if (saveName === undefined) {
                this.handleEditClick();
            } else {
                this.setState({ showModal: false, reportNameState: "", reportNameValid: false }, () => {
                    saveReport(saveName);
                });
            }
        }
    };

    handleDelete = () => {
        const { reportName, reportId, deleteReport } = this.props;
        if (reportName !== undefined && reportId !== undefined) {
            deleteReport(reportId);
            this.handleModalClose();
        }
    };

    handleTextChange = (e) => {
        this.setState({ reportNameState: e.target.value, reportNameValid: e.target.value !== "" });
    };

    handleModalClose = () => {
        this.setState({ showModal: false, reportNameState: "", reportNameValid: false, deleteView: false });
    };

    setDeleteViewState = (bool) => {
        if (bool === undefined)
            {this.setState({ deleteView: false });}
        else
            {this.setState({ deleteView: bool });}
    };

    render() {
        const { lang, reportName } = this.props;
        const { showModal, deleteView, reportNameValid, reportNameState } = this.state;
        return (
            <span className="saveButton">
                <Button onClick={this.handleSaveReport} variant="primary" size="sm">
                    <Lang lang={lang}>
                        <span key="en">Save</span>
                        <span key="fi">Tallenna</span>
                        <span key="sv">Spara</span>
                    </Lang>
                </Button>
                <IconContext.Provider value={{ className: "reactIcon-edit" }}>
                    <Button onClick={this.handleEditClick} variant="warning" size="sm" style={{ marginLeft: "0.1rem" }} disabled={reportName === undefined}>
                        <BsPencilSquare />
                    </Button>
                </IconContext.Provider>
                <Modal
                    className="reportSaveModal"
                    show={showModal}
                    onHide={this.handleModalClose}
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Lang lang={lang}>
                                <span key="en">Saving settings template</span>
                                <span key="fi">Asetuspohjan tallennus</span>
                                <span key="sv">Sparning av inställningsmall</span>
                            </Lang>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {deleteView ?
                            <div className="deleteView">
                                <div className="title">
                                    <Lang lang={lang}>
                                        <span key="en">Delete settings template?</span>
                                        <span key="fi">Poistetaanko asetuspohja?</span>
                                        <span key="sv">Radera inställningsmall?</span>
                                    </Lang>
                                </div>
                                <div className="deletable-name">
                                    {reportName}
                                </div>
                            </div>
                            :
                            <Form validated={reportNameValid}>
                                <Form.Group controlId="reportNameForm">
                                    <Form.Label>
                                        <Lang lang={lang}>
                                            <span key="en">Settings template name</span>
                                            <span key="fi">Asetuspohjan nimi</span>
                                            <span key="sv">Inställningsmall namn</span>
                                        </Lang>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        maxLength="32"
                                        type="text"
                                        value={reportNameState}
                                        onChange={this.handleTextChange}
                                        isInvalid={!reportNameValid}
                                    />
                                </Form.Group>
                            </Form>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {getFooterButtons({
                            deleteView,
                            setDeleteViewState: this.setDeleteViewState,
                            submit: this.handleSaveReport,
                            closeModal: this.handleModalClose,
                            deleteFunc: this.handleDelete,
                            valid: reportNameValid,
                            disableDelete: reportName === undefined,
                        })}
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}
