import "../../css/CardBackground.css";
import "./ProjectDataStatisticsDisplay.css";

import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { Component } from "react";
import { Button, Table } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsDownload } from "react-icons/bs";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Lang from "../LanguageSelect/Lang";

const dateFormat = "DD.MM.YYYY HH:mm";

const downloadStatistics = () => {
	const loadingToast = toast.loading(<Lang en="Downloading statistics" fi="Ladataan tilastoja" sv="Laddar ner statistik" />, { autoClose: false });
	const htmlCanvas = document.querySelectorAll(".project-data-statistics");
	const buttons = htmlCanvas[0].querySelectorAll("button");
	buttons.forEach(button => {
		// eslint-disable-next-line no-param-reassign
		button.style.opacity = 0;
	});
	html2canvas(htmlCanvas[0], {
		logging: false,
		scale: 1.2,
	}).then((canvas) => {
		const img = canvas.toDataURL("image/png");
		const element = document.createElement("a");
		element.setAttribute("href", img);
		element.setAttribute("download", `Miran_statistics_${dayjs().format("DD_MM_YYYY_HH_mm")}`);
		element.style.display = "none";
		document.body.append(element);
		element.click();
		toast.update(loadingToast, { render: <Lang en="Statistics downloaded" fi="Tilastot ladattu" sv="Statistik nedladdad" />, type: "success", isLoading: false, autoClose: 5000 });
		element.remove();
		return true;
	}).catch(() => {
		toast.update(loadingToast, { render: <Lang en="Error loading statistics" fi="Virhe ladattaessa tilastoja" sv="Statistik kunde inte laddas" />, type: "error", isLoading: false, autoClose: 5000 });
	});
	buttons.forEach(button => {
		// eslint-disable-next-line no-param-reassign
		button.style.opacity = 1;
	});
};

const formatTime = (time) => time !== null && time !== undefined && time !== 0 ? dayjs(time).format(dateFormat) : "-";

const getIntervalString = (interval) => (
	`${interval && interval.start !== undefined && interval.start !== null && interval.start !== 0 ? dayjs(interval.start * 1000).format(dateFormat) : "-"} - ${interval && interval.end !== undefined && interval.end !== null && interval.end !== 0 ? dayjs(interval.end * 1000).format(dateFormat) : "-"}`
);

const parseRows = ({ statistics, datasets, selectedProject }) => {
	const LOGGERS = Object.keys(statistics).sort((a, b) => selectedProject.loggerIds.split(",").indexOf(a) - selectedProject.loggerIds.split(",").indexOf(b));
	const res = [];
	for (const logKey of LOGGERS) {
		statistics[logKey].forEach(stats => {
			const dIndex = datasets.findIndex(x => x.deviceKey === logKey && x.measi === stats.index);
			if (dIndex > -1) {
				res[datasets[dIndex].dsi] = (
					<tr key={`stats_key_${logKey}_${stats.index}`}>
						<td className="mso-indent logger-name">{datasets[dIndex].text}</td>
						<td className="mso-indent">{stats.amountOfDatapoints}</td>
						<td className="mso-indent max">{(Math.round(stats.min * 100) / 100).toString().replace(".", ",")}</td>
						<td className="mso-indent time">({formatTime(stats.minTime * 1000)})</td>
						<td className="mso-indent min">{(Math.round(stats.max * 100) / 100).toString().replace(".", ",")}</td>
						<td className="mso-indent time">({formatTime(stats.maxTime * 1000)})</td>
						<td className="mso-indent">{(Math.round(stats.mean * 100) / 100).toString().replace(".", ",")}</td>
						<td className="mso-indent">{(Math.round(stats.stdDev * 100) / 100).toString().replace(".", ",")}</td>
					</tr>
				);
			}
		});
	}
	return res;
};

class ProjectDataStatisticsDisplay extends Component {
	componentDidUpdate(prevProps) {
		const {
			statisticsViewProjectDataStatistics, customerId, statisticsViewRequestedCalcualtion, statisticsViewSelectedSystem, statisticsViewSelectedProject, statisticsViewXAxisStartTime,
			statisticsViewXAxisEndTime, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime, statisticsViewXAxisFilter, setValues
		} = this.props;
		if (
			statisticsViewProjectDataStatistics !== undefined &&
			(
				customerId !== statisticsViewRequestedCalcualtion.customerId ||
				statisticsViewSelectedSystem.id !== statisticsViewRequestedCalcualtion.systemId ||
				statisticsViewSelectedProject.id !== statisticsViewRequestedCalcualtion.projectId ||
				dayjs(statisticsViewXAxisStartTime).valueOf() !== statisticsViewRequestedCalcualtion.start ||
				dayjs(statisticsViewXAxisEndTime).valueOf() !== statisticsViewRequestedCalcualtion.end ||
				(statisticsViewRequestedCalcualtion.timeFilter.filterStart !== undefined && statisticsViewRequestedCalcualtion.timeFilter.filterStart !== dayjs(statisticsViewXAxisFilterStartTime).utc().format("H:m")) ||
				(statisticsViewRequestedCalcualtion.timeFilter.filterEnd !== undefined && statisticsViewRequestedCalcualtion.timeFilter.filterEnd !== dayjs(statisticsViewXAxisFilterEndTime).utc().format("H:m")) ||
				prevProps.statisticsViewXAxisFilter !== statisticsViewXAxisFilter
			)
		) {
			setValues({ statisticsViewProjectDataStatistics: undefined });
		}
	}

	render() {
		const {
			statisticsViewProjectDataStatistics, statisticsViewDatasets, statisticsViewProjectDataStatisticsInterval, statisticsViewXAxisFilter, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime, statisticsViewSelectedProject
		} = this.props;
		if (statisticsViewProjectDataStatistics !== undefined && statisticsViewDatasets !== undefined) {
			const INTERVALSTRING = getIntervalString(statisticsViewProjectDataStatisticsInterval);
			return (
				<div className="project-data-statistics cardBackground">
					<Button className="statistics-dl" size="sm" variant="secondary" onClick={downloadStatistics}>
						<IconContext.Provider value={{ className: "statistics-dl-icon" }}>
							<BsDownload />
							<Lang en="Download statistics" fi="Lataa tilastot" sv="Ladda ner statistik" />
						</IconContext.Provider>
					</Button>
					<div className="title">
						<Lang en={`Calculated values from timespan ${INTERVALSTRING}`} fi={`Lasketut arvot aikaväliltä ${INTERVALSTRING}`} sv={`Beräknade värden från tiden ${INTERVALSTRING}`} />
					</div>
					{statisticsViewXAxisFilter && statisticsViewXAxisFilterStartTime !== undefined && statisticsViewXAxisFilterEndTime !== undefined ?
						<div className="subtitle">
							<Lang en="Time filter " fi="Kellonaikasuodatus " sv="Tidsfilter " />
							{`${dayjs(statisticsViewXAxisFilterStartTime).format("H:mm")} - ${dayjs(statisticsViewXAxisFilterEndTime).format("H:mm")}`}
						</div>
						: null}
					<Table className="systems-table">
						<thead>
							<tr>
								<th aria-label="empty" />
								<th aria-label={<Lang en="Amount of datapoints" fi="Datapisteiden määrä" sv="Mängden datapunkter" />}><Lang en="Amount of datapoints" fi="Datapisteiden määrä" sv="Mängden datapunkter" /></th>
								<th aria-label={<Lang en="Minimum" fi="Minimi" sv="Minimum" />} className="min-header"><Lang en="Minimum" fi="Minimi" sv="Minimum" /></th>
								<th aria-label={<Lang en="time" fi="aika" sv="tid" />} className="time-header"><Lang en="time" fi="aika" sv="tid" /></th>
								<th aria-label={<Lang en="Maximum" fi="Maksimi" sv="Maximum" />} className="max-header"><Lang en="Maximum" fi="Maksimi" sv="Maximum" /></th>
								<th aria-label={<Lang en="time" fi="aika" sv="tid" />} className="time-header"><Lang en="time" fi="aika" sv="tid" /></th>
								<th aria-label={<Lang en="Average" fi="Keskiarvo" sv="Medeltal" />}><Lang en="Average" fi="Keskiarvo" sv="Medeltal" /></th>
								<th aria-label={<Lang en="Standard deviation" fi="Keskihajonta" sv="Standardavvikelse" />}><Lang en="Standard deviation" fi="Keskihajonta" sv="Standardavvikelse" /></th>
							</tr>
						</thead>
						<tbody>
							{parseRows({ statistics: statisticsViewProjectDataStatistics, datasets: statisticsViewDatasets, selectedProject: statisticsViewSelectedProject })}
						</tbody>
					</Table>
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { customerId,
		statisticsViewProjectDataStatistics, statisticsViewProjectDataStatisticsInterval, statisticsViewDatasets,
		statisticsViewSelectedSystem, statisticsViewSelectedProject, statisticsViewXAxisStartTime, statisticsViewXAxisEndTime, statisticsViewXAxisFilter, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime, statisticsViewRequestedCalcualtion
	} = state;
	return {
		customerId,
		statisticsViewProjectDataStatistics, statisticsViewProjectDataStatisticsInterval, statisticsViewDatasets,
		statisticsViewSelectedSystem, statisticsViewSelectedProject, statisticsViewXAxisStartTime, statisticsViewXAxisEndTime, statisticsViewXAxisFilter, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime, statisticsViewRequestedCalcualtion
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDataStatisticsDisplay);