import "../../css/CardBackground.css";
import "../../css/pageDefault.css";
import "../../css/LeftSidePanel.css";
import "../../css/DeleteView.css";
import "./Customers.css";

import { Component } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { connect } from "react-redux";

import isAuthorized from "../../utilities/authorization";
import Lang from "../LanguageSelect/Lang";
import EditingModal from "./CustomersEditingModal";
import CustomersTable from "./CustomersTable";

class Customers extends Component {
	componentDidMount() {
		const { customersLoading, customers, getCustomerData, userId } = this.props;
		if (!customersLoading && customers && getCustomerData)
			getCustomerData({ customerId: null, userId });
	}

	openModal = (customer) => {
		const { setValues } = this.props;
		if (customer === undefined) {
			setValues({
				customersShowModal: true,
				customersSelectedCustomer: {
					maxUsers: 5,
					loginAllowed: 0,
					customerName: "",
				},
				customersModalType: "new",
			});
		} else {
			setValues({ 
				customersShowModal: true, 
				customersSelectedCustomer: customer, 
				customersModalType: "edit" 
			});
		}
	};

	closeModal = () => {
		const { setValues } = this.props;
		setValues({ customersShowModal: false });
	};

	render() {
		const { lang, customers, showModal, selectedCustomer, modalType, getCustomerData, removeCustomerData } = this.props;
		if (isAuthorized({ all: ["super"] })) {
			return (
				<div className="pageDefault customers">
					<div className="leftSidePanel">
						<div className="cardBackground">
							<Accordion
								defaultActiveKey="0"
								id="accordianPanel"
								className="menuPanelStatic"
							>
								<Card.Header>
									<Lang en="Customers" fi="Asiakkaat" sv="Kunder" />
								</Card.Header>
								<Accordion.Collapse eventKey="0">
									<Card.Body>
										<Button className="addCustomers-button" variant="primary" onClick={() => this.openModal()}>
											<Lang en="Add customer" fi="Lisää asiakas" sv="Lägg till kund" />
										</Button>
									</Card.Body>
								</Accordion.Collapse>
							</Accordion>
						</div>
					</div>
					<div className="customers-table-wrapper cardBackground">
						<CustomersTable
							customers={customers}
							selectCustomer={this.openModal}
						/>
					</div>
					{
						showModal ?
							<EditingModal
								lang={lang}
								show={showModal}
								close={this.closeModal}
								numCustomers={Object.keys(customers).length}
								customer={selectedCustomer}
								newCustomer={modalType === "new"}
								getCustomerData={getCustomerData}
								removeCustomerData={removeCustomerData}
							/>
							:
							null
					}
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { lang, customers, userId,
		customersShowModal,
		customersModalType,
		customersSelectedCustomer,
		customersLoading,
	} = state;
	return {
		lang, customers, userId,
		showModal: customersShowModal,
		modalType: customersModalType,
		selectedCustomer: customersSelectedCustomer,
		customersLoading
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
