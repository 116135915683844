import { Component } from "react";
import { Form } from "react-bootstrap";

const handleFocus = (e) => {
    e.target.select();
};

export default class PasswordField extends Component {
    state = {
        // eslint-disable-next-line react/destructuring-assignment
        value: this.props.content,
    };

    updateAncestor = (data) => {
        const { propertyName, updateAncestor } = this.props;
        if (propertyName) {
            updateAncestor({ [propertyName]: data });
        }
    };

    handleChange = (e) => {
        const { realtimeUpdate } = this.props;
        if (e && e.target && typeof e.target.value === "string") {
            const { value } = e.target;
            if (realtimeUpdate)
                {this.updateAncestor(value);}
            else
                {this.setState({ value });}
        }
    };

    handleBlur = (e) => {
        const { realtimeUpdate } = this.props;
        if (e && e.target && typeof e.target.value === "string") {
            const { value } = e.target;
            if (!realtimeUpdate)
                this.updateAncestor(value);
        }
    };

    render() {
        const { className, title, size, realtimeUpdate, content, maxLength, autoComplete, validation } = this.props;
        const { value } = this.state;
        const finalClassName = className === undefined ? "" : (` ${className}`);
        return (
            <div className={`passwordField${finalClassName}`}>
                <div className="title">{title}</div>
                <Form.Group controlId="formValidation">
                    <Form.Control
                        size={size}
                        type="password"
                        placeholder=""
                        value={realtimeUpdate ? content : value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={handleFocus}
                        maxLength={maxLength === undefined ? "524288" : maxLength}
                        autoComplete={autoComplete === undefined ? "on" : autoComplete}
                        isInvalid={validation !== null}
                    />
                </Form.Group>
            </div>
        );
    }
}

PasswordField.defaultProps = {
    title: "",
    content: "",
};
