import "./MessageManager.css";

import { Component } from "react";
import { Accordion, Button, Card, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import Lang from "../LanguageSelect/Lang";
import EditingModal from "./EditingModal";

class MessageManager extends Component {
	componentDidMount() {
		this.fetchMessages();
	}

	fetchMessages = () => {
		const { setValues } = this.props;
		const callback = (data) => {
			if (data.status === "error") {
				toast.error(<Lang en="Failed to fetch messages." fi="Viestin haku epäonnistui" sv="Kunde inte hämta meddelanden" />);
			} else if (data.status === "success") {
				setValues({ messageManagerInfoMessages: data.infoMessages });
			}
		};
		Messenger.requestLatestInfoMessages({ payload: { maxAmount: null }, callback: callback.bind(this) });
	};

	openEditor = ({ id, newMessage }) => {
		const { infoMessages, setValues } = this.props;
		if (!newMessage && id !== undefined && Array.isArray(infoMessages)) {
			const index = infoMessages.findIndex(x => x.infoMessageId === id);
			if (index > -1) {
				setValues({ messageManagerEditId: id, messageManagerEditorContent: infoMessages[index].infoMessageText, messageManagerEditorOpen: true });
			} else {
				toast.error(<Lang en="Message content was not found." fi="Viestin sisältöä ei löytynyt." sv="Kunde inte hitta meddelandets innehåll." />);
			}
		} else if (newMessage) {
			setValues({ messageManagerEditId: id, messageManagerEditorContent: "", messageManagerEditorOpen: true, messageManagerNewMessage: newMessage });
		}
	};

	closeEditor = () => {
		const { setValues } = this.props;
		setValues({ messageManagerEditId: undefined, messageManagerEditorContent: "", messageManagerEditorOpen: false, messageManagerNewMessage: false });
	};

	render() {
		const { lang, infoMessages, editorOpen, editorContent, newMessage, editId } = this.props;
		return (
			<div className="pageDefault messagingSettings">
				<div className="leftSidePanel">
					<div className="cardBackground">
						<Accordion
							defaultActiveKey="0"
							id="accordianPanel"
							className="menuPanelStatic"
						>
							<Card.Header>
								<Lang lang={lang}>
									<div key="en">Messaging</div>
									<div key="fi">Viestintä</div>
									<div key="sv">Meddelande</div>
								</Lang>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<Button variant="primary" onClick={() => this.openEditor({ id: undefined, newMessage: true })}>
										<Lang en="Add message" fi="Lisää viesti" sv="Tillägg meddelande" lang={lang} />
									</Button>
								</Card.Body>
							</Accordion.Collapse>
						</Accordion>
					</div>
				</div>
				<div className="rightSidePanel">
					<div className="message-list-wrapper cardBackground">
						<MessagesTable
							lang={lang}
							messages={infoMessages}
							select={this.openEditor}
						/>
					</div>
				</div>
				{
					editorOpen === undefined ? null :
						<EditingModal
							lang={lang}
							show={editorOpen}
							contentProp={editorContent}
							close={this.closeEditor}
							newMessage={newMessage}
							fetchMessages={this.fetchMessages}
							editId={editId}
						/>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang,
		messageManagerEditorOpen,
		messageManagerEditId,
		messageManagerNewMessage,
		messageManagerEditorContent,
		messageManagerInfoMessages,
	} = state;
	return {
		lang,
		editorOpen: messageManagerEditorOpen,
		editId: messageManagerEditId,
		newMessage: messageManagerNewMessage,
		editorContent: messageManagerEditorContent,
		infoMessages: messageManagerInfoMessages,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageManager);

// eslint-disable-next-line sonarjs/cognitive-complexity
const parseRows = ({ messages, select }) => {
	if (Array.isArray(messages)) {
		return messages.map((msg) => (
			<tr
				key={`msg_${msg.infoMessageId}`}
				onClick={() => {
					if (typeof select === "function") {
						select({ id: msg.infoMessageId, newMessage: false });
					}
				}}
			>
				<td>{msg.infoMessageId}</td>
				{window.matchMedia("screen and (max-width: 480px)").matches || window.matchMedia("screen and (max-height: 480px)").matches ?
					<td>{msg.infoMessageText && msg.infoMessageText.length > 30 ? `${msg.infoMessageText.slice(0, 30)}...` : msg.infoMessageText}</td>
					:
					<td>{msg.infoMessageText && msg.infoMessageText.length > 100 ? `${msg.infoMessageText.slice(0, 100)}...` : msg.infoMessageText}</td>
				}
			</tr>
		));
	}
	return [];
};

function MessagesTable({ lang }, props) {
	return (
		<Table id="table">
			<thead>
				<tr>
					<th>
						<Lang en="ID" fi="ID" sv="ID" lang={lang} />
					</th>
					<th>
						<Lang en="Preview" fi="Esikatselu" sv="Förhandsvisning" lang={lang} />
					</th>
				</tr>
			</thead>
			<tbody>
				{parseRows({ ...props })}
			</tbody>
		</Table>
	);
}