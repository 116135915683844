import { Form } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";
import LangDropdown from "../SetPassword/LangDropdown";
import { LANGUAGES } from "../SetPassword/SetPassword";
import ProjectsSelector from "./ProjectsSelector";
import SupportCustomersSelector from "./SupportCustomersSelector";

const getSystems = (customers, customerId) => {
    if (customers && customerId !== undefined && customers[customerId] !== undefined && customers[customerId].systems !== undefined) {
        return customers[customerId].systems;
    }
    return [];
};

export default function EditingOptions({
    visible, lang, newUser, user, handleUserTextChange, getRights, customers, selectedSupportCustomers, selectSupportCustomer,
    selectedRights, customerId, selectedProjects, selectProject, selectSystem, selectedSystem, newUserLang, updateNewUserLang
}) {
    if (visible) {
        return (
            <Form>
                <Form.Group controlId="userName">
                    <Form.Label>
                        <Lang lang={lang}>
                            <span key="en">Email</span>
                            <span key="fi">Sähköposti</span>
                            <span key="sv">E-post</span>
                        </Lang>
                    </Form.Label>
                    {
                        newUser ?
                            <Form.Control
                                maxLength="128"
                                type="email"
                                value={user.userName === undefined ? "" : user.userName}
                                onChange={(e) => handleUserTextChange(e, "userName")}
                            />
                            :
                            <span>{(user.userName === undefined ? ":" : `: ${user.userName}`)}</span>
                    }
                </Form.Group>
                <Form.Group controlId="rights">
                    <Form.Label>
                        <Lang lang={lang}>
                            <span key="en">Rights</span>
                            <span key="fi">Oikeudet</span>
                            <span key="sv">Rättigheter</span>
                        </Lang>
                    </Form.Label>
                    <div className="rights-selector">
                        {getRights({ lang })}
                        <SupportCustomersSelector
                            lang={lang}
                            customers={customers}
                            selectedCustomers={selectedSupportCustomers}
                            selectSupportCustomer={selectSupportCustomer}
                            visible={selectedRights ? selectedRights.supportCustomers : false}
                        />
                        <ProjectsSelector
                            lang={lang}
                            systems={getSystems(customers, customerId)}
                            selectedProjects={selectedProjects}
                            visible={selectedRights ? selectedRights.projectsSome : false}
                            selectProject={selectProject}
                            selectedSystem={selectedSystem}
                            selectSystem={selectSystem}
                        />
                    </div>
                    {
                        newUser ? <div className="new-user-lang">
                                <div><Lang en="User language" fi="Käyttäjän kieli" sv="Användarens språk" lang={lang} /></div>
                                <LangDropdown
                                    langs={LANGUAGES}
                                    lang={newUserLang}
                                    updateAncestor={updateNewUserLang}
                                />
                            </div> :
                            null
                    }
                </Form.Group>
            </Form>
        );
    }
    return null;
}
