import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./css/customTables.css";

import { Component } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import BlueprintsView from "./components/Blueprints/BlueprintsView";
import Customers from "./components/Customers/Customers";
import ErrorBoundary from "./components/ErrorBoundary";
import Lang from "./components/LanguageSelect/Lang";
import Login from "./components/Login";
import MessageManager from "./components/MessageManager/MessageManager";
import Projects from "./components/Projects/Projects";
import RootLayout from "./components/RootLayout";
import { SetPassword } from "./components/SetPassword/SetPassword";
import StatisticsView from "./components/StatisticsView";
import Systems from "./components/Systems/Systems";
import Alarms from "./components/Alarms/Alarms"
import Users from "./components/Users/Users";
import UserSettings from "./components/UserSettings/UserSettings";
import isAuthorized from "./utilities/authorization";
import Messenger from "./utilities/messenger";
import store, { REDUX_INIT_STATE } from "./utilities/redux";
import TokenHandler from "./utilities/tokenHandler";
import isDefined from "./utilities/util";
import TimePicker from "./components/TimePicker";

const rfdclone = rfdc();

const LANGUAGES = ["fi", "en", "sv"];

function PrivateRoute({ auth, children }) {
	return auth() ? children : <Navigate to="/" />;
}

function RootRedirect() {
	const userLogged = JSON.parse(sessionStorage.getItem("userLogged"));
	const url = window.location.pathname;
	if (userLogged) {
		return <Navigate to={url === "/" ? "/mainpage" : url} />;
	}
	return <Navigate to="/login" replace />;
}

const isLogged = () => JSON.parse(sessionStorage.getItem("userLogged"));

class App extends Component {
	constructor() {
		super();

		this.isAuthorized = isAuthorized.bind(this);
		this.requestedCustomers = {};
		TokenHandler.setLogout(this.logout);
	}

	state = {
		getCustomerDataLoading: false,
		getCustomerSystemsLoading: false,
		scrollTop: 0,
	};

	componentDidMount() {
		const stateObject = JSON.parse(sessionStorage.getItem("state"));
		this.setValues(stateObject);
		TokenHandler.setRenewal(60_000);
	}

	setValues = (obj) => {
		store.dispatch({
			type: "SET_VALUES",
			obj,
		});
		this.forceUpdate();
		sessionStorage.setItem("state", JSON.stringify(store.getState()));
	};

	login = (username, password) => {
		// eslint-disable-next-line sonarjs/cognitive-complexity
		const callback = (data) => {
			if (
				data.status === "success" &&
				data.token !== undefined
			) {
				const update = {
					customers: {},
					username,
					loginState: null,
					loginUsername: "",
					loginPassword: "",
				};
				let userRights = {};
				TokenHandler.setToken(data.token);
				sessionStorage.setItem("userLogged", JSON.stringify(true));
				// Parsing customers into object strucature for effieciency
				if (data.customers) {
					update.customers = {};
					// eslint-disable-next-line no-restricted-syntax
					for (const customer of data.customers) {
						update.customers[customer.customerId] = customer;
					}
				}
				// Parsing rights into object strucature for effieciency
				if (data.rights) {
					userRights = {};
					// eslint-disable-next-line no-restricted-syntax
					for (const right of data.rights) {
						userRights[right] = true;
					}
				}

				if (data.projectsSome) {
					userRights.projectsSome = true;
				}
				update.projectsSome = data.projectsSome;
				if (data.language) {
					update.lang = data.language;
				}
				// Combining new state and removing unecessary data
				const newState = { ...data, ...update };
				if (newState.token !== undefined){
					delete newState.token;
				}
				this.setValues(newState);
				sessionStorage.setItem("userRights", JSON.stringify(userRights));
			} else {
				if (data.msg !== undefined && (data.msg === "Wrong username" || data.msg === "Wrong password")) {
					toast.error(<Lang en="Incorrect username/password" fi="Väärä käyttäjänimi/salasana" sv="Fel användarnamn/lösenord" />);
				} else {
					toast.error(<Lang en="Login failed" fi="Kirjautuminen epäonnistui" sv="Inloggningen misslyckades" />);
				}
				this.setValues({ loginState: "error", loginUsername: username });
			}
		};
		Messenger.requestLogin({ username, password, callback });
	};

	logout = (error = false) => {
		const { lang } = store.getState();
		this.setValues({ ...REDUX_INIT_STATE, lang });
		sessionStorage.setItem("userLogged", JSON.stringify(false));
		sessionStorage.setItem("userRights", JSON.stringify({}));
		if (error)
			toast.error(<Lang en="Your session has expired and you have been logged out." fi="Istuntosi on vanhentunut ja sinut on kirjattu ulos." sv="Din session har löpt ut och du har blivit utloggad." />, { autoClose: false, toastId: "logout" });
	};

	selectCustomer = (customerId, checkProjects) => {
		this.setValues({ customerId });
		// Fetching customer data if user data is missing
		const state = store.getState();
		if (state.customers && state.customers[state.customerId] && state.customers[state.customerId].users === undefined) {
			this.getCustomerData(state.customers[state.customerId]);
		}
		if (checkProjects && state.customers && state.customers[state.customerId] && state.customers[state.customerId].projects === undefined) {
			this.getCustomerSystems({ customerId: state.customerId, userId: state.userId });
		}
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	getCustomerData = ({ customerId, userId }) => {
		if (isDefined({ customerId })) {
			const callback = (data) => {
				if (data.status === "success" && data.customers !== undefined) {
					const state = store.getState();
					const update = { customers: rfdclone(state.customers) };
					data.customers.forEach(customer => {
						const customerCopy = rfdclone(customer);
						update.customers[customerCopy.customerId] = customerCopy;
						if (userId !== undefined && userId === state.userId && customerCopy.users !== undefined) {
							const index = customerCopy.users.findIndex(x => x.userId === userId);
							if (index > -1 && customerCopy.users[index].rights !== undefined) {
								update.rights = customerCopy.users[index].rights;
								
								const userRights = {}
								// eslint-disable-next-line no-restricted-syntax
								for (const right of customerCopy.users[index].rights) {
									userRights[right] = true;
								}
								if (customerCopy.users[index].projectsSome !== undefined) {
									userRights.projectsSome = true;
								}
								sessionStorage.setItem("userRights", JSON.stringify(userRights));
							}
						}
					});
					this.setValues(update);
					this.requestedCustomers[customerId] = false;
				} else {
					toast.error(<Lang en="Retrieving customer data failed" fi="Asiakastietojen haku epäonnistui" sv="Det gick inte att hämta kunddata" />);
				}
				this.setState({ getCustomerDataLoading: false });
			};
			if (!this.requestedCustomers[customerId]) {
				this.requestedCustomers[customerId] = true;
				this.setState({ getCustomerDataLoading: true });
				Messenger.requestCustomerData({ customerId: Number(customerId), callback });
			}
		}
	};

	removeCustomerData = ({ customerId }) => {
		const state = store.getState();
		if (isDefined({ customerId })) {
			const customers = rfdclone(state.customers);
			if (customers[customerId] !== undefined) {
				delete customers[customerId];
				this.setValues({ customers });
			}
		}
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	getCustomerSystems = ({ customerId, userId, noUpdate }) => {
		if (isDefined({ customerId })) {
			const callback = (data) => {
				if (data.status === "success" && data.systems) {
					const state = store.getState();
					const update = { customers: rfdclone(state.customers) };
					if (update.customers[customerId] !== undefined)
						update.customers[customerId].systems = data.systems;
					if (userId !== undefined && userId === state.userId)
						update.systems = data.systems;
					if (state.settingsSelectedSystem.id !== undefined)
						update.settingsSelectedSystem = data.systems.find(system => system.id === state.settingsSelectedSystem.id);
					if (noUpdate) {
						store.dispatch({
							type: "SET_VALUES",
							obj: update,
						});
					} else {
						this.setValues(update);
					}
				} else if (data.status !== "success") {
					this.logout();
				}
				this.setState({ getCustomerSystemsLoading: false });
			};
			this.setState({ getCustomerSystemsLoading: true });
			Messenger.requestGetCustomerSystems({ customerId, callback });
		}
	};

	removeUserData = ({ customerId, userId }) => {
		if (isDefined({ customerId, userId })) {
			const state = store.getState();
			const customers = rfdclone(state.customers);
			if (customers[customerId] !== undefined && customers[customerId].users !== undefined) {
				const index = customers[customerId].users.findIndex(x => x.userId === userId);
				if (index > -1) {
					customers[customerId].users.splice(index, 1);
					const userLogged = userId !== state.userId;
					this.setValues({ customers });
					sessionStorage.setItem("userLogged", JSON.stringify(userLogged));
				}
			}
		}
	};

	removeSystemData = ({ customerId, systemId }) => {
		if (isDefined({ customerId, systemId })) {
			const state = store.getState();
			const customers = rfdclone(state.customers);
			if (customers[customerId] !== undefined && customers[customerId].systems !== undefined) {
				const index = customers[customerId].systems.findIndex(x => x.id === systemId);
				if (index > -1) {
					customers[customerId].systems.splice(index, 1);
					this.setValues({ customers });
				}
			}
		}
	};

	updateData = (obj) => {
		const state = rfdclone(store.getState());
		Object.assign(state, obj);
		this.setValues(state);
	};

	scrollTop = () => {
		this.setState({ scrollTop: document.querySelectorAll(".App")[0].scrollTop });
	};

	render() {
		const { getCustomerSystemsLoading, getCustomerDataLoading, scrollTop } = this.state;
		const router = createBrowserRouter(
			createRoutesFromElements(
				<Route path="/" element={<RootLayout />}>
					<Route index element={<RootRedirect />} />
					<Route path="login" element={
						<Login
							login={this.login}
							userLogged={JSON.parse(sessionStorage.getItem("userLogged"))}
						/>
					} />
					<Route path="setPassword" element={<SetPassword />} />
					<Route path="mainpage" element={
						<PrivateRoute auth={isLogged}>
							<ErrorBoundary>
								<StatisticsView
									updateAncestor={this.updateData}
									selectCustomer={this.selectCustomer}
									getCustomerSystems={this.getCustomerSystems}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="projects" element={
						<PrivateRoute auth={() => isAuthorized({ one: ["super", "project_admin"] })}>
							<ErrorBoundary>
								<Projects
									updateAncestor={this.updateData}
									selectCustomer={this.selectCustomer}
									getCustomerSystems={this.getCustomerSystems}
									getCustomerSystemsLoading={getCustomerSystemsLoading}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="blueprints" element={
						<PrivateRoute auth={isLogged}>
							<ErrorBoundary>
								<BlueprintsView
									updateAncestor={this.updateData}
									selectCustomer={this.selectCustomer}
									getCustomerSystems={this.getCustomerSystems}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					{/*<Route path="alarms" element={
						<PrivateRoute auth={isLogged}>
							<ErrorBoundary>
								<Alarms
									updateAncestor={this.updateData}
									selectCustomer={this.selectCustomer}
									getCustomerSystems={this.getCustomerSystems}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />*/}
					<Route path="customers" element={
						<PrivateRoute auth={() => isAuthorized({ one: ["super"] })}>
							<ErrorBoundary>
								<Customers
									getCustomerData={this.getCustomerData}
									removeCustomerData={this.removeCustomerData}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="users" element={
						<PrivateRoute auth={() => isAuthorized({ one: ["super", "admin"] })}>
							<ErrorBoundary>
								<Users
									getCustomerData={this.getCustomerData}
									removeUserData={this.removeUserData}
									getCustomerSystems={this.getCustomerSystems}
									selectCustomer={this.selectCustomer}
									loading={getCustomerSystemsLoading || getCustomerDataLoading}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="systems" element={
						<PrivateRoute auth={() => isAuthorized({ one: ["super", "system"] })}>
							<ErrorBoundary>
								<Systems
									scrollTop={scrollTop}
									getCustomerData={this.getCustomerData}
									selectCustomer={this.selectCustomer}
									removeSystemData={this.removeSystemData}
									getCustomerSystems={this.getCustomerSystems}
									systemsSpinnerLoading={getCustomerSystemsLoading || getCustomerDataLoading}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="user-settings" element={
						<PrivateRoute auth={isLogged}>
							<ErrorBoundary>
								<UserSettings
									languages={LANGUAGES}
									logout={this.logout}
									updateAncestor={this.updateData}
								/>
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="message-management" element={
						<PrivateRoute auth={() => this.isAuthorized({ one: ["super"] })}>
							<ErrorBoundary>
								<MessageManager />
							</ErrorBoundary>
						</PrivateRoute>
					} />
					<Route path="health" element={
						<h3>I&apos;m healthy</h3>
					} />
				</Route>
			)
		);
		return (
			<div data-testid="App" className="App" onScroll={this.scrollTop}>
				<RouterProvider router={router} />
			</div>
		);
	}
}

export default App;