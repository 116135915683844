import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../../utilities/messenger";
import Lang from "../../LanguageSelect/Lang";

class RemoveLoggerModal extends Component {
	closeModal = () => {
		const { setValues } = this.props;
		setValues({ systemsConfigShowRemoveLoggerModal: false, systemsConfigRemoveLoggerId: undefined });
	};

	handleLoggerRemoval = () => {
		const { customerId, systemsSelectedSystem, systemsConfigRemoveLoggerId, restartPolling, setValues } = this.props;
		const payload = {
			customerId,
			systemId: systemsSelectedSystem.id,
			loggerId: systemsConfigRemoveLoggerId,
		};
		const callback = (data) => {
			if (data.status === "success") {
				toast.success(<Lang en="Logger to be removed" fi="Loggeri poistojonossa" sv="Loggern tas bort" />);
				restartPolling();
			} else {
				toast.error(<Lang en="Failed to remove logger" fi="Loggerin poisto epäonnistui" sv="Borttagningen av loggern misslyckades" />);
			}
		};
		this.closeModal();
		setValues({ systemsConfigRemoveLoggerId: undefined, systemsConfigRemoveLoggerName: "" });
		Messenger.removeLogger({ payload, callback: callback.bind(this) });
	};

	render() {
		const { systemsConfigShowRemoveLoggerModal, systemsConfigRemoveLoggerName } = this.props;
		return (
			<Modal
				key="removeloggermodal"
				className="remove-logger-modal"
				show={systemsConfigShowRemoveLoggerModal}
				onHide={this.closeModal}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<Lang
							en={`Are you sure you want to remove logger ${systemsConfigRemoveLoggerName}?`}
							fi={`Haluatko varmasti poistaa loggerin ${systemsConfigRemoveLoggerName}?`}
							sv={`Är du säker att du vill ta bort logger ${systemsConfigRemoveLoggerName}?`} />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Lang
						en="Note that the logger will be removed from the list only once the changes have taken effect at system level, this may take 1-10 minutes. The page will update automatically after that."
						fi="Huomioi, että loggeri poistetaan listauksesta vasta kun muutokset ovat tulleet voimaan järjestelmätasolla, tähän saattaa mennä 1-10 minuuttia. Sivu päivittyy sen jälkeen automaattisesti."
						sv="Obs. loggern tas bort från listan först då ändringarna har trätt i kraft på system nivå, detta kan ta 1-10 minuter. Sidan updateras automatiskt efteråt."
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="warning"
						onClick={this.handleLoggerRemoval}
					>
						<Lang en="OK" fi="OK" sv="OK" />
					</Button>
					<Button
						variant="light"
						onClick={this.closeModal}
					>
						<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	const { customerId, systemsSelectedSystem, systemsConfigShowRemoveLoggerModal, systemsConfigRemoveLoggerId, systemsConfigRemoveLoggerName } = state;
	return { customerId, systemsSelectedSystem, systemsConfigShowRemoveLoggerModal, systemsConfigRemoveLoggerId, systemsConfigRemoveLoggerName };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveLoggerModal);
