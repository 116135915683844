import "../css/scrollableDropdown.css";

import { Component } from "react";
import { Accordion, Card, Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

function getTitle({ customers, customerId, lang }) {
	if (customers !== undefined && customerId !== undefined && customers[customerId] !== undefined && customers[customerId].customerName !== undefined) {
		return customers[customerId].customerName;
	}
	if (lang !== undefined) {
		return (
			<Lang lang={lang}>
				<span className="title" key="en">Select...</span>
				<span className="title" key="fi">Valitse...</span>
				<span className="title" key="sv">Välj...</span>
			</Lang>
		);
	}
	return "?";
}

function getItems({ customers, customerId }) {
	let options = [];
	if (customers !== undefined) {
		const keys = Object.keys(customers);
		keys.sort((a, b) => customers[a].customerName.localeCompare(customers[b].customerName, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true }));
		options = keys.map(key =>
			<Dropdown.Item
				key={`option_${customers[key].customerId}`}
				eventKey={customers[key].customerId}
				active={customers[key].customerId === customerId}
			>
				{customers[key].customerName}
			</Dropdown.Item>
		);
	}
	return options;
}

export default class CustomerSelect extends Component {
	handleSelect = (customerId) => {
		const { selectCustomer, checkProjects } = this.props;
		if (selectCustomer)
			selectCustomer(Number(customerId), checkProjects);
	};

	render() {
		const { customers, customerId } = this.props;
		if (Object.keys(customers).length > 1) {
			return (
				<Accordion defaultActiveKey="0" className="cardBackground" style={{ marginBottom: "10px" }}>
					<Card id="accordianPanel" className="menuPanelStatic customerSelect">
						<Card.Header>
							<Lang en="Customers" fi="Asiakas" sv="Kunder" />
						</Card.Header>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<div className="customerDropdown">
									<span className="dropdownFlexer scrollable-dropdown">
										<DropdownButton
											variant="default"
											title={
												<span className="title">{getTitle(this.props)}</span>
											}
											id="typeDropdown"
											onSelect={this.handleSelect}
										>
											{getItems({ customers, customerId })}
										</DropdownButton >
									</span>
								</div>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</ Accordion>
			);
		}
		return null;
	}
}