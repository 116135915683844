import { Button, Modal } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

export default function ConfirmationModal({ show, confirm, lang, children }) {
	return (
		<Modal data-testid="confirmation-modal" show={show}>
			<Modal.Header closeButton onHide={() => confirm(false)}>
				<Modal.Title>
					<Lang lang={lang} fi="Vahvistus" en="Confirmation" sv="Konfirmation" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{children}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="success"
					onClick={() => confirm(true)}>
					<Lang lang={lang} fi="OK" en="OK" sv="OK" />
				</Button>
				<Button
					variant="light"
					onClick={() => confirm(false)}>
					<Lang lang={lang} fi="Peruuta" en="Cancel" sv="Ångra" />
				</Button>
			</Modal.Footer>
		</Modal>
	);
}