import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import Lang from "../LanguageSelect/Lang";
import BlueprintClient from "./blueprintClient";

export default class EditBlueprintModal extends Component {
	state = {
		/* eslint-disable react/destructuring-assignment */
		blueprintName: this.props.blueprint.name,
		blueprintDescription: this.props.blueprint.description,
		rotation: this.props.blueprint.rotation,
		originalRotation: this.props.blueprint.rotation
		/* eslint-enable react/destructuring-assignment */
	};

	componentDidUpdate(prevProps, prevState) {
		const { blueprint } = this.props;
		const blueprintChanged = blueprint && prevProps.blueprint && blueprint.uuid !== prevProps.blueprint.uuid;
		const firstBlueprintSelected = (!prevProps.blueprint && blueprint);
		if (firstBlueprintSelected || blueprintChanged) {
			if (!blueprint) {
				return;
			}
			if (prevState.blueprintName === blueprint.name && prevState.blueprintDescription === blueprint.description && prevState.rotation === blueprint.rotation) {
				return;
			}
			this.setState({
				blueprintName: blueprint.name,
				blueprintDescription: blueprint.description,
				rotation: blueprint.rotation,
				originalRotation: blueprint.rotation
			});
		}
	}

	handleDescriptionChange = (event) => {
		this.setState({ blueprintDescription: event.target.value });
	};

	handleNameChange = (event) => {
		this.setState({ blueprintName: event.target.value });
	};

	handleRotationChange = (event) => {
		const { onRotate } = this.props;
		this.setState({ rotation: event.target.value });
		onRotate(event.target.value);
	};

	close = () => {
		const { blueprint, onRotate, uploadModalClosed } = this.props;
		const { rotation, originalRotation } = this.state;
		if (rotation === originalRotation) {
			this.setState({
				blueprintName: blueprint.name,
				blueprintDescription: blueprint.description
			});
		} else {
			this.setState({
				blueprintName: blueprint.name,
				blueprintDescription: blueprint.description,
				rotation: originalRotation
			});
			onRotate(originalRotation);
		}
		uploadModalClosed();
	};

	saveBlueprint() {
		const { blueprint, blueprintUpdated, uploadModalClosed, lang } = this.props;
		const { blueprintName, blueprintDescription, rotation } = this.state;
		if (!blueprintName || blueprintName.length === 0) {
			return;
		}
		const newBlueprint = { ...blueprint };
		newBlueprint.name = blueprintName;
		newBlueprint.description = blueprintDescription;
		newBlueprint.rotation = rotation;
		this.setState({
			originalRotation: rotation
		});
		BlueprintClient.updateBlueprint(newBlueprint);
		blueprintUpdated(newBlueprint);
		uploadModalClosed(false);
		toast.success(<Lang lang={lang} fi="Pohjakuva tallennettu" en="Blueprint saved." sv="Planen sparad." />);
	}

	render() {
		const { show, lang } = this.props;
		const { blueprintName, blueprintDescription, rotation } = this.state;
		return (
			<Modal show={show} onHide={this.close} className="edit-blueprint-modal" >
				<Modal.Header closeButton>
					<Modal.Title>
						<Lang lang={lang} fi="Muokkaa pohjakuvaa" en="Edit blueprint" sv="Redigera planet" />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<label htmlFor="blueprint-name">
						<Lang lang={lang} fi="Pohjakuvan nimi" en="Blueprint name" sv="Plan namn" />
						<input id="blueprint-name" type="text" value={blueprintName} className={blueprintName ? "form-control" : "form-control is-invalid"} onChange={this.handleNameChange} />
					</label>
					<label htmlFor="blueprint-description">
						<Lang lang={lang} fi="Kuvaus" en="Description" sv="Beskrivning" />
						<textarea id="blueprint-description" type="text"
							value={blueprintDescription}
							className="form-control blueprintDescription"
							maxLength="4000"
							onChange={this.handleDescriptionChange} />
					</label>
					<label htmlFor="rotateRange">
						<Lang lang={lang} fi="Pyöritä kuvaa" en="Rotate image" sv="Rotera bilden" />
						<input type="range" min="0" max="360" value={rotation} onChange={this.handleRotationChange} />
					</label>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="light" onClick={() => this.close()}>
						<Lang lang={lang} fi="Peruuta" en="Cancel" sv="Ångra" />
					</Button>
					<Button type="submit" variant="success" disabled={!blueprintName} onClick={() => this.saveBlueprint()}>
						<Lang lang={lang} fi="Tallenna" sv="Spara" en="Save" />
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
