export default class Logger {
    constructor(loggerId) {
        this.loggerId = loggerId;
        this.x = -1;
        this.y = -1;
        this.dot_x = -1;
        this.dot_y = -1;
        this.blueprint = null;
        this.measurements = [];
        this.systemId = null;
        this.projectId = null;
    }
}