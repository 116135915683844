import "../../css/CardBackground.css";
import "../../css/DeleteView.css";
import "../../css/LeftSidePanel.css";
import "../../css/pageDefault.css";
import "./PasswordResetView.css";
import "./Users.css";

import { Component } from "react";
import { Accordion, Button, Card, Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import isAuthorized from "../../utilities/authorization";
import CustomerSelect from "../CustomerSelect";
import Lang from "../LanguageSelect/Lang";
import EditingModal from "./EditingModal";
import UsersTable from "./UsersTable";

// Mapping to and from redux users_ props
const USERS_MAP = {
	showModal: "usersShowModal", modalType: "usersModalType",
	selectedUser: "usersSelectedUser",
};

const remapToSettingsView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (USERS_MAP[key])
			output[USERS_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

// Check if user is not super of customer
const nonOwnSuper = (customerId, customers, userId) => {
	if (customerId !== undefined && userId !== undefined && customers !== undefined && isAuthorized({ one: ["super"] })) {
		const keys = Object.keys(customers);
		// eslint-disable-next-line no-restricted-syntax
		for (const key of keys) {
			if (customers[key].users !== undefined) {
				const index = customers[key].users.findIndex(x => x.userId === userId);
				if (index > -1) {
					return customers[key].customerId !== customerId;
				}
			}
		}
	}
	return false;
};

// Check if user is not admin of customer
const nonOwnAdmin = (customerId, customers, userId) => {
	if (customerId !== undefined && userId !== undefined && customers !== undefined && isAuthorized({ one: ["admin"] })) {
		const keys = Object.keys(customers);
		// eslint-disable-next-line no-restricted-syntax
		for (const key of keys) {
			if (customers[key].users !== undefined) {
				const index = customers[key].users.findIndex(x => x.userId === userId);
				if (index > -1) {
					return customers[key].customerId !== customerId;
				}
			}
		}
	}
	return false;
};

const getAvailableRights = (customerId, customers, userId, rights) => {
	let userRights = {};
	if (rights.includes("super")) {
		userRights = {
			admin: false,
			project_admin: false,
			system: false,
			reports: false,
			projectsSome: false,
		};
		if (!nonOwnSuper(customerId, customers, userId)) {
			userRights.supportCustomers = false;
		}
	} else if (rights.includes("admin") && !nonOwnAdmin(customerId, customers, userId)) {
		userRights = {
			admin: false,
			system: false,
			reports: false,
			projectsSome: false,
			project_admin: false,
		};
	}
	return userRights;
};

class Users extends Component {
	constructor() {
		super();

		this.mounted = false;
	}

	componentDidMount() {
		const { getCustomerData, customers, customerId, userId } = this.props;
		if (
			getCustomerData &&
			customers !== undefined &&
			customerId !== undefined &&
			(
				customers[customerId] === undefined ||
				customers[customerId].users === undefined ||
				customers[customerId].systems === undefined
			)
		) {
			getCustomerData({ customerId: Number(customerId), userId });
			this.mounted = true;
		}
	}

	componentDidUpdate(prevProps) {
		const { customerId, customers, getCustomerData, userId } = this.props;
		if (prevProps.customerId !== customerId) {
			this.mounted = false;
		}
		if ( // Fetch user and systems data if initialy missing
			!this.mounted && customers !== undefined && customerId !== undefined && customers[customerId] !== undefined &&
			(
				customers[customerId].systems === undefined ||
				customers[customerId].systems[0] === undefined ||
				customers[customerId].systems[0].projects === undefined
			)
		) {
			getCustomerData({ customerId: Number(customerId), userId });
			this.mounted = true;
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	openModal = (user) => {
		const { setValues } = this.props;
		if (user === undefined) {
			const update = remapToSettingsView({
				showModal: true,
				selectedUser: {
					userName: "",
					rights: [],
				},
				modalType: "new",
			});
			setValues(update);
		} else {
			const update = remapToSettingsView({ showModal: true, selectedUser: user, modalType: "edit" });
			setValues(update);
		}
	};

	closeModal = () => {
		const { setValues } = this.props;
		setValues({ usersShowModal: false });
	};

	render() {
		const { customers, customerId, selectCustomer, lang, loading, showModal, modalType, selectedUser, userId, rights, removeUserData, getCustomerData, systems } = this.props;
		return isAuthorized({ one: ["super", "admin"] }) ? (
			<div className="users pageDefault">
				<div className="leftSidePanel">
					<div>
						<CustomerSelect
							customers={customers}
							customerId={customerId}
							selectCustomer={selectCustomer}
							lang={lang}
							checkProjects
						/>
						<div className="cardBackground">
							<Accordion
								defaultActiveKey="0"
								id="accordianPanel"
								className="menuPanelStatic"
							>
								<Card>
									<Card.Header>
										<Lang lang={lang}>
											<div key="en">Users</div>
											<div key="fi">Käyttäjät</div>
											<div key="sv">Användare</div>
										</Lang>
									</Card.Header>
									<Accordion.Collapse eventKey="0">
										<Card.Body>
											<Button className="addUsers-button" variant="primary" onClick={() => this.openModal()}>
												<Lang lang={lang}>
													<span key="en">Add user</span>
													<span key="fi">Lisää käyttäjä</span>
													<span key="sv">Lägg till användare</span>
												</Lang>
											</Button>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</div>
					</div>
				</div>
				<div className="rightSidePanel">
					{loading ?
						<Spinner id="usersRightSpinner" animation="border" />
						:
						<div className="users-table-wrapper cardBackground">
							<UsersTable
								lang={lang}
								customers={customers}
								customerId={customerId}
								selectUser={this.openModal}
							/>
						</div>
					}
					{
						showModal ?
							<EditingModal
								lang={lang}
								show={showModal}
								close={this.closeModal}
								selectedUser={selectedUser}
								customerId={customerId}
								customers={customers}
								newUser={modalType === "new"}
								availableRights={getAvailableRights(customerId, customers, userId, rights)}
								userId={userId}
								removeUserData={removeUserData}
								getCustomerData={getCustomerData}
								systems={systems}
								isAuthorized={isAuthorized}
							/>
							:
							null
					}
				</div>
			</div>
		) : null;
	}
}


function mapStateToProps(state) {
	const { customerId, lang, userId, customers, systems, rights,
		usersShowModal, usersModalType,
		usersSelectedUser,
	} = state;
	return {
		customerId, lang, userId, customers, systems, rights,
		showModal: usersShowModal, modalType: usersModalType,
		selectedUser: usersSelectedUser,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);