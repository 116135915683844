import "../css/PlotColorsEditor.css";

import { Component } from "react";
import { Accordion, Button, Card, Form, Table } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import Messenger from "../utilities/messenger";
import Lang from "./LanguageSelect/Lang";

const rfdclone = rfdc();

const DEFAULT_COLOR = "#0479ad";

export default class PlotColorsEditor extends Component {
	constructor() {
		super();

		this.mounted = false;
	}

	state = {
		plots: {},
	};

	componentDidMount() {
		const { customerId, selectedSystem, selectedProject } = this.props;
		if (
			customerId !== undefined &&
			selectedSystem.id !== undefined &&
			selectedProject.id !== undefined
		) {
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
			};
			Messenger.requestDeviceData({
				payload,
				callback: this.nthCallback
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { customerId, selectedSystem, selectedProject } = this.props;
		if (
			prevProps.customerId !== customerId ||
			prevProps.selectedSystem.id !== selectedSystem.id ||
			prevProps.selectedProject.id !== selectedProject.id
		) {
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
			};
			Messenger.requestDeviceData({
				payload,
				callback: this.nthCallback
			});
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	nthCallback = (data) => {
		const { updateAncestor } = this.props;
		const plots = {};
		if (data.devices !== undefined) {
			const deviceIds = Object.keys(data.devices);
			deviceIds.sort((a, b) => {
				if (data.devices[a].i === undefined) {
					return 1;
				}
				if (data.devices[b].i === undefined) {
					return -1;
				}
				return data.devices[a].i - data.devices[b].i;
			});
			
			for (const devId of deviceIds) {
				const device = data.devices[devId];
				for (let index = 0; index < device.s.length; index+=1) {
				  const meas = device.s[index];
				  if (plots[`${devId}_${index}`] === undefined) {
					const color = device.c !== undefined && device.c[index] && device.c[index] !== "" ? device.c[index] : null;
					plots[`${devId}_${index}`] = {
					  devId,
					  devName: device.n,
					  measIndex: index,
					  type: meas[0],
					  unit: meas[1],
					  color,
					};
				  }
				}
			  }
		}
		this.setState({ plots, devices: data.devices });
		if (this.mounted) {
			updateAncestor({ statisticsViewLastUpdate: "plotColorEditor" });
		} else {
			this.mounted = true;
		}
	};

	colorToggle = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	colorClose = (plot, e) => {
		// Finding and updating color for plot
		const { customerId, selectedSystem, selectedProject } = this.props;
		const { plots, devices } = this.state;
		const plotsCopy = rfdclone(plots);
		if (plotsCopy[`${plot.devId}_${plot.measIndex}`] !== undefined) {
			plotsCopy[`${plot.devId}_${plot.measIndex}`].color = DEFAULT_COLOR;
			// Creating base payload
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
				projectName: selectedProject.name,
			};
			// Creating devices update object and setting new color
			const devicesCopy = rfdclone(devices);
			if (devicesCopy[plot.devId].c[plot.measIndex] !== e.target.value) {
				devicesCopy[plot.devId].c[plot.measIndex] = e.target.value;
				payload.devices = devicesCopy;
				// Callback after server response
				const callback = (data) => {
					if (data.status === "success") {
						this.nthCallback({ devices: devicesCopy });
						toast.success(<Lang en="Color updated" fi="Väri päivitetty" sv="Färg uppdaterad" />, { autoClose: 2500, });
					} else {
						toast.error(<Lang en="Failed to update color" fi="Värin päivitys epäonnistui" sv="Kunde int updatera färgen" />);
						const plotsCopyNew = rfdclone(plots);
						this.setState({ plots: plotsCopyNew });
					}
				};
				// Sending request
				Messenger.requestUpdateProjectData({
					payload,
					callback: callback.bind(this),
				});
			}
		}
	};

	removeColor = (plot) => {
		// Finding and updating color for plot
		const { customerId, selectedSystem, selectedProject } = this.props;
		const { plots, devices } = this.state;
		const plotsCopy = rfdclone(plots);
		if (plotsCopy[`${plot.devId}_${plot.measIndex}`] !== undefined) {
			plotsCopy[`${plot.devId}_${plot.measIndex}`].color = null;
			// Creating base payload
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
				projectName: selectedProject.name,
			};
			// Creating devices update object and setting new color
			const devicesCopy = rfdclone(devices);
			devicesCopy[plot.devId].c[plot.measIndex] = "";
			payload.devices = devicesCopy;
			// Callback after server response
			// eslint-disable-next-line sonarjs/no-identical-functions
			const callback = (data) => {
				if (data.status === "success") {
					this.nthCallback({ devices: devicesCopy });
					toast.success(<Lang en="Color updated" fi="Väri päivitetty" sv="Färg uppdaterad" />, { autoClose: 2500, });
				} else {
					toast.error(<Lang en="Failed to update color" fi="Värin päivitys epäonnistui" sv="Kunde int updatera färgen" />);
					const plotsCopyNew = rfdclone(plots);
					this.setState({ plots: plotsCopyNew });
				}
			};
			// Sending request
			Messenger.requestUpdateProjectData({
				payload,
				callback: callback.bind(this),
			});
		}
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	setDefaultColor = (plot) => {
		// Finding and updating color for plot
		const { customerId, selectedSystem, selectedProject } = this.props;
		const { plots, devices } = this.state;
		const plotsCopy = rfdclone(plots);
		if (plotsCopy[`${plot.devId}_${plot.measIndex}`] !== undefined) {
			plotsCopy[`${plot.devId}_${plot.measIndex}`].color = DEFAULT_COLOR;
			// Creating devices update object and setting new color
			const devicesCopy = rfdclone(devices);
			// Initlizing/filling out color array if not complete
			if (devicesCopy[plot.devId].c === undefined || devicesCopy[plot.devId].c.length < devicesCopy[plot.devId].s.length) {
				if (devicesCopy[plot.devId].c === undefined)
					devicesCopy[plot.devId].c = [];
				for (let i = 0; i < devicesCopy[plot.devId].s.length; i += 1) {
					if (devicesCopy[plot.devId].c[i] === undefined)
						devicesCopy[plot.devId].c[i] = "";
				}
			}
			// Setting the new color
			devicesCopy[plot.devId].c[plot.measIndex] = DEFAULT_COLOR;
			// Creating base payload
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
				projectName: selectedProject.name,
				devices: devicesCopy,
			};
			// Callback after server response
			const callback = (data) => {
				if (data.status === "success") {
					toast.success(<Lang en="Color updated" fi="Väri päivitetty" sv="Färg uppdaterad" />, { autoClose: 2500, });
					this.nthCallback({ devices: devicesCopy });
				} else {
					toast.error(<Lang en="Failed to update color" fi="Värin päivitys epäonnistui" sv="Kunde int updatera färgen" />);
					const plotsCopyNew = rfdclone(plots);
					this.setState({ plots: plotsCopyNew });
				}
			};
			// Sending request
			Messenger.requestUpdateProjectData({
				payload,
				callback: callback.bind(this),
			});
		}
	};

	getEntriesByLoggers = (plots, lang) => {
		// Getting loggers
		
		const loggers = {}
		for (const key of Object.keys(plots)) {
			if (!loggers[plots[key].devId]){
				loggers[plots[key].devId] = { id: plots[key].devId, name: plots[key].devName, plots: {} };
			}
			loggers[plots[key].devId].plots[key] = plots[key];
			
		}
		return Object.keys(loggers).map(key => (
			<Accordion className="loggerEntry" key={`loggerEntry_${key}`}>
				<Accordion.Button as={Card.Header} className="title">
					<div>
						{loggers[key].name}
					</div>
				</Accordion.Button>
				<Accordion.Collapse eventKey='0'>
					{this.getEntries(loggers[key].plots, lang)}
				</Accordion.Collapse>
			</Accordion>
		));
	};

	getEntries = (plots, lang) => {
		let entries = [];
		const keys = Object.keys(plots);
		entries = keys.map(id => (
			<tr
				className="plotColorTableEntry"
				key={`plotColor_${id}`}
			>
				<td className="plotColorColumn">
					{plots[id].type}
				</td>
				<td className="plotColorColumn">
					{plots[id].unit}
				</td>
				<td className="plotColorColumn">
					{
						plots[id].color === null
							?
							<Button
								size="sm"
								variant="success"
								onClick={() => this.setDefaultColor(plots[id])}
							>
								<Lang lang={lang}>
									<div key="en">Set</div>
									<div key="fi">Aseta</div>
									<div key="sv">Ställ in</div>
								</Lang>
							</Button>
							:
							<Form.Control
								type="color"
								name={`plotColor_${id}`}
								// eslint-disable-next-line react/destructuring-assignment
								value={this.state && this.state[`plotColor_${id}`] ? this.state[`plotColor_${id}`] : plots[id].color}
								onChange={this.colorToggle}
								onBlur={(e) => this.colorClose(plots[id], e)}
							/>
					}
				</td>
				<td className="plotColorColumn">
					<Button
						disabled={plots[id].color === null}
						variant="danger"
						onClick={() => this.removeColor(plots[id])}
						className="remove-color-button"
					>
						<IconContext.Provider
							value={{ className: "reactIcon" }}
						>
							<BsX />
						</IconContext.Provider>
					</Button>
				</td>
			</tr>
		));
		if (entries.length > 0) {
			return (
				<Table
					style={{ display: "inline-block", width: "unset" }}
					className="plotColorEntries"
					striped hover bordered
				>
					<thead>
						<tr>
							<th>
								<Lang lang={lang}>
									<div key="en">Quantity</div>
									<div key="fi">Suure</div>
									<div key="sv">Kvantitet</div>
								</Lang>
							</th>
							<th>
								<Lang lang={lang}>
									<div key="en">Unit</div>
									<div key="fi">Yksikkö</div>
									<div key="sv">Enhet</div>
								</Lang>
							</th>
							<th>
								<Lang lang={lang}>
									<div key="en">Colour</div>
									<div key="fi">Väri</div>
									<div key="sv">Färg</div>
								</Lang>
							</th>
							<th>
								<Lang lang={lang}>
									<div key="en">Reset</div>
									<div key="fi">Palauta</div>
									<div key="sv">Återställ</div>
								</Lang>
							</th>
						</tr>
					</thead>
					<tbody>
						{entries}
					</tbody>
				</Table>
			);
		}
		return null;
	};

	render() {
		const { selectedSystem, selectedProject, lang } = this.props;
		const { plots } = this.state;
		if (selectedSystem.id !== undefined && selectedProject.id !== undefined) {
			return (
				<div className="plotColorsEditor">
					<Accordion
						id="accordianPanel"
						className="menuPanel titlePanel"
					>
						<Accordion.Button as={Card.Header}>
							<Lang lang={lang}>
								<div key="en">Plot colors</div>
								<div key="fi">Käyrien värit</div>
								<div key="sv">Graf färgerna</div>
							</Lang>
						</Accordion.Button>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<div className="tableWrapper">
									{this.getEntriesByLoggers(plots, lang)}
								</div>
							</Card.Body>
						</Accordion.Collapse>
					</Accordion>
				</div>
			);
		}
		return null;
	}
}
