import "../../css/CardBackground.css";
import "./SystemConfig.css";

import { connect } from "react-redux";

import isAuthorized from "../../utilities/authorization";
import Lang from "../LanguageSelect/Lang";
import ConfigSaveButton from "./ConfigSaveButton";
import LoggerSettings from "./LoggerSettings/LoggerSettings";
import SystemSettings from "./SystemSettings/SystemSettings";

function SystemConfig({ systemsSelectedSystem, systemsSelectedFunction, systemsConfigCorruptedData, scrollTop }) {
	if (systemsSelectedSystem && systemsSelectedSystem.id !== "all" && systemsSelectedFunction === "config") {
		return systemsConfigCorruptedData === "" ? (
			<div className="systems-config">
				<SystemSettings />
				<LoggerSettings />
				<ConfigSaveButton scrollBottom={(document.querySelectorAll(".App")[0].scrollHeight - (scrollTop + document.querySelectorAll(".App")[0].clientHeight)) < 30} editAuth={isAuthorized({ one: ["super", "system", "customer-support"] })} />
			</div>
		) : (
			<div className="systems-config">
				<div className="erro-msg cardBackground">
					<Lang
						en="There is an error with the system data, try to restart the device and refresh the page after a while"
						fi="Järjestelmän tiedoissa on virhe, yritä käynnistää laite uudelleen ja päivitä sivu hetken kuluttua"
						sv="Det finns fel i systemets data, försök att starta om enheten och ladda om sidan efter en stund"
					/>
				</div>
			</div>
		);
	}
	return null;
}

function mapStateToProps(state) {
	const { systemsSelectedFunction, systemsSelectedSystem, systemsLoading, systemsConfigCorruptedData } = state;
	return { systemsSelectedFunction, systemsSelectedSystem, systemsLoading, systemsConfigCorruptedData };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemConfig);
