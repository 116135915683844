import "./SettingsNewProject.css";

import { useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import TextField from "../GenericMenuComponents/TextField";
import InfoModal from "../InfoModal";
import Lang from "../LanguageSelect/Lang";

const rfdclone = rfdc();

export default function NewProject({ selectedSystem, customerId, getCustomerSystems, userId }) {
	const [projectName, setProjectName] = useState("");
	const [submitEnabled, setSubmitEnabled] = useState(false);
	const [status, setStatus] = useState("ok");
	const prevStatus = useRef();
	const [infoModalState, setInfoModalState] = useState({ infoModalShow: false, infoModalMsg: null, infoModalTitle: null });
	const MINUTE_MS = 60_000;

	useEffect(() => {
		const requestState = () => {
			const payload = { customerId, systemId: selectedSystem.id };
			const callback = (data) => {
				if (data.state === "ok") {
					setSubmitEnabled(true);
				} else if (data.state === "pending") {
					setSubmitEnabled(false);
				} else {
					toast.error(<Lang en="An error occurred when fetching new project queue" fi="Virhe uuden projektijonon haussa" sv="Ett fel uppstod vid hämtning av ny projektkö" />);
				}
				setStatus(data.state);
			};
			Messenger.requestNewProjectState({ payload, callback });
		}
		requestState()
		const interval = setInterval(requestState, MINUTE_MS);
		return () => clearInterval(interval);
	}, [selectedSystem.id, customerId]);

	useEffect(() => {
		if (prevStatus.current === "pending" && status === "ok")
			getCustomerSystems({ customerId, userId });
		prevStatus.current = status;
	}, [status, customerId, userId, getCustomerSystems]);

	function submissionCallback(data) {
		if (data.status === "success") {
			setInfoModalState({
				infoModalShow: true,
				infoModalMsg: <Lang
					en="It might take up to 1-10 minutes for changes to take effect at system level"
					fi="Saattaa kestää 1-10 minuuttia että muutokset tulevat voimaan järjestelmätasolla"
					sv="Det kan ta till och med 1-10 minuter för ändringarna att träda i kraft på system nivå" />,
				infoModalTitle: <Lang
					en="New project created"
					fi="Uusi projekti luotu"
					sv="Nyt projekt skapades" />
			});
			setStatus("pending");
		} else {
			toast.error(<Lang en="Failed to set new project name" fi="Projektin nimen asetus epäonnistui" sv="Insälling av projekt namn misslyckades" />);
			setStatus(data.status);
		}
		setProjectName("");
		setSubmitEnabled(false);
	}

	const submit = () => {
		if (customerId && selectedSystem && selectedSystem.id !== undefined && projectName !== "" && projectName !== undefined) {
			Messenger.requestNewProject({
				payload: {
					customerId,
					systemId: selectedSystem.id,
					projectName
				},
				callback: submissionCallback
			});
		} else {
			toast.error(<Lang en="Missing information" fi="Puuttuvia tietoja" sv="Information saknas" />);
		}
	};

	const updateState = (updateObj) => {
		const obj = rfdclone(updateObj);
		if (obj.projectName) {
			obj.projectName = obj.projectName.replace(/["'\\]/g, "");
		} else {
			obj.submitEnabled = false;
		}
		setProjectName(obj.projectName);
		if (obj.submitEnabled)
			setSubmitEnabled(obj.submitEnabled);
	};

	const closeModal = () => {
		setInfoModalState({ infoModalShow: false, infoModalMsg: null, infoModalTitle: null });
	};

	const buttonStatus = () => {
		if (status === "pending")
			return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
		if (status !== "ok")
			return (
				<IconContext.Provider value={{ className: "reactIcon" }}>
					<BsX />
				</IconContext.Provider>
			);
		return null;
	};

	if (isAuthorized({ one: ["project_admin", "customer_support"] }) && selectedSystem.id !== undefined) {
		return (
			<div className="new-project">
				<div className="name-entry">
					<TextField
						className="projectName"
						type="text"
						title={
							<Lang en="New project name" fi="Uuden projektin nimi" sv="Nya projektets namn" />
						}
						content={projectName}
						updateAncestor={updateState}
						propertyName="projectName"
						realtimeUpdate
						maxLength="80"
						validation={projectName ? null : true}
					/>
					<OverlayTrigger
						placement="bottom"
						overlay={
							status === "ok" ?
								<span />
								:
								<Tooltip show={false}>
									{status === "pending" ?
										<Lang en="New project is already in the queue" fi="Uusi projekti on jo jonossa" sv="Nytt projekt står redan i kön" />
										:
										<Lang en="An error occurred while retrieving the project information" fi="Virhe projektin tietojen haussa" sv="Ett fel uppstod när projektinformationen skulle hämtas" />
									}
								</Tooltip>
						}
					>
						<Button
							disabled={!projectName || !submitEnabled}
							variant="success"
							className="submit-new-project"
							onClick={submit}
						>
							{buttonStatus()}
							<Lang en="Set" fi="Aseta" sv="Ställ in" />
						</Button>
					</OverlayTrigger>
				</div>
				<div className="note">
					<Lang
						en="It might take up to 1-10 minutes for changes to take effect at system level"
						fi="Saattaa kestää 1-10 minuuttia että muutokset tulevat voimaan järjestelmätasolla"
						sv="Det kan ta till och med 1-10 minuter för ändringarna att träda i kraft på system nivå"
					/>
				</div>
				<InfoModal
					infoModalShow={infoModalState.infoModalShow}
					infoModalMsg={infoModalState.infoModalMsg}
					infoModalTitle={infoModalState.infoModalTitle}
					propCloseModal={closeModal}
				/>
			</div >
		);
	}
	return null;
}
