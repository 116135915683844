import "./SMSAlert.css";

import { Component } from "react";
import { Accordion, Button, Card, Form, Table } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import { connect } from "react-redux";
import Switch from "react-switch";
import rfdc from "rfdc";

import Lang from "../../LanguageSelect/Lang";

const rfdclone = rfdc();

const getPanelTitle = (numbers) => {
	const lastIndex = numbers.length - 1;
	// eslint-disable-next-line unicorn/no-array-reduce
	const string = numbers.reduce((res, email, index) => {
		let smsString = res;
		smsString += email + (lastIndex === index ? "" : ", ");
		return smsString;
	}, "");
	if (string.length === 0 || /^[ ,]+$/g.test(string))
		return <Lang en="Numbers" fi="Numerot" sv="Numren" />;
	return string.length > 40 ? `${string.slice(0, 40)}...` : string;
};

const handleChange = (value, setValues) => {
	setValues({ systemsConfigSMSActive: value });
};

class SMSAlert extends Component {
	addSMS = () => {
		const { systemsConfigSMSNumbers, setValues } = this.props;
		const systemsConfigSMSNumbersCopy = rfdclone(systemsConfigSMSNumbers);
		systemsConfigSMSNumbersCopy.push("");
		setValues({ systemsConfigSMSNumbers: systemsConfigSMSNumbersCopy });
	};

	removeSMS = (index) => {
		const { systemsConfigSMSNumbers, setValues } = this.props;
		const systemsConfigSMSNumbersCopy = rfdclone(systemsConfigSMSNumbers);
		systemsConfigSMSNumbersCopy.splice(index, 1);
		setValues({ systemsConfigSMSNumbers: systemsConfigSMSNumbersCopy });
	};

	handelSMSChange = (index, text) => {
		const { systemsConfigSMSNumbers, setValues } = this.props;
		const systemsConfigSMSNumbersCopy = rfdclone(systemsConfigSMSNumbers);
		systemsConfigSMSNumbersCopy[index] = text;
		setValues({ systemsConfigSMSNumbers: systemsConfigSMSNumbersCopy });
	};

	getNumbers = (numbers) => {
		const { editAuth } = this.props;
		let output = [];
		if (Array.isArray(numbers)) {
			output = numbers.map((email, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<tr key={`sms_item_${i}`} className="sms-item">
					<td>
						<Form.Control
							disabled={!editAuth}
							type="text"
							value={email}
							onChange={(e) => this.handelSMSChange(i, e.target.value)}
						/>
					</td>
					<td className="sms-remove-button-cell">
						<Button
							className="btn-xs"
							disabled={!editAuth}
							size="sm"
							variant="danger"
							onClick={() => this.removeSMS(i)}
						>
							<IconContext.Provider
								value={{ className: "reactIcon" }}
							>
								<BsX />
							</IconContext.Provider>
						</Button>
					</td>
				</tr>
			));
		}
		return output;
	};

	render() {
		const { systemsConfigSMSActive, editAuth, setValues, systemsConfigSMSNumbers } = this.props;
		if (systemsConfigSMSActive !== null) {
			const components = [
				<span key="sms-switch" className="setting sms-selector separated-alignment-parent switch-setting">
					<span className="title separated-alignment-left">
						<Lang en="SMS-alert" fi="Tekstiviestihälytys" sv="SMS-larm" />
					</span>
					<span className="separated-alignment-right">
						<Switch
							disabled={!editAuth}
							onChange={(value) => handleChange(value, setValues)}
							checked={systemsConfigSMSActive}
							onHandleColor="#5cb75c"
							onColor="#b1dbaf"
							height={13}
							width={33}
							handleDiameter={19}
							boxShadow="1px 1px 3px 1px rgba(0, 0, 0, 0.3)"
							uncheckedIcon={false}
							checkedIcon={false}
						/>
					</span>
				</span>
			];
			if (systemsConfigSMSActive === true)
				components.push(
					<span key="sms_numbers" className="sms-numbers dropdownFlexer scrollable-dropdown separated-alignment-parent">
						<span className="title separated-alignment-left">
							<Lang en="SMS numbers" fi="SMS numerot hälytyksille" sv="SMS numren" />
						</span>
						<span className="separated-alignment-right">
							<Accordion id="accordianPanel" className="menuPanelStatic">
								<Accordion.Item eventKey="0">
									<Accordion.Button as={Card.Header} style={{ cursor: "pointer", minWidth: "250px", height: "30px" }}>
										{getPanelTitle(systemsConfigSMSNumbers)}
									</Accordion.Button>
									<Accordion.Body>
										<Card.Body>
											<div className="numbers-list-table-wrapper">
												<Table className="numbers-list" size="sm">
													<tbody>
														{this.getNumbers(systemsConfigSMSNumbers)}
													</tbody>
												</Table>
											</div>
											<div className="add-button-wrapper">
												<Button
													disabled={!editAuth || systemsConfigSMSNumbers.length >= 3}
													className="add-button"
													size="sm"
													variant="success"
													onClick={this.addSMS}
												>
													<Lang en="Add" fi="Lisää" sv="Tillägg" />
												</Button>
											</div>
										</Card.Body>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</span>
					</span>
				);
			return components;
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { systemsConfigSMSActive, systemsConfigSMSNumbers, systemsConfigShowNumbers } = state;
	return { systemsConfigSMSActive, systemsConfigSMSNumbers, systemsConfigShowNumbers };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SMSAlert);
