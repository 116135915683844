import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

const getItems = (systems, selectedSystem) => {
    const items = [];
    const sortedSystems = JSON.parse(JSON.stringify(systems));
    // eslint-disable-next-line sonarjs/cognitive-complexity
    sortedSystems.sort((a, b) => {
        if (a.sortIndex !== undefined || b.sortIndex !== undefined) {
            if (a.sortIndex !== undefined && b.sortIndex !== undefined) {
                if (a.sortIndex < b.sortIndex)
                    return -1;
                if (a.sortIndex > b.sortIndex)
                    return 1;
            } else {
                if (a.sortIndex !== undefined)
                    return -1;
                if (a.sortIndex === undefined)
                    return 1;
            }
        } else {
            return a.systemName.localeCompare(b.systemName, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true });
        }
        return 0;
    });
    sortedSystems.forEach(system => {
        let active = false;
        if (selectedSystem !== undefined && selectedSystem.id === system.id)
            active = true;
        items.push(
            <Dropdown.Item
                key={`deviceSelection_${system.id}`}
                eventKey={system.id}
                active={active}
            >
                {system.systemName}
            </Dropdown.Item>
        );
    });
    return items;
};

export default class SystemDropdown extends Component {
    handleSelect = (system) => {
        const { systems, updateAncestor } = this.props;
        const selectedSystem = systems.find(element => element.id === system);
        updateAncestor({ selectedSystem });
    };

    render() {
        const { systems, selectedSystem } = this.props;
        if (systems) {
            return (
                <div className="systemDropdownWrapper">
                    <div className="subtitle">
                        <Lang en="System:" fi="Järjestelmä:" sv="System:" />
                    </div>
                    <span className="dropdownFlexer scrollable-dropdown">
                        <DropdownButton
                            variant="default"
                            title={
                                selectedSystem !== undefined && selectedSystem.systemName !== undefined
                                    ? <span className="title">{selectedSystem.systemName}</span>
                                    : <Lang fi="Valitse..." en="Select..." sv="Välj..." />
                            }
                            id="deviceDropdown"
                            onSelect={this.handleSelect}
                        >
                            {getItems(systems, selectedSystem)}
                        </DropdownButton >
                    </span>
                </div>
            );
        }
        return null;
    }
}