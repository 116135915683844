import Check from "../Check";
import Lang from "../LanguageSelect/Lang";

export default function Checks({ password, current, lang, verification }) {
    if (password.length > 0) {
        return (
            <div className="passCheck">
                <Check className={current.length > 0 ? "valid" : "neutral"}>
                    <Lang lang={lang}>
                        <span className="title" key="en">Old password.</span>
                        <span className="title" key="fi">Vanha salasana.</span>
                        <span className="title" key="sv">Gammalt lösenord.</span>
                    </Lang>
                </Check>
                <Check className={password.length >= 6 ? "valid" : "invalid"}>
                    <Lang lang={lang}>
                        <span className="title" key="en">At least 6 characters.</span>
                        <span className="title" key="fi">Vähintään 6 merkkiä.</span>
                        <span className="title" key="sv">Minst 6 tecken.</span>
                    </Lang>
                </Check>
                <Check className={password === verification ? "valid" : "invalid"}>
                    <Lang lang={lang}>
                        <span className="title" key="en">Passwords match.</span>
                        <span className="title" key="fi">Salasanat täsmäävät.</span>
                        <span className="title" key="sv">Lösenorden är lika.</span>
                    </Lang>
                </Check>
            </div>
        );
    }
    return (
        <div className="passCheck">
            <Check className="neutral">
                <Lang lang={lang}>
                    <span className="title" key="en">Old password.</span>
                    <span className="title" key="fi">Vanha salasana.</span>
                    <span className="title" key="sv">Gammalt lösenord.</span>
                </Lang>
            </Check>
            <Check className="neutral">
                <Lang lang={lang}>
                    <span className="title" key="en">Atleast 6 characters.</span>
                    <span className="title" key="fi">Vähintään 6 merkkiä.</span>
                    <span className="title" key="sv">Minst 6 tecken.</span>
                </Lang>
            </Check>
            <Check className="neutral">
                <Lang lang={lang}>
                    <span className="title" key="en">Passwords match.</span>
                    <span className="title" key="fi">Salasanat täsmäävät.</span>
                    <span className="title" key="sv">Lösenorden är lika.</span>
                </Lang>
            </Check>
        </div>
    );
}