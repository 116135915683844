import "../css/DatasetSelect.css";

import { Component } from "react";
import { Accordion, Button, Card, Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsPencilSquare } from "react-icons/bs";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import isAuthorized from "../utilities/authorization";
import Messenger from "../utilities/messenger";
import Lang from "./LanguageSelect/Lang";
import LimitEditor from "./LimitEditor";
import TypeDropdown from "./TypeDropdown";

const rfdclone = rfdc();

const getTypeLimits = ({ limits, type }) => {
	if (type !== undefined && limits[type] !== undefined && Array.isArray(limits[type])) {
		return limits[type];
	}
	return [];
};

export default class Limits extends Component {
	constructor() {
		super();

		this.mounted = false;
		this.lastRequest = undefined;
	}

	state = {
		show: false,
	};

	componentDidUpdate() {
		const { limits, lastUpdate } = this.props;
		if ((limits === undefined || lastUpdate === "limitEditor") && !this.mounted) {
			this.requestLimits();
			this.mounted = true;
		}
	}

	toggleShow = () => {
		this.setState(({ show }) => ({ show: !show }));
	};

	handleClick = ({ type, selected }) => {
		const { updateAncestor } = this.props;
		const updatedSelections = rfdclone(selected);
		const index = updatedSelections.indexOf(type);
		if (index > -1) {
			updatedSelections.splice(index, 1);
		} else {
			updatedSelections.push(type);
		}
		updateAncestor({ selectedLimits: updatedSelections });
	};

	renderSelectionOptions = (limits, selected) => Object.keys(limits).map((type, index) => {
		let name = type;
		if (name === "Pres_Pa") {
			name = "Pres diff";
		} else if (name === "Pres_Bar") {
			name = "Baro pres";
		}
		const selIndex = selected.indexOf(type);
		return (
			<Button
				variant="primary"
				// eslint-disable-next-line react/no-array-index-key
				key={`${type}_${index}`}
				size="sm"
				className={`typeOption${selIndex > -1 ? "" : " none-active"}`}
				onClick={() => this.handleClick({ type, selected })}
			>{name}</Button>
		);
	});

	handleClickAll = (limits, selected) => {
		const { updateAncestor } = this.props;
		updateAncestor({ selectedLimits: selected.length === Object.keys(limits).length ? [] : Object.keys(limits) });
	};

	renderSelectAll = (limits, selected) => {
		const { lang } = this.props;
		let state = " none-active";
		const keysLength = Object.keys(limits).length;
		if (selected.length === keysLength) {
			state = " all-active";
		} else if (selected.length > 0 && selected.length < keysLength) {
			state = " some-active";
		}
		return (
			<Button
				variant="primary"
				className={`btn-sm selectAll${state}`}
				onClick={() => this.handleClickAll(limits, selected)}
			>
				<Lang lang={lang}>
					<span key="en">All</span>
					<span key="fi">Kaikki</span>
					<span key="sv">All</span>
				</Lang>
			</Button>
		);
	};

	limitDataCallback = (data) => {
		const { updateAncestor } = this.props;
		if (data.limits === undefined) {
			toast.error(<Lang en="Failed to fetch project limits" fi="Rajojen haku epäonnistui" sv="Kunde inte hämta gränser" />);
			updateAncestor({ limits: {}, settingsLoading: false });
		} else {
			updateAncestor({ limits: data.limits, settingsLoading: false });
		}
		this.mounted = false;
	};

	isLastRequest = ({ customerId, systemId, projectId }) => !!(this.lastRequest !== undefined &&
		this.lastRequest.customerId === customerId &&
		this.lastRequest.systemId === systemId &&
		this.lastRequest.projectId === projectId);

	requestLimits = () => {
		const { customerId, selectedSystem, selectedProject } = this.props;
		if (customerId !== undefined &&
			selectedSystem !== undefined &&
			selectedSystem.id !== undefined &&
			selectedProject !== undefined &&
			selectedProject.id !== undefined
		) {
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
			};
			if (!this.isLastRequest(payload)) {
				Messenger.requestProjectLimits({
					payload,
					callback: this.limitDataCallback,
				});
				// Storing last request payload
				this.lastRequest = payload;
			}
		}
	};

	updateLimits = ({ typeLimits }) => {
		const { limits, selectedMeasType } = this.props;
		const limitsCopy = rfdclone(limits);
		if (typeLimits.length > 0) {
			limitsCopy[selectedMeasType] = typeLimits;
		} else if (limitsCopy[selectedMeasType] !== undefined) {
			delete limitsCopy[selectedMeasType];
		}
		this.updateLimitsToServer(limitsCopy);
	};

	updateLimitsToServer(limits) {
		const { customerId, selectedSystem, selectedProject } = this.props;
		const payload = {
			customerId,
			systemId: selectedSystem.id,
			projectId: selectedProject.id,
			limits,
		};
		Messenger.updateProjectLimits({
			payload,
			callback: this.limitUpdateCallback.bind(this, limits),
		});
	}

	limitUpdateCallback(limits, data) {
		const { updateAncestor } = this.props;
		if (data.status === "success") {
			toast.success(<Lang en="Limits updated" fi="Rajat päivitetty" sv="Gränserna updaterades" />, { autoClose: 2500, });
			updateAncestor({ statisticsViewLastUpdate: "limitEditor", limits });
		} else {
			toast.error(<Lang en="Failed to update limits" fi="Rajojen päivitys epäonnistui" sv="Kunde inte updatera gränser" />);
		}
	}

	render() {
		const { limits, selectedLimits, lang, measTypes, selectedMeasType, updateAncestor, selectedSystem, selectedProject } = this.props;
		const { show } = this.state;
		if (limits !== undefined && Array.isArray(selectedLimits)) {
			return (
				<div className="datasetSelect">
					<Accordion
						id="accordianPanel"
						className="menuPanel titlePanel"
					>
						<Accordion.Button as={Card.Header}>
							<Lang lang={lang}>
								<div key="en">Limits</div>
								<div key="fi">Rajat</div>
								<div key="sv">Gränser</div>
							</Lang>
						</Accordion.Button>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								{Object.keys(limits).length > 0 ? this.renderSelectAll(limits, selectedLimits) : null}
								<div className="selectionOptions">
									{this.renderSelectionOptions(limits, selectedLimits)}
								</div>
								{Object.keys(limits).length === 0 ?
									<div style={{ textAlign: "center", fontSize: "0.875rem" }}>
										<Lang lang={lang}>
											<div key="en">No limits</div>
											<div key="fi">Ei rajoja</div>
											<div key="sv">Inga gränser</div>
										</Lang>
									</div>
									: null
								}
								{isAuthorized({ one: ["reports"] }) ?
									<IconContext.Provider value={{ className: "reactIcon-edit" }}>
										<Button onClick={this.toggleShow} variant="warning" size="sm" className="float-end limit-edit">
											Muokkaa <BsPencilSquare />
										</Button>
									</IconContext.Provider>
									: null
								}
							</Card.Body>
						</Accordion.Collapse>
					</Accordion>
					<Modal
						className='limit-editor-modal'
						key="limitEditor"
						show={show}
						onHide={this.toggleShow}
						centered
						size='xl'
					>
						<Modal.Header closeButton>
							<Modal.Title>
								<Lang en="Limit editor" fi="Rajojen muokkaus" sv="Limit editor" />
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className='d-flex flex-column flex-lg-row'>
							<TypeDropdown
								types={measTypes}
								selectedType={selectedMeasType}
								updateAncestor={updateAncestor}
								lang={lang}
							/>
							<LimitEditor
								selectedSystem={selectedSystem}
								selectedProject={selectedProject}
								show={selectedProject !== undefined && selectedProject.id !== undefined}
								typeLimits={getTypeLimits({ limits, type: selectedMeasType })}
								type={selectedMeasType}
								updateAncestor={this.updateLimits}
								lang={lang}
							/>
						</Modal.Body>
					</Modal>
				</div>
			);
		}
		return null;
	}
}