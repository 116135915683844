import "../css/YAxisSelections.css";

import { Accordion, Card } from "react-bootstrap";

import NumberField from "./GenericMenuComponents/NumberField";
import SingleNumberField from "./GenericMenuComponents/SingleNumberField";
import TextField from "./GenericMenuComponents/TextField";
import Lang from "./LanguageSelect/Lang";
import SideSelection from "./SideSelection";

export default function YAxisSelections({ yAxisTitle = "", yAxisMax = 0, yAxisMin = 0, yAxisTicks = 0, yAxisTickZero = null, y2AxisTitle, y2AxisMax, y2AxisMin, selectedProject, lang, updateData, y2AxisQuantities, datasets }) {
	return selectedProject !== undefined && selectedProject.id !== undefined ? (
		<div className="yAxisSelections">
			<Accordion
				id="accordianPanel"
				className="menuPanel titlePanel"
			>
				<Accordion.Button as={Card.Header}>
					<Lang lang={lang}>
						<div key="en">Y-axis</div>
						<div key="fi">Y-akseli</div>
						<div key="sv">Y-axel</div>
					</Lang>
				</Accordion.Button>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<div className="side-fields left">
							<span className="title">
								<Lang en="Left" fi="Vasen" sv="Vänster" />
							</span>
							<TextField
								className="yAxisTitle"
								title={<Lang en="Title:" fi="Otsikko:" sv="Rubrik:" />}
								content={yAxisTitle}
								updateAncestor={updateData}
								propertyName="yAxisTitle"
								validation={null}
							/>
							<NumberField
								className="yAxisMin"
								title={<Lang en="Min" fi="Min" sv="Min" />}
								value={yAxisMin}
								updateAncestor={updateData}
								propertyName="yAxisMin"
								max={yAxisMax}
								maxProperty="yAxisMax"
							/>
							<NumberField
								className="yAxisMax"
								title={<Lang en="Max" fi="Maks" sv="Max" />}
								value={yAxisMax}
								updateAncestor={updateData}
								propertyName="yAxisMax"
								min={yAxisMin}
								minProperty="yAxisMin"
							/>
						</div>
						<div className="side-fields right">
							<span className="title">
								<Lang en="Right" fi="Oikea" sv="Höger" />
							</span>
							<TextField
								className="yAxisTitle"
								title={<Lang en="Title:" fi="Otsikko:" sv="Rubrik:" />}
								content={y2AxisTitle}
								updateAncestor={updateData}
								propertyName="y2AxisTitle"
								validation={null}
							/>
							<NumberField
								className="yAxisMin"
								title={<Lang en="Min" fi="Min" sv="Min" />}
								value={y2AxisMin}
								updateAncestor={updateData}
								propertyName="y2AxisMin"
								max={y2AxisMax}
								maxProperty="y2AxisMax"
							/>
							<NumberField
								className="yAxisMax"
								title={<Lang en="Max" fi="Maks" sv="Max" />}
								value={y2AxisMax}
								updateAncestor={updateData}
								propertyName="y2AxisMax"
								min={y2AxisMin}
								minProperty="y2AxisMin"
							/>
						</div>
						<div className="yAxisTicks">
							<span className="title">
								<Lang en="Tick separation" fi="Apuviivojen väli" sv="Linje mellanrum" />
							</span>
							<SingleNumberField
								className="yAxisTicksValue"
								title={<Lang en="Value" fi="Väli arvo" sv="Värde" />}
								value={yAxisTicks}
								updateAncestor={updateData}
								propertyName="yAxisTicks"
								min={0}
							/>
							<SingleNumberField
								className="yAxisTickZero"
								title={<Lang en="Zero point" fi="Nollapiste" sv="Nollställe" />}
								value={yAxisTickZero}
								updateAncestor={updateData}
								propertyName="yAxisTickZero"
								emptyIsNull
							/>
						</div>
						<div className="yAxisSide">
							<span className="title">
								<Lang
									en="Quantities axis (left / right) choice."
									fi="Suureiden akselin (vasen / oikea) valinta."
									sv="Storheternas axel (vänster / höger) val."
								/>
							</span>
							<SideSelection
								updateData={updateData}
								y2AxisQuantities={y2AxisQuantities}
								datasets={datasets}
							/>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Accordion>
		</div>
	) : null;
}