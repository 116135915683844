import { Component } from "react";
import { DropTarget } from "react-dnd";
import rfdc from "rfdc";

import QuantitySideItem from "./QuantitySideItem";

const rfdclone = rfdc();

const ItemTypes = {
	ITEM: "side-item"
};

function collect(connect) {
	return {
		connectDropTarget: connect.dropTarget()
	};
}

const quantityFromTypeAndUnit = (type, unit) => {
	if (type !== undefined && unit !== undefined) {
		if (type !== "Pres") {
			return type;
		}
		if (unit === "Pa") {
			return "Pres diff";
		}
		return "Baro pres";
	}
	return type;
};

class QuantitySideTarget extends Component {
	// eslint-disable-next-line sonarjs/cognitive-complexity
	getItems(side, datasets, y2AxisQuantities) {
		if (datasets && y2AxisQuantities) {
			// Get all possible quantities from datasets
			const allQuantities = {}
			for (const dataset of datasets) {
				const quantity = quantityFromTypeAndUnit(dataset.measType, dataset.measUnit);
				if (allQuantities[quantity] === undefined) {
					allQuantities[quantity] = true;
				}

			}
			if (side === "left") {
				const res = []
				for (const key of Object.keys(allQuantities)) {
					if (!y2AxisQuantities[key]) {
						res.push(
							<QuantitySideItem
								id={key}
								key={`sideItem_${key}`}
								handleDrop={this.drop}
							/>
						);
					}
					
				}
				return res;
			}
			if (side === "right") {
				const res = []
				for (const key of Object.keys(allQuantities)) {
					if (y2AxisQuantities[key]) {
						res.push(
							<QuantitySideItem
								id={key}
								key={`sideItem_${key}`}
								handleDrop={this.drop}
							/>
						);
					}
				}
				return res;
			}

		}
		return null;
	}

	drop = (props) => {
		const { side, updateData, y2AxisQuantities } = this.props;
		if (side === "left") {
			if (props.id && updateData) {
				const y2AxisQuantitiesCopy = rfdclone(y2AxisQuantities);
				y2AxisQuantitiesCopy[props.id] = true;
				updateData({ y2AxisQuantities: y2AxisQuantitiesCopy });
			}
		} else if (props.id && updateData) {
			const y2AxisQuantitiesCopy = rfdclone(y2AxisQuantities);
			if (y2AxisQuantitiesCopy[props.id]) {
				y2AxisQuantitiesCopy[props.id] = false;
				updateData({ y2AxisQuantities: y2AxisQuantitiesCopy });
			}
		}
	};

	render() {
		const { connectDropTarget, side, datasets, y2AxisQuantities } = this.props;
		return connectDropTarget(
			<span
				className={side}
			>
				{this.getItems(side, datasets, y2AxisQuantities)}
			</span>
		);
	}
}

export default DropTarget(ItemTypes.ITEM, {}, collect)(QuantitySideTarget);