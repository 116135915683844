import { Component } from "react";
import { connect } from "react-redux";

class Lang extends Component {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    pickChild = () => {
        const { fi, lang, sv, en, children } = this.props;
        // Allows choosing translation with properties fi, sv or en
        if (fi && lang === "fi") {
            return fi;
        }
        if (sv && lang === "sv") {
            return sv;
        }
        if (en && lang === "en") {
            return en;
        }
        if (Array.isArray(children)) {
            if (lang === undefined) {
                const index = children.findIndex(x => x.key === "en");
                return index > -1 ? children[index] : null;
            }
            let index = children.findIndex(x => x.key === lang);
            if (index > -1) {
                return children[index];
            }
            index = children.findIndex(x => x.key === lang);
            return index > -1 ? children[index] : null;
        }
        return children;
    };

    render() {
        return this.pickChild();
    }
}

function mapStateToProps(state) {
    const { lang } = state;
    return { lang };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Lang);