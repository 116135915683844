import { Component } from "react";
import { Alert, Button } from "react-bootstrap";

import Lang from "./LanguageSelect/Lang";

export default class ErrorBoundary extends Component {
    state = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        const { children } = this.props;
        const { error } = this.state;

        if (error) {
            return (
                <div className="d-flex justify-content-center align-items-center flex-column h-100">
                    <Alert variant="danger">
                        <Lang en="Oops! Something went wrong " fi="Oho! Jotain meni pieleen " sv="Hoppsan! Något gick fel " /><span>&#9785;</span><br />
                        <small className="text-muted">{error.message}</small>
                    </Alert>
                    <Button onClick={() => window.location.reload(true)}>
                        <Lang en="Try again" fi="Yritä uudestaan" sv="Försök igen" />
                    </Button>
                </div>
            );
        }
        return children;
    }
}