import "../../css/ReportSelections.css";

import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import isAuthorized from "../../utilities/authorization";
import Lang from "../LanguageSelect/Lang";
import SaveButton from "./SaveButton";

const getItems = (reports, reportId, lang) => {
	let items = [];
	if (reports) {
		reports.sort((a, b) => a.name.localeCompare(b.name, 'fi', { sensitivity: "accent", numeric: true }));
		items = reports.map(report => (
			<Dropdown.Item
				key={`option_${report.id}`}
				eventKey={report.id}
				active={reportId === report.id}
			>
				{report.name}
			</Dropdown.Item>
		));
	}
	items.unshift(
		<Dropdown.Item
			key="option_new"
			eventKey={null}
			active={reportId === undefined}
		>
			<Lang lang={lang}>
				<span key="en">New</span>
				<span key="fi">Uusi</span>
				<span key="sv">Ny</span>
			</Lang>
		</Dropdown.Item>
	);
	return items;
};

const getTitle = (reports, reportId, lang) => {
	if (reportId !== undefined && reports !== undefined) {
		const index = reports.findIndex(x => x.id === reportId);
		if (index > -1) {
			return <span className="title">{reports[index].name}</span>;
		}
	}
	return (
		<Lang lang={lang}>
			<span className="title" key="en">New</span>
			<span className="title" key="fi">Uusi</span>
			<span className="title" key="sv">Ny</span>
		</Lang>
	);
};

export default class ReportSelections extends Component {
	handleSelect = (report) => {
		const { reports, updateAncestor } = this.props;
		const selectedReport = (report === null ? { id: undefined, name: undefined } : reports.find(element => element.id === report));
		if (updateAncestor)
			updateAncestor({ reportId: selectedReport.id, reportName: selectedReport.name });
	};

	render() {
		const { selectedProject, reports, reportId, lang, reportName, storeReport, deleteReport } = this.props;
		if (selectedProject !== undefined && selectedProject.id !== undefined) {
			return (
				<div className="reportSelections scrollable-dropdown">
					<div className="loadTitle">
						<Lang en="Choose template:" fi="Valitse pohja:" sv="Välje botten:" />
					</div>
					<div className="dropdownFlexer">
						<DropdownButton
							variant="default"
							title={getTitle(reports, reportId, lang)}
							id="reportDropdown"
							className="reportDropdown"
							onSelect={this.handleSelect}
						>
							{getItems(reports, reportId, lang)}
						</DropdownButton>
						{	// Show save button only if user has right to reports
							isAuthorized({ one: ["reports"] }) ?
								<SaveButton
									title={reportId}
									reportId={reportId}
									reportName={reportName}
									saveReport={storeReport}
									deleteReport={deleteReport}
									lang={lang}
									handleSelect={this.handleSelect}
								/>
								: null
						}
					</div>
				</div>
			);
		}
		return null;
	}
}
