import "./ReportCopier.css";

import { Component } from "react";
import { Alert, Button, Col, Collapse, Dropdown, DropdownButton, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import rfdc from "rfdc";

import Messenger from "../../utilities/messenger";
import isDefined from "../../utilities/util";
import Lang from "../LanguageSelect/Lang";

const rfdclone = rfdc();

const getSourceItems = ({ reports, selectedSourceReport }) => {
	let items = [];
	if (isDefined({ reports, selectedSourceReport })) {
		const keys = Object.keys(reports).sort((a, b) => reports[a].localeCompare(reports[b], 'fi', { sensitivity: "accent", numeric: true }));
		items = keys.map(id => (
			<Dropdown.Item
				key={`sourceReport_${id}`}
				eventKey={id}
				active={selectedSourceReport.id === id}
			>
				{reports[id]}
			</Dropdown.Item>
		));
	}
	return items;
};

const getTargetItems = ({ projects, selectedProject }) => {
	let items = [];
	projects.sort((a, b) => b.id - a.id);
	if (isDefined({ projects, selectedProject })) {
		items = projects.map(project => (
			<Dropdown.Item
				key={`Project_${project.id}`}
				eventKey={project.id}
				active={selectedProject.id === project.id}
			>
				{project.name}
			</Dropdown.Item>
		));
	}
	return items;
};

export default class ReportCopier extends Component {
	state = {
		selectedSourceReport: { id: undefined, name: undefined },
		selectedSourceProject: { id: undefined, name: undefined },
		selectedTargetProject: { id: undefined, name: undefined },
		reports: {},
		// eslint-disable-next-line react/destructuring-assignment
		systemUpdate: { detached: false, systems: this.props.systems },
		checkboxes: {
			settingsTemplates: false,
			limits: false,
			plotColors: false,
			blueprints: false,
			loggerOrder: false
		},
		submitDisabled: false,
		loadingState: false
	};

	componentDidUpdate(prevprops, prevState) {
		const { customerId, selectedSystem } = this.props;
		const { selectedSourceProject } = this.state;
		if (
			selectedSourceProject.id !== undefined && (
				prevprops.customerId !== customerId ||
				prevprops.selectedSystem.id !== selectedSystem.id ||
				prevState.selectedSourceProject.id !== selectedSourceProject.id
			)) {
			this.setState({ loadingState: true });
			const payload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedSourceProject.id,
			};
			const callback = (data) => {
				if (data.status === "success") {
					this.setState({ reports: data.reports, selectedSourceReport: { id: undefined, name: undefined }, selectedTargetProject: { id: undefined, name: undefined }, loadingState: false });
				} else {
					toast.error(<Lang en="Failed to fetch settings templates." fi="Asetuspohjien haku epäonnistui." sv="Kunde inte hämta inställningsmallar." />);
					this.setState({ loadingState: false });
				}
			};
			Messenger.requestReports({ payload, callback: callback.bind(this) });
		}
	}

	componentWillUnmount() {
		const { updateAncestor } = this.props;
		const { systemUpdate } = this.state;
		if (systemUpdate.detached && updateAncestor) {
			const systems = rfdclone(systemUpdate.systems);
			updateAncestor({ systems });
		}
	}

	handleSourceSelect = (id) => {
		const { reports } = this.state;
		const name = reports[id];
		this.setState({ selectedSourceReport: { id, name } });
	};

	handleSourceProjectSelect = (id) => {
		const { projects } = this.props;
		const { name } = projects.find(element => element.id === id);
		this.setState({ selectedSourceProject: { id, name } });
	};

	handleTargetSelect = (id) => {
		const { projects } = this.props;
		const { name } = projects.find(element => element.id === id);
		this.setState({ selectedTargetProject: { id, name } });
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	submit = () => {
		const { customerId, selectedSystem, getCustomerSystems, userId } = this.props;
		const { checkboxes, selectedSourceReport, selectedTargetProject, selectedSourceProject } = this.state;
		this.setState({ submitDisabled: true });
		if (checkboxes.settingsTemplates && selectedSourceReport.id !== undefined && selectedTargetProject.id !== undefined) {
			const dataRequestPayload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedSourceProject.id,
				reportId: selectedSourceReport.id,
			};
			const dataRequestCallback = (data) => {
				if (data.status === "success") {
					let xAxisFilterStartTime = null;
					let xAxisFilterEndTime = null;
					if (data.xAxisFilter !== null) {
						[xAxisFilterStartTime, xAxisFilterEndTime] = data.xAxisFilter.split(";");
					}
					const submitPayload = {
						customerId,
						systemId: selectedSystem.id,
						projectId: selectedTargetProject.id,
						reportName: data.reportName,
						title: data.title,
						subtitle: data.subtitle,
						x_axis_title: data.x_axis_title,
						y_axis_title: data.y_axis_title,
						y2_axis_title: data.y2_axis_title,
						y_axis_min: data.y_axis_min,
						y_axis_max: data.y_axis_max,
						y_axis_ticks: data.y_axis_ticks,
						y2_axis_min: data.y2_axis_min,
						y2_axis_max: data.y2_axis_max,
						y2_axis_ticks: data.y2_axis_ticks,
						x_axis_ticks: data.x_axis_ticks,
						y_axis_tick_zero: data.y_axis_tick_zero,
						y2_axis_tick_zero: data.y2_axis_tick_zero,
						x_axis_tick_zero: data.x_axis_tick_zero,
						y2_axis_quantities: data.y2_axis_quantities,
						selectedDatasets: data.selectedDatasets,
						selectedLimits: data.selectedLimits,
						deviceDescriptions: data.deviceDescriptions,
						fontSize: data.fontSize,
						x_axis_filter_start_time: xAxisFilterStartTime,
						x_axis_filter_end_time: xAxisFilterEndTime,
						nTh: data.nTh,
						avgNth: data.avgNth,
					};
					const submitCallback = (submitData) => {
						const { systems } = this.props;
						const { systemUpdate } = this.state;
						if (submitData.status === "success") {
							if (submitData.newReportId !== undefined) {
								const systemsCopy = systemUpdate.detached ? rfdclone(systemUpdate.systems) : rfdclone(systems);
								const sysIndex = systemsCopy.findIndex(x => x.id === selectedSystem.id);
								if (sysIndex > -1) {
									const proIndex = systemsCopy[sysIndex].projects.findIndex(x => x.id === selectedTargetProject.id);
									if (proIndex > -1) {
										systemsCopy[sysIndex].projects[proIndex].reports[submitData.newReportId] = submitPayload.reportName;
										this.setState({
											systemUpdate: { detached: true, systems: systemsCopy },
											selectedSourceReport: { id: undefined, name: undefined },
											selectedTargetProject: { id: undefined, name: undefined }
										});
									}
								}
							}
						} else {
							toast.error(<Lang en="Failed to copy settings template" fi="Asetuspohjan kopiointi epäonnistui" sv="Kunde inte kopiera inställningsmall" />);
						}
						if (checkboxes.settingsTemplates && !checkboxes.limits && !checkboxes.plotColors && !checkboxes.blueprints) {
							this.setState({ submitDisabled: false });
							if (submitData.status === "success") {
								toast.success(<Lang en="Project settings copied" fi="Projektin asetukset kopioitu" sv="Projektinställningar kopierade" />);
								getCustomerSystems({ customerId, userId });
							}
						} else {
							const checkboxesCopy = { ...checkboxes };
							checkboxesCopy.settingsTemplates = false;
							this.setState({ checkboxes: checkboxesCopy });
						}
					};
					Messenger.submitReport(submitPayload, submitCallback);
				} else {
					toast.error(<Lang en="Failed to copy settings template" fi="Asetuspohjan kopiointi epäonnistui" sv="Kunde inte kopiera inställningsmall" />);
				}
			};
			Messenger.requestReportData({ payload: dataRequestPayload, callback: dataRequestCallback });
		}
		if ((checkboxes.limits || checkboxes.plotColors || checkboxes.blueprints || checkboxes.loggerOrder) && selectedTargetProject.id !== undefined) {
			const copyRequestPayload = {
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedSourceProject.id,
				targetProjectId: selectedTargetProject.id,
				limits: checkboxes.limits,
				plotColors: checkboxes.plotColors,
				blueprints: checkboxes.blueprints,
				loggerOrder: checkboxes.loggerOrder
			};
			const copyRequestCallback = (data) => {
				const checkboxesCopy = { ...checkboxes };
				checkboxesCopy.limits = false;
				checkboxesCopy.plotColors = false;
				checkboxesCopy.blueprints = false;
				checkboxesCopy.loggerOrder = false;
				if (checkboxes.settingsTemplates) {
					this.setState({ checkboxes: checkboxesCopy });
				} else {
					this.setState({ checkboxes: checkboxesCopy, submitDisabled: false });
					if (data.status === "success") {
						toast.success(<Lang en="Project settings copied" fi="Projektin asetukset kopioitu" sv="Projektinställningar kopierade" />);
						getCustomerSystems({ customerId, userId });
					} else {
						toast.error(<Lang en="Failed to copy settings" fi="Asetusten kopiointi epäonnistui" sv="Det gick inte att kopiera inställningarna" />);
					}
				}
			};
			Messenger.copyProjectData({ payload: copyRequestPayload, callback: copyRequestCallback });
		}
	};

	updateState = (id, checked) => {
		this.setState(({ checkboxes }) => {
			const checkboxesCopy = rfdclone(checkboxes);
			checkboxesCopy[id] = checked;
			return { checkboxes: checkboxesCopy };
		});
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	render() {
		const { selectedSystem, activeNav, loading, lang, projects } = this.props;
		const { loadingState, selectedSourceProject, selectedTargetProject, checkboxes, selectedSourceReport, reports, submitDisabled } = this.state;
		if (selectedSystem.id !== undefined && activeNav === "reportcopy") {
			return (
				<>
					<Spinner id="reportcopyRightSpinner" animation="border" style={{ display: loading || loadingState ? "inline-block" : "none" }} />
					<Form className={`report-copier cardBackground ${loading || loadingState ? "loading" : ""}`} >
						<Form.Group as={Row} className="pt-3 ms-3 mb-3 source-selector align-items-center">
							<Form.Label column sm={2}>
								<Lang lang={lang}>
									<div className="title" key="en">Source project</div>
									<div className="title" key="fi">Lähdeprojekti</div>
									<div className="title" key="sv">Källprojekt</div>
								</Lang>
							</Form.Label>
							<Col sm={10}>
								<DropdownButton
									variant="light"
									title={
										selectedSourceProject.id === undefined
											?
											<Lang lang={lang}>
												<span className="title" key="en">Select...</span>
												<span className="title" key="fi">Valitse...</span>
												<span className="title" key="sv">Välj...</span>
											</Lang>
											:
											<Lang lang={lang}>
												{selectedSourceProject.name}
											</Lang>
									}
									id="reportProjectDropdown"
									onSelect={this.handleSourceProjectSelect}
								>
									{getTargetItems({ projects, selectedProject: selectedSourceProject })}
								</DropdownButton>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="ms-3 mb-3 target-selector align-items-center">
							<Form.Label column sm={2}>
								<Lang lang={lang}>
									<div className="title" key="en">Target project</div>
									<div className="title" key="fi">Kohdeprojekti</div>
									<div className="title" key="sv">Mål projekt</div>
								</Lang>
							</Form.Label>
							<Col sm={10}>
								<DropdownButton
									variant="light"
									title={
										selectedTargetProject.id === undefined
											?
											<Lang lang={lang}>
												<span className="title" key="en">Select...</span>
												<span className="title" key="fi">Valitse...</span>
												<span className="title" key="sv">Välj...</span>
											</Lang>
											:
											<Lang lang={lang}>
												{selectedTargetProject.name}
											</Lang>
									}
									id="reportProjectDropdown"
									onSelect={this.handleTargetSelect}
								>
									{getTargetItems({ projects, selectedProject: selectedTargetProject })}
								</DropdownButton>
							</Col>
						</Form.Group>
						<fieldset>
							<Form.Group
								as={Row}
								className="ms-3 mb-3 align-items-baseline"
							>
								<Form.Label as="legend" column sm={2}>
									<Lang en="Settings to be copied" fi="Kopioitavat asetukset" sv="Inställningar som ska kopieras" />
								</Form.Label>
								<Col sm={10} className="report-copy-checkboxes">
									<Form.Check
										type="checkbox"
										label={<Lang en="Setting templates" fi="Asetuspohjat" sv="Inställningsmallar" />}
										name="settingTemplateCheckbox"
										id="settingsTemplateCheckbox"
										onChange={(e) => { this.updateState("settingsTemplates", e.target.checked); }}
									/>
									<Collapse in={checkboxes.settingsTemplates}>
										<Form.Group as={Row} className="mb-3 source-selector align-items-center">
											<Form.Label column sm={2}>
												<Lang lang={lang}>
													<div className="title" key="en">Source template</div>
													<div className="title" key="fi">Lähdepohja</div>
													<div className="title" key="sv">Källmall</div>
												</Lang>
											</Form.Label>
											<Col sm={10}>
												<DropdownButton
													variant="light"
													title={
														selectedSourceReport.id === undefined
															?
															<Lang lang={lang}>
																<span className="title" key="en">Select...</span>
																<span className="title" key="fi">Valitse...</span>
																<span className="title" key="sv">Välj...</span>
															</Lang>
															:
															<Lang lang={lang}>
																{selectedSourceReport.name}
															</Lang>
													}
													id="reportSourceDropdown"
													onSelect={this.handleSourceSelect}
												>
													{getSourceItems({ reports, selectedSourceReport })}
												</DropdownButton>
											</Col>
										</Form.Group>
									</Collapse>
									<Form.Check
										type="checkbox"
										label={<Lang en="Limits" fi="Rajat" sv="Gränser" />}
										name="limitsCheckbox"
										id="limitsCheckbox"
										onChange={(e) => { this.updateState("limits", e.target.checked); }}
									/>
									<Form.Check
										type="checkbox"
										label={<Lang en="Plot colors" fi="Käyrien värit" sv="Graf färgerna" />}
										name="plotColorsCheckbox"
										id="plotColorsCheckbox"
										onChange={(e) => { this.updateState("plotColors", e.target.checked); }}
									/>
									<Form.Check
										type="checkbox"
										label={<Lang en="Blueprints" fi="Pohjakuvat" sv="Planen" />}
										name="blueprintsCheckbox"
										id="blueprintsCheckbox"
										onChange={(e) => { this.updateState("blueprints", e.target.checked); }}
									/>
									{
										["APw", "APw+"].includes(selectedSystem.deviceType) ?
											<Form.Check
												type="checkbox"
												label={<Lang en="Order of loggers" fi="Loggerien järjestys" sv="Logger order" />}
												name="loggerOrderCheckbox"
												id="loggerOrderCheckbox"
												onChange={(e) => { this.updateState("loggerOrder", e.target.checked); }}
											/>
											:
											null
									}
									{checkboxes.limits || checkboxes.plotColors || checkboxes.loggerOrder ?
										<Alert variant="danger">
											<Lang en="Warning! Copied limits, plot colors and logger order will overwrite the current settings in your target project. This cannot be undone!" fi="Varoitus! Kopioidut rajat, käyrien värit ja loggerien järjestys korvaavat nykyiset asetukset kohdeprojektissa. Toimintoa ei voi peruuttaa!" sv="Varning! Kopierade gränser, plotfärger och loggerordning kommer att skriva över de nuvarande inställningarna i ditt målprojekt. Detta kan inte göras ogjort!" />
										</Alert>
										:
										null
									}
								</Col>
							</Form.Group>
						</fieldset>
						<div className="submission">
							<Button
								variant="success"
								disabled={(selectedSourceReport.id === undefined && checkboxes.settingsTemplates) || selectedTargetProject.id === undefined || Object.values(checkboxes).every(value => value === false) || submitDisabled || selectedTargetProject.id === selectedSourceProject.id}
								onClick={this.submit}
								className="ms-3"
							>
								{submitDisabled ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
								<Lang lang={lang}>
									<span key="en">Copy</span>
									<span key="fi">Kopioi</span>
									<span key="sv">Kopiera</span>
								</Lang>
							</Button>
						</div>
					</Form >
				</>
			);
		}
		return null;
	}
}
