import store from "./redux";

export default function isAuthorized({ all, one }) {
	const state = store.getState();
	let rightsValid = !(all || one);
	const sessionUserRights = JSON.parse(sessionStorage.getItem("userRights")) ?? {}
	const userRights = Object.keys(sessionUserRights).filter(right => sessionUserRights[right] === true);
	if (userRights && all) {
		rightsValid = all.every(right => userRights.includes(right));
	} else if (userRights && one) {
		const tempOne = [...one];
		let projectsSomeRights = false;
		const projectsSomeIndex = Object.values(tempOne).indexOf("projectsSome");
		if (projectsSomeIndex > -1) {
			projectsSomeRights = true;
			tempOne.splice(projectsSomeIndex, 1);
		}
		rightsValid = tempOne.some(right => userRights.includes(right));
		if (state.projectsSome && projectsSomeRights) {
			rightsValid = !!(state.projectsSome !== undefined && state.systemsSelectedSystem.id !== undefined && state.systemsCurrentProject.id !== undefined &&
				state.projectsSome[state.systemsSelectedSystem.id] !== undefined && Object.values(state.projectsSome[state.systemsSelectedSystem.id]).includes(state.systemsCurrentProject.id));
		}
	}
	return JSON.parse(sessionStorage.getItem("userLogged")) && rightsValid;
};