import "../css/pageDefault.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"
import queryString from "query-string";
import { Component, createRef, lazy, Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { flushSync } from "react-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import rfdc from "rfdc";
import { v4 as uuid } from "uuid";

import isAuthorized from "../utilities/authorization";
import Messenger from "../utilities/messenger";
import store from "../utilities/redux";
import isDefined from "../utilities/util";
import GraphComments from "./GraphComments";
import Lang from "./LanguageSelect/Lang";
import LeftSidePanel from "./LeftSidePanel";
import ProjectDataStatisticsDisplay from "./StatisticsCalc/ProjectDataStatisticsDisplay";

const Graph = lazy(() => import(/* webpackChunkName: "plotly" */ "./Graph"));

dayjs.extend(customParseFormat);
dayjs.extend(timezone)
dayjs.extend(utc);

const rfdclone = rfdc();

export const setDeviceSettings = ({ datasets, deviceSettings, value }) => {
	const deviceSettingsCopy = rfdclone(deviceSettings);
	let allUndefined = true;
	datasets.forEach((dataset) => {
		if (deviceSettingsCopy[dataset.deviceKey] === undefined) {
			deviceSettingsCopy[dataset.deviceKey] = { selected: {} };
		}
		if (deviceSettingsCopy[dataset.deviceKey].selected === undefined) {
			deviceSettingsCopy[dataset.deviceKey].selected = {};
		}
		if (value === undefined) {
			if (deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] === undefined) {
				deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = false;
			} else if (allUndefined) {
				allUndefined = false;
			}
		} else {
			deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = value;
		}
	});
	if (allUndefined && value === undefined) {
		datasets.forEach((dataset) => {
			deviceSettingsCopy[dataset.deviceKey].selected[dataset.measi] = true;
		});
	}
	return deviceSettingsCopy;
};

const getCustomQuantity = (index, quantities) => {
	if (index !== undefined &&
		quantities !== undefined && quantities !== null &&
		quantities[index] !== undefined
	) {
		return quantities[index];
	}
	return "";
};

const getSystems = ({ customers, customerId, systems }) => {
	if (isDefined({ customers, customerId })) {
		return Object.keys(customers).length > 1 && customers[customerId] !== undefined && customers[customerId].systems !== undefined ? customers[customerId].systems : systems;
	}
	return [];
};

const autoSelectSystem = ({ customers, customerId, systems, selectedSystem }) => {
	if (!selectedSystem || selectedSystem.id === undefined) {
		const sys = getSystems({ customers, customerId, systems });
		if (sys.length === 1) {
			return sys[0];
		}
	}
	return { id: undefined, title: undefined };
};

const getProjects = ({ systems, selectedSystem }) => {
	if (isDefined({ systems, selectedSystem }) && Array.isArray(systems) && systems !== undefined) {
		const index = systems.findIndex(x => x.id === selectedSystem.id);
		if (index > -1 && systems[index].projects !== undefined) {
			return systems[index].projects;
		}
	}
	return [];
};

const autoSelectProject = ({ systems, selectedSystem, selectedProject }) => {
	if (
		selectedSystem && selectedSystem.id !== undefined &&
		(!selectedProject || selectedProject.id === undefined)
	) {
		const projects = getProjects({ systems, selectedSystem });
		if (projects.length === 1) {
			return projects[0];
		}
	}
	return { id: undefined, title: undefined };
};

const getTimesFromTicks = ({ xAxisTicks }) => {
	const output = {
		xAxisTicksDays: 0,
		xAxisTicksHours: 0,
		xAxisTicksMinutes: 0
	};
	if (isDefined({ xAxisTicks })) {
		output.xAxisTicksDays = Math.floor(xAxisTicks / 86_400_000);
		output.xAxisTicksHours = Math.floor((xAxisTicks - output.xAxisTicksDays * 86_400_000) / 3_600_000);
		output.xAxisTicksMinutes = Math.floor((xAxisTicks - (output.xAxisTicksDays * 86_400_000) - (output.xAxisTicksHours * 3_600_000)) / 60_000);
	}
	return output;
};

const convertY2Quantities = (quantities, fromServer) => {
	if (fromServer) {
		if (Array.isArray(quantities)) {
			const res = {};
			for (const quantity of quantities) {
				res[quantity] = true;
			}
			return res;
		}
		return {};
	}
	if (typeof quantities === "object") {
		const res = [];
		for (const key of Object.keys(quantities)) {
			if (quantities[key])
				res.push(key);
		}
		return res;
	}
	return [];
};

const applyCustomQuantity = (quantity) => {
	if (quantity !== undefined)
		return quantity;
	return "";
};

const quantityFromTypeAndUnit = (type, unit) => {
	if (type !== undefined && unit !== undefined) {
		if (type !== "Pres") {
			return type;
		}
		if (unit === "Pa") {
			return "Pres diff";
		}
		return "Baro pres";
	}
	return "-";
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const applyDeviceSettings = ({ datasets, devices, deviceSettings, hideLegends, y2AxisQuantities }) => {
	const output = { datasets, devices };
	// Applying device settings to datasets
	if (isDefined({ datasets, deviceSettings })) {
		// Making deep copy of datasets
		const datasetsCopy = [];
		const keys = Object.keys(deviceSettings);
		datasets.forEach((datasetCopy) => {
			const dataset = rfdclone(datasetCopy);
			const index = keys.indexOf(dataset.deviceKey);
			// If dataset settings available apply them
			if (index > -1) {
				dataset.legendgroup = (deviceSettings[keys[index]].deviceName ?? dataset.deviceName);
				dataset.legendgrouptitle.text = `<b>${deviceSettings[keys[index]].deviceName ?? dataset.deviceName}</b>`;
				dataset.name = `${applyCustomQuantity(dataset.customQuantity)} [${dataset.measUnit}]`;
				dataset.text = `${dataset.legendgroup} ${dataset.name}`;
				dataset.yaxis = y2AxisQuantities[quantityFromTypeAndUnit(dataset.measType, dataset.measUnit)] ? "y2" : undefined;
				// If dataset visibility information available set dataset visible, by default set it invisible
				if (dataset.measi !== undefined && deviceSettings[keys[index]] !== undefined && deviceSettings[keys[index]].selected !== undefined && deviceSettings[keys[index]].selected[dataset.measi]) {
					dataset.visible = true;
				} else {
					dataset.visible = hideLegends ? false : "legendonly";
				}
				// Default dataset settings if no settings available
			} else {
				dataset.legendgroup = dataset.deviceName;
				dataset.legendgrouptitle.text = `<b>${dataset.deviceName}</b>`;
				dataset.name = `${applyCustomQuantity(dataset.customQuantity)} [${dataset.measUnit}]`;
				dataset.text = `${dataset.legendgroup} ${dataset.name}`;
				dataset.visible = hideLegends ? false : "legendonly";
			}
			datasetsCopy.push(dataset);
		});
		output.datasets = datasetsCopy;
	}
	// Applying device settings to devices
	if (isDefined({ devices, deviceSettings })) {
		// Making copy of devices
		const devicesCopy = rfdclone(devices);
		const keys = Object.keys(deviceSettings);
		keys.forEach((key) => {
			// Setting device name if setting is available
			if (devicesCopy[key] !== undefined && deviceSettings[key].deviceName !== undefined) {
				devicesCopy[key].description = deviceSettings[key].deviceName;
				// If there is no name setting but device has one set then remove it
			} else if (devicesCopy[key] !== undefined && devicesCopy[key].description !== undefined) {
				delete devicesCopy[key].description;
			}
		});
		output.devices = devicesCopy;
	}
	return output;
};

// Mapping to and from redux statisticsView_ props
const STATISTICSVIEW_MAP = {
	selectedSystem: "statisticsViewSelectedSystem", selectedProject: "statisticsViewSelectedProject",
	title: "statisticsViewTitle", subtitle: "statisticsViewSubtitle",
	devices: "statisticsViewDevices",
	xAxisTitle: "statisticsViewXAxisTitle", xAxisStartTime: "statisticsViewXAxisStartTime", xAxisAbsoluteStartTime: "statisticsViewXAxisAbsoluteStartTime", xAxisEndTime: "statisticsViewXAxisEndTime", xAxisAbsoluteEndTime: "statisticsViewXAxisAbsoluteEndTime", xAxisTicks: "statisticsViewXAxisTicks",
	xAxisTickZero: "statisticsViewXAxisTickZero", xAxisTicksDays: "statisticsViewXAxisTicksDays", xAxisTicksHours: "statisticsViewXAxisTicksHours", xAxisTicksMinutes: "statisticsViewXAxisTicksMinutes",
	xAxisFilter: "statisticsViewXAxisFilter", xAxisFilterStartTime: "statisticsViewXAxisFilterStartTime", xAxisFilterEndTime: "statisticsViewXAxisFilterEndTime",
	autoRangeTime: "statisticsViewAutoRangeTime", lastUpdate: "statisticsViewLastUpdate",
	yAxisTitle: "statisticsViewYAxisTitle", yAxisMax: "statisticsViewYAxisMax", yAxisMin: "statisticsViewYAxisMin",
	yAxisTicks: "statisticsViewYAxisTicks", yAxisTickZero: "statisticsViewYAxisTickZero",
	y2AxisTitle: "statisticsViewY2AxisTitle", y2AxisMax: "statisticsViewY2AxisMax", y2AxisMin: "statisticsViewY2AxisMin", y2AxisQuantities: "statisticsViewY2AxisQuantities",
	datasets: "statisticsViewDatasets", selectedDatasets: "statisticsViewSelectedDatasets", selectedDatasetGroups: "statisticsViewSelectedDatasetGroups",
	measTypes: "statisticsViewMeasTypes", selectedMeasType: "statisticsViewSelectedMeasType", deviceSettings: "statisticsViewDevicesettings", hideLegends: "statisticsViewHideLegends",
	timeLoggingInt: "statisticsViewTimeLoggingInt", timeLoggingMS: "statisticsViewTimeLoggingMS",
	nTh: "statisticsViewNth", avgNth: "statisticsViewAvgNth", tempNth: "statisticsViewTempNth", fontSize: "statisticsViewFontSize", systemsDetached: "statisticsViewSystemsDetached",
	limits: "statisticsViewLimits", selectedLimits: "statisticsViewSelectedLimits", dragmode: "statisticsViewDragmode", loading: "statisticsViewLoading", settingsLoading: "statisticsViewLeftLoading",
	reportId: "statisticsViewReportId", reportName: "statisticsViewReportName", showComments: "statisticsViewShowComments", graphComments: "statisticsViewComments",
};

const remapToStatisticsView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (STATISTICSVIEW_MAP[key])
			output[STATISTICSVIEW_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const remapFromStatisticsView = (obj) => {
	const MAP = {};
		for (const key of Object.keys(STATISTICSVIEW_MAP)){
		const value = STATISTICSVIEW_MAP[key];
		MAP[value] = key;
	}
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (MAP[key])
			output[MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const STATISTICSVIEW_VALUES = [
	"customerId", "systems", "customers", "lang", "userId", "username",
	"statisticsViewSelectedSystem", "statisticsViewSelectedProject",
	"statisticsViewTitle", "statisticsViewSubtitle",
	"statisticsViewDevices",
	"statisticsViewXAxisTitle", "statisticsViewXAxisStartTime", "statisticsViewXAxisAbsoluteStartTime", "statisticsViewXAxisEndTime", "statisticsViewXAxisAbsoluteEndTime", "statisticsViewXAxisTicks",
	"statisticsViewXAxisTickZero", "statisticsViewXAxisTicksDays", "statisticsViewXAxisTicksHours", "statisticsViewXAxisTicksMinutes",
	"statisticsViewXAxisFilter", "statisticsViewXAxisFilterStartTime", "statisticsViewXAxisFilterEndTime",
	"statisticsViewAutoRangeTime", "statisticsViewLastUpdate",
	"statisticsViewYAxisTitle", "statisticsViewYAxisMax", "statisticsViewYAxisMin",
	"statisticsViewYAxisTicks", "statisticsViewYAxisTickZero",
	"statisticsViewY2AxisTitle", "statisticsViewY2AxisMax", "statisticsViewY2AxisMin", "statisticsViewY2AxisQuantities",
	"statisticsViewDatasets", "statisticsViewSelectedDatasets", "statisticsViewSelectedDatasetGroups",
	"statisticsViewMeasTypes", "statisticsViewSelectedMeasType", "statisticsViewDevicesettings", "statisticsViewHideLegends",
	"statisticsViewTimeLoggingInt", "statisticsViewTimeLoggingMS",
	"statisticsViewNth", "statisticsViewAvgNth", "statisticsViewTempNth", "statisticsViewFontSize", "statisticsViewSystemsDetached",
	"statisticsViewLimits", "statisticsViewSelectedLimits", "statisticsViewDragmode", "statisticsViewLoading", "statisticsViewLeftLoading",
	"statisticsViewReportId", "statisticsViewReportName", "statisticsViewShowComments", "statisticsViewComments",
];

const getOnlyStatisticsViewValues = (obj) => {
	const objKeys = {};
	for (const key of Object.keys(obj)) {
	  	objKeys[key] = true;
	}
	const res = {};
	for (const key of STATISTICSVIEW_VALUES) {
	  	if (objKeys[key]) {
			res[key] = obj[key];
	  	}
	}
	return res;
  };

class StatisticsViewClass extends Component {
	constructor() {
		super();

		this.commentsRef = createRef();
	}

	componentDidMount() {
		const { customerId, systems, getCustomerSystems, userId, lastUpdate, selectedProject, location } = this.props;
		if (!this.mounted && customerId !== undefined && systems.length === 0)
			getCustomerSystems({ customerId, userId, noUpdate: true });
		if (lastUpdate === "plotColorEditor" && selectedProject.id !== undefined) {
			const obj = {
				lastUpdate: "plotColorEditor"
			};
			this.updateData(obj);
		}
		let queryParams = false;
		if (location.search) {
			queryParams = queryString.parse(location.search);
			const systemIndex = systems.findIndex((element) => element.id === queryParams.systemId);
			if (systemIndex > -1) {
				const projectIndex = systems[systemIndex].projects.findIndex((element) => element.id === queryParams.projectId);
				if (projectIndex > -1)
					this.updateData({ selectedSystem: systems[systemIndex], selectedProject: systems[systemIndex].projects[projectIndex], autoselect: true });
			}
		}
		this.mounted = true;
	}

	componentDidUpdate() {
		const state = store.getState();
		if (!state.statisticsViewLoading && state.statisticsViewSelectedSystem && state.statisticsViewSelectedSystem.id === undefined && state.systems.length === 1) {
			store.dispatch({
				type: "SET_VALUES",
				obj: {
					statisticsViewSelectedSystem: autoSelectSystem({ customers: state.customers, customerId: state.customerId, systems: state.systems })
				}
			});
		} else if (!state.statisticsViewLoading && state.statisticsViewSelectedSystem && state.statisticsViewSelectedSystem.id !== undefined && state.statisticsViewSelectedProject && state.statisticsViewSelectedProject.id === undefined) {
			const selectedProject = autoSelectProject({ systems: state.systems, selectedSystem: state.statisticsViewSelectedSystem });
			if (selectedProject.id !== undefined) {
				this.updateData({ selectedProject });
			}
		}
	}

	componentWillUnmount() {
		const { systemsDetached, updateAncestor, systems } = this.props;
		this.mounted = false;
		if (systemsDetached)
			updateAncestor({ systems: rfdclone(systems) });
	}

	/* eslint-disable react/destructuring-assignment */
	// eslint-disable-next-line sonarjs/cognitive-complexity
	updateData = (propObj) => {
		const obj = propObj;
		// eslint-disable-next-line prefer-destructuring
		const dontFlush = !!obj.dontFlush;
		delete obj.dontFlush;
		const sendMessages = [];
		if (obj.selectedSystem !== undefined && obj.autoselect === undefined) {
			obj.selectedProject = { id: undefined, title: undefined };
		}
		// Clearing previous project data and density
		if (obj.selectedProject) {
			obj.limits = undefined;
			obj.reportId = undefined;
			obj.reportName = undefined;
			if (obj.nTh === undefined)
				obj.nTh = 20;
			obj.avgNth = null;
		}
		if (obj.selectedProject !== undefined) {
			const nTh = obj.nTh === undefined ? this.props.nTh : obj.nTh;
			const avgNth = obj.avgNth ?? this.props.avgNth;
			const selectedSystem = obj.selectedSystem === undefined ? this.props.selectedSystem : obj.selectedSystem;
			const selectedProject = obj.selectedProject === undefined ? this.props.selectedProject : obj.selectedProject;
			if (
				selectedSystem !== undefined && selectedSystem.id !== undefined &&
				selectedProject !== undefined && selectedProject.id !== undefined
			) {
				const defaultProjectChangeState = {
					title: "",
					subtitle: "",
					xAxisTitle: "",
					xAxisTicks: 0,
					xAxisTickZero: null,
					xAxisTicksDays: 0,
					xAxisTicksHours: 0,
					xAxisTicksMinutes: 0,
					xAxisFilter: false,
					xAxisFilterStartTime: undefined,
					xAxisFilterEndTime: undefined,
					xAxisAbsoluteStartTime: undefined,
					xAxisAbsoluteEndTime: undefined,
					yAxisTitle: "",
					yAxisMax: 0,
					yAxisMin: 0,
					yAxisTicks: 0,
					yAxisTickZero: null,
					selectedLimits: [],
					loading: true,
					devices: [],
					datasets: [],
					measTypes: [],
					autoRangeTime: true,
					deviceSettings: {},
				};
				Object.assign(obj, defaultProjectChangeState);
				obj.timeLoggingInt = this.props.timeLoggingInt + 1;
				obj.timeLoggingMS = dayjs().valueOf();
				let payload = {
					customerId: this.props.customerId,
					nth: nTh,
					avgNth: avgNth ?? undefined,
					systemId: selectedSystem.id,
					projectId: selectedProject.id,
					start: Math.round(dayjs(this.props.xAxisStartTime).valueOf() / 1000),
					end: Math.round(dayjs(this.props.xAxisEndTime).valueOf() / 1000),
				};
				if (obj.selectedProject !== undefined && obj.selectedProject.id !== this.props.selectedProject.id) {
					payload = {
						customerId: this.props.customerId,
						nth: nTh,
						avgNth: avgNth ?? undefined,
						systemId: selectedSystem.id,
						projectId: selectedProject.id,
					};
				}
				sendMessages.push(
					Messenger.requestNthProjectData.bind(this, {
						payload,
						callback: this.nthCallback(obj)
					})
				);
			}
		}
		if (obj.nTh !== undefined && obj.selectedProject === undefined) {
			const selectedSystem = obj.selectedSystem === undefined ? this.props.selectedSystem : obj.selectedSystem;
			const { selectedProject, timeLoggingInt, customerId, xAxisStartTime, xAxisEndTime } = this.props;
			if (
				selectedSystem !== undefined && selectedSystem.id !== undefined &&
				selectedProject !== undefined && selectedProject.id !== undefined
			) {
				obj.loading = true;
				obj.timeLoggingInt = timeLoggingInt + 1;
				obj.timeLoggingMS = dayjs().valueOf();
				let timeFilter;
				if (obj.xAxisFilter && obj.xAxisFilterStartTime !== undefined && obj.xAxisFilterEndTime !== undefined) {
					timeFilter = { filterStart: dayjs(obj.xAxisFilterStartTime).format("H:m"), filterEnd: dayjs(obj.xAxisFilterEndTime).format("H:m") };
					obj.nTh = 1;
				} else {
					timeFilter = undefined;
				}
				const payload = {
					customerId,
					nth: obj.nTh,
					avgNth: obj.avgNth,
					systemId: selectedSystem.id,
					projectId: selectedProject.id,
					start: Math.round(dayjs(obj.xAxisStartTime === undefined ? xAxisStartTime : obj.xAxisStartTime).valueOf() / 1000),
					end: Math.round(dayjs(obj.xAxisEndTime === undefined ? xAxisEndTime : obj.xAxisEndTime).valueOf() / 1000),
					timeFilter,
				};
				sendMessages.push(
					Messenger.requestNthProjectData.bind(this, {
						payload,
						callback: this.nthCallback(obj)
					})
				);
			}
		}
		// Time changed, request new data
		if (
			obj.selectedProject === undefined &&
			obj.reportId === undefined &&
			obj.nTh === undefined &&
			this.props.selectedSystem !== undefined &&
			this.props.selectedSystem.id !== undefined &&
			this.props.selectedProject !== undefined &&
			this.props.selectedProject.id !== undefined
			&& (
				(obj.xAxisStartTime !== undefined && obj.xAxisStartTime !== this.props.xAxisStartTime) ||
				(obj.xAxisEndTime !== undefined && obj.xAxisEndTime !== this.props.xAxisEndTime) || (obj.statisticsViewLastUpdate === "plotColorEditor" && !this.props.loading) ||
				(this.props.xAxisFilter && obj.xAxisFilterStartTime !== undefined && obj.xAxisFilterEndTime !== undefined) ||
				(obj.xAxisFilter === false)
			)
		) {
			const start = obj.xAxisStartTime === undefined ? this.props.xAxisStartTime : obj.xAxisStartTime;
			const end = obj.xAxisEndTime === undefined ? this.props.xAxisEndTime : obj.xAxisEndTime;
			const timeZone = "Europe/Helsinki"
			let timeFilter;

			if (obj.xAxisFilterStartTime !== undefined && obj.xAxisFilterEndTime !== undefined) {
				timeFilter = { 
					filterStart: dayjs(obj.xAxisFilterStartTime).tz(timeZone).utc().format("H:m"), 
					filterEnd: dayjs(obj.xAxisFilterEndTime).tz(timeZone).utc().format("H:m") 
				};
				obj.nTh = 1;
			} else if (obj.xAxisFilter !== false && this.props.xAxisFilterStartTime !== undefined && this.props.xAxisFilterEndTime !== undefined) {
				timeFilter = { 
					filterStart: dayjs(this.props.xAxisFilterStartTime).tz(timeZone).utc().format("H:m"), 
					filterEnd: dayjs(this.props.xAxisFilterEndTime).tz(timeZone).utc().format("H:m") 
				};
				obj.nTh = 1;
			} else {
				timeFilter = undefined;
			}

			obj.loading = true;
			
			sendMessages.push(
				Messenger.requestNthProjectData.bind(this, {
					payload: {
						customerId: this.props.customerId,
						nth: obj.nTh ?? this.props.nTh,
						avgNth: this.props.avgNth ?? undefined,
						systemId: this.props.selectedSystem.id,
						projectId: this.props.selectedProject.id,
						start: Math.round(dayjs(start).tz(timeZone).utc().valueOf() / 1000),
						end: Math.round(dayjs(end).tz(timeZone).utc().valueOf() / 1000),
						timeFilter,
					},
					callback: this.nthCallback(obj)
				})
			);
		}
		// If autorange enable request all data
		if (
			obj.autoRangeTime !== undefined && obj.autoRangeTime === true &&
			obj.selectedProject === undefined &&
			obj.reportId === undefined &&
			obj.nTh === undefined &&
			this.props.selectedSystem !== undefined &&
			this.props.selectedSystem.id !== undefined &&
			this.props.selectedProject !== undefined &&
			this.props.selectedProject.id !== undefined &&
			obj.xAxisStartTime === undefined &&
			obj.xAxisEndTime === undefined
		) {
			obj.loading = true;
			sendMessages.push(
				Messenger.requestNthProjectData.bind(this, {
					payload: {
						customerId: this.props.customerId,
						nth: this.props.nTh,
						avgNth: this.props.avgNth,
						systemId: this.props.selectedSystem.id,
						projectId: this.props.selectedProject.id,
						all: true
					},
					callback: this.nthCallback(obj, true)
				})
			);
		}
		if (obj.reportId === null) {
			// Removing report data
			const datasets = this.props.datasets.map(dataset => {
				const retDataset = rfdclone(dataset);
				retDataset.visible = true;
				return retDataset;
			});
			const clearState = {
				reportName: undefined,
				reportId: undefined,
				title: "",
				subtitle: "",
				xAxisTitle: "",
				xAxisTicks: 0,
				xAxisTickZero: null,
				xAxisTicksDays: 0,
				xAxisTicksHours: 0,
				xAxisTicksMinutes: 0,
				xAxisFilter: false,
				xAxisFilterStartTime: undefined,
				xAxisFilterEndTime: undefined,
				yAxisTitle: "",
				yAxisMax: 0,
				yAxisMin: 0,
				yAxisTicks: 0,
				yAxisTickZero: null,
				autoRangeTime: true,
				datasets,
				deviceSettings: setDeviceSettings({ datasets, deviceSettings: this.props.deviceSettings, value: true }),
				selectedLimits: [],
			};
			Object.assign(obj, clearState);
		} else if (obj.reportId !== undefined) {
			const callback = (responseData) => {
				if (responseData.status === "success") {
					const data = rfdclone(responseData);
					let deviceSettings = {};
					for (const description of data.deviceDescriptions) {
						if (deviceSettings[description.devId] === undefined) {
							deviceSettings[description.devId] = {};
						}
						deviceSettings[description.devId].deviceName = description.description;
					}
					deviceSettings = setDeviceSettings({ datasets: this.props.datasets, deviceSettings, value: false });
					data.selectedDatasets.forEach((selection) => {
						if (deviceSettings[selection.devId] === undefined)
							deviceSettings[selection.devId] = {};
						if (deviceSettings[selection.devId].selected === undefined)
							deviceSettings[selection.devId].selected = {};
						deviceSettings[selection.devId].selected[selection.measIndex] = true;
					});
					const xAxisTicks = data.x_axis_ticks === null ? 0 : data.x_axis_ticks;
					const { xAxisTicksDays, xAxisTicksHours, xAxisTicksMinutes } = getTimesFromTicks({ xAxisTicks });
					if (this.mounted) {
						let timeFilter;
						const xAxisFilterStartTime = data.xAxisFilter === null ? undefined : dayjs(data.xAxisFilter.split(";")[0], "H:mm").toDate();
						const xAxisFilterEndTime = data.xAxisFilter === null ? undefined : dayjs(data.xAxisFilter.split(";")[1], "H:mm").toDate();
						if (xAxisFilterStartTime !== undefined && xAxisFilterEndTime !== undefined) {
							const formattedStartTime = dayjs(xAxisFilterStartTime).format("H:mm");
							const formattedEndTime = dayjs(xAxisFilterEndTime).format("H:mm");


							timeFilter = { filterStart: formattedStartTime, filterEnd: formattedEndTime };
							data.nTh = 1;
							data.xAxisFilter = true;
						} else {
							data.xAxisFilter = false;
						}
						const update = remapToStatisticsView({
							lastUpdate: "statisticsview",
							reportName: data.reportName,
							title: data.title,
							subtitle: data.subtitle,
							xAxisTitle: data.x_axis_title,
							xAxisTicks,
							xAxisTickZero: data.x_axis_tick_zero === undefined ? null : data.x_axis_tick_zero,
							xAxisTicksDays,
							xAxisTicksHours,
							xAxisTicksMinutes,
							xAxisFilter: data.xAxisFilter,
							xAxisFilterStartTime,
							xAxisFilterEndTime,
							yAxisTitle: data.y_axis_title,
							yAxisMax: data.y_axis_max,
							yAxisMin: data.y_axis_min,
							yAxisTicks: data.y_axis_ticks === null ? 0 : data.y_axis_ticks,
							yAxisTickZero: data.y_axis_tick_zero === undefined ? null : data.y_axis_tick_zero,
							y2AxisTitle: data.y2_axis_title,
							y2AxisMax: data.y2_axis_max === null ? 0 : data.y2_axis_max,
							y2AxisMin: data.y2_axis_min === null ? 0 : data.y2_axis_min,
							y2AxisQuantities: convertY2Quantities(data.y2_axis_quantities, true),
							autoRangeTime: true,
							deviceSettings,
							selectedLimits: Array.isArray(data.selectedLimits) && data.selectedLimits !== undefined ? data.selectedLimits : [],
							loading: true,
							nTh: data.nTh === null && data.avgNth === null ? this.props.nTh : data.nTh,
							avgNth: data.nTh === null && data.avgNth === null ? this.props.avgNth : data.avgNth,
							fontSize: data.fontSize === null ? this.props.fontSize : data.fontSize,
						});
						this.props.setValues(update);
						Messenger.requestNthProjectData({
							payload: {
								customerId: this.props.customerId,
								start: Math.round(dayjs(this.props.xAxisStartTime).valueOf() / 1000),
								end: Math.round(dayjs(this.props.xAxisEndTime).valueOf() / 1000),
								nth: data.nTh === null && data.avgNth === null ? this.props.nTh : data.nTh,
								avgNth: data.nTh === null && data.avgNth === null ? this.props.avgNth : data.avgNth,
								systemId: this.props.selectedSystem.id,
								projectId: this.props.selectedProject.id,
								timeFilter,
							},
							callback: this.nthCallback(obj, false)
						});
					}
				}
			};
			if (
				this.props.selectedSystem !== undefined &&
				this.props.selectedSystem.id !== undefined &&
				this.props.selectedProject !== undefined &&
				this.props.selectedProject.id !== undefined
			) {
				sendMessages.push(
					Messenger.requestReportData.bind(this, {
						payload: {
							customerId: this.props.customerId,
							systemId: this.props.selectedSystem.id,
							projectId: this.props.selectedProject.id,
							reportId: obj.reportId,
						},
						callback: callback.bind(this)
					})
				);
			}
		}
		if (obj.datasets || obj.devices || obj.deviceSettings || obj.hideLegends !== undefined || obj.y2AxisQuantities) {
			const transformed = applyDeviceSettings({
				datasets: obj.datasets ?? this.props.datasets,
				devices: obj.devices ?? this.props.devices,
				deviceSettings: obj.deviceSettings ?? this.props.deviceSettings,
				hideLegends: obj.hideLegends === undefined ? this.props.hideLegends : obj.hideLegends,
				y2AxisQuantities: obj.y2AxisQuantities === undefined ? this.props.y2AxisQuantities : obj.y2AxisQuantities,
			});
			Object.assign(obj, transformed);
		}
		const state = remapFromStatisticsView(rfdclone(getOnlyStatisticsViewValues(this.props)));
		Object.assign(state, obj);
		const update = remapToStatisticsView(state);
		if (this.mounted && !dontFlush) {
			flushSync(() => {
				this.props.setValues(update);
			});
		} else {
			this.props.setValues(update);
		}
		if (sendMessages.length > 0) {
			sendMessages.forEach((Msg) => {
				Msg();
			});
		}
	};
	/* eslint-enable react/destructuring-assignment */

	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleReportSave = (reportName) => {
		const {
			reportId, customerId, selectedSystem, selectedProject, title, subtitle, xAxisTitle, xAxisTicks, xAxisTickZero, xAxisFilter, xAxisFilterStartTime, xAxisFilterEndTime, yAxisTitle,
			yAxisMin, yAxisMax, yAxisTicks, yAxisTickZero, y2AxisTitle, y2AxisMin, y2AxisMax, y2AxisQuantities, selectedLimits, nTh, avgNth, fontSize, devices, datasets, systems, setValues
		} = this.props;
		if ((reportId !== undefined && Number.isInteger(reportId)) || reportName !== undefined) {
			// Collecting basic data
			const report = {
				reportId: Number(reportId),
				customerId,
				systemId: selectedSystem.id,
				projectId: selectedProject.id,
				reportName,
				title,
				subtitle,
				x_axis_title: xAxisTitle,
				x_axis_ticks: xAxisTicks,
				x_axis_tick_zero: xAxisTickZero,
				x_axis_filter_start_time: xAxisFilter && xAxisFilterStartTime && xAxisFilterEndTime ? dayjs(xAxisFilterStartTime).format("H:m") : null,
				x_axis_filter_end_time: xAxisFilter && xAxisFilterStartTime && xAxisFilterEndTime ? dayjs(xAxisFilterEndTime).format("H:m") : null,
				y_axis_title: yAxisTitle,
				y_axis_min: yAxisMin,
				y_axis_max: yAxisMax,
				y_axis_ticks: yAxisTicks,
				y_axis_tick_zero: yAxisTickZero,
				y2_axis_title: y2AxisTitle,
				y2_axis_min: y2AxisMin,
				y2_axis_max: y2AxisMax,
				y2_axis_ticks: 0,
				y2_axis_tick_zero: null,
				y2_axis_quantities: convertY2Quantities(y2AxisQuantities),
				deviceDescriptions: [],
				selectedDatasets: [],
				selectedLimits,
				nTh,
				avgNth,
				fontSize,
			};
			// Getting the device descriptions
			Object.keys(devices).forEach((key) => {
				if (devices[key].description !== undefined) {
					report.deviceDescriptions.push({ id: key, description: devices[key].description });
				}
			});
			// Filtering selected datasets
			const filtered = datasets.filter((dataset) => dataset.visible === true);
			// Adding dataset information to selectedDatasets
			filtered.forEach(dataset => {
				report.selectedDatasets.push({ devId: dataset.deviceKey, measIndex: dataset.measi });
			});
			const callback = (data) => {
				if (data.status === "success") {
					const systemsCopy = rfdclone(systems);
					const sysIndex = systemsCopy.findIndex(x => x.id === report.systemId);
					if (sysIndex > -1) {
						const index = systemsCopy[sysIndex].projects.findIndex(x => x.id === report.projectId);
						if (index > -1) {
							const reports = systemsCopy[sysIndex].projects[index].reports === undefined ? {} : systemsCopy[sysIndex].projects[index].reports;
							if (data.newReportId === undefined) {
								reports[data.reportId] = report.reportName;
							} else {
								reports[data.newReportId] = report.reportName;
							}
							systemsCopy[sysIndex].projects[index].reports = reports;
							if (this.mounted) {
								const update = remapToStatisticsView({
									systems: systemsCopy,
									systemsDetached: true,
									reportId: data.newReportId === undefined ? data.reportId.toString() : data.newReportId.toString(),
									reportName: report.reportName
								});
								setValues(update);
							}
						}
					}
					toast.success(<Lang en="Settings template saved" fi="Asetuspohja tallennettu" sv="Inställningsmall sparad" />);
				} else {
					toast.error(<Lang en="Saving settings template failed" fi="Asetuspohjan tallenus epäonnistui" sv="Inställningsmall sparning misslyckades" />);
				}
			};
			Messenger.submitReport(report, callback.bind(this));
		}
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleReportDelete = (reportIdProp) => {
		const { systems, setValues, reportId } = this.props;
		if (reportIdProp !== undefined) {
			const callback = (data) => {
				if (data.status === "success") {
					const systemsCopy = rfdclone(systems);
					const sysIndex = systemsCopy.findIndex(x => x.id === data.report.systemId);
					if (sysIndex > -1) {
						const index = systemsCopy[sysIndex].projects.findIndex(x => x.id === data.report.projectId);
						if (index > -1) {
							delete systemsCopy[sysIndex].projects[index].reports[data.report.reportId];
							if (this.mounted) {
								const update = remapToStatisticsView({
									systems: systemsCopy,
									systemsDetached: true,
									reportId: undefined,
									reportName: undefined
								});
								setValues(update);
							}
						}
					}
					toast.success(<Lang en="Settings template deleted" fi="Asetuspohja poistettu" sv="Inställningsmall raderad" />);
				} else {
					toast.error(<Lang en="Failed to delete settings template" fi="Asetuspohjan poisto epäonnistui" sv="Det gick inte att ta bort inställningsmall" />);
				}
			};
			Messenger.requestDeleteReport({ payload: { reportId }, callback: callback.bind(this) });
		}
	};

	nthCallback = (obj, autoRangeTime) => {
		// eslint-disable-next-line sonarjs/cognitive-complexity
		const callback = (responseData) => {
			const { lang, deviceSettings, hideLegends, y2AxisQuantities, timeLoggingInt, setValues } = this.props;
			let { xAxisStartTime, xAxisEndTime, xAxisAbsoluteStartTime, xAxisAbsoluteEndTime } = this.props;
			if (responseData.status === "success") {
				const data = rfdclone(responseData);
				let manualAutoRangeTime;
				// Sorting entries
				if (data.errors.max_amount_reached !== undefined && data.errors.max_amount_reached) {
					toast.warning(<Lang en="Maximum amount of data points reached. Please select smaller amount of datapoints or a shorter time period." fi="Datapisteiden enimmäismäärä saavutettu. Ole hyvä ja valitse pienempi määrä mittapisteitä tai lyhyempi aikajakso." sv="Maximalt antal datapunkter har uppnåtts. Välj ett mindre antal datapunkter eller en kortare tidsperiod." />, { autoClose: 10_000, });
				}
				if (data.errors.faulty_loggers.length !== undefined && data.errors.faulty_loggers.length > 0) {
					const beginning = data.errors.faulty_loggers.length > 1 ? { "en": "Loggers ", "fi": "Loggerit ", "sv": "Loggare " } : { "en": "Logger ", "fi": "Loggeri ", "sv": "Logger " };
					let middle = "";
					data.errors.faulty_loggers.forEach(logger => {
						middle += `${logger}, `;
					});
					middle = middle.slice(0, -2);
					const ending = data.errors.faulty_loggers.length > 1 ? { "en": " report faulty data. Please contact support.", "fi": " raportoivat virheellistä tietoa. Ota yhteyttä tukeen.", "sv": " rapportera felaktiga data. Kontakta supporten." } : { "en": " reports faulty data. Please contact support.", "fi": " raportoi virheellistä tietoa. Ota yhteyttä tukeen.", "sv": " rapporterar felaktiga data. Kontakta supporten." };
					const string = beginning[lang] + middle + ending[lang];
					toast.warning(string, { autoClose: false, toastId: "faulty-data", });
				}
				data.measurements = data.measurements.sort((a, b) => dayjs(a.t * 1000).valueOf() - dayjs(b.t * 1000).valueOf());
				// Getting datasets
				const datasets = this.processData(data.measurements, data.devices, true);
				// Set range
				if (obj.selectedProject && data.measurements.length > 0) {
					xAxisStartTime = 2 * (data.measurements[2].t - data.measurements[1].t) > (data.measurements[1].t - data.measurements[0].t) ? new Date(data.measurements[0].t * 1000) : new Date(data.measurements[1].t * 1000);
					xAxisEndTime = 2 * (data.measurements[data.measurements.length - 2].t - data.measurements[data.measurements.length - 3].t) > (data.measurements[data.measurements.length - 1].t - data.measurements[data.measurements.length - 2].t) ? new Date(data.measurements[data.measurements.length - 1].t * 1000) : new Date(data.measurements[1].t * 1000);
					manualAutoRangeTime = true;
					if (obj.xAxisAbsoluteStartTime === undefined && obj.xAxisAbsoluteEndTime === undefined) {
						xAxisAbsoluteStartTime = new Date(data.measurements[0].t * 1000);
						xAxisAbsoluteEndTime = new Date(data.measurements[data.measurements.length - 1].t * 1000);
					}
				}
				// Get measTypes
				let measTypes = {};
				Object.keys(data.devices).forEach((key) => {
					for (let i = 0; i < data.devices[key].s.length; i += 1) {
						let meas = data.devices[key].s[i][0];
						if (meas === "Pres") {
							meas = data.devices[key].s[i][1] === "Pa" ? `${data.devices[key].s[i][0]}_Pa` : `${data.devices[key].s[i][0]}_Bar`;
						}
						measTypes[meas] = true;
					}
				});
				measTypes = Object.keys(measTypes);
				const deviceSettingsSet = setDeviceSettings({ datasets, deviceSettings });
				// Applying device settings
				const transformed = applyDeviceSettings({
					datasets,
					devices: data.devices,
					deviceSettings: deviceSettingsSet,
					hideLegends,
					y2AxisQuantities,
				});
				if (this.mounted) {
					const update = remapToStatisticsView({
						lastUpdate: "statisticsview",
						devices: transformed.devices,
						datasets: transformed.datasets,
						deviceSettings: deviceSettingsSet,
						timeLoggingInt: timeLoggingInt + 1,
						timeLoggingMS: dayjs().valueOf(),
						xAxisStartTime,
						xAxisEndTime,
						xAxisAbsoluteStartTime,
						xAxisAbsoluteEndTime,
						autoRangeTime: (manualAutoRangeTime || autoRangeTime) ?? false,
						measTypes,
						selectedMeasType: measTypes[0],
						loading: false,
					});
					setValues(update);
				}
			} else {
				toast.error(<Lang en="The measurement data could not be retrieved" fi="Mittausdatan haku epäonnistui" sv="Mätdata kunde inte hämtas" />);
				setValues({
					lastUpdate: "statisticsview",
					loading: false
				});
			}
		};
		return callback.bind(this);
	};

	// Processes data to plotly supported format
	// eslint-disable-next-line sonarjs/cognitive-complexity
	processData = (entries, devices, direct) => {
		const { deviceSettings, y2AxisQuantities } = this.props;
		const datasets = [];
		let target = "o";
		if (direct)
			target = "";
		const keys = Object.keys(devices);
		keys.sort((a, b) => {
			if (devices[a].index === undefined) {
				return 1;
			}
			if (devices[b].index === undefined) {
				return -1;
			}
			return devices[a].index - devices[b].index;
		});
		keys.forEach(key => {
			const deviceName = devices[key].n === undefined ? "" : devices[key].n;
			devices[key].s.forEach((meas, index) => {
				const searchQuantity = quantityFromTypeAndUnit(meas[0], meas[1]);
				const dataset = {
					id: uuid(),
					visible: true,
					x: [],
					y: [],
					ox: [],
					oy: [],
					type: "scattergl",
					mode: "lines",
					line: {
						width: 1,
						color: devices[key].c !== undefined && devices[key].c[index] && devices[key].c[index] !== "" ? devices[key].c[index] : undefined,
					},
					marker: {
						size: 2,
					},
					name: `${getCustomQuantity(index, devices[key].q)} [${meas[1]}]`,
					text: `${deviceName ?? deviceSettings[key]} ${getCustomQuantity(index, devices[key].q)} [${meas[1]}]`,
					hoverinfo: "x+y+text",
					measType: meas[0],
					measUnit: meas[1],
					customQuantity: getCustomQuantity(index, devices[key].q),
					legendgroup: (deviceName ?? deviceSettings[key]),
					legendgrouptitle: {
						text: `<b>${deviceName ?? deviceSettings[key]}</b>`,
					},
					deviceName,
					dsi: datasets.length,
					measi: index,
					deviceKey: key,
					yaxis: y2AxisQuantities[searchQuantity] ? "y2" : undefined,
				};
				datasets.push(dataset);
			});
		});
		const x = [];
		entries.forEach(entry => {
			datasets.forEach(ds => {
				if (
					datasets[ds.dsi] !== undefined && datasets[ds.dsi][`${target}y`] !== undefined
				) {
					if (entry[ds.deviceKey] !== undefined && entry[ds.deviceKey][ds.measi] !== undefined)
						datasets[ds.dsi][`${target}y`].push(entry[ds.deviceKey][ds.measi]);
					else
						datasets[ds.dsi][`${target}y`].push(null);
				}
			});
			x.push(entry.t * 1000);
		});
		datasets.forEach(ds => {
			datasets[ds.dsi][`${target}x`] = x;
		});
		// Check if first value is "null" change to null if it is
		if (datasets !== undefined && Array.isArray(datasets) && datasets[0] !== undefined && datasets[0].y !== undefined && datasets[0].y[0] === "null") {
			datasets[0].y[0] = null;
		}
		return datasets;
	};

	toggleComments = () => {
		const { showComments, setValues } = this.props;
		const update = remapToStatisticsView({
			showComments: !showComments
		});
		setValues(update);
		setTimeout(() => this.commentsRef.current.scrollIntoView({ behavior: "smooth" }), 200);
	};

	render() {
		const {
			customers, customerId, systems, selectedSystem, selectedProject, title, subtitle, xAxisTitle, xAxisStartTime, xAxisAbsoluteStartTime, xAxisEndTime, xAxisAbsoluteEndTime, xAxisTicks,
			xAxisTickZero, xAxisTicksDays, xAxisTicksHours, xAxisTicksMinutes, xAxisFilter, xAxisFilterStartTime, xAxisFilterEndTime, yAxisTitle, yAxisMax, yAxisMin, yAxisTicks, yAxisTickZero,
			y2AxisTitle, y2AxisMax, y2AxisMin, y2AxisQuantities, datasets, measTypes, selectedMeasType, devices, deviceSettings, lastUpdate, lang, reportId, reportName, nTh, avgNth, tempNth,
			fontSize, limits, selectedLimits, selectCustomer, settingsLoading, selectedDatasets, hideLegends, autoRangeTime, timeLoggingInt, timeLoggingMS, loading, dragmode, showComments,
			graphComments, username
		} = this.props;
		const sys = getSystems({ customers, customerId, systems });
		return (
			<div className="pageDefault">
				<div className="leftSidePanel alignMiranLogo">
					<LeftSidePanel
						customerId={customerId}
						systems={sys}
						selectedSystem={selectedSystem}
						projects={getProjects({ systems: sys, selectedSystem })}
						selectedProject={selectedProject}
						title={title}
						subtitle={subtitle}
						xAxisTitle={xAxisTitle}
						xAxisStartTime={xAxisStartTime}
						xAxisAbsoluteStartTime={xAxisAbsoluteStartTime}
						xAxisEndTime={xAxisEndTime}
						xAxisAbsoluteEndTime={xAxisAbsoluteEndTime}
						xAxisTicks={xAxisTicks}
						xAxisTickZero={xAxisTickZero}
						xAxisTicksDays={xAxisTicksDays}
						xAxisTicksHours={xAxisTicksHours}
						xAxisTicksMinutes={xAxisTicksMinutes}
						xAxisFilter={xAxisFilter}
						xAxisFilterStartTime={xAxisFilterStartTime}
						xAxisFilterEndTime={xAxisFilterEndTime}
						yAxisTitle={yAxisTitle}
						yAxisMax={yAxisMax}
						yAxisMin={yAxisMin}
						yAxisTicks={yAxisTicks}
						yAxisTickZero={yAxisTickZero}
						y2AxisTitle={y2AxisTitle}
						y2AxisMax={y2AxisMax}
						y2AxisMin={y2AxisMin}
						y2AxisQuantities={y2AxisQuantities}
						datasets={datasets}
						measTypes={measTypes}
						selectedMeasType={selectedMeasType}
						devices={devices}
						deviceSettings={deviceSettings}
						lastUpdate={lastUpdate}
						updateAncestor={this.updateData}
						lang={lang}
						reportId={reportId}
						reportName={reportName}
						storeReport={this.handleReportSave}
						deleteReport={this.handleReportDelete}
						nTh={nTh}
						avgNth={avgNth}
						tempNth={tempNth}
						fontSize={fontSize}
						limits={limits}
						selectedLimits={selectedLimits}
						isAuthorized={isAuthorized}
						customers={customers}
						selectCustomer={selectCustomer}
						settingsLoading={settingsLoading}
					/>
					<Spinner id="settingsSpinner" animation="border" style={{ display: settingsLoading ? "inline-block" : "none" }} />
				</div>
				<div className="rightSidePanel" >
					{datasets.length > 0 ?
						<Suspense fallback={<Spinner animation="border" />}>
							<Graph
								datasets={datasets}
								selectedDatasets={selectedDatasets}
								devices={devices}
								deviceSettings={deviceSettings}
								hideLegends={hideLegends}
								title={title}
								subtitle={subtitle}
								xAxisTitle={xAxisTitle}
								xAxisStartTime={xAxisStartTime}
								xAxisAbsoluteStartTime={xAxisAbsoluteStartTime}
								xAxisEndTime={xAxisEndTime}
								xAxisAbsoluteEndTime={xAxisAbsoluteEndTime}
								xAxisFilter={xAxisFilter}
								xAxisFilterStartTime={xAxisFilterStartTime}
								xAxisFilterEndTime={xAxisFilterEndTime}
								xAxisTicks={xAxisTicks}
								xAxisTickZero={xAxisTickZero}
								autoRangeTime={autoRangeTime}
								yAxisTitle={yAxisTitle}
								yAxisMax={yAxisMax}
								yAxisMin={yAxisMin}
								yAxisTicks={yAxisTicks}
								yAxisTickZero={yAxisTickZero}
								y2AxisTitle={y2AxisTitle}
								y2AxisMax={y2AxisMax}
								y2AxisMin={y2AxisMin}
								lastUpdate={lastUpdate}
								nTh={nTh}
								avgNth={avgNth}
								tempNth={tempNth}
								updateAncestor={this.updateData}
								timeLoggingInt={timeLoggingInt}
								timeLoggingMS={timeLoggingMS}
								lang={lang}
								fontSize={fontSize}
								limits={limits}
								selectedLimits={selectedLimits}
								loading={loading}
								dragmode={dragmode}
								showComments={showComments}
								toggleComments={this.toggleComments}
								graphComments={graphComments}
							/>
						</Suspense>
						:
						<div className="graph">
							<div className="no-data" style={{ display: loading ? "none" : "block" }}>
								<Lang lang={lang}>
									<span key="en">No data</span>
									<span key="fi">Ei dataa</span>
									<span key="sv">Ingen data</span>
								</Lang>
							</div>
							<div className="poweredby pushDown">Powered by <span className="name">Pietiko</span></div>
						</div>
					}
					<ProjectDataStatisticsDisplay />
					<Spinner id="graphSpinner" animation="border" style={{ display: loading ? "inline-block" : "none", left: lastUpdate === "realtimegraph" ? "calc(33vw - 2.5rem)" : "calc(50% - 2.5rem)" }} />
				</div>
				<div ref={this.commentsRef} className='rightSidePanel'>
					<GraphComments
						isAuthorized={isAuthorized}
						showComments={showComments}
						username={username}
						selectedSystem={selectedSystem}
						selectedProject={selectedProject}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { customerId, systems, customers, lang, userId, username,
		statisticsViewSelectedSystem, statisticsViewSelectedProject,
		statisticsViewTitle, statisticsViewSubtitle,
		statisticsViewDevices,
		statisticsViewXAxisTitle, statisticsViewXAxisStartTime, statisticsViewXAxisAbsoluteStartTime, statisticsViewXAxisEndTime, statisticsViewXAxisAbsoluteEndTime, statisticsViewXAxisTicks,
		statisticsViewXAxisTickZero, statisticsViewXAxisTicksDays, statisticsViewXAxisTicksHours, statisticsViewXAxisTicksMinutes,
		statisticsViewXAxisFilter, statisticsViewXAxisFilterStartTime, statisticsViewXAxisFilterEndTime,
		statisticsViewAutoRangeTime, statisticsViewLastUpdate,
		statisticsViewYAxisTitle, statisticsViewYAxisMax, statisticsViewYAxisMin,
		statisticsViewYAxisTicks, statisticsViewYAxisTickZero,
		statisticsViewY2AxisTitle, statisticsViewY2AxisMax, statisticsViewY2AxisMin, statisticsViewY2AxisQuantities,
		statisticsViewDatasets, statisticsViewSelectedDatasets, statisticsViewSelectedDatasetGroups,
		statisticsViewMeasTypes, statisticsViewSelectedMeasType, statisticsViewDevicesettings, statisticsViewHideLegends,
		statisticsViewTimeLoggingInt, statisticsViewTimeLoggingMS,
		statisticsViewNth, statisticsViewAvgNth, statisticsViewTempNth, statisticsViewFontSize, statisticsViewSystemsDetached,
		statisticsViewLimits, statisticsViewSelectedLimits, statisticsViewDragmode, statisticsViewLoading, statisticsViewLeftLoading,
		statisticsViewReportId, statisticsViewReportName, statisticsViewShowComments, statisticsViewComments,
	} = state;
	return {
		customerId, systems, customers, lang, userId, username,
		selectedSystem: statisticsViewSelectedSystem, selectedProject: statisticsViewSelectedProject,
		title: statisticsViewTitle, subtitle: statisticsViewSubtitle,
		devices: statisticsViewDevices,
		xAxisTitle: statisticsViewXAxisTitle, xAxisStartTime: statisticsViewXAxisStartTime, xAxisAbsoluteStartTime: statisticsViewXAxisAbsoluteStartTime, xAxisEndTime: statisticsViewXAxisEndTime, xAxisAbsoluteEndTime: statisticsViewXAxisAbsoluteEndTime, xAxisTicks: statisticsViewXAxisTicks,
		xAxisTickZero: statisticsViewXAxisTickZero, xAxisTicksDays: statisticsViewXAxisTicksDays, xAxisTicksHours: statisticsViewXAxisTicksHours, xAxisTicksMinutes: statisticsViewXAxisTicksMinutes,
		xAxisFilter: statisticsViewXAxisFilter, xAxisFilterStartTime: statisticsViewXAxisFilterStartTime, xAxisFilterEndTime: statisticsViewXAxisFilterEndTime,
		autoRangeTime: statisticsViewAutoRangeTime, lastUpdate: statisticsViewLastUpdate,
		yAxisTitle: statisticsViewYAxisTitle, yAxisMax: statisticsViewYAxisMax, yAxisMin: statisticsViewYAxisMin,
		yAxisTicks: statisticsViewYAxisTicks, yAxisTickZero: statisticsViewYAxisTickZero,
		y2AxisTitle: statisticsViewY2AxisTitle, y2AxisMax: statisticsViewY2AxisMax, y2AxisMin: statisticsViewY2AxisMin, y2AxisQuantities: statisticsViewY2AxisQuantities,
		datasets: statisticsViewDatasets, selectedDatasets: statisticsViewSelectedDatasets, selectedDatasetGroups: statisticsViewSelectedDatasetGroups,
		measTypes: statisticsViewMeasTypes, selectedMeasType: statisticsViewSelectedMeasType, deviceSettings: statisticsViewDevicesettings, hideLegends: statisticsViewHideLegends,
		timeLoggingInt: statisticsViewTimeLoggingInt, timeLoggingMS: statisticsViewTimeLoggingMS,
		nTh: statisticsViewNth, avgNth: statisticsViewAvgNth, tempNth: statisticsViewTempNth, fontSize: statisticsViewFontSize, systemsDetached: statisticsViewSystemsDetached,
		limits: statisticsViewLimits, selectedLimits: statisticsViewSelectedLimits, dragmode: statisticsViewDragmode, loading: statisticsViewLoading, settingsLoading: statisticsViewLeftLoading,
		reportId: statisticsViewReportId, reportName: statisticsViewReportName, showComments: statisticsViewShowComments, graphComments: statisticsViewComments,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

function StatisticsView(props) {
	const location = useLocation();
	return <StatisticsViewClass {...props} location={location} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsView);
