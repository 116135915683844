import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

export default class ProjectDropdown extends Component {
    getItems = (projects) => {
        const { selectedProject } = this.props;
        projects.sort((a, b) => {
            const order = a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true });
            switch (order) {
                case -1: {
                    return 1;
                }

                case 1: {
                    return -1;
                }

                default: {
                    return 0;
                }
            }
        });
        const items = [];
        if (Array.isArray(projects)) {
            projects.forEach(project => {
                let active = false;
                if (selectedProject !== undefined && selectedProject.id === project.id)
                    active = true;
                items.push(
                    <Dropdown.Item
                        key={`deviceSelection_${project.id}`}
                        eventKey={project.id}
                        active={active}
                    >
                        {project.name}
                    </Dropdown.Item>
                );
            });
        }
        return items;
    };

    handleSelect = (project) => {
        const { projects, updateAncestor } = this.props;
        const selectedProject = projects.find(element => element.id === project);
        updateAncestor({ selectedProject, settingsLoading: true, statisticsViewComments: undefined, statisticsViewShowComments: false });
    };

    render() {
        const { selectedSystem, className, selectedProject, projects } = this.props;
        const finalClassName = className ? ` ${className}` : "";
        if (selectedSystem !== undefined && selectedSystem.id !== undefined) {
            return (
                <span className={`projectSelectionWrapper${finalClassName}`}>
                    <div className="subtitle" >
                        <Lang en="Project:" fi="Projekti:" sv="Projekt:" />
                    </div>
                    <span className="dropdownFlexer scrollable-dropdown">
                        <DropdownButton
                            variant="default"
                            className="projectDropdown"
                            title={
                                selectedProject !== undefined && selectedProject.name !== undefined
                                    ? <span className="title">{selectedProject.name}</span>
                                    :
                                    <span className="title">
                                        <Lang en="Select..." fi="Valitse..." sv="Välj..." />
                                    </span>
                            }
                            id="deviceDropdown"
                            onSelect={this.handleSelect}
                        >
                            {this.getItems(projects)}
                        </DropdownButton >
                    </span>
                </span>
            );
        }
        return null;
    }
}