import "dayjs/locale/fi";

import dayjs from "dayjs";
import { createStore } from "redux";
import rfdc from "rfdc";

const rfdclone = rfdc();

export const LANGUAGE_NAMES = {
	"fi": "Suomi",
	"en": "English",
	"sv": "Svenska",
};

dayjs.locale("fi");

const savedLanguage = localStorage.getItem("language");

export const GENERAL_INIT = {
	rights: [],
	userId: undefined,
	username: "",
	systems: [],
	customerId: undefined,
	customers: {},
	projectsSome: undefined,
	lang: savedLanguage && ["fi", "en", "sv"].includes(savedLanguage) ? savedLanguage : "fi",
	loginState: null,
	loginUsername: "",
	login_invalid: null,
	userSettingsSelectedMenuItem: "infoMessages",
	newInfoMessages: false,
	infoMessages: [],
	usersShowModal: false,
	usersModalType: "new",
	usersSelectedUser: {
		userName: "",
		rights: [],
	},
	messageManagerEditorOpen: false,
	messageManagerEditId: undefined,
	messageManagerNewMessage: false,
	messageManagerEditorContent: "",
	messageManagerInfoMessages: [],
	customersLoading: false,
	customersShowModal: false,
	customersModalType: "new",
	customersSelectedCustomer: {
		maxUsers: 5,
		loginAllowed: 0,
		customerName: "",
	},
	setPasswordPassword: "",
	setPasswordPasswordVeri: "",
	setPasswordStatus: "neutral",
	setPasswordTime: 5,
	infoModalShow: false,
	infoModalMsg: null,
	infoModalTitle: null,
};

const STATISTICSVIEW_INIT = {
	statisticsViewSelectedSystem: { id: undefined, title: undefined },
	statisticsViewSelectedProject: { id: undefined, title: undefined },
	statisticsViewTitle: "",
	statisticsViewSubtitle: "",
	statisticsViewDevices: [],
	statisticsViewXAxisTitle: "",
	statisticsViewXAxisStartTime: dayjs().subtract(1, "days").toDate(),
	statisticsViewXAxisAbsoluteStartTime: undefined,
	statisticsViewXAxisEndTime: dayjs().toDate(),
	statisticsViewXAxisAbsoluteEndTime: undefined,
	statisticsViewXAxisTicks: 0,
	statisticsViewXAxisTickZero: null,
	statisticsViewXAxisTicksDays: 0,
	statisticsViewXAxisTicksHours: 0,
	statisticsViewXAxisTicksMinutes: 0,
	statisticsViewXAxisFilter: false,
	statisticsViewXAxisFilterStartTime: undefined,
	statisticsViewXAxisFilterEndTime: undefined,
	statisticsViewAutoRangeTime: true,
	statisticsViewLastUpdate: "statisticsView",
	statisticsViewYAxisTitle: "",
	statisticsViewYAxisMax: 0,
	statisticsViewYAxisMin: 0,
	statisticsViewYAxisTicks: 0,
	statisticsViewYAxisTickZero: null,
	statisticsViewY2AxisTitle: "",
	statisticsViewY2AxisMax: 0,
	statisticsViewY2AxisMin: 0,
	statisticsViewY2AxisQuantities: {},
	statisticsViewDatasets: [],
	statisticsViewSelectedDatasets: [],
	statisticsViewSelectedDatasetGroups: [],
	statisticsViewSelectedMeasType: undefined,
	statisticsViewMeasTypes: [],
	statisticsViewDevicesettings: {},
	statisticsViewHideLegends: false,
	statisticsViewTimeLoggingInt: 0,
	statisticsViewTimeLoggingMS: dayjs().valueOf(),
	statisticsViewNth: 20,
	statisticsViewAvgNth: null,
	statisticsViewTempNth: {},
	statisticsViewFontSize: 2,
	statisticsViewSystemsDetached: false,
	statisticsViewLimits: undefined,
	statisticsViewSelectedLimits: [],
	statisticsViewDragmode: undefined,
	statisticsView_projects: [],
	statisticsViewLoading: false,
	statisticsViewLeftLoading: false,
	statisticsViewReportId: undefined,
	statisticsViewReportName: undefined,
	statisticsViewProjectDataStatistics: undefined,
	statisticsViewRequestedCalcualtion: {
		customerId: undefined,
		systemId: undefined,
		projectId: undefined,
		start: dayjs().subtract(1, "days").toDate(),
		end: dayjs().toDate(),
		timeFilter: {
			filterStart: undefined,
			filterEnd: undefined,
		},
	},
	statisticsView_dndBackend: undefined,
	statisticsViewShowComments: false,
	statisticsViewComments: undefined,
};

const batLow = "Paristojännitteen alaraja";

const SYSTEMS_INIT = {
	systemsShowModal: false,
	systemsModalType: "new",
	systemsSelectedMenuSystem: { systemName: "" },
	systemsDatasets: [
		{
			id: batLow,
			x: [dayjs().subtract(1, "days").format(), dayjs().format()],
			y: [3.2, 3.2],
			yaxis: undefined,
			type: "scattergl",
			mode: "lines",
			deviceName: batLow,
			measType: "",
			measUnit: "V",
			name: batLow,
			text: batLow,
			hoverinfo: "x+y+text",
			deviceKey: batLow,
			measi: 1,
			line: {
				width: 1,
				color: "#bf2303",
			},
			marker: {
				size: 2,
			},
		}
	],
	systemsStatuses: [],
	systemsLastTimeOnline: undefined,
	systemsSelectedSystem: {},
	systemsCurrentProject: { id: undefined, name: "-" },
	systemsAutoRangeTime: true,
	systemsXAxisStartTime: dayjs().subtract(1, "months").format(),
	systemsXAxisAbsoluteStartTime: undefined,
	systemsXAxisEndTime: dayjs().format(),
	systemsXAxisAbsoluteEndTime: undefined,
	systemsDeviceSettings: {},
	systemsHideLegends: false,
	systemsInit: false,
	systemsDragmode: undefined,
	systemsShowspikes: false,
	systemsHovermode: "closest",
	systemsSelectedFunction: "config",
	systemsConfigTimeZone: { id: "EET-2EEST,M3.5.0/3,M10.5.0/4", title: { en: "Finland - summer time", fi: "Suomi – kesäaika käytössä", sv: "Finland - sommartid" } },
	systemsConfigRecordingInterval: 0,
	systemsConfigStartupDelay: 0,
	systemsConfigSMSActive: null,
	systemsConfigEmailActive: null,
	systemsConfigBeepActive: null,
	systemsConfigExternalBeepActive: null,
	systemsConfigEmailHeader: "",
	systemsConfigShowEmails: false,
	systemsConfigEmails: [],
	systemsConfigShowNumbers: false,
	systemsConfigSMSNumbers: [],
	systemsConfigWirelessLinkActive: null,
	systemsConfigPowerDropActive: null,
	systemsConfigNodeConfig: null,
	systemsConfigSystemInfo: undefined,
	systemsConfigDeviceData: {},
	systemsConfigCorruptedData: "",
	systemsConfigSubmitEnabled: undefined,
	systemsConfigSensorTypes: null,
	systemsLoading: false,
	systemsConfigShowAddLoggerModal: false,
	systemsConfigAddLoggerId: "",
	systemsConfigAddLoggerName: "",
	systemsConfigRemovedLoggers: [],
	systemsConfigAddLoggers: [],
	systemsConfig_showLoggerRemoveModal: false,
	systemsConfigRemoveLoggerId: undefined,
	systemsConfigRemoveLoggerName: "",
	systemsConfigLastLoggerStateStatus: undefined,
	systemConfigLoggerCommand: "",
	commandHistory: [],
};

const SETTINGS_INIT = {
	settingsSelectedSystem: { id: undefined, title: undefined },
	settingsSelectedProject: { id: undefined, title: undefined },
	settingsSelectedSetting: { id: undefined, title: undefined },
	settingsLoading: false,
	settingsNewProjectInvalid: undefined,
};

const BLUEPRINT_INIT = {
	blueprintSelectedSystem: { id: undefined, title: undefined },
	blueprintSelectedProject: { id: undefined, title: undefined },
	blueprintSelectedBlueprint: undefined,
	blueprintBlueprints: [],
	blueprintBlueprintLoggers: [],
	blueprint_loading: false,
};

const ACTIONS = {
	"SET_VAL": ({ state, action, newState }) => {
		if (action.prop !== undefined)
			return { ...newState, [action.prop]: action.val };
		return state;
	},
	"SET_VALUES": ({ state, action, newState }) => {
		if (action.obj) {
			const CUSTOMER_CHANGE_INIT = action.obj.customerId !== undefined && action.obj.customerId !== state.customerId ? ({ ...STATISTICSVIEW_INIT, ...SYSTEMS_INIT, ...SETTINGS_INIT, ...BLUEPRINT_INIT }) : {};
			return { ...newState, ...action.obj, ...CUSTOMER_CHANGE_INIT };
		}
		return state;
	},
	"SET_LANG": ({ state, action, newState }) => {
		if (action.val !== undefined && LANGUAGE_NAMES[action.val]) {
			localStorage.setItem("language", action.val);
			return { ...newState, lang: action.val };
		}
		return state;
	},
};

export const REDUX_INIT_STATE = { ...GENERAL_INIT, ...STATISTICSVIEW_INIT, ...SYSTEMS_INIT, ...SETTINGS_INIT, ...BLUEPRINT_INIT };

const reducer = (state = REDUX_INIT_STATE, action = {}) => {
	const newState = rfdclone(state);
	if (ACTIONS[action.type]) {
		return ACTIONS[action.type]({ state, action, newState });
	}
	return state;
};

export default createStore(reducer);
