import "./RecordingInterval.css";

import { connect } from "react-redux";

import isAuthorized from "../../../utilities/authorization";
import Lang from "../../LanguageSelect/Lang";
import Timeselector from "./Timeselector/Timeselector";

const onChange = (value, setValues) => {
	setValues({ systemsConfigRecordingInterval: value });
};

function RecordingInterval({ editAuth, setValues, systemsConfigRecordingInterval }) {
	return (
		<span className="setting recording-interval-selector separated-alignment-parent">
			<span className="title separated-alignment-left">
				<Lang en="Recording interval" fi="Tallennusaikaväli" sv="Lagringsintervall" />
			</span>
			<span className="time-selector separated-alignment-right">
				<Timeselector
					disabled={!editAuth}
					onChange={(value) => onChange(value, setValues)}
					hours minutes
					seconds={!!isAuthorized({ one: ["super"] })}
					value={systemsConfigRecordingInterval}
				/>
			</span>
		</span>
	);
}

function mapStateToProps(state) {
	const { systemsConfigRecordingInterval } = state;
	return { systemsConfigRecordingInterval };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordingInterval);