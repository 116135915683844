import { Component } from "react";
import { Form } from "react-bootstrap";

const handleFocus = (e) => {
    e.target.select();
};

export default class TextField extends Component {
    state = {
        // eslint-disable-next-line react/destructuring-assignment
        copy: this.props.content,
        detached: false,
    };

    getValue() {
        const { realtimeUpdate, content } = this.props;
        const { detached, copy } = this.state;
        // If live chagnes
        if (realtimeUpdate) {
            return content === undefined ? "" : content;
            // If blur changes
        }
        if (detached)
            return copy === undefined ? "" : copy;
        return content === undefined ? "" : content;

    }

    updateAncestorFunc = (data) => {
        const { propertyName, updateAncestor } = this.props;
        if (propertyName) {
            updateAncestor({ [propertyName]: data });
        }
    };

    handleChange = (e) => {
        const { realtimeUpdate } = this.props;
        if (e && e.target && typeof e.target.value === "string") {
            const { value } = e.target;
            if (realtimeUpdate)
                {this.updateAncestorFunc(value);}
            else
                {this.setState({ copy: value, detached: true });}
        }
    };

    handleBlur = (e) => {
        const { realtimeUpdate } = this.props;
        if (e && e.target && typeof e.target.value === "string" && !realtimeUpdate) {
            const { value } = e.target;
            this.setState({ detached: false }, () => {
                this.updateAncestorFunc(value);
            });
        }
    };

    render() {
        const { type, className, title, size, maxLength, autoComplete, validation } = this.props;
        let finalType = "text";
        if (type) {
            finalType = type;
        }
        const finalClassName = className === undefined ? "" : (` ${className}`);
        return (
            <div className={`textField${finalClassName}`}>
                <div className="title">{title}</div>
                <Form.Group>
                    <Form.Control
                        className="smallTextField"
                        size={size}
                        type={finalType}
                        placeholder=""
                        value={this.getValue()}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={handleFocus}
                        maxLength={maxLength === undefined ? "524288" : maxLength}
                        autoComplete={autoComplete === undefined ? "on" : autoComplete}
                        isInvalid={validation !== null}
                    />
                </Form.Group>
            </div>
        );
    }
}

TextField.defaultProps = {
    title: "",
};
