import QuantitySideTarget from "./QuantitySideTarget";

export default function SideSelection({ datasets, y2AxisQuantities, updateData }) {
	return (
		<div className="sideSelectionColumns">
			<QuantitySideTarget
				datasets={datasets}
				y2AxisQuantities={y2AxisQuantities}
				side="left"
				updateData={updateData}
			/>
			<QuantitySideTarget
				datasets={datasets}
				y2AxisQuantities={y2AxisQuantities}
				side="right"
				updateData={updateData}
			/>
		</div>
	);
}