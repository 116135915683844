import dayjs from "dayjs";
import { Table } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

const getCharging = (statuses) => {
    if (Array.isArray(statuses) && statuses.length > 0) {
        if (statuses[statuses.length - 1].charging) {
            return (
                <Lang en="Charging" fi="Kytketty" sv="Laddar" />
            );
        }
        if (statuses[0].charging !== undefined) {
            return (
                <Lang en="Not charging" fi="Ei kytketty" sv="Laddar inte" />
            );
        }
    }
    return "-";
};

export default function ViewSystemBasicInfo({ loading, system, projectName, lastTimeOnline, statuses }) {
    return (
        <div className="system-current-status cardBackground" style={{ opacity: loading ? "25%" : "100%" }}>
            <Table id="table" size="sm">
                <thead>
                    <tr>
                        <th>
                            <Lang en="System" fi="Järjestelmä" sv="System" />
                        </th>
                        <th>
                            <Lang en="Current project" fi="Nykyinen projekti" sv="Nuvarande projekt" />
                        </th>
                        <th>
                            <Lang en="Last online" fi="Viimeksi online" sv="Senast online" />
                        </th>
                        <th>
                            <Lang en="Charger" fi="Laturi" sv="Laddare" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{system !== undefined && system.systemName !== undefined ? system.systemName : "-"}</td>
                        <td>{projectName === undefined ? "-" : projectName}</td>
                        <td>{lastTimeOnline === undefined ? "-" : dayjs(lastTimeOnline * 1000).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{getCharging(statuses)}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}
