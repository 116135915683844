import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

const getTitle = ({ systems, systemId, lang }) => {
    if (systems !== undefined && systemId !== undefined) {
        const index = systems.findIndex((x) => x.id === systemId);
        if (index > -1) {
            return systems[index].systemName;
        }
    }
    if (lang !== undefined) {
        return (
            <Lang lang={lang}>
                <span className="title" key="en">Select...</span>
                <span className="title" key="fi">Valitse...</span>
                <span className="title" key="sv">Välj...</span>
            </Lang>
        );
    }
    return "?";
};

const getItems = ({ systems, systemId }) => {
    let options = [];
    if (systems !== undefined) {
        options = systems.map(system => (
            <Dropdown.Item
                key={`option_${system.id}`}
                eventKey={system.id}
                active={system.id === systemId}
            >
                {system.systemName}
            </Dropdown.Item>
        ));
    }
    return options;
};

export default class SystemsSelector extends Component {
    handleSelect = (eventKey) => {
        const { selectSystem } = this.props;
        if (selectSystem !== undefined)
            selectSystem({ systemId: eventKey });
    };

    render() {
        const { systems, systemId } = this.props;
        if (systems !== undefined && systems.length > 0) {
            return (
                <div className="systemDropdown">
                    <span className="dropdownFlexer scrollable-dropdown">
                        <DropdownButton
                            id="systemDropdown"
                            variant="default"
                            title={
                                <span className="title">{getTitle(this.props)}</span>
                            }
                            onSelect={this.handleSelect}
                        >
                            {getItems({ systems, systemId })}
                        </DropdownButton >
                    </span>
                </div>
            );
        }
        return null;
    }
}