import "./ConfigSaveButton.css";

import { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import InfoModal from "../InfoModal";
import Lang from "../LanguageSelect/Lang";

const commaSeparted = (arrProp) => {
	if (Array.isArray(arrProp)) {
		let arr = arrProp;
		arr = arr.filter(Boolean);
		const LAST_INDEX = arr.length - 1;
		// eslint-disable-next-line unicorn/no-array-reduce
		return arr.reduce((res, x, i) => {
			let resCopy = res;
			resCopy += i === LAST_INDEX ? x : `${x},`;
			return resCopy;
		}, "");
	}
	return "";
};

class ConfigSaveButton extends Component {
	static POLLTIMEOUT = null;

	constructor() {
		super();

		this.timeoutSet = false;
	}

	componentDidUpdate(prevProps) {
		const { systemsSelectedSystem, systemsSelectedFunction, setValues } = this.props;
		if (
			systemsSelectedSystem &&
			(this.POLLTIMEOUT === undefined || (prevProps.systemsSelectedSystem && systemsSelectedSystem.id !== prevProps.systemsSelectedSystem.id)) &&
			systemsSelectedSystem.id !== "all" && systemsSelectedFunction === "config"
		) {
			if (systemsSelectedSystem.id !== prevProps.systemsSelectedSystem.id) {
				clearInterval(this.POLLTIMEOUT);
				this.POLLTIMEOUT = undefined;
				this.timeoutSet = false;
				setValues({ systemsConfigSubmitEnabled: false });
			}
			// Initiating the system settings que polling
			if (!this.timeoutSet) {
				this.pollSettingsQue();
				this.timeoutSet = true;
			}
		}
		if ((!systemsSelectedSystem || systemsSelectedSystem.id === "all" || systemsSelectedFunction !== "config")) {
			clearInterval(this.POLLTIMEOUT);
			this.POLLTIMEOUT = undefined;
			this.timeoutSet = false;
			setValues({ systemsConfigSubmitEnabled: false });
		}
	}

	componentWillUnmount() {
		clearInterval(this.POLLTIMEOUT);
		this.POLLTIMEOUT = undefined;
		this.timeoutSet = false;
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	pollSettingsQue = () => {
		const { customerId, systemsSelectedSystem, systemsConfigSubmitEnabled, setValues } = this.props;
		if (window.location.pathname === "/systems") {
			const payload = { customerId, systemId: systemsSelectedSystem.id };
			const callback = (data) => {
				if (data.state === "ok") {
					if (systemsConfigSubmitEnabled !== true) {
						toast.success(<Lang en="System information updated [OK]" fi="Järjestelmän tiedot päivitetty [OK]" sv="System information updaterats [OK]" />, { autoClose: 20_000, toastId: "system-data-ok", });
						setValues({ systemsConfigSubmitEnabled: true });
					}
				} else if (data.state === "pending") {
					if (systemsConfigSubmitEnabled !== false) {
						setValues({ systemsConfigSubmitEnabled: false });
					}
				} else {
					toast.error(<Lang en="An error occurred when fetching system settings queue" fi="Virhe uuden järjestelmäasetusten jonon haussa" sv="Fel vid sökning av system inställningarnas kö" />);
				}
				if (this.POLLTIMEOUT === undefined) {
					this.POLLTIMEOUT = setInterval(() => this.pollSettingsQue(), 60_000);
				}
			};
			Messenger.requestSystemConfigState({ payload, callback });
		} else {
			clearInterval(this.POLLTIMEOUT);
			this.POLLTIMEOUT = undefined;
			this.timeoutSet = false;
		}
	};

	handleSave = () => {
		const {
			setValues, customerId, systemsSelectedSystem, systemsConfigTimeZone, systemsConfigRecordingInterval, systemsConfigStartupDelay, systemsConfigSMSActive,
			systemsConfigSMSNumbers, systemsConfigBeepActive, systemsConfigPowerDropActive, systemsConfigEmailActive, systemsConfigEmails, systemsConfigEmailHeader,
			systemsConfigNodeConfig, systemsConfigSystemInfo, systemsConfigWirelessLinkActive, systemsConfigExternalBeepActive
		} = this.props;
		const callback = (data) => {
			if (data.status === "success") {
				setValues({
					infoModalShow: true,
					infoModalMsg: <Lang
						en="It might take up to 1-10 minutes for changes to take effect at system level"
						fi="Saattaa kestää 1-10 minuuttia että muutokset tulevat voimaan järjestelmätasolla"
						sv="Det kan ta till och med 1-10 minuter för ändringarna att träda i kraft på system nivå"
					/>,
					infoModalTitle: <Lang
						en="Settings updated"
						fi="Asetukset päivitetty"
						sv="Inställningarna uppdaterades"
					/>
				});
			} else {
				toast.error(<Lang en="Failed to update settings" fi="Asetuksien päivitys epäonnistui" sv="Uppdateringen av inställningarna misslyckades" />);
			}
			setValues({ systemsConfigSubmitEnabled: false });
		};
		const payload = {
			customerId,
			systemId: systemsSelectedSystem.id,
			device: { tz: systemsConfigTimeZone.id },
			log: { period: systemsConfigRecordingInterval / 1000, bootdelay: systemsConfigStartupDelay / 1000 },
			alarm: {
				sms_on: systemsConfigSMSActive, sms: commaSeparted(systemsConfigSMSNumbers),
				beep_on: systemsConfigBeepActive, pwr_on: systemsConfigPowerDropActive,
				email_on: systemsConfigEmailActive, email: commaSeparted(systemsConfigEmails),
				title: systemsConfigEmailHeader,
			},
			loggers: systemsConfigNodeConfig,
		};
		if (systemsConfigSystemInfo && (systemsConfigSystemInfo.deviceType === "APw" || systemsConfigSystemInfo.deviceType === "APw+")) {
			payload.alarm.node_on = systemsConfigWirelessLinkActive;
		}
		if (systemsConfigSystemInfo && (systemsConfigSystemInfo.deviceType === "DL-P1" || systemsConfigSystemInfo.deviceType === "DL-P2")) {
			payload.alarm.ext_beep_on = systemsConfigExternalBeepActive;
		}
		Messenger.requestSetSystemConfig({ payload, callback: callback.bind(this) });
	};

	render() {
		const { scrollBottom, editAuth, systemsConfigSubmitEnabled } = this.props;
		return (
			<div className="systems-config-save-button">
				<Button
					className={`config-button popup${scrollBottom ? " hide" : " sticky"}`}
					disabled={!editAuth || !systemsConfigSubmitEnabled}
					variant="success"
					onClick={this.handleSave}
				>
					<Lang en="Set" fi="Aseta" sv="Ställ in" />
				</Button>
				<Button
					className={`config-button${scrollBottom ? "" : " opacity-0"}`}
					disabled={!editAuth || !systemsConfigSubmitEnabled || !scrollBottom}
					variant="success"
					onClick={this.handleSave}
				>
					<Lang en="Set" fi="Aseta" sv="Ställ in" />
				</Button>
				<InfoModal />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { customerId, systemsSelectedSystem, systemsSelectedFunction,
		systemsConfigTimeZone, systemsConfigRecordingInterval, systemsConfigStartupDelay,
		systemsConfigSMSActive, systemsConfigEmailActive, systemsConfigBeepActive, systemsConfigExternalBeepActive,
		systemsConfigEmailHeader, systemsConfigShowEmails, systemsConfigEmails, systemsConfigShowNumbers, systemsConfigSMSNumbers,
		systemsConfigWirelessLinkActive, systemsConfigPowerDropActive, systemsConfigNodeConfig, systemsConfigSensorTypes,
		systemsConfigSystemInfo, systemsConfigSubmitEnabled
	} = state;
	return {
		customerId, systemsSelectedSystem, systemsSelectedFunction,
		systemsConfigTimeZone, systemsConfigRecordingInterval, systemsConfigStartupDelay,
		systemsConfigSMSActive, systemsConfigEmailActive, systemsConfigBeepActive, systemsConfigExternalBeepActive,
		systemsConfigEmailHeader, systemsConfigShowEmails, systemsConfigEmails, systemsConfigShowNumbers, systemsConfigSMSNumbers,
		systemsConfigWirelessLinkActive, systemsConfigPowerDropActive, systemsConfigNodeConfig, systemsConfigSensorTypes,
		systemsConfigSystemInfo, systemsConfigSubmitEnabled
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSaveButton);
