import "../../css/CardBackground.css";
import "./ProjectSelections.css";
import "../../css/scrollableDropdown.css";

import { Accordion, Card } from "react-bootstrap";

import TextField from "../GenericMenuComponents/TextField";
import Lang from "../LanguageSelect/Lang";
import GraphCalcButton from "../StatisticsCalc/GraphCalc";
import ExportModalButton from "./ExportModal";
import ProjectDropdown from "./ProjectDropdown";
import SystemDropdown from "./SystemDropdown";

export default function ProjectTitles({ selectedProject, lang, title, updateData, subtitle }) {
	return selectedProject !== undefined && selectedProject.id !== undefined ? (
		<div className="projectTitles">
			<Accordion
				id="accordianPanel"
				className="menuPanel titlePanel"
			>
				<Accordion.Button as={Card.Header}>
					<Lang lang={lang}>
						<div key="en">Title</div>
						<div key="fi">Otsikko</div>
						<div key="sv">Rubrik</div>
					</Lang>
				</Accordion.Button>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<TextField
							title={
								<Lang lang={lang}>
									<span key="en">Title:</span>
									<span key="fi">Otsikko:</span>
									<span key="sv">Rubrik:</span>
								</Lang>
							}
							content={title}
							updateAncestor={updateData}
							propertyName="title"
							validation={null}
						/>
						<TextField
							title={
								<Lang lang={lang}>
									<span key="en">Subtitle:</span>
									<span key="fi">Aliotsikko:</span>
									<span key="sv">Underrubrik:</span>
								</Lang>
							}
							content={subtitle}
							updateAncestor={updateData}
							propertyName="subtitle"
							validation={null}
						/>
					</Card.Body>
				</Accordion.Collapse>
			</Accordion >
		</div>
	) : null;
}

export function MainSelector({ lang, systems, selectedSystem, updateAncestor, customerId, projects, selectedProject, xAxisStartTime, xAxisEndTime, blueprints, alarms, xAxisAbsoluteStartTime, xAxisAbsoluteEndTime }) {
	return (
		<Accordion className="mainSelector cardBackground" defaultActiveKey="0">
			<Card id="accordianPanel" className="menuPanelStatic projectPanel">
				<Lang lang={lang}>
					<Card.Header key="en">Project</Card.Header>
					<Card.Header key="fi">Projekti</Card.Header>
					<Card.Header key="sv">Projekt</Card.Header>
				</Lang>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<SystemDropdown
							systems={systems}
							selectedSystem={selectedSystem}
							updateAncestor={updateAncestor}
						/>
						<ProjectDropdown
							customerId={customerId}
							projects={projects}
							selectedSystem={selectedSystem}
							selectedProject={selectedProject}
							lang={lang}
							xAxisStartTime={xAxisStartTime}
							xAxisEndTime={xAxisEndTime}
							updateAncestor={updateAncestor}
						/>
						{selectedSystem !== undefined && selectedSystem.id !== undefined && !blueprints && !alarms
							? <span className="qualityWrapper">
								<div className="export-count-buttons">
									<ExportModalButton
										customerId={customerId}
										selectedSystem={selectedSystem}
										selectedProject={selectedProject}
										xAxisStartTime={xAxisStartTime}
										xAxisAbsoluteStartTime={xAxisAbsoluteStartTime}
										xAxisEndTime={xAxisEndTime}
										xAxisAbsoluteEndTime={xAxisAbsoluteEndTime}
										lang={lang}
									/>
									<GraphCalcButton />
								</div>
							</span>
							: null}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
}

export function MainSelectorDropdownsOnly({ lang, systems, selectedSystem, updateAncestor, projects, selectedProject }) {
	return (
		<div className="mainSelector">
			<Card
				defaultActiveKey="0"
				id="accordianPanel"
				className="menuPanelStatic projectPanel"
			>
				<Card.Header>
					<Card.Title>
						<Lang lang={lang}>
							<div key="en">Project</div>
							<div key="fi">Projekti</div>
							<div key="sv">Projekt</div>
						</Lang>
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<SystemDropdown
						systems={systems}
						selectedSystem={selectedSystem}
						updateAncestor={updateAncestor}
					/>
					<ProjectDropdown
						projects={projects}
						selectedSystem={selectedSystem}
						selectedProject={selectedProject}
						lang={lang}
						updateAncestor={updateAncestor}
						noExport
					/>
				</Card.Body>
			</Card>
		</div>
	);
}