import { Accordion, Card, Nav } from "react-bootstrap";

import isAuthorized from "../../utilities/authorization";
import CustomerSelect from "../CustomerSelect";
import Lang from "../LanguageSelect/Lang";
import SystemDropdown from "../ProjectSelections/SystemDropdown";

const OPTIONS = [
	{
		id: "projects",
		title: [
			<span className="title" key="en">Projects</span>,
			<span className="title" key="fi">Projektit</span>,
			<span className="title" key="sv">Projekt</span>
		]
	},
	{
		id: "reportcopy",
		title: [
			<span className="title" key="en">Project settings copy</span>,
			<span className="title" key="fi">Projektin asetusten kopiointi</span>,
			<span className="title" key="sv">Project inställningar kopia</span>
		],
		authorized: { one: ["reports"] }
	}

];

const getNavLinks = (lang, setActiveNav) => OPTIONS.map((option) => option.authorized === undefined || isAuthorized(option.authorized) ?
	<Nav.Item key={option.id}>
		<Nav.Link eventKey={option.id} onClick={() => setActiveNav(option.id)}>
			<Lang lang={lang}>
				{option.title}
			</Lang>
		</Nav.Link>
	</Nav.Item>
	:
	<div />);


export default function ProjectsLeftsidePanel({ customerId, selectedSystem, updateAncestor, lang, customers, selectCustomer, systems, setActiveNav, activeNav }) {
	return (
		<div>
			<CustomerSelect
				customers={customers}
				customerId={customerId}
				selectCustomer={selectCustomer}
				lang={lang}
				checkProjects
			/>
			<div className="mainSelector cardBackground">
				<Accordion
					defaultActiveKey="0"
					id="accordianPanel"
					className="menuPanelStatic"
				>
					<Card>
						<Card.Header>
							<Lang lang={lang}>
								<div key="en">Systems</div>
								<div key="fi">Järjestelmät</div>
								<div key="sv">System</div>
							</Lang>
						</Card.Header>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<SystemDropdown
									systems={systems}
									selectedSystem={selectedSystem}
									updateAncestor={updateAncestor}
								/>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			</div>
			{selectedSystem !== undefined && selectedSystem.id !== undefined ?
				<Nav fill variant="tabs" activeKey={activeNav} defaultActiveKey="projects" className="flex-column">
					{getNavLinks(lang, setActiveNav)}
				</Nav>
				:
				null
			}
		</div>
	);
};