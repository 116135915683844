import fi from "date-fns/locale/fi";
import dayjs from "dayjs";
import { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

registerLocale("fi", fi);

export default class TimePicker extends Component {
    componentDidCatch() {
        const { updateAncestor, propertyName } = this.props;
        if (updateAncestor) {
            let update = [];
            update = { [propertyName]: undefined };
            updateAncestor(update);
        }
    }

    updateAncestor = (obj) => {
        const { updateAncestor } = this.props;
        if (updateAncestor) {
            updateAncestor(obj);
        }
    };

    handleChange = (value) => {
        const { propertyName } = this.props;
        if (propertyName && dayjs(value).isValid()) {
            let update = [];
            update = { [propertyName]: dayjs(value).toDate() };
            this.updateAncestor(update);
        } else {
            console.error("Invalid date value:", value)
        }
    };

    render() {
        const { className, title, value, disabled } = this.props;
        return (
            <div className={`dateTimepicker ${className === undefined ? "" : className}`}>
                {title === undefined ? null : <div className="title">{title}</div>}
                <DatePicker
                    selected={value === undefined ? null : new Date(value)}
                    onChange={(date) => this.handleChange(date)}
                    showTimeInput
                    showTimeSelectOnly
                    timeInputLabel={false}
                    dateFormat="HH:mm"
                    locale="fi"
                    className="smallTextField form-control form-control-sm"
                    disabled={disabled}
                />
            </div>
        );
    }
}
