import TokenHandler from "../../utilities/tokenHandler";

async function customFetch(url, method, body = null, json = true) {
	const headers = {
		"Authorization": TokenHandler.getCurrentToken(),
		"Content-Type": "application/json"
	};
	const options = {
		method,
		headers,
		body
	};
	try {
		const res = await fetch(url, options);
		if (!res.ok) {
			throw new Error("Error loading data.");
		}
		return await (json ? res.json() : res.text());
	} catch (error) {
		throw new Error(error);
	}
}

class BlueprintClient {
	// eslint-disable-next-line class-methods-use-this
	async requestBlueprints(projectId, systemId, customerId) {
		const url = `/api/blueprint/list?projectId=${projectId}&systemId=${systemId}&customerId=${customerId}`;
		const data = await customFetch(url, "GET");
		return data.blueprints;
	}

	// eslint-disable-next-line class-methods-use-this
	async getLoggersForBlueprint(projectId, systemId, customerId) {
		const url = `/api/blueprint/loggers?systemId=${systemId}&projectId=${projectId}&customerId=${customerId}`;
		const data = await customFetch(url);
		return data.loggers;
	}

	// eslint-disable-next-line class-methods-use-this
	uploadBlueprint(image, projectId, systemId, customerId, blueprintName, blueprintDescription) {
		const formdata = new FormData();
		formdata.set("projectId", projectId);
		formdata.set("systemId", systemId);
		formdata.set("customerId", customerId);
		formdata.set("image", image);
		formdata.set("name", blueprintName);
		formdata.set("description", blueprintDescription);
		return new Promise((resolve, reject) => {
			fetch("/api/blueprint/upload", {
				method: "POST",
				body: formdata,
				headers: {
					"Authorization": TokenHandler.getCurrentToken() 
				}
			})
				.then(res => res.json())
				// eslint-disable-next-line promise/always-return
				.then(data => {
					resolve(data.blueprint);
				}).catch(error => {
					reject(error);
			});
		});
	}
	  
	// eslint-disable-next-line class-methods-use-this
	updateBlueprint(blueprint) {
		const body = JSON.stringify({
			"name": blueprint.name,
			"description": blueprint.description,
			"rotation": blueprint.rotation,
			"locked": blueprint.locked
		});
		return customFetch(`/api/blueprint/${blueprint.uuid}`, "PUT", body, false);
	}

	// eslint-disable-next-line class-methods-use-this
	attachLoggerToBlueprint(loggerId, blueprintUuid, x, y, dot) {
		const body = JSON.stringify({
			"logger_id": loggerId,
			"blueprint_uuid": blueprintUuid,
			"x": x,
			"y": y,
			"dot": dot
		});
		return customFetch("/api/blueprint/attach", "POST", body, false);
	}

	// eslint-disable-next-line class-methods-use-this
	detachLoggerFromBlueprint(loggerId, blueprintUuid) {
		const body = JSON.stringify({
			"logger_id": loggerId,
			"blueprint_uuid": blueprintUuid
		});
		return customFetch("/api/blueprint/detach", "POST", body, false);
	}

	// eslint-disable-next-line class-methods-use-this
	deleteBlueprint(blueprintUuid) {
		return customFetch(`/api/blueprint/${blueprintUuid}`, "DELETE", null, false);
	}
}

export default new BlueprintClient();