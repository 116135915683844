import { Component } from "react";
import { Form } from "react-bootstrap";

const handleFocus = (e) => {
	e.target.select();
};

export default class MetadataNumberField extends Component {
	state = {
		// eslint-disable-next-line react/destructuring-assignment
		copy: this.props.value,
		detached: false,
	};

	updateAncestor = (obj) => {
		const { updateAncestor } = this.props;
		updateAncestor(obj);
	};

	handleChange = (e) => {
		this.setState({ copy: e.target.value, detached: true });
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleBlur = (e) => {
		const { metaData, decimals, max, maxProperty, min, minProperty } = this.props;
		if (e.target.value === "") {
			// Updating ancestor with new value if tests are passed
			this.setState({ detached: false }, () => {
				this.updateAncestor({ value: null, metaData });
			});
		} else {
			const newValue = Number(e.target.value.replace(",", "."));
			if (!Number.isNaN(newValue) && typeof newValue === "number") {
				const update = { value: newValue, metaData };
				if (decimals !== undefined)
					update.value = Math.round(update.value * (10 ** decimals)) / (10 ** decimals);
				// Checking optional limits
				if (max !== undefined && newValue > max) {
					if (maxProperty) {
						update[maxProperty] = newValue + 1;
					} else {
						this.setState({ detached: false });
					}
				}
				if (min !== undefined && newValue < min) {
					if (minProperty) {
						update[minProperty] = newValue - 1;
					} else {
						this.setState({ detached: false });
					}
				}
				// Updating ancestor with new value if tests are passed
				this.setState({ detached: false }, () => {
					this.updateAncestor(update);
				});
			} else {
				// Incase of invalid input discarding input by returning to props value
				this.setState({ detached: false });
			}
		}
	};

	render() {
		const { value, className, title, disabled } = this.props;
		const { detached, copy } = this.state;
		// If detached use component value else use ancestor value
		const realValue = detached ? copy : value;
		const realClassName = className === undefined ? "" : (` ${className}`);
		return (
			<div className={`numberField${realClassName}`}>
				<div className="title">{title}</div>
				<Form.Control
					disabled={disabled}
					type="text"
					placeholder=""
					value={realValue !== null && realValue !== undefined ? realValue : ""}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					onFocus={handleFocus}
				/>
			</div>
		);
	}
}
