import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import Messenger from "../../utilities/messenger";
import Lang from "../LanguageSelect/Lang";
import Checks from "./Checks";

const verifyPasswords = (curr, pass, veri) => curr !== undefined && pass !== undefined && veri !== undefined && curr.length > 0 && pass.length >= 6 && pass === veri;

export default class ChangePassword extends Component {
    state = {
        oldPassword: "",
        newPassword: "",
        passwordVeri: "",
    };

    submit = (e) => {
        const { oldPassword, newPassword, passwordVeri } = this.state;
        if (e)
            e.preventDefault();
        if (verifyPasswords(oldPassword, newPassword, passwordVeri)) {
            const callback = (data) => {
                if (data) {
                    if (data.status === "success") {
                        toast.success(<Lang en="Password changed successfully" fi="Salasana vaihdettu onnistuneesti" sv="Byte av lösenord lyckades" />);
                    } else if (data.msg === "Wrong current password") {
                        toast.error(<Lang en="Old password was incorrect" fi="Vanha salasana oli virheellinen" sv="Det gamla lösenordet var felaktigt" />);
                    } else {
                        toast.error(<Lang en="Failed to change password" fi="Salasanan vaihto epäonnistui" sv="Byte av lösenord misslyckades" />);
                    }
                }
            };
            Messenger.requestChangePassword({ oldPassword, newPassword, callback });
        } else {
            toast.error(<Lang en="Passwords invalid" fi="Virheelliset salasanat" sv="Felaktiga lösenord" />);
        }
        this.setState({ oldPassword: "", newPassword: "", passwordVeri: "" });
    };

    handleTextChange = (e, prop) => {
        this.setState({ [prop]: e.target.value });
    };

    render() {
        const { visible, lang } = this.props;
        const { oldPassword, newPassword, passwordVeri } = this.state;
        if (visible) {
            const validPasswords = verifyPasswords(oldPassword, newPassword, passwordVeri);
            return (
                <div className="rightSidePanel changePassword-wrapper cardBackground">
                    <form
                        className="changePassword settingsWell"
                        onSubmit={this.handleSubmit}
                    >
                        <h3 className="settingsTitle">
                            <Lang lang={lang}>
                                <span key="en">Change password</span>
                                <span key="fi">Salasanan vaihto</span>
                                <span key="sv">Byt lösenord</span>
                            </Lang>
                        </h3>
                        <Checks
                            current={oldPassword}
                            password={newPassword}
                            verification={passwordVeri}
                            lang={lang}
                        />
                        <Form.Group>
                            <Form.Label>
                                <Lang lang={lang}>
                                    <span key="en">Old password</span>
                                    <span key="fi">Vanha salasana</span>
                                    <span key="sv">Gammalt lösenord</span>
                                </Lang>
                            </Form.Label>
                            <Form.Control
                                maxLength="128"
                                type="password"
                                value={oldPassword}
                                onChange={(e) => this.handleTextChange(e, "oldPassword")}
                                autoComplete="current-password"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <Lang lang={lang}>
                                    <span key="en">New password</span>
                                    <span key="fi">Uusi salasana</span>
                                    <span key="sv">Nyt lösenord</span>
                                </Lang>
                            </Form.Label>
                            <Form.Control
                                maxLength="128"
                                type="password"
                                value={newPassword}
                                onChange={(e) => this.handleTextChange(e, "newPassword")}
                                autoComplete="new-password"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <Lang lang={lang}>
                                    <span key="en">Password verification</span>
                                    <span key="fi">Salasanan vahvistus</span>
                                    <span key="sv">Bekräftelse av lösenord</span>
                                </Lang>
                            </Form.Label>
                            <Form.Control
                                maxLength="128"
                                type="password"
                                value={passwordVeri}
                                onChange={(e) => this.handleTextChange(e, "passwordVeri")}
                                autoComplete="new-password"
                            />
                        </Form.Group>
                        <Button
                            onClick={this.submit}
                            variant={validPasswords ? "success" : "light"}
                            type="submit"
                            disabled={!validPasswords}
                        >
                            OK
                        </Button>
                    </form>
                </div>
            );
        }
        return null;
    }
}