import "../css/LimitsEditor.css";

import { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsX } from "react-icons/bs";
import rfdc from "rfdc";

import NumberField from "./GenericMenuComponents/NumberField";
import Lang from "./LanguageSelect/Lang";

const rfdclone = rfdc();

export default class LimitEditor extends Component {
	updateData = (obj) => {
		const { typeLimits, updateAncestor } = this.props;
		const keys = Object.keys(obj);
		const typeLimitsCopy = rfdclone(typeLimits);
		typeLimitsCopy[parseInt(keys[0], 10)].value = obj[keys[0]];
		updateAncestor({ typeLimits: typeLimitsCopy });
	};

	colorToggle = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	colorClose = (index, e) => {
		const { typeLimits, updateAncestor } = this.props;
		const typeLimitsCopy = rfdclone(typeLimits);
		if (typeLimitsCopy[index].color !== e.target.value) {
			typeLimitsCopy[index].color = e.target.value;
			updateAncestor({ typeLimits: typeLimitsCopy });
		}
	};

	/* eslint-disable react/no-array-index-key */
	getEntries = (typeLimits) => {
		let entries = [];
		if (typeLimits !== undefined) {
			entries = typeLimits.map((limit, index) => (
				<tr
					className="limitTableEntry"
					key={`limit_${index}`}
				>
					<td>
						<NumberField
							className="limit"
							value={limit.value}
							updateAncestor={this.updateData}
							propertyName={index.toString()}
						/>
					</td>
					<td className="tableButtonColumn">
						<Form.Control
							type="color"
							name={`limit_${index}`}
							// eslint-disable-next-line react/destructuring-assignment
							value={this.state && this.state[`limit_${index}`] ? this.state[`limit_${index}`] : limit.color}
							onChange={this.colorToggle}
							onBlur={(e) => this.colorClose(index, e)}
						/>
					</td>
					<td className="tableButtonColumn">
						<Button
							className='remove-limit-button'
							variant="danger"
							onClick={() => this.removeLimit(index)}
						>
							<IconContext.Provider
								value={{ className: "reactIcon" }}
							>
								<BsX />
							</IconContext.Provider>
						</Button>
					</td>
				</tr>
			));
		}
		if (entries.length > 0)
			return (
				<Table
					style={{ display: "inline-block", width: "unset" }}
					className="limitEntries"
					striped hover bordered
				>
					<thead>
						<tr>
							<th>
								<Lang en="Value" fi="Arvo" sv="Värde" />
							</th>
							<th>
								<Lang en="Colour" fi="Väri" sv="Färg" />
							</th>
							<th>
								<Lang en="Remove" fi="Poista" sv="Ta bort" />
							</th>
						</tr>
					</thead>
					<tbody>
						{entries}
					</tbody>
				</Table>
			);
		return null;
	};
	/* eslint-enable react/no-array-index-key */

	addLimit = () => {
		const { typeLimits, updateAncestor } = this.props;
		let typeLimitsCopy = [];
		if (typeLimits) {
			typeLimitsCopy = rfdclone(typeLimits);
		}
		if (typeLimitsCopy.length === 0)
			typeLimitsCopy.push({ value: 0, color: "#ff0000" });
		else if (typeLimitsCopy.length === 1)
			typeLimitsCopy.push({ value: 0, color: "#3333ff" });
		else
			typeLimitsCopy.push({ value: 0, color: "#aaaaaa" });
		updateAncestor({ typeLimits: typeLimitsCopy });
	};

	removeLimit = (index) => {
		const { typeLimits, updateAncestor } = this.props;
		const typeLimitsCopy = rfdclone(typeLimits);
		typeLimitsCopy.splice(index, 1);
		updateAncestor({ typeLimits: typeLimitsCopy });
	};

	render() {
		const { selectedSystem, selectedProject, show, typeLimits } = this.props;
		if (selectedSystem.id !== undefined && selectedProject.id !== undefined && selectedProject.id !== "new_project") {
			return (
				<div className="limitEditor cardBackground">
					<div className="addButtonWrapper">
						{show ? <Button
							variant="primary"
							onClick={this.addLimit}
						>
							<Lang en="Add limit" fi="Lisää raja" sv="Lägg till gräns" />
						</Button> : null}
					</div>
					<div className="tableWrapper">
						{this.getEntries(typeLimits)}
					</div>
				</div>
			);
		}
		return null;
	}
}
