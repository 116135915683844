import { Accordion, Card, Dropdown, DropdownButton } from "react-bootstrap";

import Lang from "../LanguageSelect/Lang";

const getTitle = (customers, customerId) => customers !== undefined && customerId !== undefined && customers[customerId] !== undefined && customers[customerId].customerName !== undefined ? customers[customerId].customerName : (
    <span className="title">
        <Lang en="Select..." fi="Valitse..." sv="Välj..." />
    </span>
);

const getItems = ({ customers, customerId }) => {
    let options = [];
    if (customers !== undefined) {
        const keys = Object.keys(customers);
        keys.sort((a, b) => customers[a].customerName.localeCompare(customers[b].customerName, undefined, { numeric: true, sensitivity: "base", ignorePunctuation: true }));
        options = keys.map(key => (
            <Dropdown.Item
                key={`option_${key}`}
                eventKey={customers[key].customerId}
                active={customers[key].customerId === customerId}
            >
                {customers[key].customerName}
            </Dropdown.Item>
        ));
    }
    return options;
};

const handleSelect = (customerId, selectCustomer) => {
    selectCustomer(Number(customerId), true);
};

export default function CustomerSelect({ customers, selectCustomer, customerId }) {
    if (Object.keys(customers).length > 1) {
        return (
            <Accordion defaultActiveKey="0" className="cardBackground" style={{ marginBottom: "10px" }}>
                <Card id="accordianPanel" className="menuPanelStatic customerSelect">
                    <Card.Header>
                        <Lang en="Customers" fi="Asiakas" sv="Kunder" />
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="customerDropdown">
                                <span className="dropdownFlexer scrollable-dropdown">
                                    <DropdownButton
                                        variant="default"
                                        title={
                                            <span className="title">{getTitle(customers, customerId)}</span>
                                        }
                                        id="typeDropdown"
                                        onSelect={(custId) => handleSelect(custId, selectCustomer)}
                                    >
                                        {getItems({ customers, customerId })}
                                    </DropdownButton >
                                </span>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
    return null;
}