import { connect } from "react-redux";
import Switch from "react-switch";

import Lang from "../../LanguageSelect/Lang";

const handleChange = (value, setValues) => {
	setValues({ systemsConfigPowerDropActive: value });
};

function PowerAlert({ systemsConfigPowerDropActive, editAuth, setValues }) {
	if (systemsConfigPowerDropActive !== null) {
		return (
			<span className="setting power-alarm-selector separated-alignment-parent switch-setting">
				<span className="title separated-alignment-left">
					<Lang en="Power off / drop" fi="Virransyötön katkeaminen / alhainen jännite" sv="Strömavbrott / låg spänning" />
				</span>
				<span className="separated-alignment-right">
					<Switch
						disabled={!editAuth}
						onChange={(value) => handleChange(value, setValues)}
						checked={systemsConfigPowerDropActive}
						onHandleColor="#5cb75c"
						onColor="#b1dbaf"
						height={13}
						width={33}
						handleDiameter={19}
						boxShadow="1px 1px 3px 1px rgba(0, 0, 0, 0.3)"
						uncheckedIcon={false}
						checkedIcon={false}
					/>
				</span>
			</span>
		);
	}
	return null;
}

function mapStateToProps(state) {
	const { systemsConfigPowerDropActive } = state;
	return { systemsConfigPowerDropActive };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerAlert);