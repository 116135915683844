import { Component } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Messenger from "../../../utilities/messenger";
import Lang from "../../LanguageSelect/Lang";
import HexInput from "./HexInput";

class AddLoggerModal extends Component {
	closeModal = () => {
		const { setValues } = this.props;
		setValues({ systemsConfigShowAddLoggerModal: false, systemsConfigAddLoggerId: "", systemsConfigAddLoggerName: "" });
	};

	handleAddLogger = () => {
		const { systemsConfigAddLoggerId, customerId, systemsSelectedSystem, systemsConfigAddLoggerName, setValues, restartPolling } = this.props;
		if (systemsConfigAddLoggerId.length === 8) {
			const payload = {
				customerId,
				systemId: systemsSelectedSystem.id,
				loggerId: parseInt(systemsConfigAddLoggerId, 16),
				loggerName: systemsConfigAddLoggerName !== undefined && systemsConfigAddLoggerName !== "" ? systemsConfigAddLoggerName : `Node_${systemsConfigAddLoggerId}`,
			};
			const callback = (data) => {
				switch (data.state) {
					case "ok": {
						setValues({
							systemsConfigAddLoggerId: "",
							systemsConfigAddLoggerName: "",
							infoModalShow: true,
							infoModalMsg: <Lang
								en="It might take up to 1-10 minutes for changes to take effect at system level"
								fi="Saattaa kestää 1-10 minuuttia että muutokset tulevat voimaan järjestelmätasolla"
								sv="Det kan ta till och med 1-10 minuter för ändringarna att träda i kraft på system nivå"
							/>,
							infoModalTitle: <Lang en="Logger added" fi="Loggeri lisätty" sv="Loggern tillades" />,
						});
						restartPolling();
						break;
					}

					case "warning_sensor": {
						toast.warning(<Lang en="Data missing from database for this logger. Pair logger with the MIRAN Configuration Tool -program, after which the data will be updated to the database." fi="Tälle loggerille ei ole kaikkia tarvittavia tietoja tietokannassa, parita loggeri MIRAN Configuration Tool –ohjelman avulla, jonka jälkeen tiedot päivittyvät tietokantaan" sv="Data för denna logger fattas från databasen. Para logger med MIRAN Configuration Tool -programmet, hedanefter updateras datan till databasen." />, { autoClose: 10_000, });
						break;
					}

					case "warning_logger": {
						toast.warning(<Lang en="Could not find logger in database with the given HWID, check the HWID and try again." fi="Annetulla HWID:llä ei löydy loggeria tietokannasta, tarkasta HWID ja yritä uudelleen." sv="Hittade inte loggern i databasen med given HWID, kontrollera HWID:n och försök igen." />, { autoClose: 10_000, });
						break;
					}

					default: {
						toast.error(<Lang en="Could not add logger" fi="Loggerin lisäys epäonnistui" sv="Kunde inte tillägga loggern" />);
					}
				}
			};
			this.closeModal();
			Messenger.addLogger({ payload, callback: callback.bind(this) });
		} else {
			toast.warning(<Lang en="Logger ID too short" fi="Loggerin ID on liian lyhyt" sv="Loggerns ID är för kort" />);
		}
	};

	render() {
		const { systemsConfigShowAddLoggerModal, systemsConfigAddLoggerId, setValues, systemsConfigAddLoggerName } = this.props;
		const renderTooltip = (props) => (
			<Tooltip id="loggername-tooltip" {...props}>
				<Lang en="If you leave this empty the name will be created in following format: Node 0000ABCD(<-HWID)" fi="Jos jätät tämän tyhjäksi, loggerin nimi tulee muodossa: Node 0000ABCD(<-HWID)" sv="Om du lämnar detta tomt kommer namnet att skapas i följande format: Node 0000ABCD(<-HWID)" />
			</Tooltip>
		);
		return (
			<Modal
				key="addloggermodal"
				className="add-logger-modal"
				show={systemsConfigShowAddLoggerModal}
				onHide={this.closeModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<Lang en="Add logger" fi="Lisää loggeri" sv="Tillägg logger" />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Label>HWID*</Form.Label>
					<HexInput
						value={systemsConfigAddLoggerId}
						valueProp="systemsConfigAddLoggerId"
						updateAncestor={(obj) => setValues(obj)}
						maxLength={8}
					/>
					<br />
					<Form.Label><Lang en="Name" fi="Nimi" sv="Namn" /></Form.Label>
					<OverlayTrigger
						placement="bottom-start"
						delay={{ show: 100, hide: 200 }}
						overlay={renderTooltip}
					>
						<Form.Control
							className="logger-name-input"
							type="text"
							maxLength={15}
							value={systemsConfigAddLoggerName}
							onChange={(e) => setValues({ systemsConfigAddLoggerName: e.target.value })}
						/>
					</OverlayTrigger>
					<br />
					<Lang
						en="Note that the logger will be added to the list only once the changes have taken effect at system leve, this may take 1-10 minutes. The page will update automaicaly after that."
						fi="Huomioi, että uusi loggeri lisätään listaukseen vasta kun muutokset ovat tulleet voimaan järjestelmätasolla, tähän saattaa mennä 1-10 minuuttia. Sivu päivittyy sen jälkeen automaattisesti."
						sv="Obs. loggern tilläggs listan först då ändringarna har trätt i kraft på system nivå, detta kan ta 1-10 minuter. Sidan updateras automatiskt efteråt."
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						disabled={systemsConfigAddLoggerId.length !== 8}
						variant="success"
						onClick={this.handleAddLogger}
					>
						<Lang en="OK" fi="OK" sv="OK" />
					</Button>
					<Button
						variant="danger"
						onClick={this.closeModal}
					>
						<Lang en="Cancel" fi="Peruuta" sv="Ångra" />
					</Button>
				</Modal.Footer>
			</Modal >
		);
	}
}

function mapStateToProps(state) {
	const { customerId, systemsConfigShowAddLoggerModal, systemsConfigAddLoggerId, systemsConfigAddLoggerName, systemsSelectedSystem } = state;
	return { customerId, systemsConfigShowAddLoggerModal, systemsConfigAddLoggerId, systemsConfigAddLoggerName, systemsSelectedSystem };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLoggerModal);
