import "../css/NavigationBar.css";

import { Component } from "react";
import { Badge, Button, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import isAuthorized from "../utilities/authorization";
import Lang from "./LanguageSelect/Lang";

class NavigationBar extends Component {
	navigateTo = (path) => {
		const { navigate } = this.props;
		// Adds new path to react router history
		navigate(path);
	};

	isActive = (path) => {
		const { location } = this.props;
		return path === location.pathname;
	};

	getPages = () => {
		const { userLogged, username, lang } = this.props;
		if (userLogged) {
			return (
				<>
					<Nav variant="tabs" defaultActiveKey="/mainpage">
						{this.createNavItem(1, "/mainpage", null,
							{ "fi": "Pääsivu", "en": "Main page", "sv": "Huvudsida" })}
						{this.createNavItem(2, "/projects", { one: ["super", "project_admin"] },
							{ "fi": "Projektit", "en": "Projects", "sv": "Projekt" })}
						{this.createNavItem(3, "/blueprints", null,
							{ "fi": "Pohjakuvat", "en": "Blueprints", "sv": "Planen" })}
						{/*{this.createNavItem(4, "/alarms", null,
							{ "fi": "Hälytykset", "en": "Alarms", "sv": "Larm" })} */}
						{this.createNavItem(5, "/users", { one: ["super", "admin"] },
							{ "fi": "Käyttäjät", "en": "Users", "sv": "Användare" })}
						{this.createNavItem(6, "/customers", { one: ["super"] },
							{ "fi": "Asiakkaat", "en": "Customers", "sv": "Kunder" })}
						{this.createNavItem(7, "/systems", { one: ["super", "system"] },
							{ "fi": "Järjestelmät", "en": "Systems", "sv": "System" })}
						{this.createNavItem(8, "/message-management", { one: ["super"] },
							{ "fi": "Viestintä", "en": "Messaging", "sv": "Medelande" })}
						{this.createNavItem(9, "/user-settings", null,
							{ "fi": username, "en": username, "sv": username })}
					</Nav>
					<Nav className="container-fluid">
						<Nav.Link className='ms-auto py-0 d-flex align-items-center' href='https://www.pietiko.fi/wp-content/uploads/MIRAN-Miranlink-Kayttoohje-FI.pdf' target="_blank">
							<Button variant="outline-primary" className='outline-miranlink'>
								<Lang en="MIRANLink user manual" fi="MIRANLink käyttöohje" sv="MIRANLink användarmanual" lang={lang} />
							</Button>
						</Nav.Link>
					</Nav>
				</>
			);
		}
		return (
			<Nav />
		);
	};

	createNavItem = (eventKey, path, authorized, langOptions, className) => {
		// Refactored menu items from classes to this factory function because
		// for some reason the mobile menu would not close when clicking an item.
		const { lang, newInfoMessages } = this.props;
		if (authorized == null || isAuthorized(authorized)) {
			return path === "/user-settings" ? (
				<Nav.Item style={{ minWidth: "fit-content" }}>
					<Nav.Link className={className} eventKey={eventKey} active={this.isActive(path)} onClick={() => this.navigateTo(path)}>
						<Lang lang={lang} fi={langOptions.fi} en={langOptions.en} sv={langOptions.sv} />
						{newInfoMessages ? <Badge pill bg="success"><Lang en="New message" fi="Uusi viesti" sv="Nyt meddelande" lang={lang} /></Badge> : null}
					</Nav.Link>
				</Nav.Item>
			) : (
				<Nav.Item>
					<Nav.Link className={className} eventKey={eventKey} active={this.isActive(path)} onClick={() => this.navigateTo(path)}>
						<Lang lang={lang} fi={langOptions.fi} en={langOptions.en} sv={langOptions.sv} />
					</Nav.Link>
				</Nav.Item>
			);
		}
		return null;
	};

	render() {
		const { userLogged } = this.props;
		return userLogged ? (
			<Navbar fixed="top" collapseOnSelect expand="lg" className="navigationBar">
				<Navbar.Brand href="#" className='d-flex align-items-center justify-content-center' onClick={() => this.navigateTo("/")}>
					<img
						src="MIRAN_logo_mobile.png"
						className="brandIMG"
						alt="MIRAN"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					{this.getPages()}
				</Navbar.Collapse>
			</Navbar>
		) : null;
	}
}

function NavigationBarFunction(props) {
	const navigate = useNavigate();
	const location = useLocation();
	return <NavigationBar {...props} navigate={navigate} location={location} />;
}

function mapStateToProps(state) {
	const { lang, username, newInfoMessages } = state;
	return { lang, username, newInfoMessages };
}

const mapDispatchToProps = (dispatch) => ({
		setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
	});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBarFunction);