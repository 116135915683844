import "../css/CardBackground.css";
import "../css/GraphSettings.css";

import { Component } from "react";
import { Accordion, Card } from "react-bootstrap";

import isAuthorized from "../utilities/authorization";
import DatasetByLoggerSelect from "./DatasetByLoggerSelect";
import DatasetByTypeSelect from "./DatasetByTypeSelect";
import DeviceNameEditor from "./DeviceNameEditor";
import FontSizeSelector from "./FontSizeSelector";
import Lang from "./LanguageSelect/Lang";
import Limits from "./Limits";
import PlotColorsEditor from "./PlotColorsEditor";
import ProjectTitles from "./ProjectSelections/ProjectSelections";
import QualityDropdown from "./ProjectSelections/QualityDropdown";
import ReportSelections from "./ReportSelections/ReportSelections";
import XAxisSelections from "./XAxisSelections";
import YAxisSelections from "./YAxisSelections";

const getReports = (projects, selectedProject) => {
	let reports = [];
	if (selectedProject.id !== undefined) {
		const index = projects.findIndex(x => x.id === selectedProject.id);
		if (index > -1 && projects[index] !== undefined && projects[index].reports !== undefined) {
			reports = Object.keys(projects[index].reports).map((key) => ({ id: key, name: projects[index].reports[key] }));
		}
	}
	return reports;
};

const isMobileDevice = () => window.matchMedia("screen and (max-width: 1024px)").matches;

export default class GraphSettings extends Component {
	updateAncestor = (obj) => {
		const { updateAncestor } = this.props;
		const objCopy = obj;
		objCopy.lastUpdate = "graphSettings";
		updateAncestor(objCopy);
	};

	render() {
		const {
			selectedProject, settingsLoading, lang, reportId, reportName, projects, storeReport, deleteReport, nTh, avgNth,
			tempNth, updateQualityAncestor, xAxisFilter, fontSize, title, subtitle, xAxisTitle, xAxisStartTime, xAxisAbsoluteStartTime,
			xAxisEndTime, xAxisAbsoluteEndTime, xAxisTicks, xAxisTickZero, xAxisTicksDays, xAxisTicksHours, xAxisTicksMinutes,
			xAxisFilterStartTime, xAxisFilterEndTime, yAxisTitle, yAxisMax, yAxisMin, yAxisTicks, yAxisTickZero, y2AxisTitle,
			y2AxisMax, y2AxisMin, y2AxisQuantities, datasets, deviceSettings, measTypes, devices, customerId, limits,
			selectedLimits, selectedSystem, selectedMeasType, lastUpdate
		} = this.props;
		const expand = (isMobileDevice() ? "" : "0");
		return selectedProject !== undefined && selectedProject.id !== undefined ? (
			<Accordion defaultActiveKey={expand}
				className="graphSettings cardBackground"
				style={{ opacity: settingsLoading ? "25%" : "100%", pointerEvents: settingsLoading ? "none" : "auto" }}
			>
				<Card
					id="accordianPanel"
					className="menuPanelStatic titlePanel graphPanel"
				>
					<Accordion.Item eventKey="0">
						<Accordion.Button as={Card.Header}>
							<Lang lang={lang}>
								<div key="en">Graph settings</div>
								<div key="fi">Graafin asetukset</div>
								<div key="sv">Diagram inställningar</div>
							</Lang>
						</Accordion.Button>
						<Accordion.Body>
							<Card.Body>
								<ReportSelections
									selectedProject={selectedProject}
									reportId={reportId}
									reportName={reportName}
									reports={getReports(projects, selectedProject)}
									storeReport={storeReport}
									deleteReport={deleteReport}
									updateAncestor={this.updateAncestor}
									lang={lang}
								/>
								<span className="qualityWrapper">
									<Lang lang={lang}>
										<div className="subtitle" key="en">Amount of displayed datapoints:</div>
										<div className="subtitle" key="fi">Näytettävien mittapisteiden määrä:</div>
										<div className="subtitle" key="sv">Mängden visade mätningar:</div>
									</Lang>
									<div className="dropdownFlexer">
										<QualityDropdown
											lang={lang}
											nTh={nTh}
											avgNth={avgNth}
											tempNth={tempNth}
											updateAncestor={updateQualityAncestor}
											disabled={xAxisFilter}
											reportId={reportId}
										/>
									</div>
								</span>
								<FontSizeSelector
									fontSize={fontSize}
									updateAncestor={this.updateAncestor}
								/>
								<ProjectTitles
									projects={projects}
									selectedProject={selectedProject}
									updateData={this.updateAncestor}
									title={title}
									subtitle={subtitle}
									lang={lang}
								/>
								<XAxisSelections
									selectedProject={selectedProject}
									updateData={this.updateAncestor}
									xAxisTitle={xAxisTitle}
									xAxisStartTime={xAxisStartTime}
									xAxisAbsoluteStartTime={xAxisAbsoluteStartTime}
									xAxisEndTime={xAxisEndTime}
									xAxisAbsoluteEndTime={xAxisAbsoluteEndTime}
									xAxisTicks={xAxisTicks}
									xAxisTickZero={xAxisTickZero}
									xAxisTicksDays={xAxisTicksDays}
									xAxisTicksHours={xAxisTicksHours}
									xAxisTicksMinutes={xAxisTicksMinutes}
									xAxisFilter={xAxisFilter}
									xAxisFilterStartTime={xAxisFilterStartTime}
									xAxisFilterEndTime={xAxisFilterEndTime}
									lang={lang}
								/>
								<YAxisSelections
									selectedProject={selectedProject}
									updateData={this.updateAncestor}
									yAxisTitle={yAxisTitle}
									yAxisMax={yAxisMax}
									yAxisMin={yAxisMin}
									yAxisTicks={yAxisTicks}
									yAxisTickZero={yAxisTickZero}
									y2AxisTitle={y2AxisTitle}
									y2AxisMax={y2AxisMax}
									y2AxisMin={y2AxisMin}
									y2AxisQuantities={y2AxisQuantities}
									datasets={datasets}
									lang={lang}
								/>
								<DatasetByTypeSelect
									updateAncestor={this.updateAncestor}
									datasets={datasets}
									deviceSettings={deviceSettings}
									types={measTypes}
									lang={lang}
								/>
								<DatasetByLoggerSelect
									updateAncestor={this.updateAncestor}
									datasets={datasets}
									deviceSettings={deviceSettings}
									devices={devices}
									lang={lang}
								/>
								<Limits
									customerId={customerId}
									updateAncestor={this.updateAncestor}
									datasets={datasets}
									limits={limits}
									selectedLimits={selectedLimits}
									selectedProject={selectedProject}
									selectedSystem={selectedSystem}
									measTypes={measTypes}
									selectedMeasType={selectedMeasType}
									lang={lang}
									lastUpdate={lastUpdate}
								/>
								{isAuthorized({ one: ["project_admin"] }) ?
									<PlotColorsEditor
										customerId={customerId}
										selectedSystem={selectedSystem}
										selectedProject={selectedProject}
										lang={lang}
										updateAncestor={this.updateAncestor}
									/>
									: null}
								<DeviceNameEditor
									updateAncestor={this.updateAncestor}
									devices={devices}
									datasets={datasets}
									deviceSettings={deviceSettings}
									lang={lang}
									selectedSystem={selectedSystem}
								/>
							</Card.Body>
						</Accordion.Body>
					</Accordion.Item>
				</Card>
			</Accordion>
		) : null;
	}
}