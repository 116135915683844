import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";

const closeModal = (setValues) => {
	setValues({ infoModalShow: false, infoModalMsg: null, infoModalTitle: null });
};

function InfoModal({ infoModalShow, infoModalMsg, infoModalTitle, propCloseModal, setValues }) {
	return (
		<Modal
			key="infoModal"
			className="info-modal"
			show={infoModalShow}
			onHide={closeModal}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{infoModalTitle || null}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{infoModalMsg}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="success"
					onClick={() => propCloseModal === undefined ? closeModal(setValues) : propCloseModal()}
				>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

function mapStateToProps(state, ownProps) {
	if (ownProps.infoModalMsg === undefined || ownProps.infoModalMsg === null) {
		const { infoModalShow, infoModalMsg, infoModalTitle } = state;
		return { infoModalShow, infoModalMsg, infoModalTitle };
	}
	return { infoModalShow: ownProps.infoModalShow, infoModalMsg: ownProps.infoModalMsg, infoModalTitle: ownProps.infoModalTitle };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);