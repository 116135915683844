import { Component } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { IconContext } from "react-icons";
import { RiUpload2Fill } from "react-icons/ri";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import isAuthorized from "../../utilities/authorization";
import store from "../../utilities/redux";
import CustomerSelect from "../CustomerSelect";
import Lang from "../LanguageSelect/Lang";
import { MainSelector } from "../ProjectSelections/ProjectSelections";
import BlueprintClient from "./blueprintClient";
import Logger from "./Logger";
import LoggerList from "./LoggerList";
import Measurement from "./Measurement";
import UploadBlueprintModal from "./UploadBlueprintModal";

// Mapping to and from redux blueprint_ props
const BLUEPRINT_MAP = {
	selectedProject: "blueprintSelectedProject", selectedSystem: "blueprintSelectedSystem",
	loading: "blueprint_loading",
};

const remapToBlueprintView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (BLUEPRINT_MAP[key])
			output[BLUEPRINT_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

const setSystemProject = (objProp) => {
	const obj = remapToBlueprintView(objProp);
	store.dispatch({
		type: "SET_VALUES",
		obj
	});
};

const modifyAuthorized = () => isAuthorized({ one: ["project_admin", "super"] });

class BlueprintLeftPanel extends Component {
	state = {
		showUploadModal: false,
		loading: false,
	};

	componentDidUpdate(prevProps) {
		const { selectedSystem, selectedProject } = this.props;
		if (
			selectedSystem.id !== undefined && selectedProject.id !== undefined &&
			(selectedSystem.id !== prevProps.selectedSystem.id || selectedProject.id !== prevProps.selectedProject.id)
		) {
			this.updateData({ selectedProject });
		}
		if (selectedSystem.id !== prevProps.selectedSystem.id && prevProps.selectedSystem.id !== undefined) {
			this.selectBlueprint({}, []);
			this.setLoggers([]);
			return;
		}
		if (selectedSystem.id !== prevProps.selectedSystem.id ||
			selectedProject.id !== prevProps.selectedProject.id) {
			this.updateData({ selectedProject });
		}
	}

	setLoggers(loggers) {
		const { selectedProject } = this.props;
		if (selectedProject.loggerIds) {
			loggers.sort((a, b) => selectedProject.loggerIds.split(",").indexOf(a.loggerId) - selectedProject.loggerIds.split(",").indexOf(b.loggerId));
		}
		store.dispatch({
			type: "SET_VALUES",
			obj: {
				blueprintBlueprintLoggers: loggers,
			}
		});
	}

	getDeviceData(projectId, systemId, customerId) {
		this.setState({ loading: true });
		BlueprintClient.getLoggersForBlueprint(projectId, systemId, customerId).then(loggersResponse => {
			const loggers = [];
			// eslint-disable-next-line no-restricted-syntax, promise/always-return
			for (const l of loggersResponse) {
				const logger = new Logger(l.loggerId);
				logger.x = l.x;
				logger.y = l.y;
				logger.dot_x = l.dot_x;
				logger.dot_y = l.dot_y;
				logger.blueprint = l.blueprintUuid;
				logger.name = l.name;
				logger.type = l.type;
				logger.timestamp = l.timestamp;
				logger.systemId = l.systemId;
				logger.projectId = l.projectId;
				if (l.measurements) {
					// eslint-disable-next-line no-restricted-syntax
					for (const measurement of l.measurements) {
						logger.measurements.push(new Measurement(measurement.a, measurement.u, measurement.v));
					}
				}
				logger.value = l.value;
				loggers.push(logger);
			}
			this.setState({ loading: false });
			this.setLoggers(loggers);
		}).catch(() => {
			this.setState({ loading: false });
		});
	}

	updateData = (obj) => {
		const { selectedSystem, customerId } = this.props;
		if (obj.selectedProject !== undefined && obj.selectedProject.id) {
			const projectId = obj.selectedProject.id;
			this.setLoggers([]);
			// eslint-disable-next-line promise/catch-or-return
			BlueprintClient.requestBlueprints(projectId, selectedSystem.id, customerId).then(blueprints => {
				// eslint-disable-next-line promise/always-return
				const selectedBlueprint = blueprints.length > 0 ? blueprints[0] : null;
				this.getDeviceData(projectId, selectedSystem.id, customerId);
				this.selectBlueprint(selectedBlueprint, blueprints);
			});
		}
	};

	uploadFile = (file, filename, blueprintName, blueprintDescription) => {
		const { lang, selectedProject, selectedSystem, customerId, blueprints } = this.props;
		const uploadToast = toast.loading(<Lang lang={lang} fi="Pohjakuvaa lähetetään..." en="Uploading blueprint..." sv="Loadering planet..." />, { autoClose: false, });
		const name = blueprintName || filename;
		// eslint-disable-next-line promise/always-return
		BlueprintClient.uploadBlueprint(file, selectedProject.id, selectedSystem.id, customerId, name, blueprintDescription).then(blueprint => {
				const newBlueprints = [...blueprints, blueprint];
				this.setState({
					showUploadModal: false
			});
			toast.update(uploadToast, { render: <Lang lang={lang} fi="Pohjakuva lähetetty onnistuneesti." en="Blueprint successfully uploaded." sv="Planet loaderat." />, type: "success", isLoading: false, autoClose: 5000 });
			this.selectBlueprint(blueprint, newBlueprints);
		}).catch(() => {
			toast.update(uploadToast, { render: <Lang lang={lang} fi="Pohjakuvan lähetys epäonnistui" en="Blueprint upload failed" sv="Uppladdningen av planen misslyckades" />, type: "error", isLoading: false, autoClose: 5000 });
		});
	};

	showUploadModal = () => {
		if (!modifyAuthorized()) {
			this.showInsufficientRights();
			return;
		}
		this.setState({ showUploadModal: true });
	};

	uploadModalClosed = () => {
		this.setState({ showUploadModal: false });
	};

	selectBlueprint(blueprint, blueprintsProp) {
		const { blueprints } = this.props;
		let newBlueprint = {};
		if (blueprint !== null) {
			newBlueprint = blueprint.uuid ? ({ ...blueprint }) : ({ ...blueprints.find(element => element.uuid === blueprint) });
		}
		const obj = {
			blueprintSelectedBlueprint: newBlueprint,
		};
		if (blueprintsProp) {
			obj.blueprintBlueprints = blueprintsProp;
		}
		store.dispatch({
			type: "SET_VALUES",
			obj
		});
	}

	render() {
		const { blueprints, lang, bp, customers, customerId, selectCustomer, systems, selectedSystem, selectedProject, loggers } = this.props;
		const { loading, showUploadModal } = this.state;
		let blueprintItems = [];
		if (blueprints) {
			blueprintItems = blueprints.map(item => (
				<Dropdown.Item
					key={item.uuid}
					href="#"
					eventKey={item.uuid}
				>
					{item.name}
				</Dropdown.Item>
			));
		}
		let dropdownTitle = (
			<Lang lang={lang} fi="Valitse" en="Select" sv="Valja" />
		);
		if (bp) {
			dropdownTitle = bp.name;
		}
		const spinnerClass = loading ? "spinner" : "";
		return (
			<div>
				<CustomerSelect
					customers={customers}
					customerId={customerId}
					selectCustomer={selectCustomer}
					lang={lang}
					checkProjects
				/>
				<div className="mainSelector cardBackground">
					<MainSelector
						systems={systems}
						selectedSystem={selectedSystem}
						lang={lang}
						updateAncestor={setSystemProject}
						projects={selectedSystem.id === undefined ? undefined : selectedSystem.projects}
						selectedProject={selectedProject}
						blueprints
					/>
				</div>
				{selectedSystem.id !== undefined && selectedProject.id !== undefined ?
					<div className={`blueprintLeftSidePanel ${spinnerClass}`}>
						{blueprintItems.length > 0 ?
							<DropdownButton
								onSelect={(item) => this.selectBlueprint(item)}
								as={ButtonGroup}
								variant="light"
								id="blueprint-dropdown"
								title={dropdownTitle}
							>
								{blueprintItems}
							</DropdownButton> : null}
						&nbsp;
						<Button
							variant="light"
							onClick={() => this.showUploadModal()}
							disabled={!selectedProject}
						>
							<IconContext.Provider value={{ className: "reactIcon-text" }}>
								<RiUpload2Fill />
							</IconContext.Provider>
							<Lang lang={lang} fi="Lähetä pohjakuva" en="Upload blueprint" sv="Ladda upp planet" />
						</Button>
						<UploadBlueprintModal show={showUploadModal}
							lang={lang}
							uploadModalClosed={this.uploadModalClosed}
							uploadFile={this.uploadFile} />
						<LoggerList loggers={loggers}
							bp={bp}
							loggerMoved={this.loggerMoved}
							lang={lang} />
					</div>
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, username, systems, customerId, blueprintSelectedSystem, blueprintSelectedBlueprint, blueprintBlueprints, blueprintBlueprintLoggers } = state;
	return {
		lang, username, systems, customerId,
		selectedSystem: blueprintSelectedSystem, bp: blueprintSelectedBlueprint, blueprints: blueprintBlueprints, loggers: blueprintBlueprintLoggers
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintLeftPanel);
