import { Component } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { IconContext } from "react-icons";
import { BsArrowRepeat } from "react-icons/bs";
import { toast } from "react-toastify";
import rfdc from "rfdc";
import { v4 as uuid } from "uuid";

import isAuthorized from "../../utilities/authorization";
import Messenger from "../../utilities/messenger";
import getFooterButtons from "../ConfigModalOptions";
import Lang from "../LanguageSelect/Lang";
import DeviceTypeDropdown from "./DeviceTypeDropdown";

const rfdclone = rfdc();

function EditingOptions({ visible, newSystem, system, handleSystemTextChange, handleSystemIdGeneration, handleSystemDeviceTypeChange }) {
    if (visible) {
        return (
            <form>
                <Form.Group controlId="systemId">
                    <Form.Label>
                        <Lang en="ID" fi="ID" sv="ID" />
                    </Form.Label>
                    {
                        newSystem ?
                            <InputGroup>
                                <Form.Control
                                    maxLength="128"
                                    type="text"
                                    value={system.id === undefined ? "" : system.id}
                                    onChange={(e) => handleSystemTextChange(e, "id")}
                                    isInvalid={system.id === undefined}
                                />
                                <Button variant="light" onClick={handleSystemIdGeneration}>
                                    <IconContext.Provider value={{ className: "reactIcon-refresh" }}>
                                        <BsArrowRepeat />
                                    </IconContext.Provider>
                                </Button>
                            </InputGroup>
                            :
                            <span>{(system.id === undefined ? ":" : `: ${system.id}`)}</span>
                    }
                </Form.Group>
                <Form.Group controlId="systemName">
                    <Form.Label>
                        <Lang en="Name" fi="Nimi" sv="Namn" />
                    </Form.Label>
                    <Form.Control
                        maxLength="128"
                        type="text"
                        value={system.systemName === undefined ? "" : system.systemName}
                        onChange={(e) => handleSystemTextChange(e, "systemName")}
                        isInvalid={system.systemName === ""}
                    />
                </Form.Group>
                {
                    newSystem ?
                        <Form.Group>
                            <DeviceTypeDropdown
                                onChange={handleSystemDeviceTypeChange}
                                value={system.deviceType}
                            />
                        </Form.Group> : null
                }
            </form>
        );
    }
    return null;
}

function DeleteView({ visible, name }) {
    if (visible) {
        return (
            <div className="deleteView">
                <div className="title">
                    <Lang en="Delete system?" fi="Poistetaanko järjestelmä?" sv="Radera system?" />
                </div>
                <div className="deletable-name">
                    {name}
                </div>
            </div>
        );
    }
    return null;
}

function RegenerationView({ visible, name }) {
    if (visible) {
        return (
            <div className="regenView">
                <div className="title">
                    <Lang en="Re-generate system token?" fi="Uudistetaanko järjestelmä todennin?" sv="Förnya system token?" />
                </div>
                <div className="regen-name">
                    {name}
                </div>
            </div>
        );
    }
    return null;
}

function TokenView({ visible, name, token }) {
    if (visible) {
        return (
            <div className="tokenView">
                <div className="title">
                    <Lang en={`${name} token`} fi={`${name} todennin`} sv={`${name} token`} />
                </div>
                <div className="token">
                    {token}
                </div>
            </div>
        );
    }
    return null;
}

const getEditorModalTitle = ({ newView, deleteView, regenView, tokenView }) => {
    if (!deleteView && !regenView && !tokenView) {
        return newView ? (
            <Lang en="New system" fi="Uusi järjestelmä" sv="Nyt system" />
        ) : (
            <Lang en="Edit system" fi="Muokkaa järjestelmää" sv="Redigera system" />
        );
    }
    if (tokenView)
        return (
            <Lang en="Token" fi="Todennin" sv="Token" />
        );
    return regenView ? (
        <Lang en="Token re-generation" fi="Todentimen uusiminen" sv="Token förnyelse" />
    ) : (
        <Lang en="System removal" fi="Järjestelmän poisto" sv="System radering" />
    );

};

export default class EditingModal extends Component {
    state = {
        // eslint-disable-next-line react/destructuring-assignment
        system: this.props.selectedSystem,
        deleteView: false,
        regenView: false,
        tokenView: false,
        systemToken: "",
    };

    handleModalClose = () => {
        const { close } = this.props;
        if (close)
            close();
    };

    handleSystemTextChange = (e, prop) => {
        const { system } = this.state;
        if (prop && e) {
            const tempSystem = rfdclone(system);
            tempSystem[prop] = e.target.value;
            this.setState({ system: tempSystem });
        }
    };

    handleSystemDeviceTypeChange = (deviceType) => {
        const { system } = this.state;
        const tempSystem = rfdclone(system);
        tempSystem.deviceType = deviceType;
        this.setState({ system: tempSystem });
    };

    handleSystemIdGeneration = () => {
        const { system } = this.state;
        const tempSystem = rfdclone(system);
        tempSystem.id = uuid();
        this.setState({ system: tempSystem });
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    submit = () => {
        const { newSystem, getCustomerData, toggleState, customerId, getCustomerSystems, userId } = this.props;
        const { system } = this.state;
        if (newSystem) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="New system created" fi="Uusi järjestelmä luotu" sv="Nyt system skapades" />);
                    getCustomerData({ customerId });
                } else if (data.status === "UniqueViolation") {
                    toast.error(<Lang en="System creation failed. System ID already exists." fi="Uuden järjestelmän luonti epäonnistui. Järjestelmän ID on jo olemassa." sv="Skapningen av systemet misslyckades. System ID finns redan." />, { autoClose: 10_000, });
                } else {
                    toast.error(<Lang en="System creation failed" fi="Uuden järjestelmän luonti epäonnistui" sv="Skapningen av systemet misslyckades" />);
                }
                toggleState(false);
            };
            const payload = {
                customerId,
                systemId: system.id,
                name: system.systemName,
                deviceType: system.deviceType
            };
            Messenger.requestNewSystem({ payload, callback });
        } else {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="System updated" fi="Järjestelmä päivitetty" sv="Systemet updaterades" />);
                    if (isAuthorized({ one: ["super"] }) && getCustomerData)
                        getCustomerData({ customerId });
                    else
                        getCustomerSystems({ customerId, userId });
                } else {
                    toast.error(<Lang en="Failed to update system" fi="Järjestelmän päivitys epäonnistui" sv="Updateringen av systemet misslyckades" />);
                }
                toggleState(false);
            };
            Messenger.requestUpdateSystem({ systemId: system.id, systemName: system.systemName, callback });
        }
        toggleState(true);
        this.handleModalClose();
    };

    deleteSystem = () => {
        const { removeSystemData, customerId, toggleState } = this.props;
        const { system } = this.state;
        if (system && system.id !== undefined) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="System deleted" fi="Järjestelmä poistettu" sv="Systemet raderades" />);
                    removeSystemData({ customerId, systemId: system.id });
                } else {
                    toast.error(<Lang en="Failed to remove system" fi="Järjestelmän poisto epäonnistui" sv="Raderingen av systemet misslyckades" />);
                }
                toggleState(false);
            };
            Messenger.requestDeleteSystem({ systemId: system.id, callback });
        }
        toggleState(true);
        this.handleModalClose();
    };

    regenToken = () => {
        const { system } = this.state;
        if (system && system.id !== undefined) {
            const callback = (data) => {
                if (data.status === "success") {
                    toast.success(<Lang en="System token regenerated" fi="Järjestelmän todennin uusittu" sv="System token förnyades" />);
                    this.setState({ tokenView: true, regenView: false, systemToken: data.systemToken });
                } else {
                    toast.error(<Lang en="Failed to regenerate system token" fi="Järjestelmän todentimen uusiminen epäonnistui" sv="Förnyadet av systemets token misslyckades" />);
                    this.handleModalClose();
                }
            };
            Messenger.requestRegenerateSystemToken({ systemId: system.id, callback });
        } else {
            this.handleModalClose();
        }
    };

    setDeleteViewState = (bool) => {
        if (bool === undefined)
            {this.setState({ deleteView: false });}
        else
            {this.setState({ deleteView: bool });}
    };

    setRegenViewState = (bool) => {
        if (bool === undefined)
            {this.setState({ regenView: false });}
        else
            {this.setState({ regenView: bool });}
    };

    render() {
        const { show, newSystem } = this.props;
        const { system, deleteView, regenView, tokenView, systemToken } = this.state;
        if (system !== undefined) {
            return (
                <Modal
                    className="customerEditModal"
                    show={show}
                    onHide={this.handleModalClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {getEditorModalTitle({
                                newView: newSystem,
                                deleteView,
                                regenView,
                                tokenView
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditingOptions
                            newSystem={newSystem}
                            handleSystemTextChange={this.handleSystemTextChange}
                            handleSystemDeviceTypeChange={this.handleSystemDeviceTypeChange}
                            handleSystemIdGeneration={this.handleSystemIdGeneration}
                            system={system}
                            visible={!deleteView && !tokenView && !regenView}
                        />
                        <DeleteView
                            visible={deleteView}
                            name={system.systemName}
                        />
                        <RegenerationView
                            visible={regenView}
                            name={system.systemName}
                        />
                        <TokenView
                            visible={tokenView}
                            name={system.systemName}
                            token={systemToken}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {getFooterButtons({
                            newView: newSystem,
                            deleteView,
                            setDeleteViewState: this.setDeleteViewState,
                            submit: this.submit,
                            closeModal: this.handleModalClose,
                            deleteFunc: this.deleteSystem,
                            valid: !!(system.id && system.systemName && (!newSystem || system.deviceType)),
                            regenView,
                            tokenView,
                            setRegenViewState: this.setRegenViewState,
                            regenFunc: this.regenToken,
                            disableDelete: !(isAuthorized({ one: ["super"] })),
                            disableRegen: !(isAuthorized({ one: ["super"] }))
                        })}
                    </Modal.Footer>
                </Modal>
            );
        }
        return null;
    }
}
