import "../../css/SetPassword.css";
import "../../css/PasswordCheck.css";

import queryString from "query-string";
import { Component } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SITE_VERSION from "../../config/version";
import Messenger from "../../utilities/messenger";
import Check from "../Check";
import PasswordField from "../GenericMenuComponents/PasswordField";
import Lang from "../LanguageSelect/Lang";
import LangDropdown from "./LangDropdown";

export const LANGUAGES = ["fi", "en", "sv"];

const getMail = (email) => {
	if (email !== undefined) {
		return (
			<div className="uname">{email}</div>
		);
	}
	return null;
};

const checkPassValidity = (pass, veri) => pass.length >= 6 && pass === veri;

// Mapping to and from redux settings_ props
const SETPASS_MAP = {
	password: "setPasswordPassword",
	passwordVeri: "setPasswordPasswordVeri",
	status: "setPasswordStatus",
	time: "setPasswordTime",
};

const remapToSetPassView = (obj) => {
	const keys = Object.keys(obj);
	const output = {};
	keys.forEach((key) => {
		if (SETPASS_MAP[key])
			output[SETPASS_MAP[key]] = obj[key];
		else
			output[key] = obj[key];
	});
	return output;
};

class SetPasswordWithotRouter extends Component {
	componentDidMount() {
		const { location, lang, setValues } = this.props;
		const queryParams = queryString.parse(location.search);
		if (queryParams.lang && queryParams.lang !== lang) {
			setValues({ lang: queryParams.lang });
		}
		if (queryParams.resetPassword) {
			this.resetPassword();
		}
	}

	getMSG(status, verification) {
		const { lang, time } = this.props;
		switch (status) {
			case "fail": {
				return (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">Passwords do not match</span>
							<span key="fi">Salasanat eivät täsmää</span>
							<span key="sv">Lösenorden är inte lika</span>
						</Lang>
					</div>
				);
			}

			case "invalidToken": {
				return (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">Session expired</span>
							<span key="fi">Istunto vanhentunut</span>
							<span key="sv">Sessionen löpte</span>
						</Lang>
					</div>
				)
			}

			case "success": {
				return verification === "1" ? (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">User activated successfully.<br />Redirecting to login in {time} seconds...</span>
							<span key="fi">Käyttäjän aktivointi onnistui.<br />Ohjataan kirjatumiseen {time} sekunnin kuluttua...</span>
							<span key="sv">Aktiveringen av användare lyckades.<br />Leder till inloggining efter {time} sekunder...</span>
						</Lang>
					</div>
				) : (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">Password reseted successfully.<br />Redirecting to login in {time} seconds...</span>
							<span key="fi">Salasana palautus onnistui.<br />Ohjataan kirjatumiseen {time} sekunnin kuluttua...</span>
							<span key="sv">Återställning av lösenord framgångsrikt.<br />Leder till inloggining efter {time} sekunder...</span>
						</Lang>
					</div>
				);
			}

			case "reset": {
				return (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">Password resetting</span>
							<span key="fi">Salasana resetoidaan</span>
							<span key="sv">Lösenordet återställs</span>
						</Lang>
						<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
					</div>
				);
			}

			default: {
				return verification === "1" ? (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">User activation</span>
							<span key="fi">Käyttäjän aktivointi</span>
							<span key="sv">Aktivering av användare</span>
						</Lang>
					</div>
				) : (
					<div className="msg">
						<Lang lang={lang}>
							<span key="en">Password reset</span>
							<span key="fi">Salasanan palautus</span>
							<span key="sv">Återställning av lösenord</span>
						</Lang>
					</div>
				);
			}
		}
	}

	resetDone = () => {
		const { location, navigate } = this.props;
		const queryParams = location.search.split("&resetPassword", 1);
		navigate(location.pathname + queryParams);
	};

	resetPassword = () => {
		const { location, history } = this.props;
		const queryParams = queryString.parse(location.search);
		const callback = (data) => {
			console.log(data)
			if (data && data.status === "success") {
				setTimeout(this.resetDone, 3000);
			} else if (data.msg) {
				toast.error(data.msg);
				setTimeout(history.push("/setPassword"), 5000);
			} else {
				toast.error(<Lang en="Password could not be reset" fi="Salasanaa ei voitu resetoida" sv="Lösenordet kunde inte återställas" />);
				setTimeout(history.push("/setPassword"), 5000);
			}
		};
		Messenger.requestResetPassword({ userName: queryParams.email, email: false, token: queryParams.token, callback });
	};

	updateData = (obj) => {
		const { setValues } = this.props;
		setValues(remapToSetPassView(obj));
	};

	submit = (e, token) => {
		const { password, passwordVeri, setValues, lang } = this.props;
		if (e)
			e.preventDefault();
		if (password === passwordVeri && token !== undefined) {
			console.log(password, passwordVeri)
			const callback = (data) => {
				console.log(data)
				if (data && data.status === "success") {
					setValues({ setPasswordStatus: "success", setPasswordPassword: "", setPasswordPasswordVeri: "", setPasswordTime: 5 });
					setTimeout(this.countDown, 1000);
				} else {
					setValues({ setPasswordStatus: "invalidToken", setPasswordPassword: "", setPasswordPasswordVeri: "" });
				}
			};
			Messenger.requestSetPassword({ password, language: lang, token, callback });
		} else {
			setValues({ setPasswordStatus: "fail", setPasswordPassword: "", setPasswordPasswordVeri: "" });
		}
	};

	countDown = () => {
		const { time, setValues, navigate } = this.props;
		if (time > 0) {
			setValues({ setPasswordTime: time - 1 });
			setTimeout(this.countDown, 1000);
		} else {
			navigate("/");
		}
	};

	updateLang = ({ lang }) => {
		const { setLang } = this.props;
		if (lang)
			setLang(lang);
	};

	render() {
		const { location, password, passwordVeri, status, lang } = this.props;
		if (location && location.search) {
			const queryParams = queryString.parse(location.search);
			if (queryParams.token) {
				const passValid = checkPassValidity(password, passwordVeri);
				if (queryParams.resetPassword) {
					return (
						<div className={`setPassword ${status}`}>
							<div className="siteVersion">{SITE_VERSION()}</div>
							{this.getMSG("neutral", queryParams.verification)}
							<Form className="formBox">
								{this.getMSG("reset")}
							</Form>
							<LangDropdown
								langs={LANGUAGES}
								lang={lang}
								updateAncestor={this.updateLang}
							/>
						</div>
					);
				}
				return (
					<div className={`setPassword ${status}`}>
						<div className="siteVersion">{SITE_VERSION()}</div>
						{this.getMSG(status, queryParams.verification)}
						{status === "success" ? null :
							<Form className="formBox">
								{getMail(queryParams.email)}
								<Checks
									password={password}
									verification={passwordVeri}
									lang={lang}
								/>
								<PasswordField
									className="password"
									title={
										<Lang lang={lang}>
											<span key="en">Password</span>
											<span key="fi">Salasana</span>
											<span key="sv">Lösenord</span>
										</Lang>
									}
									content={password}
									updateAncestor={this.updateData}
									validation={null}
									propertyName="password"
									realtimeUpdate
									autoComplete="new-password"
								/>
								<PasswordField
									className="password"
									title={
										<Lang lang={lang}>
											<span key="en">Password verification</span>
											<span key="fi">Salasanan vahvistus</span>
											<span key="sv">Bekräfta lösenord</span>
										</Lang>
									}
									content={passwordVeri}
									updateAncestor={this.updateData}
									validation={null}
									propertyName="passwordVeri"
									realtimeUpdate
									autoComplete="new-password"
								/>
								<Button
									type="submit"
									variant={passValid ? "success" : "light"}
									onClick={(e) => this.submit(e, queryParams.token)}
									disabled={!passValid}
								>
									<Lang lang={lang}>
										<span key="en">OK</span>
										<span key="fi">OK</span>
										<span key="sv">OK</span>
									</Lang>
								</Button>
							</Form>
						}
						<LangDropdown
							langs={LANGUAGES}
							lang={lang}
							updateAncestor={this.updateLang}
						/>
					</div>
				);
			}
		}
		return (
			<div className="setPassword invalid">
				<div className="siteVersion">{SITE_VERSION()}</div>
				<div className="msg">
					<Lang en="URL data is corrupt." fi="Linkin tiedot ovat virheellisiä." sv="Länkens information är felaktig." />
				</div>
				<LangDropdown
					langs={LANGUAGES}
					lang={lang}
					updateAncestor={this.updateLang}
				/>
			</div>
		);
	}
}

function SetPasswordFunction(props) {
	const navigate = useNavigate();
	const location = useLocation();
	return <SetPasswordWithotRouter {...props} navigate={navigate} location={location} />;
}

function mapStateToProps(state) {
	const { lang,
		setPasswordPassword,
		setPasswordPasswordVeri,
		setPasswordStatus,
		setPasswordTime,
	} = state;
	return {
		lang,
		password: setPasswordPassword,
		passwordVeri: setPasswordPasswordVeri,
		status: setPasswordStatus,
		time: setPasswordTime,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
	setLang: (val) => dispatch({ type: "SET_LANG", val }),
});

export const SetPassword = connect(mapStateToProps, mapDispatchToProps)(SetPasswordFunction);

function Checks({ password, lang, verification }) {
	if (password.length > 0) {
		return (
			<div className="passCheck">
				<Check className={password.length >= 6 ? "valid" : "invalid"}>
					<Lang lang={lang}>
						<span className="title" key="en">Atleast 6 characters.</span>
						<span className="title" key="fi">Vähintään 6 merkkiä.</span>
						<span className="title" key="sv">Minst 6 tecken.</span>
					</Lang>
				</Check>
				<Check className={password === verification ? "valid" : "invalid"}>
					<Lang lang={lang}>
						<span className="title" key="en">Passwords match.</span>
						<span className="title" key="fi">Salasanat täsmäävät.</span>
						<span className="title" key="sv">Lösenorden är lika.</span>
					</Lang>
				</Check>
			</div>
		);
	}
	return (
		<div className="passCheck">
			<Check className="neutral">
				<Lang lang={lang}>
					<span className="title" key="en">Atleast 6 characters.</span>
					<span className="title" key="fi">Vähintään 6 merkkiä.</span>
					<span className="title" key="sv">Minst 6 tecken.</span>
				</Lang>
			</Check>
			<Check className="neutral">
				<Lang lang={lang}>
					<span className="title" key="en">Passwords match.</span>
					<span className="title" key="fi">Salasanat täsmäävät.</span>
					<span className="title" key="sv">Lösenorden är lika.</span>
				</Lang>
			</Check>
		</div>
	);
}