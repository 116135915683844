import "./TimeZoneSelect.css";

import { Dropdown, DropdownButton } from "react-bootstrap";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";

import Lang from "../../LanguageSelect/Lang";

export const TITLES = {
	"EET-2EEST,M3.5.0/3,M10.5.0/4": {
		en: "Finland - Daylight Saving Time",
		fi: "Suomi – kesäaika käytössä",
		sv: "Finland - sommartid",
	},
};

const OPTIONS = [
	{
		id: "EET-2EEST,M3.5.0/3,M10.5.0/4",
		title: TITLES["EET-2EEST,M3.5.0/3,M10.5.0/4"],
	},
];

const getTitle = (titles, setting) => {
	if (setting !== undefined && setting.id !== undefined && titles[setting.id] !== undefined)
		return titles[setting.id];
	return ({ en: "Select...", fi: "Valitse...", sv: "Välj..." });
};

// eslint-disable-next-line unicorn/no-array-reduce
const getItems = (options, selected) => options.reduce((res, option) => {
	res.push(
		<Dropdown.Item
			key={`system_config_timezone_${option.id}`}
			eventKey={option}
			active={selected && selected.id === option.id}
		>
			{typeof option.title === "object" ? <Lang {...option.title} /> : <Lang en="Error" fi="Error" sv="Error" />}
		</Dropdown.Item>
	);
	return res;
}, []);

const handleSelect = (selection, setValues) => {
	setValues({ systemsConfigTimeZone: selection });
};

function TimeZoneSelect({ editAuth, systemsConfigTimeZone, setValues }) {
	return (
		<span className={`setting timezone-selector dropdownFlexer scrollable-dropdown separated-alignment-parent${isDesktop ? "" : " mobile-timezone-selector"}`}>
			<span className="title separated-alignment-left">
				<Lang en="Timezone" fi="Aikavyöhyke" sv="Tidszon" />
			</span>
			<span className="separated-alignment-right">
				<DropdownButton
					disabled={!editAuth}
					variant="default"
					title={<span className="title"><Lang {...getTitle(TITLES, systemsConfigTimeZone)} /></span>}
					id="system-function-dropdown"
					onSelect={(selection) => handleSelect(selection, setValues)}
				>
					{getItems(OPTIONS, systemsConfigTimeZone)}
				</DropdownButton >
			</span>
		</span>
	);
}

function mapStateToProps(state) {
	const { systemsConfigTimeZone } = state;
	return { systemsConfigTimeZone };
}

const mapDispatchToProps = (dispatch) => ({
	setValues: (obj) => dispatch({ type: "SET_VALUES", obj }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeZoneSelect);